import { httpClient } from '../../../../net/httpClient';
import UrlConfig from '../../../../net/UrlConfig';

import {
    OPEN_HISTORY_UPLOAD_DIALOG,
    RESET_HISTORY_UPLOAD_DIALOG,
    START_QUERY_HISTORY_UPLOAD,
    QUERY_HISTORY_UPLOAD_SUCESS,
    QUERY_HISTORY_UPLOAD_FAILURE,
    INIT_QUERY_HISTORY_UPLOAD_STATUS,
} from './HistoryUploadConstants';

function openHistoryUploadDialog(params) {
    return {
        type: OPEN_HISTORY_UPLOAD_DIALOG,
        payload: params,
    };
}
function resetHistoryUploadDialog() {
    return {
        type: RESET_HISTORY_UPLOAD_DIALOG,
    }
}


function startQueryHistoryUpload() {
    return {
        type: START_QUERY_HISTORY_UPLOAD,
    }
}
function openHistoryUploadDialogAndQuery(queryFields, baseDownloadUrl) {
    const params = {
        queryFields,
        baseDownloadUrl,
    }
    return function(dispatch) {
        dispatch(openHistoryUploadDialog(params));
        dispatch(startQueryHistoryUpload());
        httpClient
          .get(UrlConfig.QUERY_HISTORY_UPLOAD, queryFields)
          .then(resp => dispatch(queryHistoryUploadSuccess(resp)))
          .catch(err => dispatch(queryHistoryUploadFailure(err)));
    };
}
function queryHistoryUploadSuccess(resp) {
    return {
        type: QUERY_HISTORY_UPLOAD_SUCESS,
        payload: resp,
    }
}
function queryHistoryUploadFailure(err) {
    return {
        type: QUERY_HISTORY_UPLOAD_FAILURE,
        payload: err,
    }
}
function initQueryHistoryUploadStatus() {
    return {
        type: INIT_QUERY_HISTORY_UPLOAD_STATUS,
    }
}


// export
export function doHistoryUploadAction(params) {
    const {action, queryFields, baseDownloadUrl } = params;
    if(action === 'OPEN_AND_QUERY') {
        const {module, dataId} = queryFields;
        return openHistoryUploadDialogAndQuery({module, dataId}, baseDownloadUrl);
    } else if(action === 'INIT_QUERY') {
        return initQueryHistoryUploadStatus();
    } else if(action === 'RESET_QUERY') {
        return resetHistoryUploadDialog();
    } 
}
