import React, { Component } from 'react';
import { AgGridReact } from "ag-grid-react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';


const validAgreementStatus = ['New', 'Valid', 'Rejected', 'Submitted', 'RequestExpired'];

class MeetingAndTrainingGrid extends Component {
    constructor(props) {
        super(props);
        const that = this;
        this.state = {
            
            columnDefs: [
                {
                    field: 'trainingOrRotation',
                    headerName: "Type*",
                    cellClass: 'non-number',
                    width: 150,
                    // cellRenderer: 'agGroupCellRenderer',
                    enableRowGroup: true,
                    rowGroup: true,
                    hide: true,
                    
                   
                },


                {
                    field: 'title',
                    headerName: "Title*",
                    cellClass: 'non-number',
                    width: 300,
                    cellRenderer: 'agGroupCellRenderer',
                   
                },
                {
                    field: 'department',
                    headerName: "Department*",
                    cellClass: 'non-number',
                    width: 200,
                   
                },

                {field: 'comperename', headerName: 'Coach', cellClass: 'non-number', width: 100},
                {
                    field: 'totlescore',
                    headerName: "Credit",
                    cellClass: 'non-number',
                    width: 100,
                },
                {
                  field: 'progress',
                  headerName: "Participation Rate",
                  cellClass: 'non-number',
                  width: 200,
                },
                {
                    field: 'score',
                    headerName: "Score",
                    cellClass: 'non-number',
                    width: 100,
                },
                {
                  field: 'required',
                  headerName: "Mandatory or Optional",
                  cellClass: 'non-number',
                  width: 200,
                },
                {
                    field: 'starttime',
                    headerName: "Start Time",
                    cellClass: 'non-number',
                    width: 150,
                },
                {
                    field: 'endtime',
                    headerName: "End Time",
                    cellClass: 'non-number',
                    width: 150,
                },
                {field: 'speaker', headerName: 'Speaker', cellClass: 'non-number', width: 100},
                {field: 'desc', headerName: 'Desc', cellClass: 'non-number', width: 200},
            ],

            rowSelection: "multiple",
            context: { componentParent: this },

            rowClassRules: (params) =>{
                if (params.node.group) {
                    return 'my-group-class'; // 自定义分组行的CSS类
                  } else {
                    return 'background-color: #FF8888 !important;'; // 自定义数据行的CSS类
                    // 'MarkBackgroundRed': (params) => {
                    // return params.data.category === 'Mandatory' && params.data.signin === '-';
                    // },
                  }
                // 'MarkBackgroundRed': function(params) {
                //     return params.data.category === 'Mandatory' && params.data.signin === '-';
                // },
                // 'MarkBackgroundYellow': function(params) {
                //     return params.data.category === 'Optional' && params.data.signin === '-';
                // },
            },
            groupDefaultExpanded: 1,
            rowGroupPanelShow: 'always',
            pivotPanelShow: 'always',
            sideBar: {
              toolPanels: ['columns', 'filters']
            },
            statusBar: {
            },
            detailCellRendererParams: {
                detailGridOptions: {
                  columnDefs:[                                                                                                   
                    {field: 'id', headerName: 'id', cellClass: 'non-number', width: 120 , cellRenderer: 'agGroupCellRenderer', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
                    {field: 'starttime', 
                    headerName: 'Training Date', 
                    cellClass: 'non-number',
                     width: 150,
                     cellRenderer: (params) => {
                        return params.data.starttime.substring(0, 10);;
                    },
                    },

                    {
                          field: 'signin',
                          headerName: "Sign in*",
                          cellClass: 'non-number',
                          width: 150,
                          cellStyle: this.dataStatusColorCellStyle
                    },
                    {headerName: 'Remark Detial',
                        children: [

                            {
                                field: 'subtitle',
                                headerName: "Sub Title",
                                cellClass: 'non-number',
                                width: 150,
                            },
                            {
                                field: 'note',
                                headerName: "What did you learn",
                                cellClass: 'non-number',
                                width: 300,
                            },
                            {
                                field: 'comments',
                                headerName: "Coach's comments",
                                cellClass: 'non-number',
                                width: 300,
                            },
                            {
                                field: 'filepath',
                                headerName: "Attachment",
                                cellClass: 'non-number',
                                width: 300,
                                cellRenderer: this.downloadMeetingAndTrainingCellRenderer
                            },
                        ]
                    },
                    {field: 'lastmodifieddate', headerName: 'LastModifiedDate', cellClass: 'non-number', width: 120},
                    {headerName: 'action', cellClass: 'non-number', width: 200, cellRenderer: (node) => this.finishTaskOpenModalBtn(node, props)},
                
                     
                  ],
                  suppressRowClickSelection: true,
                  suppressAggFuncInHeader: true,
                  enableColResize: true,
                  enableSorting: true,
                  enableFilter: true,
                  animateRows: true,
                  deltaRowDataMode: false,
                  onSelectionChanged(event) {
                      let rowNodeArray = event.api.getSelectedNodes();
                      let cptRcdDataArray = rowNodeArray.map(rowNode => rowNode.data);
                      that.props.selectMeetingAndTraining(cptRcdDataArray);
                  },
        
        
              },
              getDetailRowData: function(params) {
                  params.successCallback(params.data.detialList);
              },
              getRowNodeId: function(data) {
                  return data.id;
              },

              template:
              '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
              '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">  Detail</div>' +
              '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
              '</div>'
            },
        };
    }

    meetingAndTrainingDownloadUrl = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_DOWNLOAD_URL
    downloadMeetingAndTrainingCellRenderer = (node) => {
        const attachement = node.data.filepath;
        if(!attachement) {
            return '';
        }

        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        attArray.forEach(e => {
            if(!first) {
                retElement.append(' | ');
            }
            first = false;

            const url = this.props.meetingAndTrainingDownloadUrl + '/' + e;
            const link = document.createElement('a');
            link.innerText = e;
            link.target = '_blank';
            link.href = url;
            retElement.appendChild(link);

        });
        return retElement;
    }
    downloadMeetingAndTrainingCellaaRenderer = (node) => {
        const attachement = node.data.trainingData;
        if(!attachement) {
            return '';
        }

        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        attArray.forEach(e => {
            if(!first) {
                retElement.append(' | ');
            }
            first = false;

            const url = this.props.meetingAndTrainingDownloadUrl + '/' + e;
            const link = document.createElement('a');
            link.innerText = e;
            link.target = '_blank';
            link.href = url;
            retElement.appendChild(link);

        });
        return retElement;

    }

    noticePeriodCellRenderer = (node) => {
        return node.data.noticePeriod + ' days';
    }


    // dataStatusColorCellStyle(param) {
    //     const status = param.value;
    //     if(status === 'Y') {
    //         return {color: 'green'};
    //     }
    //     if(status === '-') {
    //         return {color: 'red'};
    //     }
    //     return {color: 'black'};
    // }


    getRowNodeId = data => {
        return data.id;
    };

    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.gridApi.closeToolPanel();
      };



    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let meetingAndTrainingArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectMeetingAndTraining(meetingAndTrainingArray);
    }
    finishTaskOpenModalBtn = (node, props) => {
        const retDiv = document.createElement('div');
        const data = node.data;
      
        if(data.remark ==='true'){
            const finishTaskBtn = document.createElement('button');
            const finishTaskText = document.createTextNode("Remark");
            finishTaskBtn.appendChild(finishTaskText);
            finishTaskBtn.onclick = () => {
                this.addOrEditMeetingAndTrainingBtnOnClicked('Remark',data)
            }
            retDiv.appendChild(finishTaskBtn);
        }
        if(data.feedback ==='true'){
            const finishTaskBtnF = document.createElement('button');
            const finishTaskTextF = document.createTextNode("Feedback");
            finishTaskBtnF.appendChild(finishTaskTextF);
            finishTaskBtnF.onclick = () => {

                this.addOrEditMeetingAndTrainingBtnOnClicked('Feedback',data)
            }
            retDiv.appendChild(finishTaskBtnF);
        }

        
        return retDiv;
    }
    addOrEditMeetingAndTrainingBtnOnClicked = (mode,data) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditMeetingAndTrainingModal(mode, null);
        } else {  // COPY, EDIT
            // const {
            //     selectedMeetingAndTrainingArray,
            // } = this.props;
            // if(selectedMeetingAndTrainingArray.length !== 1) {
            //     message.warning("Please choose one and only one General Agreement");
            //     return;
            // }
            // const currentData = selectedMeetingAndTrainingArray[0];

            // if(mode === 'EDIT') {
            //     if(currentData.state === 'New' ) {
            //         message.warning("Please sign in first and feedback later");
            //         return;
            //     }
            // }
            this.props.openAddOrEditMeetingAndTrainingModal(mode, data);
        }
    }
    dataStatusColorCellStyle(param) {
        const status = param.value;
        // if(status === 'Y') {
        //     // return {color: 'green'};
        //     return { background: '#11e91c '};
        // }
        if(status === '-'){
            return {background: '#f80b0b'};
        }
        
    }
    dataColorCellStyle(param) {
        const status = param.value;
        if(status === 'true') {
            // return {color: 'green'};
            return { background: '#fcf800 '};
        }
        
        
    }
    render () {
        const {
            loadMeetingAndTrainingActionStatus,
            loadMeetingAndTrainingResp,
            onlyShowValidMeetingAndTraining,
        } = this.props;

        const isLoading = loadMeetingAndTrainingActionStatus === ACTION_STATUS.LOGINING;
        let loadMeetingAndTrainingArray = [];
        if(!!loadMeetingAndTrainingResp && !!loadMeetingAndTrainingResp.data.list) {
            loadMeetingAndTrainingArray = loadMeetingAndTrainingResp.data.list;
        }
        // if(onlyShowValidMeetingAndTraining) {
        //     loadMeetingAndTrainingArray = loadMeetingAndTrainingArray.filter(a => validAgreementStatus.filter(s => s === a.status).length > 0);
        // }

        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            // columnDefs={this.state.columnDefs}
                            // rowData={loadMeetingAndTrainingArray}
                            // defaultColDef={this.state.defaultColDef}
                            // rowSelection={this.state.rowSelection}
                            rowClassRules={this.state.rowClassRules}
                            // groupDefaultExpanded={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            statusBar={this.state.statusBar}
                            masterDetail={true}
                            // sideBar={true}
                            resizable={true}
                 
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            onSelectionChanged={this.onDataSelectionChanged}


                            columnDefs={this.state.columnDefs}
                            defaultColDef={this.state.defaultColDef}
                            rowData={loadMeetingAndTrainingArray}
                            autoGroupColumnDef={this.state.autoGroupColumnDef}
                            rowGroupPanelShow={this.state.rowGroupPanelShow}
                            groupDefaultExpanded={this.state.groupDefaultExpanded}
                            pivotPanelShow={this.state.pivotPanelShow}
                            rowSelection="single"
                            context={this.state.context}
                            sideBar={this.state.sideBar}
                            onGridReady={this.onGridReady}
                            onSelectionChanged={this.onSelectionChanged}
                        />
                )}
            </div>
        );
    }
}


export default MeetingAndTrainingGrid;
