import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Card, Form, Icon, Input, Button, message } from 'antd';

import { localStorageClient } from '../../../../utils/localStorageClient';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

import './Login.css';

const FormItem = Form.Item;

class Login extends Component {
  state = {

  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.login(values);
      }
    });
  }

  componentDidUpdate() {
    let status = this.props.loginStatus;
    const {
      loginErrMsg
    } = this.props;

    if (status === ACTION_STATUS.ERROR) {
      message.error(loginErrMsg || '系统异常');
      this.props.resetLoginStatus();
    }  
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let status = this.props.loginStatus;

    const isLoading = status === ACTION_STATUS.LOGINING;
    if (status === ACTION_STATUS.SUCCESS && !localStorageClient.isLoggedIn()) {
      this.props.resetLoginStatus();
      this.props.logout();
      status = ACTION_STATUS.ERROR;
    }

    if (status === ACTION_STATUS.SUCCESS) {
      return <Redirect to="/" />;
    } else {
      return (
        <div className="loginContainer">
          <Card className="loginCard">
            <Form onSubmit={this.handleSubmit} className="login-form">
              <FormItem>
                {getFieldDecorator('userName', {
                  rules: [{ required: true, message: 'Please input your username!' }],
                })(
                  <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Username" />
                )}
              </FormItem>
              <FormItem>
                {getFieldDecorator('password', {
                  rules: [{ required: true, message: 'Please input your password!' }],
                })(
                  <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type="password" placeholder="Password" />
                )}
              </FormItem>
              <FormItem>
                <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoading}>
                  Log in
                </Button>
              </FormItem>
            </Form>
          </Card>
        </div>
      );
    }
  }
}

const LoginForm = Form.create({ name: 'login' })(Login);

export default LoginForm;