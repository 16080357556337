const APPROVED = 'Approved';
const REJECTED = 'Rejected';
const UNPROCESSED = 'Unprocessed';
const UNKNOWN = 'Unknown';

export default {
  APPROVED,
  REJECTED,
  UNPROCESSED,
  UNKNOWN,
};
