import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';
import { localStorageClient } from '../../../utils/localStorageClient';


import {
    // query
    START_LOAD_COLLEGE_APPLICATION_INFO,
    LOAD_COLLEGE_APPLICATION_INFO_SUCCESS,
    LOAD_COLLEGE_APPLICATION_INFO_FAILURE,
    INIT_LOAD_COLLEGE_APPLICATION_INFO_ACTION_STATUS,
    // select
    SELECT_COLLEGE_APPLICATION,
    SET_GRID_EVENT_ON_STATE,
    // add or edit
    OPEN_ADD_OR_EDIT_COLLEGE_APPLICATION_MODAL,
    RESET_SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL,
    ADD_OR_EDIT_COLLEGE_APPLICATION_CHANGE_INPUT,
    START_SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL,
    SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL_SUCCESS,
    SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL_FAILURE,
    INIT_SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL_ACTION_STATUS,
    // change status
    OPEN_CHANGE_COLLEGE_APPLICATION_STATUS_MODAL,
    RESET_CHANGE_COLLEGE_APPLICATION_STATUS_MODAL,
    CHANGE_COLLEGE_APPLICATION_STATUS_CHANGE_INPUT,
    START_SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS,
    SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS_SUCCESS,
    SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS_FAILURE,
    INIT_SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS_ACTION_STATUS,
    // showAllshowValid
    SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED,


} from './CollegeApplicationConstants';


// query
function startLoadCollegeApplicationInfo() {
    return {
      type: START_LOAD_COLLEGE_APPLICATION_INFO,
    };
}
export function loadCollegeApplicationInfo(params) {
    const employee = localStorageClient.getUserName();
    const queryParam = {

        type: params.type,
        starttime: params.starttime,
        endtime: params.endtime,
        speaker: params.speaker,
        applyName: employee,

    };
    return function(dispatch) {
        dispatch(startLoadCollegeApplicationInfo());
        httpClient
          .get(UrlConfig.LOAD_MEETING_AND_TRAINING_APPLYRECORD_INFO, queryParam)
          .then(resp => dispatch(loadCollegeApplicationInfoSuccess(resp)))
          .catch(err => dispatch(loadCollegeApplicationInfoFailure(err)));
    };
}
function loadCollegeApplicationInfoSuccess(resp) {
    return {
      type: LOAD_COLLEGE_APPLICATION_INFO_SUCCESS,
      payload: resp,
    };
}
function loadCollegeApplicationInfoFailure(err) {
    return {
        type: LOAD_COLLEGE_APPLICATION_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadCollegeApplicationInfoActionStatus() {
    return {
      type: INIT_LOAD_COLLEGE_APPLICATION_INFO_ACTION_STATUS,
    };
}


// select
export function selectCollegeApplication(array) {
    return {
        type: SELECT_COLLEGE_APPLICATION,
        payload: array,
    };
}
export function setGridEventOnState(event) {
    return {
        type: SET_GRID_EVENT_ON_STATE,
        payload: event,
    }; 
}


// add or edit
export function openAddOrEditCollegeApplicationModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_COLLEGE_APPLICATION_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function resetSubmitSaveCollegeApplicationModal() {
    return {
        type: RESET_SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL,
    };
}
export function addOrEditGeneralAgreemenChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_COLLEGE_APPLICATION_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startSubmitSaveCollegeApplicationModal() {
    return {
      type: START_SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL,
    };
}
export function submitSaveCollegeApplicationModal(mode, bean) {
    let url = UrlConfig.UPDATE_COLLEGE_APPLICATION_INFO;  // ADD, COPY
    if(mode === 'Feedback') {
        url = UrlConfig.UPDATE_FEEDBACKCOLLEGE_APPLICATION_INFO;
    }
    return function(dispatch) {
        dispatch(startSubmitSaveCollegeApplicationModal());
        httpClient
          .post(url, bean)
          .then(resp => dispatch(submitSaveCollegeApplicationModalSuccess(resp)))
          .catch(err => dispatch(submitSaveCollegeApplicationModalFailure(err)));
    };
}
function submitSaveCollegeApplicationModalSuccess(resp) {
    return {
      type: SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL_SUCCESS,
      payload: resp,
    };
}
function submitSaveCollegeApplicationModalFailure(err) {
    return {
        type: SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL_FAILURE,
        payload: err,
    };
}
export function initSubmitSaveCollegeApplicationModalActionStatus() {
    return {
      type: INIT_SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL_ACTION_STATUS,
    };
}


// change status
export function openChangeCollegeApplicationStatusModal(mode, idList) {
    return {
        type: OPEN_CHANGE_COLLEGE_APPLICATION_STATUS_MODAL,
        payload: {
            mode,
            idList,
        },
    };
}
export function resetChangeCollegeApplicationStatusModal() {
    return {
        type: RESET_CHANGE_COLLEGE_APPLICATION_STATUS_MODAL,
    };
}
export function changeGeneralAgreemenStatusChangeInput({name, value}) {
    return {
        type: CHANGE_COLLEGE_APPLICATION_STATUS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startSubmitChangeCollegeApplicationStatusModal() {
    return {
      type: START_SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS,
    };
}
export function submitChangeCollegeApplicationStatusModal(bean) {
    if(bean.modes === 'Sign In' ){
        return function(dispatch) {
            dispatch(startSubmitChangeCollegeApplicationStatusModal());
            httpClient
              .post(UrlConfig.SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS, bean)
              .then(resp => dispatch(submitChangeCollegeApplicationStatusModalSuccess(resp)))
              .catch(err => dispatch(submitChangeCollegeApplicationStatusModalFailure(err)));
        };
    }else{
        return function(dispatch) {
            dispatch(startSubmitChangeCollegeApplicationStatusModal());
            httpClient
              .post(UrlConfig.APPLY_CHANGE_COLLEGE_APPLICATION_STATUS, bean)
              .then(resp => dispatch(submitChangeCollegeApplicationStatusModalSuccess(resp)))
              .catch(err => dispatch(submitChangeCollegeApplicationStatusModalFailure(err)));
        };
    }
}
function submitChangeCollegeApplicationStatusModalSuccess(resp) {
    return {
      type: SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS_SUCCESS,
      payload: resp,
    };
}
function submitChangeCollegeApplicationStatusModalFailure(err) {
    return {
        type: SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS_FAILURE,
        payload: err,
    };
}
export function initSubmitChangeCollegeApplicationStatusModalActionStatus() {
    return {
      type: INIT_SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS_ACTION_STATUS,
    };
}


// showAllshowValid
export function showAllShowValidBtnOnClicked() {
    return {
        type: SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED,
    }
}
