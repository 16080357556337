import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';


import {
    // select data
    SELECT_PERSONAL_ACCOUNT_LIST,
    SET_GRID_EVENT_ON_STATE,
    // Load Personal Account
    START_LOAD_PERSONAL_ACCOUNT,
    LOAD_PERSONAL_ACCOUNT_SUCCESS,
    LOAD_PERSONAL_ACCOUNT_FAILURE,
    INIT_LOAD_PERSONAL_ACCOUNT_ACTION_STATUS,
    LOAD_ACCOUNT_OPTIONS_FOR_ADD_OR_EDIT_SUCCESS,
    LOAD_ACCOUNT_OPTIONS_FOR_ADD_OR_EDIT_FAILURE,
    // add or edit account
    OPEN_ADD_OR_EDIT_ACCOUNT_DIALOG,
    RESET_ADD_OR_EDIT_ACCOUNT_DIALOG,
    START_SUBMIT_ADD_OR_EDIT_ACCOUNT,
    SUBMIT_ADD_OR_EDIT_ACCOUNT_SUCCESS,
    SUBMIT_ADD_OR_EDIT_ACCOUNT_FAILURE,
    INIT_SUBMIT_ADD_OR_EDIT_ACCOUNT_ACTION_STATUS,
    ON_ACCOUNT_INPUT_CHANGE,
    // submit account
    START_SUBMIT_PERSONAL_ACCOUNT,
    SUBMIT_PERSONAL_ACCOUNT_SUCCESS,
    SUBMIT_PERSONAL_ACCOUNT_FAILURE,
    INIT_SUBMIT_PERSONAL_ACCOUNT_ACTION_STATUS,
    // del account
    START_DELETE_PERSONAL_ACCOUNT,
    DELETE_PERSONAL_ACCOUNT_SUCCESS,
    DELETE_PERSONAL_ACCOUNT_FAILURE,
    INIT_DELETE_PERSONAL_ACCOUNT_ACTION_STATUS,

    // add or edit holding
    OPEN_ADD_OR_EDIT_HOLDING_DIALOG,
    RESET_ADD_OR_EDIT_HOLDING_DIALOG,
    START_SUBMIT_ADD_OR_EDIT_HOLDING,
    SUBMIT_ADD_OR_EDIT_HOLDING_SUCCESS,
    SUBMIT_ADD_OR_EDIT_HOLDING_FAILURE,
    INIT_SUBMIT_ADD_OR_EDIT_HOLDING_ACTION_STATUS,
    // bbgTicker
    START_LOAD_HOLDING_BBG_TICKERS,
    LOAD_HOLDING_BBG_TICKERS_SUCCESS,
    LOAD_HOLDING_BBG_TICKERS_FAILURE,
    INIT_LOAD_HOLDING_BBG_TICKERS_ACTION_STATUS,
    CLEAR_HOLDING_BBG_TICKERS,
    HOLDING_BBG_TICKER_SELECT_CHANGED,
    // del holding
    OPEN_DEL_HOLDING_DIALOG,
    RESET_DEL_HOLDING_DIALOG,
    START_DEL_PERSONAL_HOLDING,
    DEL_PERSONAL_HOLDING_SUCCESS,
    DEL_PERSONAL_HOLDING_FAILURE,
    INIT_DEL_PERSONAL_HOLDING_ACTION_STATUS,
    SELECT_PERSONAL_HOLDING_LIST,
    SET_DETAIL_GRID_EVENT_ON_STATE,
    ON_ADD_OR_EDIT_HOLDING_INPUT_CHANGE,
    ON_DEL_HOLDING_INPUT_CHANGE,

    ON_ACCOUNT_QUERY_INPUT_CHANGE,
} from './AccountHoldingConstants';


// select data
export function selectPersonalAccountList(list) {
    return {
        type: SELECT_PERSONAL_ACCOUNT_LIST,
        payload: list,
    };
}
export function setGridEventOnState(event) {
    return {
        type: SET_GRID_EVENT_ON_STATE,
        payload: event,
    }; 
}


// Load Personal Account
function startLoadPersonalAccount() {
    return {
        type: START_LOAD_PERSONAL_ACCOUNT,
    };
}
export function loadPersonalAccount(params) {
    const reqParams = {
        ...params,
        source: 'Report',
    };
    return function(dispatch) {
        dispatch(startLoadPersonalAccount());
        httpClient
            .get(UrlConfig.QUERY_PERSONAL_ACCOUNT_LIST, reqParams)
            .then(resp => dispatch(loadPersonalAccountSuccess(resp)))
            .catch(err => dispatch(loadPersonalAccountFailure(err)));
    };
}
function loadPersonalAccountSuccess(resp) {
    return {
        type: LOAD_PERSONAL_ACCOUNT_SUCCESS,
        payload: resp,
    };
}
function loadPersonalAccountFailure(err) {
    return {
        type: LOAD_PERSONAL_ACCOUNT_FAILURE,
        payload: err,
    };
}
export function initLoadPersonalAccountActionStatus() {
    return {
        type: INIT_LOAD_PERSONAL_ACCOUNT_ACTION_STATUS,
    };
}

export function loadAccountOptionsForAddOrEdit(employee) {
    const reqParams = {
        employee,
        needHoldingData: 'false',
        source: 'Report',
    };
    return function(dispatch) {
        httpClient
        .get(UrlConfig.QUERY_PERSONAL_ACCOUNT_LIST, reqParams)
        .then(resp => dispatch(loadAccountOptionsForAddOrEditSuccess(resp)))
        .catch(err => dispatch(loadAccountOptionsForAddOrEditFailure(err)));
    };
}
function loadAccountOptionsForAddOrEditSuccess(resp) {
    return {
        type: LOAD_ACCOUNT_OPTIONS_FOR_ADD_OR_EDIT_SUCCESS,
        payload: resp,
    };
}
function loadAccountOptionsForAddOrEditFailure(err) {
    return {
        type: LOAD_ACCOUNT_OPTIONS_FOR_ADD_OR_EDIT_FAILURE,
        payload: err,
    };
}


// add or edit account
export function openAddOrEditAccountDialog(mode, account) {
    return {
        type: OPEN_ADD_OR_EDIT_ACCOUNT_DIALOG,
        payload: {mode, account},
    }
}
export function resetAddOrEditAccountDialog() {
    return {
        type: RESET_ADD_OR_EDIT_ACCOUNT_DIALOG,
    }
}

function startSubmitAddOrEditAccount() {
    return {
        type: START_SUBMIT_ADD_OR_EDIT_ACCOUNT,
    };
}
export function submitAddOrEditAccount(account) {
    return function(dispatch) {
        dispatch(startSubmitAddOrEditAccount());
        httpClient
        .post(UrlConfig.SUBMIT_ADD_OR_EDIT_ACCOUNT, account)
        .then(resp => dispatch(submitAddOrEditAccountSuccess(resp)))
        .catch(err => dispatch(submitAddOrEditAccountFailure(err)));
    };
}
function submitAddOrEditAccountSuccess(resp) {
    return {
        type: SUBMIT_ADD_OR_EDIT_ACCOUNT_SUCCESS,
        payload: resp,
    };
}
export function submitAddOrEditAccountFailure(err) {
    return {
        type: SUBMIT_ADD_OR_EDIT_ACCOUNT_FAILURE,
        payload: err,
    };
}
export function initSubmitAddOrEditAccountActionStatus() {
    return {
        type: INIT_SUBMIT_ADD_OR_EDIT_ACCOUNT_ACTION_STATUS,
    };
}

export function onAccountInputChange(param) {
    return {
        type: ON_ACCOUNT_INPUT_CHANGE,
        payload: param,
    };
}


// submit account
function startSubmitPersonalAccount() {
    return {
        type: START_SUBMIT_PERSONAL_ACCOUNT,
    };
}
export function submitPersonalAccount(account) {
    return function(dispatch) {
        dispatch(startSubmitPersonalAccount());
        httpClient
        .post(UrlConfig.SUBMIT_PERSONAL_ACCOUNT, account)
        .then(resp => dispatch(submitPersonalAccountSuccess(resp)))
        .catch(err => dispatch(submitPersonalAccountFailure(err)));
    };
}
function submitPersonalAccountSuccess(resp) {
    return {
        type: SUBMIT_PERSONAL_ACCOUNT_SUCCESS,
        payload: resp,
    };
}
export function submitPersonalAccountFailure(err) {
    return {
        type: SUBMIT_PERSONAL_ACCOUNT_FAILURE,
        payload: err,
    };
}
export function initSubmitPersonalAccountActionStatus() {
    return {
        type: INIT_SUBMIT_PERSONAL_ACCOUNT_ACTION_STATUS,
    };
}


// delete account
function startDeletePersonalAccount() {
    return {
        type: START_DELETE_PERSONAL_ACCOUNT,
    };
}
export function deletePersonalAccount(account) {
    return function(dispatch) {
        dispatch(startDeletePersonalAccount());
        httpClient
        .post(UrlConfig.DELETE_PERSONAL_ACCOUNT, account)
        .then(resp => dispatch(deletePersonalAccountSuccess(resp)))
        .catch(err => dispatch(deletePersonalAccountFailure(err)));
    };
}
function deletePersonalAccountSuccess(resp) {
    return {
        type: DELETE_PERSONAL_ACCOUNT_SUCCESS,
        payload: resp,
    };
}
export function deletePersonalAccountFailure(err) {
    return {
        type: DELETE_PERSONAL_ACCOUNT_FAILURE,
        payload: err,
    };
}
export function initDeletePersonalAccountActionStatus() {
    return {
        type: INIT_DELETE_PERSONAL_ACCOUNT_ACTION_STATUS,
    };
}

// add or edit holding
export function openAddOrEditHoldingDialog(mode, holding) {
    return {
        type: OPEN_ADD_OR_EDIT_HOLDING_DIALOG,
        payload: {mode, holding},
    }
}
export function resetAddOrEditHoldingDialog() {
    return {
        type: RESET_ADD_OR_EDIT_HOLDING_DIALOG,
    }
}


function startSubmitAddOrEditHolding() {
    return {
        type: START_SUBMIT_ADD_OR_EDIT_HOLDING,
    };
}
export function submitAddOrEditHolding(params) {
    return function(dispatch) {
        dispatch(startSubmitAddOrEditHolding());
        httpClient
        .post(UrlConfig.SUBMIT_ADD_OR_EDIT_HOLDING, params)
        .then(resp => dispatch(submitAddOrEditHoldingSuccess(resp)))
        .catch(err => dispatch(submitAddOrEditHoldingFailure(err)));
    };
}
function submitAddOrEditHoldingSuccess(resp) {
    return {
        type: SUBMIT_ADD_OR_EDIT_HOLDING_SUCCESS,
        payload: resp,
    };
}
function submitAddOrEditHoldingFailure(err) {
    return {
        type: SUBMIT_ADD_OR_EDIT_HOLDING_FAILURE,
        payload: err,
    };
}
export function initSubmitAddOrEditHoldingActionStatus() {
    return {
        type: INIT_SUBMIT_ADD_OR_EDIT_HOLDING_ACTION_STATUS,
    };
}


// bbgTicker
function startLoadHoldingBBGTickers() {
    return {
      type: START_LOAD_HOLDING_BBG_TICKERS,
    };
}
export function loadHoldingBbgTickers(queryText) {
    const requestParams = {
        'query-text': queryText,
        'page': 'accountHolding',
        'source': 'Admin',
    };
    return function(dispatch) {
        dispatch(startLoadHoldingBBGTickers());
        httpClient
            .get(UrlConfig.QUERY_BBG_TICKERS, requestParams)
            .then(resp => dispatch(loadHoldingBbgTickersSuccess(resp)))
            .catch(err => dispatch(loadHoldingBbgTickersFailure(err)));
    };
}
function loadHoldingBbgTickersSuccess(resp) {
    return {
        type: LOAD_HOLDING_BBG_TICKERS_SUCCESS,
        payload: resp
    };
}
function loadHoldingBbgTickersFailure(err) {
    return {
        type: LOAD_HOLDING_BBG_TICKERS_FAILURE,
        payload: err
    };
}
export function initLoadHoldingBbgTickersActionStatus() {
    return {
        type: INIT_LOAD_HOLDING_BBG_TICKERS_ACTION_STATUS,
    };
}
export function clearHoldingBbgTickers() {
    return {
        type: CLEAR_HOLDING_BBG_TICKERS,
    };
}
export function holdingBbgTickerSelectChanged(bbgTicker) {
    return {
      type: HOLDING_BBG_TICKER_SELECT_CHANGED,
      payload: bbgTicker,
    };
}


// del holding
export function openDelHoldingDialog(idList) {
    return {
        type: OPEN_DEL_HOLDING_DIALOG,
        payload: idList,
    }
}
export function resetDelHoldingDialog() {
    return {
        type: RESET_DEL_HOLDING_DIALOG,
    }
}

function startDelPersonalHolding() {
    return {
        type: START_DEL_PERSONAL_HOLDING,
    };
}
export function delPersonalHolding(params) {
    return function(dispatch) {
        dispatch(startDelPersonalHolding());
        httpClient
            .post(UrlConfig.DEL_PERSONAL_HOLDING, params)
            .then(resp => dispatch(delPersonalHoldingSuccess(resp)))
            .catch(err => dispatch(delPersonalHoldingFailure(err)));
    };
}
function delPersonalHoldingSuccess(resp) {
    return {
        type: DEL_PERSONAL_HOLDING_SUCCESS,
        payload: resp,
    };
}
function delPersonalHoldingFailure(err) {
    return {
        type: DEL_PERSONAL_HOLDING_FAILURE,
        payload: err,
    };
}
export function initDelPersonalHoldingActionStatus() {
    return {
        type: INIT_DEL_PERSONAL_HOLDING_ACTION_STATUS,
    };
}

export function selectPersonalHoldingList(list) {
    return {
        type: SELECT_PERSONAL_HOLDING_LIST,
        payload: list,
    };
}
export function setDetailGridEventOnState(event) {
    return {
        type: SET_DETAIL_GRID_EVENT_ON_STATE,
        payload: event,
    }; 
}

export function onAddOrEditHoldingInputChange(param) {
    return {
        type: ON_ADD_OR_EDIT_HOLDING_INPUT_CHANGE,
        payload: param,
    }; 
}
export function onDelHoldingInputChange(param) {
    return {
        type: ON_DEL_HOLDING_INPUT_CHANGE,
        payload: param,
    }; 
}

// change input
export function onQueryInputChange(param) {
    return {
        type: ON_ACCOUNT_QUERY_INPUT_CHANGE,
        payload: param,
    };
}

