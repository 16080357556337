import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { Loader } from 'semantic-ui-react';

import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import StateSynchronizer from '../../../../utils/StateSynchronizer';
import { localStorageClient } from '../../../../utils/localStorageClient';
import RestrictedSecAttachRenderer from '../../../renderer/RestrictedSecAttachRenderer';
import './RestrictedSecurityRequest.css';

class RestrictedSecurityRequestGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.restrictedSecurityGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },
      context: { componentParent: this },
      frameworkComponents: {
        restrictedSecAttachRenderer: RestrictedSecAttachRenderer,
      },
      rowSelection: "multiple",
    };
  }

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var restrictedSecurityRequestArray = rowNodeArray.map(rowNode => rowNode.data);

    this.props.selectRestrictedSecurityRequest(restrictedSecurityRequestArray);
  }

  getRowNodeId = data => {
    return data.guid;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    this.gridApi.closeToolPanel();

    // const COLUMNS_KEY = 'restricted-security-request-grid-col-state';

    // StateSynchronizer.syncGrid(
    //   params,
    //   this.state.columnDefs,
    //   () => localStorageClient.getGridColumnState(COLUMNS_KEY),
    //   s => localStorageClient.saveGridColumnState(COLUMNS_KEY, s)
    // );
  };

  render() {
    const {
      restrictedSecurityRequestArray,
      className = 'grid-wrapper',
      loadRestrictedSecurityRequestActionStatus
    } = this.props;

    const isLoading = loadRestrictedSecurityRequestActionStatus === ACTION_STATUS.LOGINING;
    if (loadRestrictedSecurityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadRestrictedSecurityRequestActionStatus();
    }

    return (
      <div className={`ag-theme-balham ${className}`}>
        {isLoading ? (
            <Loader active content="Loading..." />
          ) : (
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={restrictedSecurityRequestArray}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            rowClassRules={this.state.rowClassRules}
            enableSorting={true}
            enableFilter={true}
            animateRows={true}
            deltaRowDataMode={false}
            enableColResize={true}
            resizable={true}
            sideBar={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        )}
      </div>
    );
  }
}

export default RestrictedSecurityRequestGrid;