import React, {Component, useEffect} from 'react';

import { Button, message, Modal } from 'antd';

import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { localStorageClient } from '../../../../utils/localStorageClient';
import RestrictedSecurityOperateMode from '../../../../utils/RestrictedSecurityOperateMode';
import RestrictedSecurityRequestGrid from './RestrictedSecurityRequestGrid';
import RestrictedSecurityRequestEditDialog from './RestrictedSecurityRequestEditDialog';
import RestrictedSecurityOperateDialog from './RestrictedSecurityOperateDialog';
import RestrictedSecurityCheckDialog from './RestrictedSecurityCheckDialog';
import RestrictedSecurityRequestStatus from '../../../../utils/RestrictedSecurityRequestStatus';
import RestrictedSecurityApprovalStatus from '../../../../utils/RestrictedSecurityApprovalStatus';
import HistoryUploadDialog from '../../common/historyUpload/components/HistoryUploadDialog';

import './RestrictedSecurityRequest.css';

const confirm = Modal.confirm;

class RestrictedSecurityRequestDashboard extends Component {

  componentDidMount() {
    this.props.loadRestrictedSecurityRequest();
  }

  componentDidUpdate() {
    const {
      batchDeleteRestrictedSecurityRequestsActionStatus,
      submitRestrictedSecurityRequestActionStatus,
    } = this.props;

    // Restricted Security 批量删除成功
    if (batchDeleteRestrictedSecurityRequestsActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted security application are deleted successfully!');
      this.props.loadRestrictedSecurityRequest();
      this.props.initBatchDeleteRestrictedSecurityRequestActionStatus();
    }

    // Restricted Security 批量删除失败
    if (batchDeleteRestrictedSecurityRequestsActionStatus === ACTION_STATUS.ERROR) {
      message.error('Restricted security application are deleted failed!');
      this.props.initBatchDeleteRestrictedSecurityRequestActionStatus();
    }

    // Restricted Security 提交成功
    if (submitRestrictedSecurityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted security application are submitted successfully!');
      this.props.loadRestrictedSecurityRequest();
      this.props.initSubmitRestrictedSecurityRequestActionStatus();
    }

    // Restricted Security 提交失败
    if (submitRestrictedSecurityRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error('Restricted security application are submitted failed!');
      this.props.initSubmitRestrictedSecurityRequestActionStatus();
    }
  }

  addButtonOnClicked = () => {
    this.props.openRestrictedSecurityRequestEditModalDialog(true, null);
  }

  editButtonOnClicked = () => {
    const {
      selectedRestrictedSecurityRequestArray
    } = this.props;

    if (selectedRestrictedSecurityRequestArray.length !== 1) {
      message.warning("Please select a restricted security application!");
      return;
    }

    let filterRestrictedSecReqArray = selectedRestrictedSecurityRequestArray
      .filter(resctrictedSecurity => resctrictedSecurity.requestStatus === RestrictedSecurityRequestStatus.ONLIST || 
        resctrictedSecurity.requestStatus === RestrictedSecurityRequestStatus.OFFLIST || 
        resctrictedSecurity.approvalStatus === RestrictedSecurityApprovalStatus.APPROVED || 
        resctrictedSecurity.approvalStatus === RestrictedSecurityApprovalStatus.REJECTED);

    if (filterRestrictedSecReqArray.length > 0) {
      message.warning("You cannot edit the restricted security application that have been applied!");
      return;
    }

    this.props.openRestrictedSecurityRequestEditModalDialog(false, selectedRestrictedSecurityRequestArray[0]);
  }

  deleteButtonOnClicked = () => {
    const {
      selectedRestrictedSecurityRequestArray
    } = this.props;

    if (selectedRestrictedSecurityRequestArray.length === 0) {
      message.warning("Please select a restricted security application at least!");
      return;
    }

    let filterRestrictedSecReqArray = selectedRestrictedSecurityRequestArray
      .filter(resctrictedSecurity => resctrictedSecurity.requestStatus === RestrictedSecurityRequestStatus.ONLIST || 
        resctrictedSecurity.requestStatus === RestrictedSecurityRequestStatus.OFFLIST || 
        resctrictedSecurity.approvalStatus === RestrictedSecurityApprovalStatus.APPROVED || 
        resctrictedSecurity.approvalStatus === RestrictedSecurityApprovalStatus.REJECTED);

    if (filterRestrictedSecReqArray.length > 0) {
      message.warning("You cannot delete the restricted security application that have been applied!");
      return;
    }

    let that = this;
    confirm({
      title: 'Are you sure you want to delete the restricted security application?',
      content: '',
      onOk() {
        const restrictedSecGuidList = selectedRestrictedSecurityRequestArray.map(resctrictedSecurity => resctrictedSecurity.restrictedSecGuid);

        that.props.batchDeleteRestrictedSecurityRequest(restrictedSecGuidList);
      },
      onCancel() {},
    });
  }

  refreshButtonOnClicked = () => {
    this.props.loadRestrictedSecurityRequest();
  }

  onListBtnOnClicked = () => {
    const {
      selectedRestrictedSecurityRequestArray
    } = this.props;

    if (selectedRestrictedSecurityRequestArray.length === 0) {
      message.warning("Please select a restricted security application at least!");
      return;
    }

    let filterRestrictedSecReqArray = selectedRestrictedSecurityRequestArray.filter(resctrictedSecurity => resctrictedSecurity.requestStatus === RestrictedSecurityRequestStatus.ONLIST);
    if (filterRestrictedSecReqArray.length > 0) {
      message.warning("You cannot onlist the restricted security application again!");
      return;
    }

    const restrictedSecGuidList = selectedRestrictedSecurityRequestArray.map(resctrictedSecurity => resctrictedSecurity.restrictedSecGuid);

    let onListReason = '';
    if (selectedRestrictedSecurityRequestArray.length === 1) {
      onListReason = selectedRestrictedSecurityRequestArray[0].onListReason;
    }

    this.props.openRestrictedSecurityRequestOperateModalDialog(RestrictedSecurityOperateMode.ONLIST, restrictedSecGuidList, onListReason);
  }

  offListBtnOnClicked = () => {
    const {
      selectedRestrictedSecurityRequestArray
    } = this.props;

    if (selectedRestrictedSecurityRequestArray.length === 0) {
      message.warning("Please select a restricted security application at least!");
      return;
    }

    const restrictedSecGuidList = selectedRestrictedSecurityRequestArray.map(resctrictedSecurity => resctrictedSecurity.restrictedSecGuid);

    let offListReason = null;
    if (selectedRestrictedSecurityRequestArray.length === 1) {
      offListReason = selectedRestrictedSecurityRequestArray[0];
    }

    this.props.openRestrictedSecurityRequestOperateModalDialog(RestrictedSecurityOperateMode.OFFLIST, restrictedSecGuidList, offListReason);
  }

  checkBtnOnClicked = () => {
    this.props.openRestrictedSecurityListCheckModalDialog();
  }

  historyUploadOnClicked = () => {
    const {
        selectedRestrictedSecurityRequestArray,
        restrictedSecAttachmentDownloadUrl,
    } = this.props;
    if(!selectedRestrictedSecurityRequestArray || selectedRestrictedSecurityRequestArray.length !== 1) {
        message.warn('Please choose one and only one Personal Account');
        return;
    }
    const dataId = selectedRestrictedSecurityRequestArray[0].restrictedSecGuid;;
    this.props.doHistoryUploadAction({action:'OPEN_AND_QUERY', queryFields: {module: 'RestrictedSecurity', dataId: dataId}, 
                                        baseDownloadUrl: restrictedSecAttachmentDownloadUrl });
}
  
  render() {
    const {
      loadRestrictedSecurityRequestActionStatus,
      batchDeleteRestrictedSecurityRequestsActionStatus,
      submitRestrictedSecurityRequestActionStatus,
    } = this.props;

    const loadBtnIsLoading = loadRestrictedSecurityRequestActionStatus === ACTION_STATUS.LOGINING;
    const submitBtnIsLoading = submitRestrictedSecurityRequestActionStatus === ACTION_STATUS.LOGINING;
    const deleteBtnIsLoading = batchDeleteRestrictedSecurityRequestsActionStatus === ACTION_STATUS.LOGINING;
    
    let employee = !!localStorageClient.getUserName() ? localStorageClient.getUserName() : '';
    employee = employee.toLowerCase();

    const checkBtnShown = employee === 'zhichen.wang' || employee === 'chao.luo' || employee === 'hongchao.luo'
                          || employee === 'joey.ching' || employee === 'zheng.chang' || employee === 'eleanor.chan' || employee === 'yang.liu';

    return (
      <div className="tradeDashboardWrapper">
        
        <div className='tradeOperationBar'>
          {/* <Button 
            onClick={this.addButtonOnClicked} 
            style={{ marginLeft: 10 }}
            size='small'
            className='positive-button'>
            Add
          </Button>

          <Button 
            onClick={this.editButtonOnClicked} 
            size='small'
            type='primary'>
            Edit
          </Button>

          <Button 
            onClick={this.deleteButtonOnClicked} 
            size='small'
            loading={deleteBtnIsLoading}
            className='negative-button'>
            Del
          </Button> */}

          <Button 
            onClick={this.addButtonOnClicked} 
            size='small'
            type='primary'>
            Apply On List
          </Button>

          <Button 
            onClick={this.offListBtnOnClicked} 
            size='small'
            type='primary'>
            Apply Off List
          </Button>

          <Button 
            onClick={this.refreshButtonOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Refresh
          </Button>
          
          <Button 
              onClick={() => this.historyUploadOnClicked()} 
              size='small'
              type='primary'>
              HistoryUpload
          </Button>

          <Button 
            onClick={this.checkBtnOnClicked} 
            size='small'
            hidden={!checkBtnShown}
            type='primary'>
            Check
          </Button>

        </div>

        <div className='tradeGrid'>
          <RestrictedSecurityRequestGrid {...this.props} />
        </div>

        <RestrictedSecurityRequestEditDialog {...this.props} />

        {/* OnList/OffList Operation */}
        <RestrictedSecurityOperateDialog {...this.props} />

        {/* Restricted Security Check */}
        <RestrictedSecurityCheckDialog {...this.props} />
        {/* HistoryUpload */}
        <HistoryUploadDialog {...this.props} />
      </div>
    );
  }
}

export default RestrictedSecurityRequestDashboard;