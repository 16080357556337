import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Button } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

const TextArea = Input.TextArea;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

class BatchChangeStatusModalDialog extends Component {

    refreshBtnOnClicked = () => {
        const {
            startDate,
            endDate,
        } = this.props.workManagementQueryFields;
        this.props.loadWorkManagementInfo({startDate,endDate});
    }


    componentDidUpdate() {
        const {
            batchChangeStatusForWorkManagementStatus,
        } = this.props;
        const {
            mode,
        } = this.props.batchChangeStatusModal;

        if(batchChangeStatusForWorkManagementStatus === ACTION_STATUS.SUCCESS) {
            const modalTitle = this.getModalAction(mode);
            const msg = modalTitle + ' succeeded.';
            message.success(msg);
            this.props.initBatchChangeStatusForWorkManagementActionStatus();
            this.props.resetBatchChangeStatusModal();
            this.refreshBtnOnClicked();
        }
        if(batchChangeStatusForWorkManagementStatus === ACTION_STATUS.ERROR) {
            this.props.initBatchChangeStatusForWorkManagementActionStatus();
        }
    }


    getModalAction(mode) {
        if(mode === 'DEL') {
            return 'Batch delete Work Management';
        } else if(mode === 'PAUSE') {
            return 'Batch pause Reporting Summary';
        } else if(mode === 'DEL SUB'){
            return 'Batch resume Work ManagementSUB';
        }
    }


    onInputChange = ({name, value}) => {
        this.props.batchChangeStatusChangeInput({name, value});
    }


    submitBatchChangeStatusModal = () => {
        const {
            mode,
            fields,
        } = this.props.batchChangeStatusModal;
        const {
            comments,
            idList,
        } = fields;
        if(!idList || idList.length === 0) {
            message.error('System error! No Data is selected.');
            return;
        }

        const idNum = idList.length;
        let action = '';
        if(mode === 'DEL') {
            action = 'delete';
        } else if(mode === 'PAUSE') {
            action = 'pause';
        } else {
            action = 'Del Sub';
        }
        const confirmTitle = 'Are you sure to ' + action + ' the selected ' + idNum + ' ?';

        Modal.confirm({
            title: confirmTitle,
            onOk: () => {
                this.props.batchChangeStatusForWorkManagement({
                    idList,
                    actionMode: mode,
                    comments,
                });
            }
        });
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            batchChangeStatusForWorkManagementStatus,
        } = this.props;
        const {
            isOpened,
            mode,
            respErrMsg,
            fields,
        } = this.props.batchChangeStatusModal;
        const {
            comments,
        } = fields;

        const modalTitle = this.getModalAction(mode);
        

        const isSubmitLoading = batchChangeStatusForWorkManagementStatus === ACTION_STATUS.LOGINING;

        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={600}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetBatchChangeStatusModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetBatchChangeStatusModal() }>
                          Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitBatchChangeStatusModal() }>
                          Submit
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">
                        <Row gutter={24}>
                            <Col span={24}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>

                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }

}

const BatchChangeStatusModalDialogForm = Form.create({ name: 'BatchChangeStatusModalDialog' })(BatchChangeStatusModalDialog);
export default BatchChangeStatusModalDialogForm;
