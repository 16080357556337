import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';

import {
  // Load Trade Request
  START_LOAD_TRADE_REQUEST,
  LOAD_TRADE_REQUEST_SUCCESS,
  LOAD_TRADE_REQUEST_FAILURE,
  INIT_LOAD_TRADE_REQUEST_ACTION_STATUS,

  // Select Trade Request
  SELECT_TRADE_REQUEST,

  // Open/Reset Trade Request Modal Dialog
  TRADE_REQUEST_MODAL_DIALOG_OPEN,
  TRADE_REQUEST_MODAL_DIALOG_RESET,

  // Trade Request Edit Change Input
  TRADE_REQUEST_EDIT_CHANGE_INPUT,

  // Create Trade Request
  START_CREATE_TRADE_REQUEST,
  CREATE_TRADE_REQUEST_SUCCESS,
  CREATE_TRADE_REQUEST_FAILURE,
  INIT_CREATE_TRADE_REQUEST_ACTION_STATUS,

  // Update Trade Request
  START_UPDATE_TRADE_REQUEST,
  UPDATE_TRADE_REQUEST_SUCCESS,
  UPDATE_TRADE_REQUEST_FAILURE,
  INIT_UPDATE_TRADE_REQUEST_ACTION_STATUS,

  // Batch Delete Trade Request
  START_BATCH_DELETE_TRADE_REQUEST,
  BATCH_DELETE_TRADE_REQUEST_SUCCESS,
  BATCH_DELETE_TRADE_REQUEST_FAILURE,
  INIT_BATCH_DELETE_TRADE_REQUEST_ACTION_STATUS,

  // Submit Trade Request
  START_SUBMIT_TRADE_REQUEST,
  SUBMIT_TRADE_REQUEST_SUCCESS,
  SUBMIT_TRADE_REQUEST_FAILURE,
  INIT_SUBMIT_TRADE_REQUEST_ACTION_STATUS,

  // Load BBG Tickers
  START_LOAD_BBG_TICKERS,
  LOAD_BBG_TICKERS_SUCCESS,
  LOAD_BBG_TICKERS_FAILURE,
  INIT_LOAD_BBG_TICKERS_ACTION_STATUS,

  // Clear bbgTickerArray bbgTickerOptions
  CLEAR_BBG_TICKERS,

  // BBG Ticker Select Changed
  BBG_TICKER_SELECT_CHANGED,

  // Load Personal Trade Field History
  START_LOAD_TRADE_FIELD_HISTORY,
  LOAD_TRADE_FIELD_HISTORY_SUCCESS,
  LOAD_TRADE_FIELD_HISTORY_FAILURE,
  INIT_LOAD_TRADE_FIELD_HISTORY_ACTION_STATUS,

  // Load Exchange Code
  START_LOAD_EXCHANGE_CODE,
  LOAD_EXCHANGE_CODE_SUCCESS,
  LOAD_EXCHANGE_CODE_FAILURE,
  INIT_LOAD_EXCHANGE_CODE_ACTION_STATUS,

  // Clear exchageCodeArray exchageCodeOptions
  CLEAR_EXCHANGE_CODES,

  // Exchange Code Select Changed
  EXCHANGE_CODE_SELECT_CHANGED,

  // Open/Reset Trade Request Clone Edit Modal Dialog
  TRADE_REQUEST_CLONE_EDIT_MODAL_DIALOG_OPEN,
  TRADE_REQUEST_CLONE_EDIT_MODAL_DIALOG_RESET,

  // Trade Request Clone Edit Change Input
  TRADE_REQUEST_CLONE_EDIT_CHANGE_INPUT,

  // load personal account
  START_LOAD_PERSONAL_ACCOUNT,
  LOAD_PERSONAL_ACCOUNT_SUCCESS,
  LOAD_PERSONAL_ACCOUNT_FAILURE,
  INIT_LOAD_PERSONAL_ACCOUNT_ACTION_STATUS,

  // 30天持仓相关预检查
  START_QUERY_30_DAY_HOLDING_CHECK_INFO,
  QUERY_30_DAY_HOLDING_CHECK_INFO_SUCCESS,
  QUERY_30_DAY_HOLDING_CHECK_INFO_FAILURE,
  RESET_30_DAY_HOLDING_CHECK_INFO,
  START_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS,
  STOP_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS,

  // openCheckHoldingsPage
  OPEN_CHECK_HOLDINGS_PAGE,
  RESET_CHECK_HOLDINGS_PAGE,
  START_QUERY_HOLDINGS_FOR_CURR_ACCOUNT,
  QUERY_HOLDINGS_FOR_CURR_ACCOUNT_SUCCESS,
  QUERY_HOLDINGS_FOR_CURR_ACCOUNT_FAILURE,
  INIT_QUERY_HOLDINGS_FOR_CURR_ACCOUNT_ACTION_STATUS,
  SET_TRADE_REQUEST_EDIT_DIAGLOG_FORM,

  CHECK_CAN_DO_PA_SUCCESS,
  CHECK_CAN_DO_PA_FAILURE,
} from './tradeRequestConstants';


// Load Trade Request
function startLoadTradeRequest() {
  return {
    type: START_LOAD_TRADE_REQUEST
  };
}

export function loadTradeRequest() {
  return function(dispatch) {
    dispatch(startLoadTradeRequest());
    httpClient
      .get(UrlConfig.QUERY_PERSONAL_TRADE)
      .then(resp => dispatch(loadTradeRequestSuccess(resp)))
      .catch(err => dispatch(loadTradeRequestFailure(err)));
  };
}

function loadTradeRequestSuccess(resp) {
  return {
    type: LOAD_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function loadTradeRequestFailure(err) {
  return {
    type: LOAD_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initLoadTradeRequestActionStatus() {
  return {
    type: INIT_LOAD_TRADE_REQUEST_ACTION_STATUS
  };
}

// Select Trade Request
export function selectTradeRequest(payload) {
  return {
    type: SELECT_TRADE_REQUEST,
    payload
  };
}

// Open/Reset Trade Request Modal Dialog
export function openTradeRequestEditModalDialog(mode, tradeRequest) {
  return {
    type: TRADE_REQUEST_MODAL_DIALOG_OPEN,
    payload: {
      mode,
      tradeRequest,
    },
  };
}

export function resetTradeRequestEditModalDialog() {
  return {
    type: TRADE_REQUEST_MODAL_DIALOG_RESET
  };
}

// Trade Request Edit Change Input
export function tradeRequestEditChangeInput({ name, value }) {
  return {
    type: TRADE_REQUEST_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// =============================================================
// Create Trade Request
function startCreateTradeRequest() {
  return {
    type: START_CREATE_TRADE_REQUEST
  };
}

export function createTradeRequest(tradeRequest) {
  return function(dispatch) {
    dispatch(startCreateTradeRequest());
    httpClient
      .post(UrlConfig.NEW_PERSONAL_TRADE, tradeRequest)
      .then(resp => dispatch(createTradeRequestSuccess(resp)))
      .catch(err => dispatch(createTradeRequestFailure(err)));
  };
}

function createTradeRequestSuccess(resp) {
  return {
    type: CREATE_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function createTradeRequestFailure(err) {
  return {
    type: CREATE_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initCreateTradeRequestActionStatus() {
  return {
    type: INIT_CREATE_TRADE_REQUEST_ACTION_STATUS
  };
}

// Update Trade Request
function startUpdateTradeRequest() {
  return {
    type: START_UPDATE_TRADE_REQUEST
  };
}

export function updateTradeRequest(tradeRequest) {
  return function(dispatch) {
    dispatch(startUpdateTradeRequest());
    httpClient
      .post(UrlConfig.UPDATED_PERSONAL_TRADE, tradeRequest)
      .then(resp => dispatch(updateTradeRequestSuccess(resp)))
      .catch(err => dispatch(updateTradeRequestFailure(err)));
  };
}

function updateTradeRequestSuccess(resp) {
  return {
    type: UPDATE_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function updateTradeRequestFailure(err) {
  return {
    type: UPDATE_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initUpdateTradeRequestActionStatus() {
  return {
    type: INIT_UPDATE_TRADE_REQUEST_ACTION_STATUS
  };
}

// Batch Delete Trade Request
function startBatchDeleteTradeRequest() {
  return {
    type: START_BATCH_DELETE_TRADE_REQUEST
  };
}

export function batchDeleteTradeRequest(tradeRequestIdList) {
  return function(dispatch) {
    dispatch(startBatchDeleteTradeRequest());
    httpClient
      .post(UrlConfig.BATCH_DELETED_PERSONAL_TRADES, tradeRequestIdList)
      .then(resp => dispatch(batchDeleteTradeRequestSuccess(resp)))
      .catch(err => dispatch(batchDeleteTradeRequestFailure(err)));
  };
}

function batchDeleteTradeRequestSuccess(resp) {
  return {
    type: BATCH_DELETE_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function batchDeleteTradeRequestFailure(err) {
  return {
    type: BATCH_DELETE_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initBatchDeleteTradeRequestActionStatus() {
  return {
    type: INIT_BATCH_DELETE_TRADE_REQUEST_ACTION_STATUS
  };
}

// Submit Trade Request
function startSubmitTradeRequest() {
  return {
    type: START_SUBMIT_TRADE_REQUEST
  };
}

export function submitTradeRequest(tradeRequestIdList) {
  return function(dispatch) {
    dispatch(startSubmitTradeRequest());
    httpClient
      .post(UrlConfig.SUBMITTED_PERSONAL_TRADE, tradeRequestIdList)
      .then(resp => dispatch(submitTradeRequestSuccess(resp)))
      .catch(err => dispatch(submitTradeRequestFailure(err)));
  };
}

function submitTradeRequestSuccess(resp) {
  return {
    type: SUBMIT_TRADE_REQUEST_SUCCESS,
    payload: resp
  };
}

function submitTradeRequestFailure(err) {
  return {
    type: SUBMIT_TRADE_REQUEST_FAILURE,
    payload: err
  };
}

export function initSubmitTradeRequestActionStatus() {
  return {
    type: INIT_SUBMIT_TRADE_REQUEST_ACTION_STATUS
  };
}


// =================================================================
// Load BBG Tickers
function startLoadBBGTickers() {
  return {
    type: START_LOAD_BBG_TICKERS
  };
}

export function loadBbgTickers(queryText) {
  const requestParams = {
    'query-text': queryText,
    'page': 'personalTrade',
    'source': 'Report',
  };

  return function(dispatch) {
    dispatch(startLoadBBGTickers());
    httpClient
      .get(UrlConfig.QUERY_BBG_TICKERS, requestParams)
      .then(resp => dispatch(loadBbgTickersSuccess(resp)))
      .catch(err => dispatch(loadBbgTickersFailure(err)));
  };
}

function loadBbgTickersSuccess(resp) {
  return {
    type: LOAD_BBG_TICKERS_SUCCESS,
    payload: resp
  };
}

function loadBbgTickersFailure(err) {
  return {
    type: LOAD_BBG_TICKERS_FAILURE,
    payload: err
  };
}

export function initLoadBbgTickersActionStatus() {
  return {
    type: INIT_LOAD_BBG_TICKERS_ACTION_STATUS
  };
}

// Clear bbgTickerArray bbgTickerOptions
export function clearBbgTickers() {
  return {
    type: CLEAR_BBG_TICKERS,
  };
}

// BBG Ticker Select Changed
export function bbgTickerSelectChanged(bbgTicker) {
  return {
    type: BBG_TICKER_SELECT_CHANGED,
    payload: bbgTicker
  };
}

// Load Personal Trade Field History
function startLoadPersonalTradeFieldHistory() {
  return {
    type: START_LOAD_TRADE_FIELD_HISTORY
  };
}

export function loadPersonalTradeFieldHistory(employee) {
  const requestParams = {
    employee,
  };

  return function(dispatch) {
    dispatch(startLoadPersonalTradeFieldHistory());
    httpClient
      .get(UrlConfig.PERSONAL_TRADE_FIELD_HISTORY, requestParams)
      .then(resp => dispatch(loadPersonalTradeFieldHistorySuccess(resp)))
      .catch(err => dispatch(loadPersonalTradeFieldHistoryFailure(err)));
  };
}

function loadPersonalTradeFieldHistorySuccess(resp) {
  return {
    type: LOAD_TRADE_FIELD_HISTORY_SUCCESS,
    payload: resp
  };
}

function loadPersonalTradeFieldHistoryFailure(err) {
  return {
    type: LOAD_TRADE_FIELD_HISTORY_FAILURE,
    payload: err
  };
}

export function initLoadPersonalTradeFieldHistoryActionStatus() {
  return {
    type: INIT_LOAD_TRADE_FIELD_HISTORY_ACTION_STATUS
  };
}

// Load Exchange Code
function startLoadExchangeCode() {
  return {
    type: START_LOAD_EXCHANGE_CODE
  };
}

export function loadExchangeCode(queryText) {
  const requestParams = {
    'query-text': queryText,
  };

  return function(dispatch) {
    dispatch(startLoadExchangeCode());
    httpClient
      .get(UrlConfig.QUERY_EXCHANGE_CODE, requestParams)
      .then(resp => dispatch(loadExchangeCodeSuccess(resp)))
      .catch(err => dispatch(loadExchangeCodeFailure(err)));
  };
}

function loadExchangeCodeSuccess(resp) {
  return {
    type: LOAD_EXCHANGE_CODE_SUCCESS,
    payload: resp
  };
}

function loadExchangeCodeFailure(err) {
  return {
    type: LOAD_EXCHANGE_CODE_FAILURE,
    payload: err
  };
}

export function initLoadExchangeCodeActionStatus() {
  return {
    type: INIT_LOAD_EXCHANGE_CODE_ACTION_STATUS
  };
}

// Clear exchageCodeArray exchageCodeOptions
export function clearExchangeCodes() {
  return {
    type: CLEAR_EXCHANGE_CODES,
  };
}

// Exchange Code Select Changed
export function exchangeCodeSelectChanged(exchangeCode) {
  return {
    type: EXCHANGE_CODE_SELECT_CHANGED,
    payload: exchangeCode
  };
}

// Open/Reset Trade Request Clone Edit Modal Dialog
export function openTradeRequestCloneEditModalDialog(tradeRequest) {
  return {
    type: TRADE_REQUEST_CLONE_EDIT_MODAL_DIALOG_OPEN,
    payload: {
      tradeRequest,
    },
  };
}

export function resetTradeRequestCloneEditModalDialog() {
  return {
    type: TRADE_REQUEST_CLONE_EDIT_MODAL_DIALOG_RESET
  };
}

// Trade Request Clone Edit Change Input
export function tradeRequestCloneEditChangeInput({ name, value }) {
  return {
    type: TRADE_REQUEST_CLONE_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}


// load personal account
function startLoadPersonalAccount() {
  return {
    type: START_LOAD_PERSONAL_ACCOUNT,
  };
}
export function loadPersonalAccount(employee)  {
  const requestParams = {
    employee,
    approvalStatus: 'FinalApproved',
    needHoldingData: 'false',
    source: 'Report',
  };
  return function(dispatch) {
    dispatch(startLoadPersonalAccount());
    httpClient
      .get(UrlConfig.QUERY_PERSONAL_ACCOUNT_LIST, requestParams)
      .then(resp => dispatch(loadPersonalAccountSuccess(resp)))
      .catch(err => dispatch(loadPersonalAccountFailure(err)));
  };
}
function loadPersonalAccountSuccess(resp) {
  return {
    type: LOAD_PERSONAL_ACCOUNT_SUCCESS,
    payload: resp
  };
}
function loadPersonalAccountFailure(err) {
  return {
    type: LOAD_PERSONAL_ACCOUNT_FAILURE,
    payload: err
  };
}
export function initLoadPersonalAccountActionStatus() {
  return {
    type: INIT_LOAD_PERSONAL_ACCOUNT_ACTION_STATUS,
  };
}


  // 30天持仓相关预检查
function startQuery30DayHoldingCheckInfo() {
  return {
    type: START_QUERY_30_DAY_HOLDING_CHECK_INFO,
  };
}
export function query30DayHoldingCheckInfo(personalTrade) {
  return function(dispatch) {
    dispatch(startQuery30DayHoldingCheckInfo());
    httpClient
      .post(UrlConfig.QUERY_30_DAY_HOLDING_CHECK_INFO, personalTrade)
      .then(resp => dispatch(query30DayHoldingCheckInfoSuccess(resp)))
      .catch(err => dispatch(query30DayHoldingCheckInfoFailure(err)));
  };
}
function query30DayHoldingCheckInfoSuccess(resp) {
  return {
    type: QUERY_30_DAY_HOLDING_CHECK_INFO_SUCCESS,
    payload: resp
  };
}
function query30DayHoldingCheckInfoFailure(err) {
  return {
    type: QUERY_30_DAY_HOLDING_CHECK_INFO_FAILURE,
    payload: err
  };
}
export function reset30DayHoldingCheckInfo() {
  return {
    type: RESET_30_DAY_HOLDING_CHECK_INFO,
  }
}
export function startTrigger30DayHoldingCheckInfoStatus() {
  return {
    type: START_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS,
  }
}
export function stopTrigger30DayHoldingCheckInfoStatus() {
  return {
    type: STOP_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS,
  }
}


// openCheckHoldingsPage
export function openCheckHoldingsPage(accountGuid) {
  return {
    type: OPEN_CHECK_HOLDINGS_PAGE,
    payload: {
      accountGuid,
    },
  };
}
export function resetCheckHoldingsPage() {
  return {
    type: RESET_CHECK_HOLDINGS_PAGE,
  };
}


function startQueryHoldingsForCurrAccount() {
  return {
    type: START_QUERY_HOLDINGS_FOR_CURR_ACCOUNT,
  };
}
export function queryHoldingsForCurrAccount(accountGuid) {
  const params = {
    accountGuid,
  };
  return function(dispatch) {
    dispatch(startQueryHoldingsForCurrAccount());
    httpClient
      .get(UrlConfig.QUERY_HOLDINGS_FOR_CURR_ACCOUNT, params)
      .then(resp => dispatch(queryHoldingsForCurrAccountSuccess(resp)))
      .catch(err => dispatch(queryHoldingsForCurrAccountFailure(err)));
  };
}
function queryHoldingsForCurrAccountSuccess(resp) {
  return {
    type: QUERY_HOLDINGS_FOR_CURR_ACCOUNT_SUCCESS,
    payload: resp
  };
}
function queryHoldingsForCurrAccountFailure(err) {
  return {
    type: QUERY_HOLDINGS_FOR_CURR_ACCOUNT_FAILURE,
    payload: err
  };
}
export function initQueryHoldingsForCurrAccountActionStatus() {
  return {
    type: INIT_QUERY_HOLDINGS_FOR_CURR_ACCOUNT_ACTION_STATUS,
  };
}


export function setTradeRequestEditDialogForm(form) {
  return {
    type: SET_TRADE_REQUEST_EDIT_DIAGLOG_FORM,
    payload: form,
  }
}

export function checkCanDoPa() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.CHECK_CAN_APPLY_PA_URL)
      .then(resp => dispatch(checkCanDoPaSuccess(resp)))
      .catch(err => dispatch(checkCanDoPaFailure(err)));
  };
}

export function checkCanDoPaSuccess(resp) {
  return {
    type: CHECK_CAN_DO_PA_SUCCESS,
    payload: resp
  };
}

export function checkCanDoPaFailure(err) {
  return {
    type: CHECK_CAN_DO_PA_FAILURE,
    payload: err
  };
}