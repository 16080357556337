import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import GridColumnMap from './GridColumnMap';
import BaseUrlConfig from '../../../net/BaseUrlConfig';
import UrlConfig from '../../../net/UrlConfig';
import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_EXPENSE_SUMMARYNEW_INFO,
    LOAD_EXPENSE_SUMMARYNEW_INFO_SUCCESS,
    LOAD_EXPENSE_SUMMARYNEW_INFO_FAILURE,
    INIT_LOAD_EXPENSE_SUMMARYNEW_INFO_ACTION_STATUS,
    // select
    SELECT_EXPENSE_SUMMARYNEW_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_MODAL,
    ADD_OR_EDIT_EXPENSE_SUMMARYNEW_CHANGE_INPUT,
    START_ADD_OR_EDIT_EXPENSE_SUMMARYNEW,
    ADD_OR_EDIT_EXPENSE_SUMMARYNEW_SUCCESS,
    ADD_OR_EDIT_EXPENSE_SUMMARYNEW_FAILURE,
    INIT_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_ACTION_STATUS,
    RESET_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_EXPENSE_SUMMARYNEW_MODAL,
    DEL_EXPENSE_SUMMARYNEW_CHANGE_INPUT,
    START_DEL_EXPENSE_SUMMARYNEW,
    DEL_EXPENSE_SUMMARYNEW_SUCCESS,
    DEL_EXPENSE_SUMMARYNEW_FAILURE,
    INIT_DEL_EXPENSE_SUMMARYNEW_ACTION_STATUS,
    RESET_DEL_EXPENSE_SUMMARYNEW_MODAL,

} from './ExpenseSummaryNewConstants';


const Option = Select.Option;

const categoryOptions = [];

categoryOptions.push(<Option key={'Compliance'}>{'Compliance'}</Option>);
categoryOptions.push(<Option key={'Professional'}>{'Professional'}</Option>);
categoryOptions.push(<Option key={'Tax'}>{'Tax'}</Option>);
categoryOptions.push(<Option key={'Audit'}>{'Audit'}</Option>);
categoryOptions.push(<Option key={'BBG/Data'}>{'BBG/Data'}</Option>);
categoryOptions.push(<Option key={'Office/Admin'}>{'Office/Admin'}</Option>);

const fundFirmRelatedOptions = [];
fundFirmRelatedOptions.push(<Option key={'PAML'}>{'PAML'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PASG'}>{'PASG'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PIAS'}>{'PIAS'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PAJP'}>{'PAJP'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
fundFirmRelatedOptions.push(<Option key={'VCC'}>{'VCC'}</Option>);
fundFirmRelatedOptions.push(<Option key={'COP'}>{'COP'}</Option>);
fundFirmRelatedOptions.push(<Option key={'COP GP'}>{'COP GP'}</Option>);
fundFirmRelatedOptions.push(<Option key={'MTP'}>{'MTP'}</Option>);
fundFirmRelatedOptions.push(<Option key={'PCMG'}>{'PCMG'}</Option>);
fundFirmRelatedOptions.push(<Option key={'EJ'}>{'EJ'}</Option>);
fundFirmRelatedOptions.push(<Option key={'WHO'}>{'WHO'}</Option>);
fundFirmRelatedOptions.push(<Option key={'上海保银'}>{'上海保银'}</Option>);
fundFirmRelatedOptions.push(<Option key={'上海保银(海南)'}>{'上海保银(海南)'}</Option>);
fundFirmRelatedOptions.push(<Option key={'海南保银'}>{'海南保银'}</Option>);
fundFirmRelatedOptions.push(<Option key={'保银投顾'}>{'保银投顾'}</Option>);
fundFirmRelatedOptions.push(<Option key={'保银资产'}>{'保银资产'}</Option>);
fundFirmRelatedOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
fundFirmRelatedOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);
fundFirmRelatedOptions.push(<Option key={'SLHL'}>{'SLHL'}</Option>);
fundFirmRelatedOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);

// fundFirmRelatedOptions.push(<Option key={'上海保银私募'}>{'上海保银私募'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'上海保银私募 – funds prepaid'}>{'上海保银私募 – funds prepaid'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'Wealth Ocean'}>{'Wealth Ocean'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'Pinpoint Charity'}>{'Pinpoint Charity'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'PLUS'}>{'PLUS'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'国内基金'}>{'国内基金'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'MetaPoint'}>{'MetaPoint'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'SLHL'}>{'SLHL'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'投资顾问 (上海)'}>{'投资顾问 (上海)'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'私募管理 (上海)'}>{'私募管理 (上海)'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'私募管理 (海南)'}>{'私募管理 (海南)'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'私募管理 (上海) - funds prepaid'}>{'私募管理 (上海) - funds prepaid'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'私募管理 (海南) - funds prepaid'}>{'私募管理 (海南) - funds prepaid'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'资产管理 (上海)'}>{'资产管理 (上海)CL'}</Option>);
// fundFirmRelatedOptions.push(<Option key={'Others'}>{'Others'}</Option>);


const ccyOptions = [];
ccyOptions.push(<Option key={'USD'}>{'USD'}</Option>);
ccyOptions.push(<Option key={'CNY'}>{'CNY'}</Option>);
ccyOptions.push(<Option key={'HKD'}>{'HKD'}</Option>);
ccyOptions.push(<Option key={'SGD'}>{'SGD'}</Option>);
ccyOptions.push(<Option key={'JPY'}>{'JPY'}</Option>);
ccyOptions.push(<Option key={'INR'}>{'INR'}</Option>);
ccyOptions.push(<Option key={'GBP'}>{'GBP'}</Option>);


  
const frequencyOptions = [];
frequencyOptions.push(<Option key={'One-off'}>{'One-off'}</Option>);
frequencyOptions.push(<Option key={'Monthly'}>{'Monthly'}</Option>);
frequencyOptions.push(<Option key={'Quarterly'}>{'Quarterly'}</Option>);
frequencyOptions.push(<Option key={'Bi-Annual'}>{'Bi-Annual'}</Option>);
frequencyOptions.push(<Option key={'Annual'}>{'Annual'}</Option>);

const instructionyOptions = [];
instructionyOptions.push(<Option key={'Fund Admin'}>{'Fund Admin'}</Option>);
instructionyOptions.push(<Option key={'Soft-dollar'}>{'Soft-dollar'}</Option>);
instructionyOptions.push(<Option key={'OLB'}>{'OLB'}</Option>);

const approverOptions = [];
approverOptions.push(<Option key={'eleanor.chan'}>{'eleanor.chan'}</Option>);
approverOptions.push(<Option key={'audrey.lee'}>{'audrey.lee'}</Option>);
approverOptions.push(<Option key={'sherry.li'}>{'sherry.li'}</Option>);
approverOptions.push(<Option key={'vivienne.chan'}>{'vivienne.chan'}</Option>);

const payInstructionOptions = [];
payInstructionOptions.push(<Option key={'Fund Admin'}>{'Fund Admin'}</Option>);
payInstructionOptions.push(<Option key={'Soft-dollar'}>{'Soft-dollar'}</Option>);
payInstructionOptions.push(<Option key={'OLB'}>{'OLB'}</Option>);

const initialState = {
    expenseSummaryNewGridColumns: GridColumnMap.expenseSummaryNewGridColumns,
    monthlyReportAttachmentUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MONTHLY_REVIEW_UPLOAD_ATTACHMENT,
    monthlyReportAttachmentDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MONTHLY_REVIEW_DOWNLOAD_ATTACHMENT,
    categoryOptions: categoryOptions,
    fundFirmRelatedOptions: fundFirmRelatedOptions,
    ccyOptions: ccyOptions,
    frequencyOptions: frequencyOptions,
    instructionyOptions: instructionyOptions,
    approverOptions: approverOptions,
    payInstructionOptions: payInstructionOptions,
    expenseSummaryNewQueryFields: {
        category: '',
        invDateStart: '',
        invDateEnd: '',
        invNo: '',
        firm: ''
    },
    selectedExpenseSummaryNewInfoArray: [],
    addOrEditExpenseSummaryNewModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: null,
            guid: '',
            category: '',
            firm: '',
            fundFirmRelated: '',
            invDate: '',
            invNo: '',
            ccy: '',
            amount: '',
            fx: '',
            amtUSD: '',
            payDate: '',
            nature: '',
            remarks: '',
            payMethod: '',
            comments: '',
        },
    },
    delExpenseSummaryNewModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: null,
            guid: '',
            comments: '',
            mode: '',
        }
    },

};


// query
function onQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.expenseSummaryNewQueryFields,
        [name]: value,
    };
    return {
        ...state,
        expenseSummaryNewQueryFields: upatedFields,
    }
}
function startLoadExpenseSummaryNewInfo(state) {
    return {
      ...state,
      loadExpenseSummaryNewInfoActionStatus: ACTION_STATUS.LOGINING,
    };
}
function loadExpenseSummaryNewInfoSuccess(state, resp) {
    return {
        ...state,
        loadExpenseSummaryNewInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadExpenseSummaryNewInfoResp: resp,
    };
}
function loadExpenseSummaryNewInfoFailure(state, err) {
    return {
        ...state,
        loadExpenseSummaryNewInfoActionStatus: ACTION_STATUS.ERROR,
        loadExpenseSummaryNewInfoResp: err,
    };
}
function initLoadExpenseSummaryNewInfoActionStatus(state) {
    return {
        ...state,
        loadExpenseSummaryNewInfoActionStatus: ACTION_STATUS.READY,
        selectedExpenseSummaryNewInfoArray: [],
    };
}


// select
function selectExpenseSummaryNewInfo(state, array) {
    return {
        ...state,
        selectedExpenseSummaryNewInfoArray: array,
    }
}


// add or edit
function openAddOrEditExpenseSummaryNewModal(state, {mode, data}) {
    if(mode === 'ADD') {
        return {
            ...state,
            addOrEditExpenseSummaryNewModal: {
                ...state.addOrEditExpenseSummaryNewModal,
                isOpened: true,
                mode,
            },
        };
    } else {
        return {
            ...state,
            addOrEditExpenseSummaryNewModal: {
                ...state.addOrEditExpenseSummaryNewModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditExpenseSummaryNewModal.fields,
                    id: data.id,
                    guid: data.guid,
                    category: data.category,
                    invDate: data.invDate,
                    invNo: data.invNo,
                    ccy: data.ccy,
                    amount: data.amount,
                    fx: data.fx,
                    amtUSD: data.amtUSD,
                    payDate: data.payDate,
                    remarks: data.remarks,
                    payMethod: data.payMethod,
                    billingEntity: data.billingEntity,
                    checkStatue: data.checkStatue,
                    approvalStatus: data.approvalStatus,
                    callBackOrOlb: data.callBackOrOlb,
                    payee: data.payee,
                    payer: data.payer,
                    frequency: data.frequency,
                    description: data.description,
                    approver: data.approver,
                    broker: data.broker,
                    payInstruction: data.payInstruction,
                    filePath: data.filePath,
                }
            },
        };
    }
}
function addOrEditExpenseSummaryNewChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditExpenseSummaryNewModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditExpenseSummaryNewModal: {
          ...state.addOrEditExpenseSummaryNewModal,
          fields: updatedFields,
        },
    };
}
function startAddOrEditExpenseSummaryNew(state) {
    return {
      ...state,
      addOrEditExpenseSummaryNewStatus: ACTION_STATUS.LOGINING,
    };
}
function addOrEditExpenseSummaryNewSuccess(state, resp) {
    return {
        ...state,
        addOrEditExpenseSummaryNewStatus: ACTION_STATUS.SUCCESS,
    };
}
function addOrEditExpenseSummaryNewFailure(state, err) {
    return {
        ...state,
        addOrEditExpenseSummaryNewStatus: ACTION_STATUS.ERROR,
        addOrEditExpenseSummaryNewModal: {
            ...state.addOrEditExpenseSummaryNewModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initAddOrEditExpenseSummaryNewActionStatus(state) {
    return {
        ...state,
        addOrEditExpenseSummaryNewStatus: ACTION_STATUS.READY,
    };
}
function resetAddOrEditExpenseSummaryNewModal(state) {
    return {
        ...state,
        addOrEditExpenseSummaryNewModal: initialState.addOrEditExpenseSummaryNewModal,
    }
}


// refresh fx and amtUSD
function startRefreshFx(state) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.LOGINING,
    };
}
function refreshFxSuccess(state, resp) {
    const fx = (!!resp && !!resp.data && !!resp.data.last) ? resp.data.last : '';
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.SUCCESS,
        addOrEditExpenseSummaryNewModal: {
            ...state.addOrEditExpenseSummaryNewModal,
            fields: {
                ...state.addOrEditExpenseSummaryNewModal.fields,
                fx: fx,
            },
        }
    }
}
function refreshFxFailure(state, err) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.ERROR,
        refreshFxResp: err,
        addOrEditExpenseSummaryNewModal: {
            ...state.addOrEditExpenseSummaryNewModal,
            fields: {
                ...state.addOrEditExpenseSummaryNewModal.fields,
                fx: '',
            },
        }
    }
}
function initRefreshFxActionStatus(state) {
    return {
        ...state,
        refreshFxStatus: ACTION_STATUS.READY,
    };
}
function refreshAmtUSD(state, amtUSD) {
    return {
        ...state,
        addOrEditExpenseSummaryNewModal: {
            ...state.addOrEditExpenseSummaryNewModal,
            fields: {
                ...state.addOrEditExpenseSummaryNewModal.fields,
                amtUSD: amtUSD,
            },
        }
    };
}


// del
function openDelExpenseSummaryNewModal(state, {info,mode}) {
    return {
        ...state,
        delExpenseSummaryNewModal: {
            ...state.delExpenseSummaryNewModal,
            isOpened: true,
            mode:mode,
            fields: {
                ...state.delExpenseSummaryNewModal.fields,
                id: info.id,
                guid: info.guid,

            }
        }
    }
}
function delExpenseSummaryNewChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.delExpenseSummaryNewModal.fields,
        [name]: value,
    };
    return {
        ...state,
        delExpenseSummaryNewModal: {
          ...state.delExpenseSummaryNewModal,
          fields: updatedFields,
        },
    };
}
function startDelExpenseSummaryNew(state) {
    return {
      ...state,
      delExpenseSummaryNewStatus: ACTION_STATUS.LOGINING,
    };
}
function delExpenseSummaryNewSuccess(state, resp) {
    return {
        ...state,
        delExpenseSummaryNewStatus: ACTION_STATUS.SUCCESS,
    };
}
function delExpenseSummaryNewFailure(state, err) {
    return {
        ...state,
        delExpenseSummaryNewStatus: ACTION_STATUS.ERROR,
        delExpenseSummaryNewModal: {
            ...state.delExpenseSummaryNewModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initDelExpenseSummaryNewActionStatus(state) {
    return {
        ...state,
        delExpenseSummaryNewStatus: ACTION_STATUS.READY,
    };
}
function resetDelExpenseSummaryNewModal(state) {
    return {
        ...state,
        delExpenseSummaryNewModal: initialState.delExpenseSummaryNewModal,
    }
}




export default createReducer(initialState, {
    // query
    [ON_QUERY_INPUT_CHANGE]: onQueryInputChange,
    [START_LOAD_EXPENSE_SUMMARYNEW_INFO]: startLoadExpenseSummaryNewInfo,
    [LOAD_EXPENSE_SUMMARYNEW_INFO_SUCCESS]: loadExpenseSummaryNewInfoSuccess,
    [LOAD_EXPENSE_SUMMARYNEW_INFO_FAILURE]: loadExpenseSummaryNewInfoFailure,
    [INIT_LOAD_EXPENSE_SUMMARYNEW_INFO_ACTION_STATUS]: initLoadExpenseSummaryNewInfoActionStatus,
    // select
    [SELECT_EXPENSE_SUMMARYNEW_INFO]: selectExpenseSummaryNewInfo,
    // add or edit
    [OPEN_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_MODAL]: openAddOrEditExpenseSummaryNewModal,
    [ADD_OR_EDIT_EXPENSE_SUMMARYNEW_CHANGE_INPUT]: addOrEditExpenseSummaryNewChangeInput,
    [START_ADD_OR_EDIT_EXPENSE_SUMMARYNEW]: startAddOrEditExpenseSummaryNew,
    [ADD_OR_EDIT_EXPENSE_SUMMARYNEW_SUCCESS]: addOrEditExpenseSummaryNewSuccess,
    [ADD_OR_EDIT_EXPENSE_SUMMARYNEW_FAILURE]: addOrEditExpenseSummaryNewFailure,
    [INIT_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_ACTION_STATUS]: initAddOrEditExpenseSummaryNewActionStatus,
    [RESET_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_MODAL]: resetAddOrEditExpenseSummaryNewModal,
    // refresh fx and amtUSD
    [START_REFRESH_FX]: startRefreshFx,
    [REFRESH_FX_SUCCESS]: refreshFxSuccess,
    [REFRESH_FX_FAILURE]: refreshFxFailure,
    [INIT_REFRESH_FX_ACTION_STATUS]: initRefreshFxActionStatus,
    [REFRESH_AMT_USD]: refreshAmtUSD,
    // del
    [OPEN_DEL_EXPENSE_SUMMARYNEW_MODAL]: openDelExpenseSummaryNewModal,
    [DEL_EXPENSE_SUMMARYNEW_CHANGE_INPUT]: delExpenseSummaryNewChangeInput,
    [START_DEL_EXPENSE_SUMMARYNEW]: startDelExpenseSummaryNew,
    [DEL_EXPENSE_SUMMARYNEW_SUCCESS]: delExpenseSummaryNewSuccess,
    [DEL_EXPENSE_SUMMARYNEW_FAILURE]: delExpenseSummaryNewFailure,
    [INIT_DEL_EXPENSE_SUMMARYNEW_ACTION_STATUS]: initDelExpenseSummaryNewActionStatus,
    [RESET_DEL_EXPENSE_SUMMARYNEW_MODAL]: resetDelExpenseSummaryNewModal,

});