import React, {Component} from 'react';
import './MeetingAndTrainingDashboard.css';
import {Breadcrumb, Button, message , Input,Select,DatePicker,Divider,Modal} from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import MeetingAndTrainingGrid from './MeetingAndTrainingGrid';
import AddOrEditMeetingAndTrainingModalDialog from './AddOrEditMeetingAndTrainingModalDialog';
import ChangeMeetingAndTrainingStatusModalDialog from './ChangeMeetingAndTrainingStatusModalDialog';
import { Message } from 'semantic-ui-react'

class MeetingAndTrainingDashboard extends Component {
    state = {
        showModal: false,
        confirmDisabled: false,
        remark: '',
    };
    componentDidMount() {
        this.refreshBtnOnClicked();
    }


    componentDidUpdate() {
        const {
            loadMeetingAndTrainingActionStatus,
            loadMeetingAndTrainingResp,
            
        } = this.props;

        if(loadMeetingAndTrainingActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadMeetingAndTrainingInfoActionStatus();
        }
        if(loadMeetingAndTrainingActionStatus === ACTION_STATUS.ERROR) {
            let err = 'load General Agreement failed';
            if(!!loadMeetingAndTrainingResp && !!loadMeetingAndTrainingResp.respMessage) {
                err = loadMeetingAndTrainingResp.respMessage;
            }
            message.error(err);
            this.props.initLoadMeetingAndTrainingInfoActionStatus();
        }
        if(!!loadMeetingAndTrainingResp && (loadMeetingAndTrainingResp.data.agreeOrReject == null || loadMeetingAndTrainingResp.data.agreeOrReject == 'Reject')){
            // const remark = loadMeetingAndTrainingResp.data.notification ;
            const showModal = !!loadMeetingAndTrainingResp && (loadMeetingAndTrainingResp.data.agreeOrReject == null || loadMeetingAndTrainingResp.data.agreeOrReject == 'Reject')
            // this.setState({ showModal: showModal, remark:'' });
        }
        

    }


    refreshBtnOnClicked = () => {
        const {
            mode,
        } = this.props.addOrEditMeetingAndTrainingModal;
        const {
            id,
            type,
            starttime,
            endtime,
            speaker,
            uploadRealName,
            
        } = this.props.addOrEditMeetingAndTrainingModal.fields;
        // const {
        //     type,
        //     starttime,
        //     endtime,
        //     speaker,
        //     status
        // } = this.props.addOrEditMeetingAndTrainingModal;
        this.props.loadMeetingAndTrainingInfo({type,starttime,endtime,speaker})

    }


    addOrEditMeetingAndTrainingBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditMeetingAndTrainingModal(mode, null);
        } else {  // COPY, EDIT
            const {
                selectedMeetingAndTrainingArray,
            } = this.props;
            if(selectedMeetingAndTrainingArray.length !== 1) {
                message.warning("Please choose one and only one General Agreement");
                return;
            }
            const currentData = selectedMeetingAndTrainingArray[0];

            // if(mode === 'EDIT') {
            //     if(currentData.state === 'New' ) {
            //         message.warning("Please sign in first and feedback later");
            //         return;
            //     }
            // }
            this.props.openAddOrEditMeetingAndTrainingModal(mode, currentData);
        }
    }


    changeMeetingAndTrainingStatusBtnOnClicked = (mode) => {
        const {
            selectedMeetingAndTrainingArray,
        } = this.props;
        if(mode === 'Sign In' ){
            if(selectedMeetingAndTrainingArray.length === 0) {
                message.warning("Please choose one ");
                return;
            }
            const currentData = selectedMeetingAndTrainingArray[0];

            // 
            if(currentData.remark === 'true' && currentData.note === null) {
                message.warn('Please finish the Remark first.');
                return;
            }
            // 
            if(currentData.feedback === 'true' && currentData.feedbackComments === null) {
                message.warn('Please finish the feedback first.');
                return;
            }

            const idList = selectedMeetingAndTrainingArray.map(e => e.id);
            this.props.submitChangeMeetingAndTrainingStatusModal({
                idList,

                modes: mode,
            });
        }else {

            const idList = selectedMeetingAndTrainingArray.map(e => e.id);
            this.props.submitChangeMeetingAndTrainingStatusModal({
             

                modes: mode,
            });
        }
    }
    changeBtnOnClicked = (mode) => {
     
    }


    applyRecord = (mode) => {
        // const {
          
        // } = this.props.addOrEditMeetingAndTrainingModal;
        // const {
            
        //     speaker,

        // } = this.props.addOrEditMeetingAndTrainingModal.fields;
        const {

            speaker,

        } = this.props.addOrEditMeetingAndTrainingModal;
        this.props.openChangeMeetingAndTrainingStatusModal(mode, speaker);
        // this.props.submitChangeMeetingAndTrainingStatusModal({
          
        //     speaker: speaker,
        //     modes: mode,
        // });

    }

    onInputChange = ({name, value}) => {
        this.props.addOrEditGeneralAgreemenChangeInput({name, value});
    }
    showAllShowValidBtnOnClicked = () => {
        // 在这里去掉UI上所有选中，在Reducer中清空状态记录
        this.props.meetingAndTrainingGridEvent.api.forEachNode(node => node.setSelected(false));
        this.props.showAllShowValidBtnOnClicked();
    }
    getRScore =(array) =>{
        if(array.length>0){
        let rArray = array.filter(r=>r.required==='M').reduce(function(prev, cur) {
          return parseInt(cur.score) + prev;
          }, 0);
          return rArray;
        }else{
          return 0;
        }
      
      };
    
      getOScore =(array) =>{
        if(array.length>0){
          let rArray = array.filter(r=>r.required==='O').reduce(function(prev, cur) {
            return parseInt(cur.score) + prev;
            }, 0);
            return rArray;
          }else{
            return 0;
          }
    };
    handleConfirm = () => {
        this.setState({ showModal: false });
    };
    
    handleReject = () => {
        const {
            selectedMeetingAndTrainingArray,
        } = this.props;
        this.props.submitChangeMeetingAndTrainingStatusModal({
             
        
            modes: 'Notifi',
        });
    };
    render() {
        const {
            loadMeetingAndTrainingActionStatus,
            onlyShowValidMeetingAndTraining,
            loadMeetingAndTrainingResp,
            meetingAndTrainingQueryFields,
            typeOptions,
        } = this.props;
        
        const refreshBtnIsLoading = loadMeetingAndTrainingActionStatus === ACTION_STATUS.LOGINING;
        const showAllShowValidBtnName = onlyShowValidMeetingAndTraining ? 'showAll' : 'showValid';
        let loadMeetingAndTrainingArray = [];
        if(!!loadMeetingAndTrainingResp && !!loadMeetingAndTrainingResp.data) {
            loadMeetingAndTrainingArray = loadMeetingAndTrainingResp.data;
        }
        const rScore = this.getRScore(loadMeetingAndTrainingArray);
        const oScore = this.getOScore(loadMeetingAndTrainingArray);
        const confirmDisabled = !!loadMeetingAndTrainingResp && (loadMeetingAndTrainingResp.data.agreeOrReject == 'Reject');

        const showModal = !!loadMeetingAndTrainingResp && (loadMeetingAndTrainingResp.data.agreeOrReject == null || loadMeetingAndTrainingResp.data.agreeOrReject == 'Reject') && loadMeetingAndTrainingResp.data.notes !== null 
       
        const remark  = !!loadMeetingAndTrainingResp && loadMeetingAndTrainingResp.data.notification!== null ?loadMeetingAndTrainingResp.data.notification.split('\n').map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))  :''
        const modalTitle = !!loadMeetingAndTrainingResp ?loadMeetingAndTrainingResp.data.notes:''
       



        return (
             

            <div className='MeetingAndTrainingWrapper'>

                <div className='OperationBar'>
                    <div className='breadcrumbWrapper'>   
                        <Breadcrumb>
                        <Breadcrumb.Item>  Search by:</Breadcrumb.Item>
                        </Breadcrumb>
                    </div>
                    {/* <Breadcrumb>
                    <Breadcrumb.Item>     Search by:</Breadcrumb.Item>
                    </Breadcrumb> */}
                    <Select
                        allowClear
                        style={{ width: 130, marginLeft: 10 }}
                        onChange={(val) => {
                            this.onInputChange({ name: 'type', value: val })
                        }} 

                        placeholder="Category">
                        {typeOptions}
                    </Select>
                    <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="Start Time"
                      onChange={(invDateStart, dateString) => {
                        this.onInputChange({ name: 'starttime', value: dateString });
                      }}
                    />
                    <DatePicker 
                      allowClear={true}
                      size='small'
                      style={{ width: 150, marginLeft: 10 }}
                      placeholder="End Time"
                      onChange={(invDateEnd, dateString) => {
                        this.onInputChange({ name: 'endtime', value: dateString });
                      }}
                    />
                    <Input 
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="Speaker"
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'speaker', value: event.target.value })
                        }} 
                    />
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />
                  
                    {/* <Button 
                        onClick={()=>this.addOrEditMeetingAndTrainingBtnOnClicked('Remark')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Feedback
                    </Button> */}
                  
                     <Button 
                        onClick={()=>this.changeMeetingAndTrainingStatusBtnOnClicked('Sign In')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Sign In
                    </Button>
                    <Divider type='vertical' />
                    {/* <Button 
                        onClick={()=>this.applyRecord('Stock Pitch')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Stock Pitch
                    </Button>
                    <Button 
                        onClick={()=>this.applyRecord('Paper Portfolio')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Paper Portfolio
                    </Button> */}
                    {/*<Button 
                        onClick={()=>this.changeMeetingAndTrainingStatusBtnOnClicked('EXPIRE')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Expire
                    </Button> */}

                </div>
                <div className='OperationBar'>
                    <Message negative>
                      <Message.Header> Mandatory Score- {rScore}, Optional Score- {oScore}.</Message.Header>
                    </Message>
                </div>
                <div className='MeetingAndTrainingGrid'>
                    {showModal && (
                        <Modal
                            title={modalTitle}
                            centered
                            width={1000}
                            visible={showModal}
                            closable={false}
                            footer={[

                                 <Button key="Save" type="primary"  onClick={() => this.changeMeetingAndTrainingStatusBtnOnClicked('Notifi') }  disabled={confirmDisabled}>
                                 I acknowledged
                             </Button>
                            ]}
                        >
                            <p>{remark}</p>

                        </Modal>
                    )}
                    {!showModal && (
                        <MeetingAndTrainingGrid {...this.props} />
                    )}
                    
                    
                    
                    </div>
                <AddOrEditMeetingAndTrainingModalDialog {...this.props} />
                <ChangeMeetingAndTrainingStatusModalDialog {...this.props} />

            </div>
        );
    }
}

export default MeetingAndTrainingDashboard;

