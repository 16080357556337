import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import GridColumnMap from './components/GridColumnMap';

import {
    // do query
    START_LOAD_QUESTIONNAIRE_RESULTS,
    LOAD_QUESTIONNAIRE_RESULTS_SUCCESS,
    LOAD_QUESTIONNAIRE_RESULTS_FAILURE,
    INIT_LOAD_QUESTIONNAIRE_RESULTS_ACTION_STATUS,
    
} from './QuestionnaireRequestConstants';


const initialState = {
    questionnaireResultGridColumns: GridColumnMap.questionnaireResultGridColumns,
    questionAnswerGridColumns: GridColumnMap.questionAnswerGridColumns,
}


// do query
function startLoadQuestionnaireResults(state) {
    return {
      ...state,
      loadQuestionnaireResultsActionStatus: ACTION_STATUS.LOGINING,
    };
}
function loadQuestionnaireResultsSuccess(state, resp) {
    return {
      ...state,
      loadQuestionnaireResultsActionStatus: ACTION_STATUS.SUCCESS,
      loadQuestionnaireResultsActionResp: resp,
    };
}
function loadQuestionnaireResultsFailure(state, err) {
    return {
      ...state,
      loadQuestionnaireResultsActionStatus: ACTION_STATUS.ERROR,
      loadQuestionnaireResultsActionResp: err,
    };
}
function initLoadQuestionnaireResultsActionStatus(state) {
    return {
      ...state,
      loadQuestionnaireResultsActionStatus: ACTION_STATUS.READY,
    };
}


export default createReducer(initialState, {
    // do query
    [START_LOAD_QUESTIONNAIRE_RESULTS]: startLoadQuestionnaireResults,
    [LOAD_QUESTIONNAIRE_RESULTS_SUCCESS]: loadQuestionnaireResultsSuccess,
    [LOAD_QUESTIONNAIRE_RESULTS_FAILURE]: loadQuestionnaireResultsFailure,
    [INIT_LOAD_QUESTIONNAIRE_RESULTS_ACTION_STATUS]: initLoadQuestionnaireResultsActionStatus,


});
