import axios from 'axios';

import BaseUrlConfig from './BaseUrlConfig';
import requestHandler from '../middleware/requestHandler';
import respHandler from '../middleware/responseHandler';
import { localStorageClient } from '../utils/localStorageClient';

axios.defaults.baseURL = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION;
axios.defaults.headers['Content-Type'] = 'application/json';
axios.defaults.withCredentials=true;//让ajax携带cookie

// 全局统一请求拦截器
axios.interceptors.request.use(
  requestHandler.succRequestHandle,
  requestHandler.errRequestHandle
);

axios.interceptors.response.use(
  respHandler.succResponseHandle,
  respHandler.errResponseHandle
);

class HttpClient {

  setHeaders() {
    // 改成基于cookie的方案，不再setHeaders
  }

  get(url, params) {
    this.setHeaders();
    return axios.get(url, {params});
  }

  post(url, params) {
    this.setHeaders();
    return axios.post(url, params);
  }

  put(url, params) {
    this.setHeaders();
    return axios.put(url, params);
  }

  delete(url, params) {
    this.setHeaders();
    return axios.delete(url, { data: params });
  }

  download(url, params) {
    this.setHeaders();
    return axios.get(url, {
      params,
      responseType: 'arraybuffer'
    }, 
    );
  }
};

export const httpClient = new HttpClient();