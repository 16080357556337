import React from 'react';
import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import GridColumnMap from '../../../utils/GridColumnMap';
import RestrictedSecurityOperateMode from '../../../utils/RestrictedSecurityOperateMode';
import BaseUrlConfig from '../../../net/BaseUrlConfig';
import UrlConfig from '../../../net/UrlConfig';
import { Select } from 'antd';
import moment from 'moment';

import {
  // Load Restricted Security Request
  START_LOAD_RESTRICTED_SECURITY_REQUEST,
  LOAD_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  LOAD_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_LOAD_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Select Restricted Security Request
  SELECT_RESTRICTED_SECURITY_REQUEST,

  // Open/Reset Restricted Security Request Modal Dialog
  RESTRICTED_SECURITY_REQUEST_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_REQUEST_MODAL_DIALOG_RESET,

  // Restricted Security Request Edit Change Input
  RESTRICTED_SECURITY_REQUEST_EDIT_CHANGE_INPUT,

  // Create Restricted Security Request
  START_CREATE_RESTRICTED_SECURITY_REQUEST,
  CREATE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  CREATE_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_CREATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Update Restricted Security Request
  START_UPDATE_RESTRICTED_SECURITY_REQUEST,
  UPDATE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  UPDATE_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_UPDATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Batch Delete Restricted Security Request
  START_BATCH_DELETE_RESTRICTED_SECURITY_REQUEST,
  BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Submit Restricted Security Request
  START_SUBMIT_RESTRICTED_SECURITY_REQUEST,
  SUBMIT_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  SUBMIT_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_SUBMIT_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Load Restricted Security BBG Tickers
  START_LOAD_RESTRICTED_SECURITY_BBG_TICKERS,
  LOAD_RESTRICTED_SECURITY_BBG_TICKERS_SUCCESS,
  LOAD_RESTRICTED_SECURITY_BBG_TICKERS_FAILURE,
  INIT_LOAD_RESTRICTED_SECURITY_BBG_TICKERS_ACTION_STATUS,

  // Clear Restricted Security BBG Ticker
  CLEAR_RESTRICTED_SECURITY_BBG_TICKERS,

  // Restricted Security BBG Ticker Select Changed
  RESTRICTED_SECURITY_BBG_TICKER_SELECT_CHANGED,

  // Restricted Security Fund Code Select Changed
  RESTRICTED_SECURITY_FUND_CODE_SELECT_CHANGED,

  // Restricted Security Book Code Select Changed
  RESTRICTED_SECURITY_BOOK_CODE_SELECT_CHANGED,

  // Open/Reset Restricted Security Request Operate Modal Dialog
  RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_RESET,

  // On List Restricted Security Request
  START_ON_LIST_RESTRICTED_SECURITY_REQUEST,
  ON_LIST_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  ON_LIST_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_ON_LIST_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Off List Restricted Security Request
  START_OFF_LIST_RESTRICTED_SECURITY_REQUEST,
  OFF_LIST_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  OFF_LIST_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_OFF_LIST_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Restricted Security Operate Edit Change Input
  RESTRICTED_SECURITY_OPERATE_EDIT_CHANGE_INPUT,

  // Open/Reset Restricted Security List Check Modal Dialog
  RESTRICTED_SECURITY_LIST_CHECK_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_LIST_CHECK_MODAL_DIALOG_RESET,

  // Search Valid Restricted Security 
  START_SEARCH_VALID_RESTRICTED_SECURITY,
  SEARCH_VALID_RESTRICTED_SECURITY_SUCCESS,
  SEARCH_VALID_RESTRICTED_SECURITY_FAILURE,
  INIT_SEARCH_VALID_RESTRICTED_SECURITY_ACTION_STATUS,

} from './restrictedSecurityRequestConstants';

import {
  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,

  // Load Fund Book Data
  LOAD_FUND_BOOK_DATA_SUCCESS,
} from '../home/homeConstants';

const Option = Select.Option;

const initialState = {
  // Grdi Columns
  restrictedSecurityGridColumns: GridColumnMap.restrictedSecurityGridColumns,

  // Restricted Security Request
  restrictedSecurityRequestArray: [],

  // Selected Restricted Security Request
  selectedRestrictedSecurityRequestArray: [],

  // Restricted Security Request Edit Modal
  restrictedSecurityRequestEditControlModal: {
    isOpened: false,
    isAddMode: false,
    respErrMsg: '',
    fields: {
      guid: '',
      restrictionType: '',
      fund: '',
      book: '',
      personInCharge: '',
      securityId: '',
      bbgTicker: '',
      secName: '',
      validFrom: '',
      validTo: '9999-12-31 00:00',
      onListReason: '',
      relatedCompany: '',
      restrictedSecReqGuid: '',
      attachment: '',
      attachmentOriginName: '',
      fileList: [],
      source: '',
    }
  },

  // load/create/update/delete/submit Restricted Security Request 
  loadRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  createRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  updateRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  batchDeleteRestrictedSecurityRequestsActionStatus: ACTION_STATUS.READY,
  submitRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,

  // Security Ticker Data
  securityTickerData: null,
  bbgTickerArray: [],
  bbgTickerOptions: [],
  securityTickerArray: [],

  // Fund Book Data
  fundBookData: null,
  fundCodeArray: [],
  fundCodeOptions: [],
  bookCodeArray: [],
  bookCodeOptions: [],
  fundBookArray: [],

  // Load Restricted Security BBG Tickers Action Status
  loadRestrictedSecurityBbgTickersActionStatus: ACTION_STATUS.READY,

  // Restricted Security Request Operate Modal Dialog
  restrictedSecurityRequestOperateControlModal: {
    isOpened: false,
    operateMode: RestrictedSecurityOperateMode.APPROVE,
    restrictedSecGuidList: [],
    respErrMsg: '',
    fields: {
      onListReason: '',
      offListReason: '',
      relatedCompany: '',
    },
  },

  // On/Off List Restricted Security Request Action Status
  onListRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  offListRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,

  // 附件操作URL
  restrictedSecAttachmentUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.UPLOAD_RESTRICTED_SECURITY_ATTACHMENT,
  restrictedSecAttachmentPreviewUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.PREVIEW_RESTRICTED_SECURITY_ATTACHMENT,
  restrictedSecAttachmentDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.DOWNLOAD_RESTRICTED_SECURITY_ATTACHMENT,

  // Restricted Security List Check Modal Dialog
  restrictedSecurityListCheckControlModal: {
    isOpened: false,
    respErrMsg: '',
  },

  // Search Valid Restricted Security 
  searchValidRestrictedSecurityActionStatus: ACTION_STATUS.READY,
  matchedValidRestrictedSecurityArray: [],
} 

// Load Restricted Security Request
function startLoadRestrictedSecurityRequest(state) {
  return {
    ...state,
    loadRestrictedSecurityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadRestrictedSecurityRequestSuccess(state, resp) {
  return {
    ...state,
    loadRestrictedSecurityRequestActionStatus: ACTION_STATUS.SUCCESS,
    restrictedSecurityRequestArray: resp.data,
  };
}

function loadRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    loadRestrictedSecurityRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    loadRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Select Restricted Security Request
function selectRestrictedSecurityRequest(state, payload) {
  return {
    ...state,
    selectedRestrictedSecurityRequestArray: payload,
  };
}

// Open/Reset Restricted Security Request Modal Dialog
function openRestrictedSecurityRequestEditModalDialog(state, {isAddMode, restrictedSecurityRequest}) {
  if (isAddMode) {
    return {
      ...state,
      restrictedSecurityRequestEditControlModal: {
        ...state.restrictedSecurityRequestEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.restrictedSecurityRequestEditControlModal.fields,
          validFrom: moment().format("YYYY-MM-DD HH:mm"),
        }
      },
    };
  } else {
    const restrictedSecAttachmentDownloadUrl = state.restrictedSecAttachmentDownloadUrl;

    const fileList = [];
    const attachment = restrictedSecurityRequest.attachment;
    const attachmentOriginName = restrictedSecurityRequest.attachmentOriginName;

    if (attachment && attachmentOriginName) {
      const file = {
        uid: '-1',
        name: attachmentOriginName,
        status: 'done',
        url: restrictedSecAttachmentDownloadUrl + "/" + attachment,
        response: {
          respCode: "S0001",
          data: {
            attachment,
            attachmentOriginName,
          }
        }
      };
      fileList.push(file);
    }

    return {
      ...state,
      restrictedSecurityRequestEditControlModal: {
        ...state.restrictedSecurityRequestEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.restrictedSecurityRequestEditControlModal.fields,
          guid: restrictedSecurityRequest.restrictedSecGuid,
          restrictionType: restrictedSecurityRequest.restrictionType,
          fund: restrictedSecurityRequest.fund,
          book: restrictedSecurityRequest.book,
          personInCharge: restrictedSecurityRequest.personInCharge,
          securityId: restrictedSecurityRequest.securityId,
          bbgTicker: restrictedSecurityRequest.bbgTicker,
          secName: restrictedSecurityRequest.secName,
          validFrom: restrictedSecurityRequest.validFrom,
          validTo: restrictedSecurityRequest.validTo,
          onListReason: restrictedSecurityRequest.onListReason,
          relatedCompany: restrictedSecurityRequest.relatedCompany,
          restrictedSecReqGuid: restrictedSecurityRequest.guid,
          attachment: restrictedSecurityRequest.attachment,
          attachmentOriginName: restrictedSecurityRequest.attachmentOriginName,
          fileList,
        }
      },
    };
  }
}

function resetRestrictedSecurityRequestEditModalDialog(state) {
  return {
    ...state,
    restrictedSecurityRequestEditControlModal: initialState.restrictedSecurityRequestEditControlModal,
    selectedRestrictedSecurityRequestArray: [],
  };
}

// Restricted Security Request Edit Change Input
function restrictedSecurityRequestEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.restrictedSecurityRequestEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      fields: updatedFields,
    },
  };
}

// =============================================================
// Create Restricted Security Request
function startCreateRestrictedSecurityRequest(state) {
  return {
    ...state,
    createRestrictedSecurityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function createRestrictedSecurityRequestSuccess(state, resp) {
  return {
    ...state,
    createRestrictedSecurityRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function createRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    createRestrictedSecurityRequestActionStatus: ACTION_STATUS.ERROR,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initCreateRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    createRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Update Restricted Security Request
function startUpdateRestrictedSecurityRequest(state) {
  return {
    ...state,
    updateRestrictedSecurityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateRestrictedSecurityRequestSuccess(state, resp) {
  return {
    ...state,
    updateRestrictedSecurityRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    updateRestrictedSecurityRequestActionStatus: ACTION_STATUS.ERROR,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initUpdateRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    updateRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete Restricted Security Request
function startBatchDeleteRestrictedSecurityRequest(state) {
  return {
    ...state,
    batchDeleteRestrictedSecurityRequestsActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeleteRestrictedSecurityRequestSuccess(state, resp) {
  return {
    ...state,
    batchDeleteRestrictedSecurityRequestsActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function batchDeleteRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    batchDeleteRestrictedSecurityRequestsActionStatus: ACTION_STATUS.ERROR,
  };
}

function initBatchDeleteRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    batchDeleteRestrictedSecurityRequestsActionStatus: ACTION_STATUS.READY,
  };
}

// Submit Restricted Security Request
function startSubmitRestrictedSecurityRequest(state) {
  return {
    ...state,
    submitRestrictedSecurityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function submitRestrictedSecurityRequestSuccess(state, resp) {
  return {
    ...state,
    submitRestrictedSecurityRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function submitRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    submitRestrictedSecurityRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initSubmitRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    submitRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// =================================================================
// Load Restricted Security BBG Tickers
function startLoadRestrictedSecurityBbgTickers(state) {
  return {
    ...state,
    loadRestrictedSecurityBbgTickersActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadRestrictedSecurityBbgTickersSuccess(state, resp) {
  const bbgTickerArray = resp.data;

  const bbgTickerOptions = bbgTickerArray.map(bbgTicker => <Option key={bbgTicker}>{bbgTicker}</Option>);
  
  return {
    ...state,
    bbgTickerArray,
    bbgTickerOptions,
    loadRestrictedSecurityBbgTickersActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function loadRestrictedSecurityBbgTickersFailure(state, err) {
  return {
    ...state,
    loadRestrictedSecurityBbgTickersActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadRestrictedSecurityBbgTickersActionStatus(state) {
  return {
    ...state,
    loadBbgTickersActionStatus: ACTION_STATUS.READY,
  };
}

// Clear Restricted Security bbgTickerArray bbgTickerOptions
function clearRestrictedSecurityBbgTickers(state) {
  return {
    ...state,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      fields: {
        ...state.restrictedSecurityRequestEditControlModal.fields,
        secName: '',
      },
    },
    bbgTickerArray: [],
    bbgTickerOptions: [],
  };
}

// Restricted Security BBG Ticker Select Changed
function restrictedSecurityBbgTickerSelectChanged(state, bbgTicker) {
  const securityTickerArray = state.securityTickerArray;

  let securityName = '';
  let securityId = '';
  if (!!bbgTicker) {
    const filterSecurityTickerArray = securityTickerArray.filter(securityTicker => securityTicker.bbgTicker === bbgTicker);

    if (filterSecurityTickerArray.length > 0) {
      const securityTicker = filterSecurityTickerArray[0];
      securityName = securityTicker.securityName;
      securityId = securityTicker.securityId;
    }
  }

  const fields = state.restrictedSecurityRequestEditControlModal.fields;

  securityName = securityName === '' ? fields.secName : securityName;

  return {
    ...state,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      fields: {
        ...state.restrictedSecurityRequestEditControlModal.fields,
        secName: securityName,
        securityId,
      },
    },
  };
}

// Restricted Security Fund Code Select Changed
function restrictedSecurityFundCodeSelectChanged(state, fundCode) {
  const fundBookArray = state.fundBookArray;
  const bookCode = state.restrictedSecurityRequestEditControlModal.fields.book;

  let personInCharge = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      personInCharge = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      fields: {
        ...state.restrictedSecurityRequestEditControlModal.fields,
        personInCharge,
      },
    },
  };
}

// Restricted Security Book Code Select Changed
function restrictedSecurityBookCodeSelectChanged(state, bookCode) {
  const fundBookArray = state.fundBookArray;
  const fundCode = state.restrictedSecurityRequestEditControlModal.fields.fund;

  let personInCharge = '';
  if (!!fundCode && !!bookCode) {
    const filterFundBookArray = fundBookArray.filter(fundBook => fundBook.bookCode === bookCode && fundBook.fundCode === fundCode);
    
    if (filterFundBookArray.length > 0) {
      const fundBook = filterFundBookArray[0];
      personInCharge = fundBook.manager;
    }
  }

  return {
    ...state,
    restrictedSecurityRequestEditControlModal: {
      ...state.restrictedSecurityRequestEditControlModal,
      fields: {
        ...state.restrictedSecurityRequestEditControlModal.fields,
        personInCharge,
      },
    },
  };
}

// =================================================================
// Load Fund Book Data
function loadFundBookDataSuccess(state, resp) {
  let fundBookData = resp.data;
  let fundCodeArray = fundBookData.fundCodeList;
  let bookCodeArray = fundBookData.bookCodeList;
  let fundBookArray = fundBookData.fundBookList;

  let fundCodeOptions = fundCodeArray.map(fundCode => <Option key={fundCode}>{fundCode}</Option>);
  let bookCodeOptions = bookCodeArray.map(bookCode => <Option key={bookCode}>{bookCode}</Option>);

  return {
    ...state,
    fundBookData,
    fundCodeArray,
    bookCodeArray,
    fundBookArray,
    fundCodeOptions,
    bookCodeOptions,
  };
}

// =================================================================
// Load Security Tickers
function loadSecurityTickersSuccess(state, resp) {
  let securityTickerData = resp.data;   
  // let bbgTickerArray = securityTickerData.bbgTickerList;
  let securityTickerArray = securityTickerData.securityTickerList;

  // let bbgTickerOptions = bbgTickerArray.map(bbgTicker => <Option key={bbgTicker}>{bbgTicker}</Option>);

  return {
    ...state,
    securityTickerData,
    // bbgTickerArray,
    securityTickerArray,
  };
}

// Open/Reset Restricted Security Request Operate Modal Dialog
function openRestrictedSecurityRequestOperateModalDialog(state, {operateMode, restrictedSecGuidList, reason}) {
  let onListReason = '';
  let offListReason = '';
  let relatedCompany = '';
  const fileList = [];
  if(reason){
    const resultReason = reason.offListReason;

    if (operateMode === RestrictedSecurityOperateMode.ONLIST) {
      onListReason = resultReason;
    } 

    if (operateMode === RestrictedSecurityOperateMode.OFFLIST) {
      offListReason = resultReason;
    } 
   
    const attachment = reason.attachment;
    const attachmentOriginName = reason.attachmentOriginName;
    const restrictedSecAttachmentDownloadUrl = state.restrictedSecAttachmentDownloadUrl;

    if (attachment && attachmentOriginName) {
      const file = {
        uid: '-1',
        name: attachmentOriginName,
        status: 'done',
        url: restrictedSecAttachmentDownloadUrl + "/" + attachment,
        response: {
          respCode: "S0001",
          data: {
            attachment,
            attachmentOriginName,
          }
        }
      };
      fileList.push(file);
      }
  }

  return {
    ...state,
    restrictedSecurityRequestOperateControlModal: {
      ...state.restrictedSecurityRequestOperateControlModal,
      isOpened: true,
      operateMode,
      restrictedSecGuidList,
      fields: {
        ...state.restrictedSecurityRequestOperateControlModal.fields,
        onListReason,
        offListReason,
        relatedCompany,
        fileList,
      },      
    },
  };
}

function resetRestrictedSecurityRequestOperateModalDialog(state) {
  return {
    ...state,
    restrictedSecurityRequestOperateControlModal: initialState.restrictedSecurityRequestOperateControlModal,
    selectedRestrictedSecurityRequestArray: [],
  };
}

// On List Restricted Security Request
function startOnListRestrictedSecurityRequest(state) {
  return {
    ...state,
    onListRestrictedSecurityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function onListRestrictedSecurityRequestSuccess(state, resp) {
  return {
    ...state,
    onListRestrictedSecurityRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function onListRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    onListRestrictedSecurityRequestActionStatus: ACTION_STATUS.ERROR,
    restrictedSecurityRequestOperateControlModal: {
      ...state.restrictedSecurityRequestOperateControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initOnListRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    onListRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  };
}


// Off List Restricted Security Request
function startOffListRestrictedSecurityRequest(state) {
  return {
    ...state,
    offListRestrictedSecurityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function offListRestrictedSecurityRequestSuccess(state, resp) {
  return {
    ...state,
    offListRestrictedSecurityRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function offListRestrictedSecurityRequestFailure(state, err) {
  return {
    ...state,
    offListRestrictedSecurityRequestActionStatus: ACTION_STATUS.ERROR,
    restrictedSecurityRequestOperateControlModal: {
      ...state.restrictedSecurityRequestOperateControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initOffListRestrictedSecurityRequestActionStatus(state) {
  return {
    ...state,
    offListRestrictedSecurityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Restricted Security Operate Edit Change Input
function restrictedSecurityOperateEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.restrictedSecurityRequestOperateControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    restrictedSecurityRequestOperateControlModal: {
      ...state.restrictedSecurityRequestOperateControlModal,
      fields: updatedFields,
    },
  };
}

// Open/Reset Restricted Security List Check Modal Dialog
function openRestrictedSecurityListCheckModalDialog(state) {
  return {
    ...state,
    restrictedSecurityListCheckControlModal: {
      ...state.restrictedSecurityListCheckControlModal,
      isOpened: true,
    },
  };
}

function resetRestrictedSecurityListCheckModalDialog(state) {
  return {
    ...state,
    restrictedSecurityListCheckControlModal: initialState.restrictedSecurityListCheckControlModal,
    selectedRestrictedSecurityRequestArray: [],
  };
}

// Search Valid Restricted Security 
function startSearchValidRestrictedSecurity(state) {
  return {
    ...state,
    searchValidRestrictedSecurityActionStatus: ACTION_STATUS.LOGINING,
  };
}

function searchValidRestrictedSecuritySuccess(state, resp) {
  return {
    ...state,
    searchValidRestrictedSecurityActionStatus: ACTION_STATUS.SUCCESS,
    matchedValidRestrictedSecurityArray: resp.data,
  };
}

function searchValidRestrictedSecurityFailure(state, err) {
  return {
    ...state,
    searchValidRestrictedSecurityActionStatus: ACTION_STATUS.ERROR,
    restrictedSecurityListCheckControlModal: {
      ...state.restrictedSecurityListCheckControlModal,
      respErrMsg: err.respMessage,
    },
  };
}

function initSearchValidRestrictedSecurityActionStatus(state) {
  return {
    ...state,
    searchValidRestrictedSecurityActionStatus: ACTION_STATUS.READY,
  };
}

export default createReducer(initialState, {
  // Load Restricted Security Request
  [START_LOAD_RESTRICTED_SECURITY_REQUEST]: startLoadRestrictedSecurityRequest,
  [LOAD_RESTRICTED_SECURITY_REQUEST_SUCCESS]: loadRestrictedSecurityRequestSuccess,
  [LOAD_RESTRICTED_SECURITY_REQUEST_FAILURE]: loadRestrictedSecurityRequestFailure,
  [INIT_LOAD_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initLoadRestrictedSecurityRequestActionStatus,

  // Select Restricted Security Request
  [SELECT_RESTRICTED_SECURITY_REQUEST]: selectRestrictedSecurityRequest,

  // Open/Reset Restricted Security Request Modal Dialog
  [RESTRICTED_SECURITY_REQUEST_MODAL_DIALOG_OPEN]: openRestrictedSecurityRequestEditModalDialog,
  [RESTRICTED_SECURITY_REQUEST_MODAL_DIALOG_RESET]: resetRestrictedSecurityRequestEditModalDialog,

  // Restricted Security Request Edit Change Input
  [RESTRICTED_SECURITY_REQUEST_EDIT_CHANGE_INPUT]: restrictedSecurityRequestEditChangeInput,

  // Create Restricted Security Request
  [START_CREATE_RESTRICTED_SECURITY_REQUEST]: startCreateRestrictedSecurityRequest,
  [CREATE_RESTRICTED_SECURITY_REQUEST_SUCCESS]: createRestrictedSecurityRequestSuccess,
  [CREATE_RESTRICTED_SECURITY_REQUEST_FAILURE]: createRestrictedSecurityRequestFailure,
  [INIT_CREATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initCreateRestrictedSecurityRequestActionStatus,

  // Update Restricted Security Request
  [START_UPDATE_RESTRICTED_SECURITY_REQUEST]: startUpdateRestrictedSecurityRequest,
  [UPDATE_RESTRICTED_SECURITY_REQUEST_SUCCESS]: updateRestrictedSecurityRequestSuccess,
  [UPDATE_RESTRICTED_SECURITY_REQUEST_FAILURE]: updateRestrictedSecurityRequestFailure,
  [INIT_UPDATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initUpdateRestrictedSecurityRequestActionStatus,

  // Batch Delete Restricted Security Request
  [START_BATCH_DELETE_RESTRICTED_SECURITY_REQUEST]: startBatchDeleteRestrictedSecurityRequest,
  [BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_SUCCESS]: batchDeleteRestrictedSecurityRequestSuccess,
  [BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_FAILURE]: batchDeleteRestrictedSecurityRequestFailure,
  [INIT_BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initBatchDeleteRestrictedSecurityRequestActionStatus,

  // Submit Restricted Security Request
  [START_SUBMIT_RESTRICTED_SECURITY_REQUEST]: startSubmitRestrictedSecurityRequest,
  [SUBMIT_RESTRICTED_SECURITY_REQUEST_SUCCESS]: submitRestrictedSecurityRequestSuccess,
  [SUBMIT_RESTRICTED_SECURITY_REQUEST_FAILURE]: submitRestrictedSecurityRequestFailure,
  [INIT_SUBMIT_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initSubmitRestrictedSecurityRequestActionStatus,

  // Load Restricted Security BBG Tickers
  [START_LOAD_RESTRICTED_SECURITY_BBG_TICKERS]: startLoadRestrictedSecurityBbgTickers,
  [LOAD_RESTRICTED_SECURITY_BBG_TICKERS_SUCCESS]: loadRestrictedSecurityBbgTickersSuccess,
  [LOAD_RESTRICTED_SECURITY_BBG_TICKERS_FAILURE]: loadRestrictedSecurityBbgTickersFailure,
  [INIT_LOAD_RESTRICTED_SECURITY_BBG_TICKERS_ACTION_STATUS]: initLoadRestrictedSecurityBbgTickersActionStatus,

  // Clear Restricted Security BBG Ticker
  [CLEAR_RESTRICTED_SECURITY_BBG_TICKERS]: clearRestrictedSecurityBbgTickers,

  // Restricted Security BBG Ticker Select Changed
  [RESTRICTED_SECURITY_BBG_TICKER_SELECT_CHANGED]: restrictedSecurityBbgTickerSelectChanged,

  // Restricted Security Fund Code Select Changed
  [RESTRICTED_SECURITY_FUND_CODE_SELECT_CHANGED]: restrictedSecurityFundCodeSelectChanged,

  // Restricted Security Book Code Select Changed
  [RESTRICTED_SECURITY_BOOK_CODE_SELECT_CHANGED]: restrictedSecurityBookCodeSelectChanged,

  // Load Fund Book Data
  [LOAD_FUND_BOOK_DATA_SUCCESS]: loadFundBookDataSuccess,

  // Load Security Tickers
  [LOAD_SECURITY_TICKERS_SUCCESS]: loadSecurityTickersSuccess,

  // Open/Reset Restricted Security Request Operate Modal Dialog
  [RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_OPEN]: openRestrictedSecurityRequestOperateModalDialog,
  [RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_RESET]: resetRestrictedSecurityRequestOperateModalDialog,

  // On List Restricted Security Request
  [START_ON_LIST_RESTRICTED_SECURITY_REQUEST]: startOnListRestrictedSecurityRequest,
  [ON_LIST_RESTRICTED_SECURITY_REQUEST_SUCCESS]: onListRestrictedSecurityRequestSuccess,
  [ON_LIST_RESTRICTED_SECURITY_REQUEST_FAILURE]: onListRestrictedSecurityRequestFailure,
  [INIT_ON_LIST_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initOnListRestrictedSecurityRequestActionStatus,

  // Off List Restricted Security Request
  [START_OFF_LIST_RESTRICTED_SECURITY_REQUEST]: startOffListRestrictedSecurityRequest,
  [OFF_LIST_RESTRICTED_SECURITY_REQUEST_SUCCESS]: offListRestrictedSecurityRequestSuccess,
  [OFF_LIST_RESTRICTED_SECURITY_REQUEST_FAILURE]: offListRestrictedSecurityRequestFailure,
  [INIT_OFF_LIST_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS]: initOffListRestrictedSecurityRequestActionStatus,

  // Restricted Security Operate Edit Change Input
  [RESTRICTED_SECURITY_OPERATE_EDIT_CHANGE_INPUT]: restrictedSecurityOperateEditChangeInput,

  // Open/Reset Restricted Security List Check Modal Dialog
  [RESTRICTED_SECURITY_LIST_CHECK_MODAL_DIALOG_OPEN]: openRestrictedSecurityListCheckModalDialog,
  [RESTRICTED_SECURITY_LIST_CHECK_MODAL_DIALOG_RESET]: resetRestrictedSecurityListCheckModalDialog,

  // Search Valid Restricted Security 
  [START_SEARCH_VALID_RESTRICTED_SECURITY]: startSearchValidRestrictedSecurity,
  [SEARCH_VALID_RESTRICTED_SECURITY_SUCCESS]: searchValidRestrictedSecuritySuccess,
  [SEARCH_VALID_RESTRICTED_SECURITY_FAILURE]: searchValidRestrictedSecurityFailure,
  [INIT_SEARCH_VALID_RESTRICTED_SECURITY_ACTION_STATUS]: initSearchValidRestrictedSecurityActionStatus,
});