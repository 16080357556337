import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import BaseUrlConfig from '../net/BaseUrlConfig';
function RedirectComponent() {
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    // !location.pathname || location.pathname === '/'
    // 如果是登录页面，不执行重定向逻辑
    console.log('currentPath:'+currentPath)
    if (location.pathname === '/' || currentPath === '/login' || currentPath === '/home' || currentPath ===  '/home/manuals-and-policies') {
      return;
    }
    if ((location.pathname === '/home/apply-recon' || currentPath === '/home/meeting-and-training') && document.URL.includes('.cn')) {
      window.location.href = `https://cms.report.pinpointfund.com${location.pathname}`;
    }
    if ((currentPath === '/home/meeting-and-training' || location.pathname === '/home/apply-recon') && document.URL.includes('.com')) {
      return;
    }
    const activeProfile = BaseUrlConfig.profile;
    const cookieLocation = document.cookie
      .split('; ')
      .find(row => row.startsWith('officeLocation='))
      ?.split('=')[1];

    // 检查 location 参数并进行重定向
    // if (cookieLocation !== 'Shanghai' && cookieLocation !== 'SanYa') {
    //   window.location.href = `https://cms.report.pinpointfund.com${location.pathname}`;
    // }

    if (activeProfile === 'prodsh' && cookieLocation !== undefined) {
      if (cookieLocation !== 'Shanghai' && cookieLocation !== 'SanYa') {
        if (location.search.includes('authToken')){
          window.location.href = `https://cms.report.pinpointfund.com${location.pathname}`+location.search;
        }else{
          window.location.href = `https://cms.report.pinpointfund.com${location.pathname}`;
        }

      }
    } else if (activeProfile === 'prod' && cookieLocation !== undefined) {
      if (cookieLocation !== 'HongKong' && cookieLocation !== 'Singapore' && cookieLocation !==  'India' && cookieLocation !==  'Japan' && cookieLocation !==  'Taipei') {
        if (location.search.includes('authToken')){
          window.location.href = `https://cms.report.pinpointfund.cn${location.pathname}`+location.search;
        }else{
          window.location.href = `https://cms.report.pinpointfund.cn${location.pathname}`;
        }
      }
    }
  }, [location]);

  return null;
}

export default RedirectComponent;
