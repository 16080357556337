import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Button } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

const TextArea = Input.TextArea;
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


class FinishTaskModalDialog extends Component {
    refreshBtnOnClicked = () => {
        const {
            id,
            location,
            fundCompany,
        } = this.props.applyReconQueryFields;
        this.props.loadApplyReconInfo({id, location, fundCompany});
    }


    componentDidUpdate() {
        const {
            finishApplyReconTaskStatus,
        } = this.props;
        if(finishApplyReconTaskStatus === ACTION_STATUS.SUCCESS) {
            message.success('Finish Reporting Summary Task succeeded');
            this.props.initFinishApplyReconTaskActionStatus();
            this.props.resetFinishTaskModal();
            this.refreshBtnOnClicked();
            this.props.queryPendingTasksNum();  // 操作成功时，要刷新PendingTasks任务数量
        }
        if(finishApplyReconTaskStatus === ACTION_STATUS.ERROR) {
            this.props.initFinishApplyReconTaskActionStatus();
        }
    }


    onInputChange = ({name, value}) => {
        this.props.finishTaskChangeInput({name, value});
    }


    submitFinishTaskModal = () => {
        const submitTask = this.props.finishTaskModal.fields;
        Modal.confirm({
            title: 'Are you sure to mark this Reporting Summary Task as Finished?',
            onOk: () => {
                this.props.finishApplyReconTask(submitTask);
            },
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            finishApplyReconTaskStatus,
        } = this.props;

        const {
            isOpened,
            respErrMsg,
            fields,
        } = this.props.finishTaskModal;
        const {
            remarks,
            comments,
        } = fields;


        const isSubmitLoading = finishApplyReconTaskStatus === ACTION_STATUS.LOGINING;


        return (
            <div>
                <Modal
                    title={'Finish Reporting Summary Task'}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetFinishTaskModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetFinishTaskModal() }>
                          Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitFinishTaskModal() }>
                          Submit
                        </Button>,
                    ]}
                >

                    <Form layout="horizontal">
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="remarks">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remarks,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                    </Form>

                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }

}

const FinishTaskModalDialogForm = Form.create({ name: 'FinishTaskModalDialog' })(FinishTaskModalDialog);
export default FinishTaskModalDialogForm;
