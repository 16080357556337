import React, { Component } from 'react';
import { AgGridReact } from "ag-grid-react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';


class LicensingGrid extends Component {

    constructor(props) {
        super(props);
        const that = this;
        this.state = {
            columnDefs: props.licensingGridColumns,
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 100
            },
            rowSelection: "single",
            context: { componentParent: this },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:props.licensingCPTYearRcdGridColumns,
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                },
                getDetailRowData: function(params) {
                    params.successCallback(params.data.licenseCPTYearRcdList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">CPT Record</div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                "</div>"
            },
        };
    }

    getRowNodeId = data => {
        return data.id;
    };


    onLicensingDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let licensingDataArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectLicensingData(licensingDataArray);
    }


    render() {
        const {
            loadLicensingInfoResp,
            loadLicensingInfoActionStatus,
        } = this.props;

        const isLoading = loadLicensingInfoActionStatus === ACTION_STATUS.LOGINING;
        const loadLicensingInfoArray = (loadLicensingInfoActionStatus === ACTION_STATUS.READY && !!loadLicensingInfoResp && !!loadLicensingInfoResp.data) 
                                        ? loadLicensingInfoResp.data : [];

        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadLicensingInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            frameworkComponents={this.state.frameworkComponents}
                            // master/detail
                            masterDetail={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            // event
                            onSelectionChanged={this.onLicensingDataSelectionChanged}
                        />
                    )}
            </div>
        );
    }

}

export default LicensingGrid;
