import React, { Component } from 'react';
import { Modal, message, Button } from 'antd';
import { ACTION_STATUS } from '../../../../../utils/ActionStatus';
import HistoryUploadGrid from './HistoryUploadGrid';

{/*
    关于本模块的使用：
    客户模块需要在Container的：
        （1）mapStateToProps 上设置：historyUpload: state.historyUpload,
        （2）mapDispatchToProps 上设置：doHistoryUploadAction: (params) => dispatch(doHistoryUploadAction(params)),
    按钮所在页面需要添加：
        <HistoryUploadDialog {...this.props} />
    在客户模块打开本页面时，调用：
        this.props.doHistoryUploadAction({action:'OPEN_AND_QUERY', queryFields: {module: 'xxx', dataId: 'xxx'}, baseDownloadUrl: 'xxx' })
        其中baseDownloadUrl是下载链接的一部分，本模块将以 baseDownloadUrl+realFileName 的方式拼接完整的下载链接
        queryFields不传入attachementType，将该数据所有类型的附件都查询出

    本模块的后端部分见CommonHistoryUploadService
*/}

class HistoryUploadDialog extends Component {
    
    componentDidUpdate() {
        const {
            queryHistoryUploadStatus,
            queryHistoryUploadResp,
        } = this.props.historyUpload;
        if(queryHistoryUploadStatus === ACTION_STATUS.SUCCESS) {
            this.props.doHistoryUploadAction({action:'INIT_QUERY'});
        }
        if(queryHistoryUploadStatus === ACTION_STATUS.ERROR) {
            let err = 'query History Upload failed';
            if(!!queryHistoryUploadResp && !!queryHistoryUploadResp.respMessage) {
                err = queryHistoryUploadResp.respMessage;
            }
            message.error(err);
            this.props.doHistoryUploadAction({action:'INIT_QUERY'});
        }
    }


    btnClicked = (action) => {
        const {
            historyUploadDialogModal,
        } = this.props.historyUpload;
        if(action === 'QUERY') {
            this.props.doHistoryUploadAction({action:'OPEN_AND_QUERY', queryFields: historyUploadDialogModal.queryFields, baseDownloadUrl: historyUploadDialogModal.baseDownloadUrl});
        } else {
            this.props.doHistoryUploadAction({action:'RESET_QUERY', queryFields: historyUploadDialogModal.queryFields, baseDownloadUrl: historyUploadDialogModal.baseDownloadUrl});
        }
    }


    render() {

        const {
            queryHistoryUploadStatus,
            historyUploadDialogModal,
            queryHistoryUploadResp,
        } = this.props.historyUpload;

        const isOpened = historyUploadDialogModal.isOpened;
        const refreshBtnIsLoading = queryHistoryUploadStatus === ACTION_STATUS.LOGINING;


        return (
            <div>
                <Modal
                    title={'History Upload'}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.btnClicked('CLOSE')}
                    footer={[
                        <Button key="back" onClick={() => this.btnClicked('CLOSE')}>
                          Close
                        </Button>,
                      ]}
                >
                    <Button 
                        onClick={() => this.btnClicked('QUERY')} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />

                    <div style={{height: 300, marginTop: 10 }}><HistoryUploadGrid {...this.props} /></div>

                </Modal>
            </div>
        ); 
    }

}


export default HistoryUploadDialog;
