import {connect} from 'react-redux';
import GiftHospitalityRequestDashboard from '../components/GiftHospitalityRequestDashboard';

import {
  // Load Gift Hospitality Request
  loadGiftHospitalityRequest,
  initLoadGiftHospitalityRequestActionStatus,

  // Select Gift Hospitality Request
  selectGiftHospitalityRequest,

  // Open/Reset Gift Hospitality Request Modal Dialog
  openGiftHospitalityRequestEditModalDialog,
  resetGiftHospitalityRequestEditModalDialog,

  // Gift Hospitality Request Edit Change Input
  giftHospitalityRequestEditChangeInput,

  // =============================================================
  // Create Gift Hospitality Request
  createGiftHospitalityRequest,
  initCreateGiftHospitalityRequestActionStatus,

  // Update Gift Hospitality Request
  updateGiftHospitalityRequest,
  initUpdateGiftHospitalityRequestActionStatus,

  // Batch Delete Gift Hospitality Request
  batchDeleteGiftHospitalityRequest,
  initBatchDeleteGiftHospitalityRequestActionStatus,

  // Submit Gift Hospitality Request
  submitGiftHospitalityRequest,
  initSubmitGiftHospitalityRequestActionStatus,

} from '../giftHospitalityRequestActions';

const mapStateToProps = state => {
  return {
    // Grdi Columns
    giftHospitalityRequestGridColumns: state.giftHospitalityRequest.giftHospitalityRequestGridColumns,

    // Gift Hospitality Request
    giftHospitalityRequestArray: state.giftHospitalityRequest.giftHospitalityRequestArray,

    // Selected Gift Hospitality Request
    selectedGiftHospitalityRequestArray: state.giftHospitalityRequest.selectedGiftHospitalityRequestArray,

    // Gift Hospitality Request Edit
    giftHospitalityRequestEditControlModal: state.giftHospitalityRequest.giftHospitalityRequestEditControlModal,

    // load/create/update/delete/submit Gift Hospitality Request
    loadGiftHospitalityRequestActionStatus: state.giftHospitalityRequest.loadGiftHospitalityRequestActionStatus,
    createGiftHospitalityRequestActionStatus: state.giftHospitalityRequest.createGiftHospitalityRequestActionStatus,
    createGiftHospitalityRequestActionResp: state.giftHospitalityRequest.createGiftHospitalityRequestActionResp,
    updateGiftHospitalityRequestActionStatus: state.giftHospitalityRequest.updateGiftHospitalityRequestActionStatus,
    updateGiftHospitalityRequestActionResp:  state.giftHospitalityRequest.updateGiftHospitalityRequestActionResp,
    batchDeleteGiftHospitalityRequestsActionStatus: state.giftHospitalityRequest.batchDeleteGiftHospitalityRequestsActionStatus,
    submitGiftHospitalityRequestActionStatus: state.giftHospitalityRequest.submitGiftHospitalityRequestActionStatus,

    // Gfit Hospitality Upload / Preview Url
    uploadUrl: state.giftHospitalityRequest.uploadUrl,
    previewImgPath: state.giftHospitalityRequest.previewImgPath,

    // Security Ticker Data
    securityTickerData: state.giftHospitalityRequest.securityTickerData,
    currencyArray: state.giftHospitalityRequest.currencyArray,
    currencyOptions: state.giftHospitalityRequest.currencyOptions,
    securityTickerArray: state.giftHospitalityRequest.securityTickerArray,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Gift Hospitality Request
    loadGiftHospitalityRequest: () => dispatch(loadGiftHospitalityRequest()),
    initLoadGiftHospitalityRequestActionStatus: () => dispatch(initLoadGiftHospitalityRequestActionStatus()),

    // Select Gift Hospitality Request
    selectGiftHospitalityRequest: (payload) => dispatch(selectGiftHospitalityRequest(payload)),

    // Open/Reset Gift Hospitality Request Modal Dialog
    openGiftHospitalityRequestEditModalDialog: (isAddMode, giftHospitalityRequest) => dispatch(openGiftHospitalityRequestEditModalDialog(isAddMode, giftHospitalityRequest)),
    resetGiftHospitalityRequestEditModalDialog: () => dispatch(resetGiftHospitalityRequestEditModalDialog()),

    // Gift Hospitality Request Edit Change Input
    giftHospitalityRequestEditChangeInput: (input) => dispatch(giftHospitalityRequestEditChangeInput(input)),

    // =============================================================
    // Create Gift Hospitality Request
    createGiftHospitalityRequest: (giftHospitalityRequest) => dispatch(createGiftHospitalityRequest(giftHospitalityRequest)),
    initCreateGiftHospitalityRequestActionStatus: () => dispatch(initCreateGiftHospitalityRequestActionStatus()),

    // Update Gift Hospitality Request
    updateGiftHospitalityRequest: (giftHospitalityRequest) => dispatch(updateGiftHospitalityRequest(giftHospitalityRequest)),
    initUpdateGiftHospitalityRequestActionStatus: () => dispatch(initUpdateGiftHospitalityRequestActionStatus()),

    // Batch Delete Gift Hospitality Request
    batchDeleteGiftHospitalityRequest: (giftHospitalityRequestIdList) => dispatch(batchDeleteGiftHospitalityRequest(giftHospitalityRequestIdList)),
    initBatchDeleteGiftHospitalityRequestActionStatus: () => dispatch(initBatchDeleteGiftHospitalityRequestActionStatus()),

    // Submit Gift Hospitality Request
    submitGiftHospitalityRequest: (giftHospitalityRequestIdList) => dispatch(submitGiftHospitalityRequest(giftHospitalityRequestIdList)),
    initSubmitGiftHospitalityRequestActionStatus: () => dispatch(initSubmitGiftHospitalityRequestActionStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftHospitalityRequestDashboard);