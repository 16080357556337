const EntityMap = {
  fromArray(entities, keyPropName = 'key') {
    return EntityMap.merge({ byKey: {}, keys: [] }, entities, keyPropName);
  },

  merge(source, entities, keyPropName = 'key') {
    if (!entities || !entities.length) return source;

    return {
      byKey: {
        ...source.byKey,
        ...entities.reduce((acc, entity) => {
          acc[entity[keyPropName]] = entity;
          return acc;
        }, {})
      },
      keys: [
        ...new Set(
          entities
            .map(entity => '' + entity[keyPropName]) // The byKey object stores key as string, thus the keys also need to be string.
            .filter(key => !source.keys.includes(key))
            .concat(source.keys)
        )
      ]
    };
  },

  remove(source, keys) {
    if (!keys || !keys.length) return source;

    const filteredKeys = source.keys.filter(
      originalKey => !keys.includes(originalKey)
    );
    return {
      byKey: filteredKeys.reduce((acc, key) => {
        acc[key] = source.byKey[key];
        return acc;
      }, {}),
      keys: filteredKeys
    };
  },

  get(source, key, fallback = {}) {
    const item = (source.byKey && source.byKey[key]) || fallback;
    return item;
  },

  map(source, fn = e => e, limit = source.keys.length, offset = 0) {
    return source.keys
      .slice(offset, offset + limit)
      .map(key => fn(source.byKey[key], key));
  },

  filter(source, fn = _ => true) {
    return source.keys.reduce((acc, key) => {
      if (fn(source.byKey[key], key)) {
        acc.push(source.byKey[key]);
      }
      return acc;
    }, []);
  },

  apply(source, fn = e => e) {
    return {
      byKey: source.keys.reduce((acc, key) => {
        acc[key] = fn(source.byKey[key], key);
        return acc;
      }, {}),
      keys: source.keys
    };
  }
};

export default EntityMap;
