import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';

import {
  // Load Restricted Security Request
  START_LOAD_RESTRICTED_SECURITY_REQUEST,
  LOAD_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  LOAD_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_LOAD_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Select Restricted Security Request
  SELECT_RESTRICTED_SECURITY_REQUEST,

  // Open/Reset Restricted Security Request Modal Dialog
  RESTRICTED_SECURITY_REQUEST_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_REQUEST_MODAL_DIALOG_RESET,

  // Restricted Security Request Edit Change Input
  RESTRICTED_SECURITY_REQUEST_EDIT_CHANGE_INPUT,

  // Create Restricted Security Request
  START_CREATE_RESTRICTED_SECURITY_REQUEST,
  CREATE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  CREATE_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_CREATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Update Restricted Security Request
  START_UPDATE_RESTRICTED_SECURITY_REQUEST,
  UPDATE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  UPDATE_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_UPDATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Batch Delete Restricted Security Request
  START_BATCH_DELETE_RESTRICTED_SECURITY_REQUEST,
  BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Submit Restricted Security Request
  START_SUBMIT_RESTRICTED_SECURITY_REQUEST,
  SUBMIT_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  SUBMIT_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_SUBMIT_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Load Restricted Security BBG Tickers
  START_LOAD_RESTRICTED_SECURITY_BBG_TICKERS,
  LOAD_RESTRICTED_SECURITY_BBG_TICKERS_SUCCESS,
  LOAD_RESTRICTED_SECURITY_BBG_TICKERS_FAILURE,
  INIT_LOAD_RESTRICTED_SECURITY_BBG_TICKERS_ACTION_STATUS,

  // Clear Restricted Security BBG Ticker
  CLEAR_RESTRICTED_SECURITY_BBG_TICKERS,

  // Restricted Security BBG Ticker Select Changed
  RESTRICTED_SECURITY_BBG_TICKER_SELECT_CHANGED,

  // Restricted Security Fund Code Select Changed
  RESTRICTED_SECURITY_FUND_CODE_SELECT_CHANGED,

  // Restricted Security Book Code Select Changed
  RESTRICTED_SECURITY_BOOK_CODE_SELECT_CHANGED,

  // Open/Reset Restricted Security Request Operate Modal Dialog
  RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_RESET,

  // On List Restricted Security Request
  START_ON_LIST_RESTRICTED_SECURITY_REQUEST,
  ON_LIST_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  ON_LIST_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_ON_LIST_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Off List Restricted Security Request
  START_OFF_LIST_RESTRICTED_SECURITY_REQUEST,
  OFF_LIST_RESTRICTED_SECURITY_REQUEST_SUCCESS,
  OFF_LIST_RESTRICTED_SECURITY_REQUEST_FAILURE,
  INIT_OFF_LIST_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS,

  // Restricted Security Operate Edit Change Input
  RESTRICTED_SECURITY_OPERATE_EDIT_CHANGE_INPUT,

  // Open/Reset Restricted Security List Check Modal Dialog
  RESTRICTED_SECURITY_LIST_CHECK_MODAL_DIALOG_OPEN,
  RESTRICTED_SECURITY_LIST_CHECK_MODAL_DIALOG_RESET,

  // Search Valid Restricted Security 
  START_SEARCH_VALID_RESTRICTED_SECURITY,
  SEARCH_VALID_RESTRICTED_SECURITY_SUCCESS,
  SEARCH_VALID_RESTRICTED_SECURITY_FAILURE,
  INIT_SEARCH_VALID_RESTRICTED_SECURITY_ACTION_STATUS,

} from './restrictedSecurityRequestConstants';

// Load Restricted Security Request
function startLoadRestrictedSecurityRequest() {
  return {
    type: START_LOAD_RESTRICTED_SECURITY_REQUEST
  };
}

export function loadRestrictedSecurityRequest() {
  return function(dispatch) {
    dispatch(startLoadRestrictedSecurityRequest());
    httpClient
      .get(UrlConfig.LOAD_RESTRICTED_SECURITIES)
      .then(resp => dispatch(loadRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(loadRestrictedSecurityRequestFailure(err)));
  };
}

function loadRestrictedSecurityRequestSuccess(resp) {
  return {
    type: LOAD_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function loadRestrictedSecurityRequestFailure(err) {
  return {
    type: LOAD_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initLoadRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_LOAD_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}

// Select Restricted Security Request
export function selectRestrictedSecurityRequest(payload) {
  return {
    type: SELECT_RESTRICTED_SECURITY_REQUEST,
    payload
  };
}

// Open/Reset Restricted Security Request Modal Dialog
export function openRestrictedSecurityRequestEditModalDialog(isAddMode, restrictedSecurityRequest) {
  return {
    type: RESTRICTED_SECURITY_REQUEST_MODAL_DIALOG_OPEN,
    payload: {
      isAddMode,
      restrictedSecurityRequest,
    },
  };
}

export function resetRestrictedSecurityRequestEditModalDialog() {
  return {
    type: RESTRICTED_SECURITY_REQUEST_MODAL_DIALOG_RESET
  };
}

// Restricted Security Request Edit Change Input
export function restrictedSecurityRequestEditChangeInput({ name, value }) {
  return {
    type: RESTRICTED_SECURITY_REQUEST_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// =============================================================
// Create Restricted Security Request
function startCreateRestrictedSecurityRequest() {
  return {
    type: START_CREATE_RESTRICTED_SECURITY_REQUEST
  };
}

export function createRestrictedSecurityRequest(restrictedSecurityRequest) {
  return function(dispatch) {
    dispatch(startCreateRestrictedSecurityRequest());
    httpClient
      .post(UrlConfig.NEW_RESTRICTED_SECURITY, restrictedSecurityRequest)
      .then(resp => dispatch(createRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(createRestrictedSecurityRequestFailure(err)));
  };
}

function createRestrictedSecurityRequestSuccess(resp) {
  return {
    type: CREATE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function createRestrictedSecurityRequestFailure(err) {
  return {
    type: CREATE_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initCreateRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_CREATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}

// Update Restricted Security Request
function startUpdateRestrictedSecurityRequest() {
  return {
    type: START_UPDATE_RESTRICTED_SECURITY_REQUEST
  };
}

export function updateRestrictedSecurityRequest(restrictedSecurityRequest) {
  return function(dispatch) {
    dispatch(startUpdateRestrictedSecurityRequest());
    httpClient
      .post(UrlConfig.UPDATE_RESTRICTED_SECURITY, restrictedSecurityRequest)
      .then(resp => dispatch(updateRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(updateRestrictedSecurityRequestFailure(err)));
  };
}

function updateRestrictedSecurityRequestSuccess(resp) {
  return {
    type: UPDATE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function updateRestrictedSecurityRequestFailure(err) {
  return {
    type: UPDATE_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initUpdateRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_UPDATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}

// Batch Delete Restricted Security Request
function startBatchDeleteRestrictedSecurityRequest() {
  return {
    type: START_BATCH_DELETE_RESTRICTED_SECURITY_REQUEST
  };
}

export function batchDeleteRestrictedSecurityRequest(restrictedSecGuidList) {
  return function(dispatch) {
    dispatch(startBatchDeleteRestrictedSecurityRequest());
    httpClient
      .post(UrlConfig.BATCH_DEL_RESTRICTED_SECURITIES, restrictedSecGuidList)
      .then(resp => dispatch(batchDeleteRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(batchDeleteRestrictedSecurityRequestFailure(err)));
  };
}

function batchDeleteRestrictedSecurityRequestSuccess(resp) {
  return {
    type: BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function batchDeleteRestrictedSecurityRequestFailure(err) {
  return {
    type: BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initBatchDeleteRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}

// Submit Restricted Security Request
function startSubmitRestrictedSecurityRequest() {
  return {
    type: START_SUBMIT_RESTRICTED_SECURITY_REQUEST
  };
}

export function submitRestrictedSecurityRequest(restrictedSecurityRequestIdList) {
  return function(dispatch) {
    dispatch(startSubmitRestrictedSecurityRequest());
    httpClient
      .post(UrlConfig.SUBMIT_RESTRICTED_SECURITIES, restrictedSecurityRequestIdList)
      .then(resp => dispatch(submitRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(submitRestrictedSecurityRequestFailure(err)));
  };
}

function submitRestrictedSecurityRequestSuccess(resp) {
  return {
    type: SUBMIT_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function submitRestrictedSecurityRequestFailure(err) {
  return {
    type: SUBMIT_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initSubmitRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_SUBMIT_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}

// =================================================================
// Load Restricted Security BBG Tickers
function startLoadRestrictedSecurityBbgTickers() {
  return {
    type: START_LOAD_RESTRICTED_SECURITY_BBG_TICKERS,
  };
}

export function loadRestrictedSecurityBbgTickers(queryText) {
  const requestParams = {
    'query-text': queryText,
    'page': 'restrictedSecurityRequest',
    'source': 'Report',
  };

  return function(dispatch) {
    dispatch(startLoadRestrictedSecurityBbgTickers());
    httpClient
      .get(UrlConfig.QUERY_BBG_TICKERS, requestParams)
      .then(resp => dispatch(loadRestrictedSecurityBbgTickersSuccess(resp)))
      .catch(err => dispatch(loadRestrictedSecurityBbgTickersFailure(err)));
  };
}

function loadRestrictedSecurityBbgTickersSuccess(resp) {
  return {
    type: LOAD_RESTRICTED_SECURITY_BBG_TICKERS_SUCCESS,
    payload: resp
  };
}

function loadRestrictedSecurityBbgTickersFailure(err) {
  return {
    type: LOAD_RESTRICTED_SECURITY_BBG_TICKERS_FAILURE,
    payload: err
  };
}

export function initLoadRestrictedSecurityBbgTickersActionStatus() {
  return {
    type: INIT_LOAD_RESTRICTED_SECURITY_BBG_TICKERS_ACTION_STATUS
  };
}

// Clear Restricted Security bbgTickerArray bbgTickerOptions
export function clearRestrictedSecurityBbgTickers() {
  return {
    type: CLEAR_RESTRICTED_SECURITY_BBG_TICKERS,
  };
}

// Restricted Security BBG Ticker Select Changed
export function restrictedSecurityBbgTickerSelectChanged(bbgTicker) {
  return {
    type: RESTRICTED_SECURITY_BBG_TICKER_SELECT_CHANGED,
    payload: bbgTicker
  };
}

// Restricted Security Fund Code Select Changed
export function restrictedSecurityFundCodeSelectChanged(fundCode) {
  return {
    type: RESTRICTED_SECURITY_FUND_CODE_SELECT_CHANGED,
    payload: fundCode
  };
}

// Restricted Security Book Code Select Changed
export function restrictedSecurityBookCodeSelectChanged(bookCode) {
  return {
    type: RESTRICTED_SECURITY_BOOK_CODE_SELECT_CHANGED,
    payload: bookCode
  };
}

// Open/Reset Restricted Security Request Operate Modal Dialog
export function openRestrictedSecurityRequestOperateModalDialog(operateMode, restrictedSecGuidList, reason) {
  return {
    type: RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_OPEN,
    payload: {
      operateMode,
      restrictedSecGuidList,
      reason,
    },
  };
}

export function resetRestrictedSecurityRequestOperateModalDialog() {
  return {
    type: RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_RESET
  };
}

// On List Restricted Security Request
function startOnListRestrictedSecurityRequest() {
  return {
    type: START_ON_LIST_RESTRICTED_SECURITY_REQUEST
  };
}

export function onListRestrictedSecurityRequest(restrictedSecGuidList, reason) {
  const requestParams = {
    restrictedSecGuidList, 
    reason,
  };

  return function(dispatch) {
    dispatch(startOnListRestrictedSecurityRequest());
    httpClient
      .post(UrlConfig.ON_LIST_RESTRICTED_SECURITIES, requestParams)
      .then(resp => dispatch(onListRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(onListRestrictedSecurityRequestFailure(err)));
  };
}

function onListRestrictedSecurityRequestSuccess(resp) {
  return {
    type: ON_LIST_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function onListRestrictedSecurityRequestFailure(err) {
  return {
    type: ON_LIST_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initOnListRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_ON_LIST_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}

// Off List Restricted Security Request
function startOffListRestrictedSecurityRequest() {
  return {
    type: START_OFF_LIST_RESTRICTED_SECURITY_REQUEST
  };
}

export function offListRestrictedSecurityRequest(params) {
  return function(dispatch) {
    dispatch(startOffListRestrictedSecurityRequest());
    httpClient
      .post(UrlConfig.OFF_LIST_RESTRICTED_SECURITIES, params)
      .then(resp => dispatch(offListRestrictedSecurityRequestSuccess(resp)))
      .catch(err => dispatch(offListRestrictedSecurityRequestFailure(err)));
  };
}

function offListRestrictedSecurityRequestSuccess(resp) {
  return {
    type: OFF_LIST_RESTRICTED_SECURITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function offListRestrictedSecurityRequestFailure(err) {
  return {
    type: OFF_LIST_RESTRICTED_SECURITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initOffListRestrictedSecurityRequestActionStatus() {
  return {
    type: INIT_OFF_LIST_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS
  };
}

// Restricted Security Operate Edit Change Input
export function restrictedSecurityOperateEditChangeInput({ name, value }) {
  return {
    type: RESTRICTED_SECURITY_OPERATE_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// Open/Reset Restricted Security List Check Modal Dialog
export function openRestrictedSecurityListCheckModalDialog() {
  return {
    type: RESTRICTED_SECURITY_LIST_CHECK_MODAL_DIALOG_OPEN,
    payload: {
    },
  };
}

export function resetRestrictedSecurityListCheckModalDialog() {
  return {
    type: RESTRICTED_SECURITY_LIST_CHECK_MODAL_DIALOG_RESET
  };
}

// Search Valid Restricted Security 
function startSearchValidRestrictedSecurity() {
  return {
    type: START_SEARCH_VALID_RESTRICTED_SECURITY
  };
}

export function searchValidRestrictedSecurity(queryText) {
  const requestParams = {
    queryText, 
  };

  return function(dispatch) {
    dispatch(startSearchValidRestrictedSecurity());
    httpClient
      .post(UrlConfig.SEARCH_VALID_RESTRICTED_SECURITY, requestParams)
      .then(resp => dispatch(searchValidRestrictedSecuritySuccess(resp)))
      .catch(err => dispatch(searchValidRestrictedSecurityFailure(err)));
  };
}

function searchValidRestrictedSecuritySuccess(resp) {
  return {
    type: SEARCH_VALID_RESTRICTED_SECURITY_SUCCESS,
    payload: resp
  };
}

function searchValidRestrictedSecurityFailure(err) {
  return {
    type: SEARCH_VALID_RESTRICTED_SECURITY_FAILURE,
    payload: err
  };
}

export function initSearchValidRestrictedSecurityActionStatus() {
  return {
    type: INIT_SEARCH_VALID_RESTRICTED_SECURITY_ACTION_STATUS
  };
}