// Load Restricted Security Request
export const START_LOAD_RESTRICTED_SECURITY_REQUEST = 'START_LOAD_RESTRICTED_SECURITY_REQUEST';
export const LOAD_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'LOAD_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const LOAD_RESTRICTED_SECURITY_REQUEST_FAILURE = 'LOAD_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_LOAD_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_LOAD_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// Select Restricted Security Request
export const SELECT_RESTRICTED_SECURITY_REQUEST = 'SELECT_RESTRICTED_SECURITY_REQUEST';

// Open/Reset Restricted Security Request Modal Dialog
export const RESTRICTED_SECURITY_REQUEST_MODAL_DIALOG_OPEN = 'RESTRICTED_SECURITY_REQUEST_MODAL_DIALOG_OPEN';
export const RESTRICTED_SECURITY_REQUEST_MODAL_DIALOG_RESET = 'RESTRICTED_SECURITY_REQUEST_MODAL_DIALOG_RESET';

// Restricted Security Request Edit Change Input
export const RESTRICTED_SECURITY_REQUEST_EDIT_CHANGE_INPUT = 'RESTRICTED_SECURITY_REQUEST_EDIT_CHANGE_INPUT';

// Create Restricted Security Request
export const START_CREATE_RESTRICTED_SECURITY_REQUEST = 'START_CREATE_RESTRICTED_SECURITY_REQUEST';
export const CREATE_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'CREATE_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const CREATE_RESTRICTED_SECURITY_REQUEST_FAILURE = 'CREATE_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_CREATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_CREATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// Update Restricted Security Request
export const START_UPDATE_RESTRICTED_SECURITY_REQUEST = 'START_UPDATE_RESTRICTED_SECURITY_REQUEST';
export const UPDATE_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'UPDATE_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const UPDATE_RESTRICTED_SECURITY_REQUEST_FAILURE = 'UPDATE_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_UPDATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_UPDATE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// Batch Delete Restricted Security Request
export const START_BATCH_DELETE_RESTRICTED_SECURITY_REQUEST = 'START_BATCH_DELETE_RESTRICTED_SECURITY_REQUEST';
export const BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_FAILURE = 'BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_BATCH_DELETE_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// Submit Restricted Security Request
export const START_SUBMIT_RESTRICTED_SECURITY_REQUEST = 'START_SUBMIT_RESTRICTED_SECURITY_REQUEST';
export const SUBMIT_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'SUBMIT_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const SUBMIT_RESTRICTED_SECURITY_REQUEST_FAILURE = 'SUBMIT_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_SUBMIT_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_SUBMIT_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// Load Restricted Security BBG Tickers
export const START_LOAD_RESTRICTED_SECURITY_BBG_TICKERS = 'START_LOAD_RESTRICTED_SECURITY_BBG_TICKERS';
export const LOAD_RESTRICTED_SECURITY_BBG_TICKERS_SUCCESS = 'LOAD_RESTRICTED_SECURITY_BBG_TICKERS_SUCCESS';
export const LOAD_RESTRICTED_SECURITY_BBG_TICKERS_FAILURE = 'LOAD_RESTRICTED_SECURITY_BBG_TICKERS_FAILURE';
export const INIT_LOAD_RESTRICTED_SECURITY_BBG_TICKERS_ACTION_STATUS = 'INIT_LOAD_RESTRICTED_SECURITY_BBG_TICKERS_ACTION_STATUS';

// Clear Restricted Security BBG Ticker
export const CLEAR_RESTRICTED_SECURITY_BBG_TICKERS = 'CLEAR_RESTRICTED_SECURITY_BBG_TICKERS';

// Restricted Security BBG Ticker Select Changed
export const RESTRICTED_SECURITY_BBG_TICKER_SELECT_CHANGED = 'RESTRICTED_SECURITY_BBG_TICKER_SELECT_CHANGED';

// Restricted Security Fund Code Select Changed
export const RESTRICTED_SECURITY_FUND_CODE_SELECT_CHANGED = 'RESTRICTED_SECURITY_FUND_CODE_SELECT_CHANGED';

// Restricted Security Book Code Select Changed
export const RESTRICTED_SECURITY_BOOK_CODE_SELECT_CHANGED = 'RESTRICTED_SECURITY_BOOK_CODE_SELECT_CHANGED';

// Open/Reset Restricted Security Request Operate Modal Dialog
export const RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_OPEN = 'RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_OPEN';
export const RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_RESET = 'RESTRICTED_SECURITY_REQUEST_OPERATE_MODAL_DIALOG_RESET';

// On List Restricted Security Request
export const START_ON_LIST_RESTRICTED_SECURITY_REQUEST = 'START_ON_LIST_RESTRICTED_SECURITY_REQUEST';
export const ON_LIST_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'ON_LIST_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const ON_LIST_RESTRICTED_SECURITY_REQUEST_FAILURE = 'ON_LIST_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_ON_LIST_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_ON_LIST_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// Off List Restricted Security Request
export const START_OFF_LIST_RESTRICTED_SECURITY_REQUEST = 'START_OFF_LIST_RESTRICTED_SECURITY_REQUEST';
export const OFF_LIST_RESTRICTED_SECURITY_REQUEST_SUCCESS = 'OFF_LIST_RESTRICTED_SECURITY_REQUEST_SUCCESS';
export const OFF_LIST_RESTRICTED_SECURITY_REQUEST_FAILURE = 'OFF_LIST_RESTRICTED_SECURITY_REQUEST_FAILURE';
export const INIT_OFF_LIST_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS = 'INIT_OFF_LIST_RESTRICTED_SECURITY_REQUEST_ACTION_STATUS';

// Restricted Security Operate Edit Change Input
export const RESTRICTED_SECURITY_OPERATE_EDIT_CHANGE_INPUT = 'RESTRICTED_SECURITY_OPERATE_EDIT_CHANGE_INPUT';

// Open/Reset Restricted Security List Check Modal Dialog
export const RESTRICTED_SECURITY_LIST_CHECK_MODAL_DIALOG_OPEN = 'RESTRICTED_SECURITY_LIST_CHECK_MODAL_DIALOG_OPEN';
export const RESTRICTED_SECURITY_LIST_CHECK_MODAL_DIALOG_RESET = 'RESTRICTED_SECURITY_LIST_CHECK_MODAL_DIALOG_RESET';

// Search Valid Restricted Security 
export const START_SEARCH_VALID_RESTRICTED_SECURITY = 'START_SEARCH_VALID_RESTRICTED_SECURITY';
export const SEARCH_VALID_RESTRICTED_SECURITY_SUCCESS = 'SEARCH_VALID_RESTRICTED_SECURITY_SUCCESS';
export const SEARCH_VALID_RESTRICTED_SECURITY_FAILURE = 'SEARCH_VALID_RESTRICTED_SECURITY_FAILURE';
export const INIT_SEARCH_VALID_RESTRICTED_SECURITY_ACTION_STATUS = 'INIT_SEARCH_VALID_RESTRICTED_SECURITY_ACTION_STATUS';