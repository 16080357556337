import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Select, Button, AutoComplete, DatePicker, Upload, Icon,InputNumber,Tooltip } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import moment from 'moment';


const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


class AddOrEditMeetingAndTrainingModalDialog extends Component {

    componentDidMount() {
    }

    refreshBtnOnClicked = () => {
        // const {
        //     id,
        //     type,
        //     starttime,
        //     endtime,
        //     speaker,
        //     uploadRealName,
            
        // } = this.props.addOrEditMeetingAndTrainingModal.fields;
        // const {
        //     type,
        //     starttime,
        //     endtime,
        //     speaker,
        //     status
        // } = this.props.addOrEditMeetingAndTrainingModal;
        this.props.loadMeetingAndTrainingInfo({type:undefined,starttime:undefined,endtime:undefined,speaker:undefined})



        // const {
        //     userName,
        //     status
        // } = this.props.meetingAndTrainingQueryFields;
        // this.props.loadMeetingAndTrainingInfo();
    }


    componentDidUpdate() {
        const {
            submitSaveMeetingAndTrainingActionStatus,
        } = this.props;
        const {
            mode,
        } = this.props.addOrEditMeetingAndTrainingModal;

        if(submitSaveMeetingAndTrainingActionStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getActionFromMode(mode) + ' General Agreement succeeded.';
            message.success(msg);
            this.props.initSubmitSaveMeetingAndTrainingModalActionStatus();
            this.props.resetSubmitSaveMeetingAndTrainingModal();
            this.refreshBtnOnClicked();
        }
        if(submitSaveMeetingAndTrainingActionStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitSaveMeetingAndTrainingModalActionStatus();
        }
    }


    getActionFromMode(mode) {
        if(mode === 'Remark') {
            return 'Remark';
        } else if(mode === 'Feedback') {
            return 'Feedback';
        } 
    }


    generalAgreeementHandleChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'agreementUploadFileList', value: []});
            this.onInputChange({name: 'uploadRealName', value: undefined});
            this.onInputChange({name: 'uploadOriName', value: undefined});
            return;
        }

        let attachment = '';
        let attachmentOriginName= '';
        fileList.forEach(file => {
          if (file.response) {
            const filename = file.response.data.fileNameWithTimeStamp;
            const originalFileName = file.response.data.originalFileName;
            attachment = attachment + ";" + filename;
            attachmentOriginName = attachmentOriginName + ";" + originalFileName;
          }
        });
        this.onInputChange({name: 'agreementUploadFileList', value: fileList});
        this.onInputChange({name: 'uploadRealName', value: attachment});
        this.onInputChange({name: 'uploadOriName', value: attachmentOriginName});

    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditGeneralAgreemenChangeInput({name, value});
    }
    onPICInputChange = (val) => {
        const value = !val ? undefined : val.join(';');
        this.props.addOrEditGeneralAgreemenChangeInput({name: 'personInCharge', value});
    }


    submitSaveMeetingAndTrainingModal = (needSubmit) => {
        const {
            mode,
        } = this.props.addOrEditMeetingAndTrainingModal;
        const {
            id,
            status,
            title,
            note,
            subtitle,
            comments,
            uploadOriName,
            uploadRealName,
            feedbackScore,
            feedbackComments,

            
        } = this.props.addOrEditMeetingAndTrainingModal.fields;

        if(mode === 'EDIT' && status === 'Valid' && !needSubmit ) {
            const msg = 'For the General Agreements in Valid status, you can only Submit the edit.';
            message.warn(msg);
            return;
        }

        // 通过Report端ADD、COPY、EDIT，可以同时SUBMIT
        let modes = mode;
        if(needSubmit) {
            modes += ',SUBMIT';
        }

        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            // 在addOrEdit页面添加附件后又删除，Form控件会检查不出来，要单独检查
            // if(!uploadOriName || !uploadRealName) {
            //     message.error('Upload of  is required');
            //     return;
            // }

            this.props.submitSaveMeetingAndTrainingModal(mode, {
                id,
                title,
                note,
                subtitle,
                comments,
                uploadOriName,
                uploadRealName,
                feedbackScore,
                feedbackComments,
            });
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            departmentOptions,
            companyOptions,
            agreementTypeOptions,
            userAccountOptions,
            ccyOptions,
            meetingAndTrainingUploadUrl,
            addOrEditMeetingAndTrainingModal,
            submitSaveMeetingAndTrainingActionStatus,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditMeetingAndTrainingModal;

        const {
            id,
            status,
            title,
            desc,
            comperename,
            starttime,
            address,
            note,
            subtitle,
            filepath,
            uploadOriName,
            feedbackScore,
            feedbackComments,
            uploadRealName,
            amount,
            amountCCY,
            remarks,
            comments,
            agreementUploadFileList,
            submit2ValidTimes,
        } = addOrEditMeetingAndTrainingModal.fields;

        // const executionDateMonent = !executionDate ? undefined : moment(executionDate);
        // const expiryDateMonent = !expiryDate ? undefined : moment(expiryDate);

      
        const modalTitle = this.getActionFromMode(mode) + ' this record';
        const isSubmitLoading = submitSaveMeetingAndTrainingActionStatus === ACTION_STATUS.LOGINING;

        const showRemark = mode === 'Remark';
        const showFeedback = mode === 'Remark';
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetSubmitSaveMeetingAndTrainingModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetSubmitSaveMeetingAndTrainingModal() }>
                            Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveMeetingAndTrainingModal(false) }>
                            Save
                        </Button>,
                        // <Button key="SaveAndSubmit" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveMeetingAndTrainingModal(true) }>
                        //     Save&nbsp;&amp;&nbsp;Submit
                        // </Button>,
                    ]}
                >
                    <Form layout="horizontal">

                        {/* <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="title">
                                    {getFieldDecorator('title', {
                                        initialValue: title,
                                        rules: [{
                                            required: true,
                                            message: 'Please input title',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 250, marginLeft: 10 }}
                                            disabled={needDisable}
                                            rows={4} 
                                            placeholder='Please input title' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'title', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                            <FormItem {...formItemLayout} label="Desc">
                                    {getFieldDecorator('desc', {
                                        initialValue: desc,
                                        rules: [{
                                            required: true,
                                            message: 'Please input title',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 250, marginLeft: 10 }}
                                            disabled={needDisable}
                                            rows={4} 
                                            placeholder='Please input title' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'desc', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Compere Name">
                                {getFieldDecorator('comperename', {
                                    initialValue: comperename,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Compere Name',
                                    }],
                                    })(
                                    <Select
                                            allowClear
                                            showSearch
                                            style={{ width: 300, marginLeft: 10 }}
                                            disabled={needDisable}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'comperename', value: val })
                                            }} 
                                            placeholder="Please select Compere Name">
                                        {userAccountOptions}
                                    </Select>
                                    )}
                                </FormItem>
                            </Col>
                            
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Location">
                                    {getFieldDecorator('address', {
                                        initialValue: address,
                                        rules: [{
                                            required: false,
                                            message: 'Please input Location',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 250, marginLeft: 10 }}
                                            disabled={needDisable}
                                            rows={3} 
                                            placeholder='Please input Location' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'address', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>

                        </Row> */}
                    {
                     showRemark && 
                     <div>
                        <Row gutter={24}>
                        <Col span={18}>
                                <FormItem {...formItemLayout} label={
                                    <span>
                                    Sub Title&nbsp;
                                    <Tooltip title="Training theme and number of class">
                                    <Icon type="question-circle-o" />
                                    </Tooltip>
                                </span>
                                }>
                                    {getFieldDecorator('sub title', {
                                        initialValue: subtitle,
                                        rules: [{
                                            required: true,
                                            message: 'Please input title',
                                        }],
                                        })(
                                            <Input
                                            placeholder='Training theme and number of class' 
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'subtitle', value: e.target.value })
                                            }}
                                        />
                                        )}
                                      
                                </FormItem>
                        </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={18}>
                                <FormItem {...formItemLayout} label=" What did you learn?">
                                    {getFieldDecorator('note', {
                                        initialValue: note,
                                        rules: [{
                                            required: true,
                                            message: 'Please input you learn',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 1000, marginLeft: 10 }}
                                            // disabled={needDisable}
                                            rows={5} 
                                            placeholder='Please input you learn' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'note', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={18}>
                                <FormItem {...formItemLayout} label="Coach's comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: true,
                                            message: 'Please input Coach comments',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 1000, marginLeft: 10 }}
                                            // disabled={needDisable}
                                            rows={5} 
                                            placeholder='Please input Coach comments' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={18}>
                                <FormItem {...formItemLayout} label="upload">
                                    {getFieldDecorator('uploadOriName', {
                                        initialValue: uploadOriName,
                                        rules: [{
                                            required: false,
                                            message: 'Please upload the Agreement',
                                        }],
                                        })(
                                        <Upload
                                            name="uploadOriName"
                                            withCredentials={true}
                                            // disabled={needDisable}
                                            multiple={true}
                                            showUploadList={true}
                                            fileList={agreementUploadFileList}
                                            action={meetingAndTrainingUploadUrl}
                                            onChange={this.generalAgreeementHandleChange}
                                        >
                                            <Button 
                                                style={{ width: 250, marginLeft: 10 }}
                                                // disabled={needDisable}
                                            >
                                                <Icon type="upload" />Upload  Attachment
                                            </Button>
                                        </Upload>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <p>1.Maximum Attachment File Size: 10MB<br/>
                                   2.Support for multiple files<br/>                
                                   3.Maximum Attachment File Name Length: 80 English characters or 40 Chinese characters</p>
                            </Col>
                            
                        </Row>
                        </div>
                        }
                        {
                            !showRemark && 
                            <div>
                            <Row gutter={24}>
                                <Col span={18}>
                                    <FormItem {...formItemLayout} label="score">
                                        {getFieldDecorator('score', {
                                            initialValue: feedbackScore===null?3:feedbackScore,
                                            rules: [{
                                                required: true,
                                                message: 'Please input Remark',
                                            }],
                                            })(
                                            <InputNumber
                                                defaultValue={3}
                                                min={1}
                                                max={5}
                                            
                                                style={{ width: 200, marginLeft: 10 }}
                                                onChange={(val) => {
                                                    this.onInputChange({ name: 'feedbackScore', value: val })
                                                }}
                                            />
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>

                            <Row gutter={24}>
                                <Col span={18}>
                                    <FormItem {...formItemLayout} label="Feedback">
                                        {getFieldDecorator('note', {
                                            initialValue: feedbackComments,
                                            rules: [{
                                                required: false,
                                                message: 'Please input FeedbackComments',
                                            }],
                                            })(
                                            <TextArea 
                                                style={{ width: 1000, marginLeft: 10 }}
                                                // disabled={needDisable}
                                                rows={5} 
                                                placeholder='Please input FeedbackComments' 
                                                onChange={(e) => {
                                                    this.onInputChange({ name: 'feedbackComments', value: e.target.value })
                                            }}/>
                                        )}
                                    </FormItem>
                                </Col>
                            </Row>
                            </div>
                        }
                                    
                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}

const AddOrEditMeetingAndTrainingModalDialogForm = Form.create({ name: 'AddOrEditMeetingAndTrainingModalDialog' })(AddOrEditMeetingAndTrainingModalDialog);
export default AddOrEditMeetingAndTrainingModalDialogForm;
