import React, { Component } from 'react';

import moment from 'moment';
import { Form, Row, Col, Input, Button, Modal, Select, DatePicker, Upload, AutoComplete, Icon, message } from 'antd';
import { Message } from 'semantic-ui-react'

import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { RESTRICTION_TYPE_MAP } from '../../../../utils/RestrictionTypeUtil';
import { localStorageClient } from '../../../../utils/localStorageClient';

const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 14 },
};

// Restriction Type
const RestrictionTypeOptions = [];
for (let i = 0; i < RESTRICTION_TYPE_MAP.length; i++) {
  RestrictionTypeOptions.push(<Option key={RESTRICTION_TYPE_MAP[i].code}>{RESTRICTION_TYPE_MAP[i].label}</Option>);
}


class RestrictedSecurityRequestEditDialog extends Component {
  state = {
    needOnList: false,
  };

  componentDidUpdate() {
    const { 
      createRestrictedSecurityRequestActionStatus,
      updateRestrictedSecurityRequestActionStatus,
    } = this.props;

    // 创建 Restricted Security 成功
    if (createRestrictedSecurityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.loadRestrictedSecurityRequest();
      this.props.initCreateRestrictedSecurityRequestActionStatus();

      this.props.resetRestrictedSecurityRequestEditModalDialog();
    }
      
    // 创建 Restricted Security 失败
    if (createRestrictedSecurityRequestActionStatus === ACTION_STATUS.ERROR) {
      this.props.initCreateRestrictedSecurityRequestActionStatus();
    }

    // 更新 Restricted Security 成功
    if (updateRestrictedSecurityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.loadRestrictedSecurityRequest();
      this.props.initUpdateRestrictedSecurityRequestActionStatus();

      this.props.resetRestrictedSecurityRequestEditModalDialog();
    } 

    // 更新 Restricted Security 失败
    if (updateRestrictedSecurityRequestActionStatus === ACTION_STATUS.ERROR) {
      this.props.initUpdateRestrictedSecurityRequestActionStatus();
    }
  }

  onInputChange = ({name, value}) => {
    this.props.restrictedSecurityRequestEditChangeInput({name, value});
  }

  bbgTickerOnChange = (value) => {
    if (!!!value) {
      this.props.clearRestrictedSecurityBbgTickers();
    }

    this.props.restrictedSecurityRequestEditChangeInput({ name: 'bbgTicker', value });
    this.props.form.resetFields();
    this.props.restrictedSecurityBbgTickerSelectChanged(value);
  }


  lastTimeoutID = null;
  bbgTickerOnSearch = (value) => {
    if (!!!value) {
      this.props.clearRestrictedSecurityBbgTickers();
      return;
    }
    this.props.restrictedSecurityRequestEditChangeInput({ name: 'bbgTicker', value });
    if(value.length < 2) {
      return;
    }
    // 输入完毕等待0.5秒后再查询
    // 无此控制会有以下bug：
    // 当输入 2007 时，会分别根据 20、200、2007进行查询
    // 根据2007的查询结果数据量少，最先返回
    // 根据20的查询结果数据量大，最后返回
    // 因此最终展示的，反而是根据20的查询结果，尽管输入的是2007
    if(!!this.lastTimeoutID) {
      clearTimeout(this.lastTimeoutID);
    }
    this.lastTimeoutID = setTimeout(() => {
      this.props.loadRestrictedSecurityBbgTickers(value);
    }, 500);
  }


  fundCodeOnChange = (value) => {
    this.props.restrictedSecurityRequestEditChangeInput({ name: 'fund', value });

    if (!!value) {
      this.props.restrictedSecurityFundCodeSelectChanged(value);
    }
  }

  bookCodeOnChange = (value) => {
    this.props.restrictedSecurityRequestEditChangeInput({ name: 'book', value });

    if (!!value) {
      this.props.restrictedSecurityBookCodeSelectChanged(value);
    }
  }

  // 提交数据
  submitButtonOnClicked = (needOnList) => {
    const {
      restrictedSecurityRequestEditControlModal,
    } = this.props;

    const isAddMode = restrictedSecurityRequestEditControlModal.isAddMode;

    const {
      guid,
      restrictionType,
      fund,
      book,
      personInCharge,
      securityId,
      bbgTicker,
      secName,
      validFrom,
      validTo,
      onListReason,
      relatedCompany,
      restrictedSecReqGuid,
      attachment,
      attachmentOriginName,
    } = restrictedSecurityRequestEditControlModal.fields;

    let source = 'Client';

    const form = this.props.form;

    // 表单校验
    form.validateFields((err) => {
        if (!err) {
          if (validTo === '') {
            validTo = '9999-12-31';
          }

          let needSendEmail = true;
          if (isAddMode) {
            this.props.createRestrictedSecurityRequest({
              guid,
              restrictionType,
              fund,
              book,
              personInCharge,
              securityId,
              bbgTicker,
              secName,
              validFrom,
              validTo,
              onListReason,
              relatedCompany,
              restrictedSecReqGuid,
              attachment,
              attachmentOriginName,
              needOnList,
              source,
              needSendEmail,
            });
          } else {
            this.props.updateRestrictedSecurityRequest({
              guid,
              restrictionType,
              fund,
              book,
              personInCharge,
              securityId,
              bbgTicker,
              secName,
              validFrom,
              validTo,
              onListReason,
              relatedCompany,
              restrictedSecReqGuid,
              attachment,
              attachmentOriginName,
              needOnList,
              source,
              needSendEmail,
            });
          }
        }
      },
    );
  }

  handleSaveRestrictedSecurity = () => {
    this.setState({
      needOnList: false,
    });

    this.submitButtonOnClicked(false);
  }

  handleOnListRestrictedSecurity = () => {
    this.setState({
      needOnList: true,
    });

    this.submitButtonOnClicked(true);
  }

  // 附件上传
  attachmentHandleChange = (info) => {
    let fileList = [...info.fileList]; 
    fileList = fileList.slice(-1);
  
    let fail = false;
    fileList.forEach(file => {
      if (file.response) {
        if(file.response.respCode !== 'S0001') {
          let err = file.response.respMessage;
          message.error(err);
          fail = true;
          return;
        }
        const filename = file.response.data.fileNameWithTimeStamp;
        const originalFileName = file.response.data.originalFileName;
        this.props.restrictedSecurityRequestEditChangeInput({name: 'attachment', value: filename});
        this.props.restrictedSecurityRequestEditChangeInput({name: 'attachmentOriginName', value: originalFileName});
      }
    });

    let newFileList = fail ? [] : fileList;
    this.props.restrictedSecurityRequestEditChangeInput({name: 'fileList', value: newFileList});

  }

  render() {
    const {
      restrictedSecurityRequestEditControlModal,
      fundCodeOptions,
      bookCodeOptions,
      bbgTickerOptions,
      bbgTickerArray,
      loadRestrictedSecurityBbgTickersActionStatus,
      restrictedSecAttachmentUploadUrl,
    } = this.props;

    const {
      isOpened,
      isAddMode,
      respErrMsg,
    } = restrictedSecurityRequestEditControlModal;

    const { getFieldDecorator } = this.props.form;

    let {
      restrictionType,
      fund,
      book,
      personInCharge,
      bbgTicker,
      secName,
      validFrom,
      validTo,
      onListReason,
      relatedCompany,
      fileList,
    } = restrictedSecurityRequestEditControlModal.fields;

    const {
      createRestrictedSecurityRequestActionStatus,
      updateRestrictedSecurityRequestActionStatus,
    } = this.props;

    const {
      needOnList,
    } = this.state;

    let modalTitle = "Apply On List Restricted Security";
    // if (isAddMode) {
    //   modalTitle = "Add Restricted Security";
    // }

    const isLoading = createRestrictedSecurityRequestActionStatus === ACTION_STATUS.LOGINING || 
        updateRestrictedSecurityRequestActionStatus === ACTION_STATUS.LOGINING; 

    const bbgTickersSelectIsLoading = loadRestrictedSecurityBbgTickersActionStatus === ACTION_STATUS.LOGINING;

    const isSaveLoading = isLoading  && !needOnList;
    const isOnListLoading = isLoading  && needOnList;

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1000}
          visible={isOpened}
          confirmLoading={isLoading}
          onCancel={() => this.props.resetRestrictedSecurityRequestEditModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          okText="On List"
          cancelText="Cancel"
          footer={[
            <Button key="back" onClick={() => this.props.resetRestrictedSecurityRequestEditModalDialog()}>
              Cancel
            </Button>,
            // <Button key="save" type="primary" loading={isSaveLoading} onClick={this.handleSaveRestrictedSecurity}>
            //   Save
            // </Button>,
            <Button key="submit" type="primary" loading={isOnListLoading} onClick={this.handleOnListRestrictedSecurity}>
              Submit
            </Button>,
          ]}
        >
          <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Bbg Ticker">
                  <AutoComplete 
                    allowClear
                    value={bbgTicker}
                    dataSource={bbgTickerArray}
                    onChange={this.bbgTickerOnChange}
                    onSearch={this.bbgTickerOnSearch} />
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Sec Name">
                  {getFieldDecorator('secName', {
                      initialValue: secName,
                      rules: [{
                        required: true,
                        message: 'Please input sec name',
                      }],
                    })(
                    <Input placeholder="" 
                      allowClear
                      onChange={(e) => {
                          this.onInputChange({ name: 'secName', value: e.target.value })
                        }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Valid From">
                  {getFieldDecorator('validFrom', {
                    initialValue: validFrom === '' ? null : moment(validFrom),
                    rules: [{
                      required: true,
                      message: 'Please select valid from',
                    }],
                  })(
                    <DatePicker 
                      showTime={{ format: 'HH:mm' }}
                      format="YYYY-MM-DD HH:mm"
                      allowClear={false}
                      disabledDate={(currentDate) => {
                        return currentDate.isBefore(moment().format("YYYY-MM-DD"));
                      }}
                      onChange={(date, dateString) => {
                        this.onInputChange({ name: 'validFrom', value: dateString });
                      }} />
                    )
                  }
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Valid To">
                  <DatePicker 
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    value={moment(validTo)}
                    allowClear={false}
                    disabled
                    onChange={(date, dateString) => {
                      this.onInputChange({ name: 'validTo', value: dateString === '' ? '9999-12-31 00:00' : dateString });
                    }} 
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="On List Reason">
                  {getFieldDecorator('onListReason', {
                      initialValue: onListReason,
                      rules: [{
                        required: true,
                        message: 'Please input on list reason',
                      }],
                    })(
                    <TextArea placeholder="On List Reason" 
                      rows={4}
                      onChange={(e) => {
                          this.onInputChange({ name: 'onListReason', value: e.target.value })
                        }}
                    />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Related Company">
                  <TextArea placeholder="Related Company" 
                    value={relatedCompany}
                    rows={4}
                    onChange={(e) => {
                        this.onInputChange({ name: 'relatedCompany', value: e.target.value })
                      }}
                  />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Attachment">
                  <Upload
                    name="attachment"
                    withCredentials={true}
                    multiple={true}
                    showUploadList={true}
                    fileList={fileList}
                    action={restrictedSecAttachmentUploadUrl}
                    onChange={this.attachmentHandleChange}
                  >
                    <Button>
                      <Icon type="upload" /> Upload Attachment
                    </Button>
                  </Upload>
                  
                </FormItem>
              </Col>
              <Col span={12}>
                  <p>Maximum Attachment File Size: 10MB<br/>
                  Maximum Attachment File Name Length: 80 English characters or 40 Chinese characters</p>
              </Col>
            </Row>
          </Form>
          {
            !!respErrMsg &&  
            <Message negative>
                <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

const RestrictedSecurityRequestEditDialogForm = Form.create({ name: 'restricted_list_request_edit_dialog' })(RestrictedSecurityRequestEditDialog);

export default RestrictedSecurityRequestEditDialogForm;