import React, { Component } from 'react';
import { Input, Modal, message } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

class RestrictedSecurityCheckDialog extends Component {

  state = {
    queryText: '',
  };

  componentDidUpdate() {
    const {
      // Search Valid Restricted Security 
      searchValidRestrictedSecurityActionStatus,
    } = this.props;

    // Search Valid Restricted Security 成功
    if (searchValidRestrictedSecurityActionStatus === ACTION_STATUS.SUCCESS) {
      // this.props.initSearchValidRestrictedSecurityActionStatus();
    }

    // Search Valid Restricted Security 失败
    if (searchValidRestrictedSecurityActionStatus === ACTION_STATUS.ERROR) {
      this.props.initSearchValidRestrictedSecurityActionStatus();
    }
  }

  queryTextOnChanged = (e) => {
    this.setState({
      queryText: e.target.value,
    });
  }

  // 提交数据
  submitButtonOnClicked = () => {
    this.props.searchValidRestrictedSecurity(this.state.queryText);
  }

  render() {
    const {
      restrictedSecurityListCheckControlModal,

      // Search Valid Restricted Security 
      searchValidRestrictedSecurityActionStatus,
      matchedValidRestrictedSecurityArray,
    } = this.props;

    const {
      isOpened,
      respErrMsg,
    } = restrictedSecurityListCheckControlModal;

    const {
      queryText
    } = this.state;
    
    let modalTitle = 'Restricted Security Checking';

    const isLoading = searchValidRestrictedSecurityActionStatus === ACTION_STATUS.LOGINING; 

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={500}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetRestrictedSecurityListCheckModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          afterClose={this.modalClosedHandle}
          okText="Submit"
          cancelText="Cancel"
        >
          <Input 
            value={queryText}
            placeholder="Input BbgTicker Or Security Name" 
            onPressEnter={this.submitButtonOnClicked}
            onChange={this.queryTextOnChanged}/>
          {
            matchedValidRestrictedSecurityArray.length > 0 && 
            <div className="matched-valid-restricted-security">
              <span>Restricted security hits: </span>
              <table border="1">
                <thead>
                  <tr>
                    <th>BbgTicker</th>
                    <th>Security Name</th>
                  </tr>
                </thead>
                <tbody>
                    {
                      matchedValidRestrictedSecurityArray.map((restrictedSecurity) => {
                        return (
                          <tr key={restrictedSecurity.guid}>
                            <td>{ restrictedSecurity.bbgTicker }</td>
                            <td>{ restrictedSecurity.secName }</td>
                          </tr>
                        );
                      })
                    }                
                </tbody>
              </table>
            </div>
          }
          {
            (searchValidRestrictedSecurityActionStatus === ACTION_STATUS.SUCCESS && matchedValidRestrictedSecurityArray.length === 0) && 
            <div style={{ marginTop: 10 }}>
              No match
            </div>
          }
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

export default RestrictedSecurityCheckDialog;