import React, { Component } from 'react';
import { Popover } from 'antd';

export default class JsonTooltipComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.valueFormatted || props.value
    };
  }

  render() {
    const { value } = this.state;

    if (value === 'N.A.' || value === null || value === '') return <span> </span>;

    const formatedJsonValue = JSON.stringify(value, undefined, 2);
    const popoverContent = (
      <pre style={{ fontSize: '12px', lineHeight: 1.2, fontStyle: 'italic' ,fontWeight: 'bold'}}>
        {formatedJsonValue}
      </pre>
    );

    return (
      <Popover content={popoverContent} trigger="click">
        <span>{JSON.stringify(value)}</span>
      </Popover>
    );
  }

  refresh(params) {
    this.setState({
      value: params.valueFormatted || params.value
    });
    return true;
  }
}
