import React, {Component} from 'react';
import './ReportingSummaryDashboard.css';
import { Breadcrumb, Select, Button, message, Input, Divider } from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import ReportingSummaryGrid from './ReportingSummaryGrid';
import AddOrEditReportingSummaryModalDialog from './AddOrEditReportingSummaryModalDialog';
import BatchChangeStatusModalDialog from './BatchChangeStatusModalDialog';
import FinishTaskModalDialog from './FinishTaskModalDialog';
// import OperationRecordDialog from '../../../../common/operationRcd/components/OperationRecordDialog';
import { localStorageClient } from '../../../../utils/localStorageClient';

class ReportingSummaryDashboard extends Component {

    componentDidMount() {
        // queryString中的查询条件
        let searchStr = !!this.props.location ? this.props.location.search : null;
        let id = undefined;
        if(!!searchStr) {
            if(searchStr.indexOf('?')  >= 0) {
                searchStr = searchStr.substring(searchStr.indexOf('?') + 1);
                const searchArray = searchStr.split('&');
                for (let i=0; i<searchArray.length; i++) {
                    const subParamArray = searchArray[i].split("=");
                    if(!!subParamArray && subParamArray.length > 1 && subParamArray[0] === 'id') {
                        id = subParamArray[1];
                    }
                }
            }
        }
        this.onQueryInputChange({name: 'id', value: id});

        const employee = localStorageClient.getUserName();
        this.props.loadReportingSummaryInfo({id, location: undefined, fundCompany: undefined,employee});
    }


    componentDidUpdate() {
        const {
            loadReportingSummaryInfoActionStatus,
            loadReportingSummaryInfoResp,
        } = this.props;
        if(loadReportingSummaryInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadReportingSummaryInfoActionStatus();
        }
        if(loadReportingSummaryInfoActionStatus === ACTION_STATUS.ERROR) {
            let err = 'load Reporting Summary Info failed';
            if(!!loadReportingSummaryInfoResp && !!loadReportingSummaryInfoResp.respMessage) {
                err = loadReportingSummaryInfoResp.respMessage;
            }
            message.error(err);
            this.props.initLoadReportingSummaryInfoActionStatus();
        }
    }

    onDataIDQueryInputChange = ({ name, value }) => {
        if(isNaN(value)) {
            return;
        }
        this.props.onQueryInputChange({ name, value });
    }

    onQueryInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };


    refreshBtnOnClicked = () => {
        const employee = localStorageClient.getUserName();
        const {
            id,
            location,
            fundCompany,
        } = this.props.reportingSummaryQueryFields;
        this.props.loadReportingSummaryInfo({id, location, fundCompany,employee});
    }


    addOrEditReportingSummaryBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditReportingSummaryModal(mode, null);
        } else {  // COPY, EDIT
            const {
                selectedReportingSummaryArray,
            } = this.props;
            if(selectedReportingSummaryArray.length !== 1) {
                message.warning("Please choose one and only one Reporting Summary");
                return;
            }
            const currentData = selectedReportingSummaryArray[0];
            this.props.openAddOrEditReportingSummaryModal(mode, currentData);
        }
    }


    batchChangeStatusBtnOnClicked = (mode) => {
        const {
            selectedReportingSummaryArray,
        } = this.props;
        if(mode === 'REPLACE') {
            if(selectedReportingSummaryArray.length === 0) {
                message.warning("Please choose at least one Reporting Summary");
                return;
            }
            const idList = selectedReportingSummaryArray.map(e => e.id);
            const ids_str = idList.join(',');
            this.props.openBatchChangeStatusModal(mode, idList);
        }else {
            if(selectedReportingSummaryArray.length === 0) {
                message.warning("Please choose at least one Reporting Summary");
                return;
            }
            const idList = selectedReportingSummaryArray.map(e => e.id);
            this.props.openBatchChangeStatusModal(mode, idList);
        }
    }


    // 操作记录页面，自定义需要展示的列
    opRcdCustomColumns = ['operator', 'operateTime', 'dataId', 'dataType', 'otherInfo', 'operateType', 'comments', 'before', 'after'];

    render() {
        const {
            locationOptions,
            fundCompanyOptions,
            loadReportingSummaryInfoActionStatus,
        } = this.props;
        const {
            id,
            location,
            fundCompany,
        } = this.props.reportingSummaryQueryFields;

        const refreshBtnIsLoading = loadReportingSummaryInfoActionStatus === ACTION_STATUS.LOGINING;


        return (
            <div className='ReportingSummaryWrapper'>
                <div className='breadcrumbWrapper'>
                    <Breadcrumb>
                        <Breadcrumb.Item>Departments</Breadcrumb.Item>
                        <Breadcrumb.Item>Reporting Summary</Breadcrumb.Item>
                    </Breadcrumb>
                </div>
                <div className='OperationBar'>
                    <Input
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="dataID" 
                        value={id}
                        onChange={(e) => {
                            this.onDataIDQueryInputChange({ name: 'id', value: e.target.value })
                        }}
                    />
                    <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="location" 
                        value={location}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'location', value: val })
                        }} >
                        {locationOptions}
                    </Select>
                    <Select
                        allowClear
                        size='small'
                        style={{ width: 150, marginLeft: 10 }}
                        placeholder="fundCompany" 
                        value={fundCompany}
                        onChange={(val) => {
                            this.onQueryInputChange({ name: 'fundCompany', value: val })
                        }} >
                        {fundCompanyOptions}
                    </Select>
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.addOrEditReportingSummaryBtnOnClicked('ADD')} 
                        size='small'
                        className='positive-button'>
                        Add
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditReportingSummaryBtnOnClicked('COPY')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditReportingSummaryBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button>
                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('DEL')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Del
                    </Button>
                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('REPLACE')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negativeeee-button'>
                        REPLACE
                    </Button>
                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('PAUSE')} 
                        size='small'
                        type='primary'>
                        Pause
                    </Button>
                    <Button 
                        onClick={()=>this.batchChangeStatusBtnOnClicked('RESUME')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Resume
                    </Button>

                    <Divider type='vertical' />

                    <Button 
                        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'ReportingSummary'}, customColumns: this.opRcdCustomColumns})} 
                        size='small'
                        type='primary'>
                        Operation Record
                    </Button>

                </div>

                <div className='ReportingSummaryGrid'><ReportingSummaryGrid {...this.props} /></div>
                <AddOrEditReportingSummaryModalDialog {...this.props} />
                <BatchChangeStatusModalDialog {...this.props} />
                <FinishTaskModalDialog {...this.props} />
                {/* <OperationRecordDialog {...this.props} /> */}

            </div>
        );
    }
}

export default ReportingSummaryDashboard;
