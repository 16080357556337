import {connect} from 'react-redux';
import MeetingAndTrainingDashboard from '../components/MeetingAndTrainingDashboard';

import {
    // query
    loadMeetingAndTrainingInfo,
    initLoadMeetingAndTrainingInfoActionStatus,
    // select
    selectMeetingAndTraining,
    setGridEventOnState,
    // add or edit
    openAddOrEditMeetingAndTrainingModal,
    resetSubmitSaveMeetingAndTrainingModal,
    addOrEditGeneralAgreemenChangeInput,
    submitSaveMeetingAndTrainingModal,
    initSubmitSaveMeetingAndTrainingModalActionStatus,
    // change status
    openChangeMeetingAndTrainingStatusModal,
    resetChangeMeetingAndTrainingStatusModal,
    changeGeneralAgreemenStatusChangeInput,
    submitChangeMeetingAndTrainingStatusModal,
    initSubmitChangeMeetingAndTrainingStatusModalActionStatus,
    // showAllshowValid
    showAllShowValidBtnOnClicked,
    

} from '../MeetingAndTrainingActions';


const mapStateToProps = state => {
    return {
        // options
        departmentOptions: state.meetingandtraining.departmentOptions,
        typeOptions: state.meetingandtraining.typeOptions,
        companyOptions: state.meetingandtraining.companyOptions,
        agreementTypeOptions: state.meetingandtraining.agreementTypeOptions,
        userAccountOptions: state.meetingandtraining.userAccountOptions,

        noticePeriodOptions: state.meetingandtraining.noticePeriodOptions,
        ccyOptions: state.meetingandtraining.ccyOptions,
        // upload/download
        meetingAndTrainingUploadUrl: state.meetingandtraining.meetingAndTrainingUploadUrl,
        meetingAndTrainingDownloadUrl: state.meetingandtraining.meetingAndTrainingDownloadUrl,
                // meetingAndTrainingQueryFields
                meetingAndTrainingQueryFields: state.meetingandtraining.meetingAndTrainingQueryFields,
        // query
                // query
                // loadMeetingAndTrainingInfoActionStatus: state.meetingAndTraining.loadMeetingAndTrainingInfoActionStatus,
                // loadMeetingAndTrainingInfoResp: state.meetingAndTraining.loadMeetingAndTrainingInfoResp,
        meetingandtrainingGridEvent: state.meetingandtraining.meetingandtrainingGridEvent,
        selectedMeetingAndTrainingArray: state.meetingandtraining.selectedMeetingAndTrainingArray,
        loadMeetingAndTrainingActionStatus: state.meetingandtraining.loadMeetingAndTrainingActionStatus,
        loadMeetingAndTrainingResp: state.meetingandtraining.loadMeetingAndTrainingResp,
        // select
        selectedMeetingAndTrainingArray: state.meetingandtraining.selectedMeetingAndTrainingArray,
        // add or edit
        addOrEditMeetingAndTrainingModal: state.meetingandtraining.addOrEditMeetingAndTrainingModal,
        submitSaveMeetingAndTrainingActionStatus: state.meetingandtraining.submitSaveMeetingAndTrainingActionStatus,
        // change status
        changeMeetingAndTrainingStatusModal: state.meetingandtraining.changeMeetingAndTrainingStatusModal,
        submitChangeMeetingAndTrainingStatusActionStatus: state.meetingandtraining.submitChangeMeetingAndTrainingStatusActionStatus,
        // showAllshowValid
        onlyShowValidMeetingAndTraining: state.meetingandtraining.onlyShowValidMeetingAndTraining,
        
    };
};


const mapDispatchToProps = dispatch => {
    return {
        // query
        // loadMeetingAndTrainingInfo: () => dispatch(loadMeetingAndTrainingInfo()),
        loadMeetingAndTrainingInfo: (params) => dispatch(loadMeetingAndTrainingInfo(params)),
        initLoadMeetingAndTrainingInfoActionStatus: () => dispatch(initLoadMeetingAndTrainingInfoActionStatus()),
        // select
        selectMeetingAndTraining: (array) => dispatch(selectMeetingAndTraining(array)),
        setGridEventOnState: (event) => dispatch(setGridEventOnState(event)),
        // add or edit
        openAddOrEditMeetingAndTrainingModal: (mode, data) => dispatch(openAddOrEditMeetingAndTrainingModal(mode, data)),
        resetSubmitSaveMeetingAndTrainingModal: () => dispatch(resetSubmitSaveMeetingAndTrainingModal()),
        addOrEditGeneralAgreemenChangeInput: (input) => dispatch(addOrEditGeneralAgreemenChangeInput(input)),
        submitSaveMeetingAndTrainingModal: (mode, bean) => dispatch(submitSaveMeetingAndTrainingModal(mode, bean)),
        initSubmitSaveMeetingAndTrainingModalActionStatus: () => dispatch(initSubmitSaveMeetingAndTrainingModalActionStatus()),
        // change status
        openChangeMeetingAndTrainingStatusModal: (mode, idList) => dispatch(openChangeMeetingAndTrainingStatusModal(mode, idList)),
        resetChangeMeetingAndTrainingStatusModal: () => dispatch(resetChangeMeetingAndTrainingStatusModal()),
        changeGeneralAgreemenStatusChangeInput: (param) => dispatch(changeGeneralAgreemenStatusChangeInput(param)),
        submitChangeMeetingAndTrainingStatusModal: (bean) => dispatch(submitChangeMeetingAndTrainingStatusModal(bean)),
        initSubmitChangeMeetingAndTrainingStatusModalActionStatus: () => dispatch(initSubmitChangeMeetingAndTrainingStatusModalActionStatus()),
        // showAllshowValid
        showAllShowValidBtnOnClicked: () => dispatch(showAllShowValidBtnOnClicked()),

    };
};


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(MeetingAndTrainingDashboard);

