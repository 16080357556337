import React from 'react';
import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import GridColumnMap from '../../../utils/GridColumnMap';
import BaseUrlConfig from '../../../net/BaseUrlConfig';
import UrlConfig from '../../../net/UrlConfig';
import { Select } from 'antd';
import moment from 'moment';

import {
  // Load Gift Hospitality Request
  START_LOAD_GIFT_HOSPITALITY_REQUEST,
  LOAD_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  LOAD_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_LOAD_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Select Gift Hospitality Request
  SELECT_GIFT_HOSPITALITY_REQUEST,

  // Open/Reset Gift Hospitality Request Modal Dialog
  GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_OPEN,
  GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_RESET,

  // Gift Hospitality Request Edit Change Input
  GIFT_HOSPITALITY_REQUEST_EDIT_CHANGE_INPUT,

  // Create Gift Hospitality Request
  START_CREATE_GIFT_HOSPITALITY_REQUEST,
  CREATE_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  CREATE_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_CREATE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Update Gift Hospitality Request
  START_UPDATE_GIFT_HOSPITALITY_REQUEST,
  UPDATE_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  UPDATE_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_UPDATE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Batch Delete Gift Hospitality Request
  START_BATCH_DELETE_GIFT_HOSPITALITY_REQUEST,
  BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Submit Gift Hospitality Request
  START_SUBMIT_GIFT_HOSPITALITY_REQUEST,
  SUBMIT_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  SUBMIT_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_SUBMIT_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

} from './giftHospitalityRequestConstants';

import {
  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,
} from '../home/homeConstants';

const Option = Select.Option;

const initialState = {
  // Grdi Columns
  giftHospitalityRequestGridColumns: GridColumnMap.giftHospitalityRequestGridColumns,

  // Gift Hospitality Request
  giftHospitalityRequestArray: [],

  // Selected Gift Hospitality Request
  selectedGiftHospitalityRequestArray: [],

  // Gift Hospitality Request Edit
  giftHospitalityRequestEditControlModal: {
    isOpened: false,
    isAddMode: true,
    respErrMsg: '',
    fields: {
      id: '',
      date: moment().format('YYYY-MM-DD'),
      type: 'Gift',
      giver: '',
      giverPosition: '',
      giverCompany: '',
      amount: '',
      currency: '',
      localAmount: '',
      description: '',
      attachment: '',
      attachmentOriginName: '',
    }
  },

  // load/create/update/delete/submit Gift Hospitality Request
  loadGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,
  createGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,
  updateGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,
  batchDeleteGiftHospitalityRequestsActionStatus: ACTION_STATUS.READY,
  submitGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,

  // Gfit Hospitality Upload / Preview Url
  uploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.UPLOAD_GIFT_HOSPITALITY_ATTACHMENT,
  previewImgPath: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.UPLOAD_GIFT_HOSPITALITY_ATTACHMENT,

  // Security Ticker Data
  securityTickerData: null,
  currencyArray: [],
  currencyOptions: [],
  securityTickerArray: [],
} 

// Load Gift Hospitality Request
function startLoadGiftHospitalityRequest(state) {
  return {
    ...state,
    loadGiftHospitalityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function loadGiftHospitalityRequestSuccess(state, resp) {
  return {
    ...state,
    loadGiftHospitalityRequestActionStatus: ACTION_STATUS.SUCCESS,
    giftHospitalityRequestArray: resp.data,
  };
}

function loadGiftHospitalityRequestFailure(state, err) {
  return {
    ...state,
    loadGiftHospitalityRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initLoadGiftHospitalityRequestActionStatus(state) {
  return {
    ...state,
    loadGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Select Gift Hospitality Request
function selectGiftHospitalityRequest(state, payload) {
  return {
    ...state,
    selectedGiftHospitalityRequestArray: payload,
  };
}

// Open/Reset Gift Hospitality Request Modal Dialog
function openGiftHospitalityRequestEditModalDialog(state, {isAddMode, giftHospitalityRequest}) {
  if (isAddMode) {
    return {
      ...state,
      giftHospitalityRequestEditControlModal: {
        ...state.giftHospitalityRequestEditControlModal,
        isOpened: true,
        isAddMode,
      },
    };
  } else {
    return {
      ...state,
      giftHospitalityRequestEditControlModal: {
        ...state.giftHospitalityRequestEditControlModal,
        isOpened: true,
        isAddMode,
        fields: {
          ...state.giftHospitalityRequestEditControlModal.fields,
          id: giftHospitalityRequest.id,
          date: giftHospitalityRequest.date,
          type: giftHospitalityRequest.type,
          giver: giftHospitalityRequest.giver,
          giverPosition: giftHospitalityRequest.giverPosition,
          giverCompany: giftHospitalityRequest.giverCompany,
          amount: giftHospitalityRequest.amount,
          currency: giftHospitalityRequest.currency,
          localAmount: giftHospitalityRequest.localAmount,
          description: giftHospitalityRequest.description,
          attachment: giftHospitalityRequest.attachment,
        }
      },
    };
  }
}

function resetGiftHospitalityRequestEditModalDialog(state) {
  return {
    ...state,
    createGiftHospitalityRequestActionResp: '',
    updateGiftHospitalityRequestActionResp: '',
    giftHospitalityRequestEditControlModal: initialState.giftHospitalityRequestEditControlModal,
  };
}

// Gift Hospitality Request Edit Change Input
function giftHospitalityRequestEditChangeInput(state, { name, value }) {
  let updatedFields = {
    ...state.giftHospitalityRequestEditControlModal.fields,
    [name]: value,
  };

  return {
    ...state,
    giftHospitalityRequestEditControlModal: {
      ...state.giftHospitalityRequestEditControlModal,
      fields: updatedFields,
    },
  };
}

// =============================================================
// Create Gift Hospitality Request
function startCreateGiftHospitalityRequest(state) {
  return {
    ...state,
    createGiftHospitalityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function createGiftHospitalityRequestSuccess(state, resp) {
  return {
    ...state,
    createGiftHospitalityRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function createGiftHospitalityRequestFailure(state, err) {
  return {
    ...state,
    createGiftHospitalityRequestActionStatus: ACTION_STATUS.ERROR,
    createGiftHospitalityRequestActionResp: err,
  };
}

function initCreateGiftHospitalityRequestActionStatus(state) {
  return {
    ...state,
    createGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Update Gift Hospitality Request
function startUpdateGiftHospitalityRequest(state) {
  return {
    ...state,
    updateGiftHospitalityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function updateGiftHospitalityRequestSuccess(state, resp) {
  return {
    ...state,
    updateGiftHospitalityRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function updateGiftHospitalityRequestFailure(state, err) {
  return {
    ...state,
    updateGiftHospitalityRequestActionStatus: ACTION_STATUS.ERROR,
    updateGiftHospitalityRequestActionResp: err,
  };
}

function initUpdateGiftHospitalityRequestActionStatus(state) {
  return {
    ...state,
    updateGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,
  };
}

// Batch Delete Gift Hospitality Request
function startBatchDeleteGiftHospitalityRequest(state) {
  return {
    ...state,
    batchDeleteGiftHospitalityRequestsActionStatus: ACTION_STATUS.LOGINING,
  };
}

function batchDeleteGiftHospitalityRequestSuccess(state, resp) {
  return {
    ...state,
    batchDeleteGiftHospitalityRequestsActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function batchDeleteGiftHospitalityRequestFailure(state, err) {
  return {
    ...state,
    batchDeleteGiftHospitalityRequestsActionStatus: ACTION_STATUS.ERROR,
  };
}

function initBatchDeleteGiftHospitalityRequestActionStatus(state) {
  return {
    ...state,
    batchDeleteGiftHospitalityRequestsActionStatus: ACTION_STATUS.READY,
    selectedGiftHospitalityRequestArray: [],
  };
}

// Submit Gift Hospitality Request
function startSubmitGiftHospitalityRequest(state) {
  return {
    ...state,
    submitGiftHospitalityRequestActionStatus: ACTION_STATUS.LOGINING,
  };
}

function submitGiftHospitalityRequestSuccess(state, resp) {
  return {
    ...state,
    submitGiftHospitalityRequestActionStatus: ACTION_STATUS.SUCCESS,
  };
}

function submitGiftHospitalityRequestFailure(state, err) {
  return {
    ...state,
    submitGiftHospitalityRequestActionStatus: ACTION_STATUS.ERROR,
  };
}

function initSubmitGiftHospitalityRequestActionStatus(state) {
  return {
    ...state,
    submitGiftHospitalityRequestActionStatus: ACTION_STATUS.READY,
    selectedGiftHospitalityRequestArray: [],
  };
}

// =================================================================
// Load Security Tickers
function loadSecurityTickersSuccess(state, resp) {
  let securityTickerData = resp.data;   
  let securityTickerArray = securityTickerData.securityTickerList;
  let currencyArray = securityTickerArray.map(securityTicker => securityTicker.currency);
  currencyArray = currencyArray.filter(currency => currency !== 'NOT SPECIFIED');
  currencyArray = [...new Set(currencyArray)];

  let currencyOptions = currencyArray.map(currency => <Option key={currency}>{currency}</Option>);

  return {
    ...state,
    securityTickerData,
    securityTickerArray,
    currencyArray,
    currencyOptions,
  };
}

export default createReducer(initialState, {
  // Load Gift Hospitality Request
  [START_LOAD_GIFT_HOSPITALITY_REQUEST]: startLoadGiftHospitalityRequest,
  [LOAD_GIFT_HOSPITALITY_REQUEST_SUCCESS]: loadGiftHospitalityRequestSuccess,
  [LOAD_GIFT_HOSPITALITY_REQUEST_FAILURE]: loadGiftHospitalityRequestFailure,
  [INIT_LOAD_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS]: initLoadGiftHospitalityRequestActionStatus,

  // Select Gift Hospitality Request
  [SELECT_GIFT_HOSPITALITY_REQUEST]: selectGiftHospitalityRequest,

  // Open/Reset Gift Hospitality Request Modal Dialog
  [GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_OPEN]: openGiftHospitalityRequestEditModalDialog,
  [GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_RESET]: resetGiftHospitalityRequestEditModalDialog,

  // Gift Hospitality Request Edit Change Input
  [GIFT_HOSPITALITY_REQUEST_EDIT_CHANGE_INPUT]: giftHospitalityRequestEditChangeInput,

  // Create Gift Hospitality Request
  [START_CREATE_GIFT_HOSPITALITY_REQUEST]: startCreateGiftHospitalityRequest,
  [CREATE_GIFT_HOSPITALITY_REQUEST_SUCCESS]: createGiftHospitalityRequestSuccess,
  [CREATE_GIFT_HOSPITALITY_REQUEST_FAILURE]: createGiftHospitalityRequestFailure,
  [INIT_CREATE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS]: initCreateGiftHospitalityRequestActionStatus,

  // Update Gift Hospitality Request
  [START_UPDATE_GIFT_HOSPITALITY_REQUEST]: startUpdateGiftHospitalityRequest,
  [UPDATE_GIFT_HOSPITALITY_REQUEST_SUCCESS]: updateGiftHospitalityRequestSuccess,
  [UPDATE_GIFT_HOSPITALITY_REQUEST_FAILURE]: updateGiftHospitalityRequestFailure,
  [INIT_UPDATE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS]: initUpdateGiftHospitalityRequestActionStatus,

  // Batch Delete Gift Hospitality Request
  [START_BATCH_DELETE_GIFT_HOSPITALITY_REQUEST]: startBatchDeleteGiftHospitalityRequest,
  [BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_SUCCESS]: batchDeleteGiftHospitalityRequestSuccess,
  [BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_FAILURE]: batchDeleteGiftHospitalityRequestFailure,
  [INIT_BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS]: initBatchDeleteGiftHospitalityRequestActionStatus,

  // Submit Gift Hospitality Request
  [START_SUBMIT_GIFT_HOSPITALITY_REQUEST]: startSubmitGiftHospitalityRequest,
  [SUBMIT_GIFT_HOSPITALITY_REQUEST_SUCCESS]: submitGiftHospitalityRequestSuccess,
  [SUBMIT_GIFT_HOSPITALITY_REQUEST_FAILURE]: submitGiftHospitalityRequestFailure,
  [INIT_SUBMIT_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS]: initSubmitGiftHospitalityRequestActionStatus,

  // Load Security Tickers
  [LOAD_SECURITY_TICKERS_SUCCESS]: loadSecurityTickersSuccess,
});
