// Login
export const START_LOGIN = 'START_LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const RESET_LOGIN_STATUS = 'RESET_LOGIN_STATUS';

// Logout
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

// Login By Access Token
export const START_LOGIN_BY_ACCESS_TOKEN = 'START_LOGIN_BY_ACCESS_TOKEN';
export const LOGIN_BY_ACCESS_TOKEN_SUCCESS = 'LOGIN_BY_ACCESS_TOKEN_SUCCESS';
export const LOGIN_BY_ACCESS_TOKEN_FAILURE = 'LOGIN_BY_ACCESS_TOKEN_FAILURE';
export const INIT_LOGIN_BY_ACCESS_TOKEN_STATUS = 'INIT_LOGIN_BY_ACCESS_TOKEN_STATUS';