import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import BaseUrlConfig from '../../../net/BaseUrlConfig';
import UrlConfig from '../../../net/UrlConfig';
import { localStorageClient } from '../../../utils/localStorageClient';


import {
    // query
    START_LOAD_COLLEGE_APPLICATION_INFO,
    LOAD_COLLEGE_APPLICATION_INFO_SUCCESS,
    LOAD_COLLEGE_APPLICATION_INFO_FAILURE,
    INIT_LOAD_COLLEGE_APPLICATION_INFO_ACTION_STATUS,
    // select
    SELECT_COLLEGE_APPLICATION,
    SET_GRID_EVENT_ON_STATE,
    // add or edit
    OPEN_ADD_OR_EDIT_COLLEGE_APPLICATION_MODAL,
    RESET_SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL,
    ADD_OR_EDIT_COLLEGE_APPLICATION_CHANGE_INPUT,
    START_SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL,
    SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL_SUCCESS,
    SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL_FAILURE,
    INIT_SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL_ACTION_STATUS,
    // change status
    OPEN_CHANGE_COLLEGE_APPLICATION_STATUS_MODAL,
    RESET_CHANGE_COLLEGE_APPLICATION_STATUS_MODAL,
    CHANGE_COLLEGE_APPLICATION_STATUS_CHANGE_INPUT,
    START_SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS,
    SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS_SUCCESS,
    SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS_FAILURE,
    INIT_SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS_ACTION_STATUS,
    // showAllshowValid
    SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED,


} from './CollegeApplicationConstants';
// import {
//     // Load Security Tickers
//     LOAD_SECURITY_TICKERS_SUCCESS,
  
//     // Load User Account
//     LOAD_USER_ACCOUNTS_SUCCESS,
  
//   } from '../../home/homeConstants';

const Option = Select.Option;
const typeOptions = [];
// typeOptions.push(<Option key={'Meeting'}>{'Meeting'}</Option>);
typeOptions.push(<Option key={'CIO Tutorial'}>{'CIO Tutorial'}</Option>);
typeOptions.push(<Option key={'Sell Side Sector Training'}>{'Sell Side Sector Training'}</Option>);
typeOptions.push(<Option key={'Group Training'}>{'Group Training'}</Option>);
typeOptions.push(<Option key={'Modeling Class'}>{'Modeling Class'}</Option>);
typeOptions.push(<Option key={'Macro and Strategy Training'}>{'Macro and Strategy Training'}</Option>);
typeOptions.push(<Option key={'Buy Side Sharing'}>{'Buy Side Sharing'}</Option>);
// typeOptions.push(<Option key={'Tranining'}>{'Tranining'}</Option>);
typeOptions.push(<Option key={'Other'}>{'Other'}</Option>);

const departmentOptions = [];
departmentOptions.push(<Option key={'Marketing'}>{'Marketing'}</Option>);
departmentOptions.push(<Option key={'IT'}>{'IT'}</Option>);
departmentOptions.push(<Option key={'BD'}>{'BD'}</Option>);
departmentOptions.push(<Option key={'Admin'}>{'Admin'}</Option>);
departmentOptions.push(<Option key={'Compliance'}>{'Compliance'}</Option>);
departmentOptions.push(<Option key={'Operation'}>{'Operation'}</Option>);
departmentOptions.push(<Option key={'Finance'}>{'Finance'}</Option>);
departmentOptions.push(<Option key={'Investment'}>{'Investment'}</Option>);

const companyOptions = [];
companyOptions.push(<Option key={'PAJP'}>{'PAJP'}</Option>);
companyOptions.push(<Option key={'PAML'}>{'PAML'}</Option>);
companyOptions.push(<Option key={'PASG'}>{'PASG'}</Option>);
companyOptions.push(<Option key={'PIAS'}>{'PIAS'}</Option>);
companyOptions.push(<Option key={'投资顾问'}>{'投资顾问'}</Option>);
companyOptions.push(<Option key={'投资管理'}>{'投资管理'}</Option>);
companyOptions.push(<Option key={'资产管理'}>{'资产管理'}</Option>);
companyOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
companyOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);
companyOptions.push(<Option key={'SLHL'}>{'SLHL'}</Option>);
companyOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);
companyOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
companyOptions.push(<Option key={'PLUS'}>{'PLUS'}</Option>);
companyOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);

const agreementTypeOptions = [];
agreementTypeOptions.push(<Option key={'Agreement'}>{'Agreement'}</Option>);
agreementTypeOptions.push(<Option key={'ApplicationForm'}>{'ApplicationForm'}</Option>);
agreementTypeOptions.push(<Option key={'NDA'}>{'NDA'}</Option>);
agreementTypeOptions.push(<Option key={'Contract'}>{'Contract'}</Option>);
agreementTypeOptions.push(<Option key={'EngagementLetter'}>{'EngagementLetter'}</Option>);

const noticePeriodOptions = [];
// noticePeriodOptions.push(<Option key={'7'}>{'7 days'}</Option>);
// noticePeriodOptions.push(<Option key={'30'}>{'30 days'}</Option>);
// noticePeriodOptions.push(<Option key={'60'}>{'60 days'}</Option>);
// noticePeriodOptions.push(<Option key={'90'}>{'90 days'}</Option>);
// noticePeriodOptions.push(<Option key={'180'}>{'180 days'}</Option>);
// noticePeriodOptions.push(<Option key={'365'}>{'365 days'}</Option>);

const ccyOptions = [];
ccyOptions.push(<Option key={'USD'}>{'USD'}</Option>);
ccyOptions.push(<Option key={'CNY'}>{'CNY'}</Option>);
ccyOptions.push(<Option key={'HKD'}>{'HKD'}</Option>);
ccyOptions.push(<Option key={'SGD'}>{'SGD'}</Option>);
ccyOptions.push(<Option key={'JPY'}>{'JPY'}</Option>);
ccyOptions.push(<Option key={'INR'}>{'INR'}</Option>);


const initialState = {
    departmentOptions: departmentOptions,
    typeOptions: typeOptions,
    companyOptions: companyOptions,
    agreementTypeOptions: agreementTypeOptions,
    noticePeriodOptions: noticePeriodOptions,
    ccyOptions: ccyOptions,

    collegeApplicationUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.COLLEGE_APPLICATION_UPLOAD_URL,
    collegeApplicationDownloadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.COLLEGE_APPLICATION_DOWNLOAD_URL,
    collegeApplicationQueryFields: {
        userName: '',
        status: ''
    },
    selectedCollegeApplicationArray: [],
    userAccountOptions: [],
    addOrEditCollegeApplicationModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            status: undefined,
            title: undefined,
            department: undefined,
            personInCharge: undefined,
            company: undefined,
            counterparty: undefined,
            agreementType: undefined,
            executionDate: undefined,
            expiryDate: undefined,
            noticePeriod: undefined,
            terms: undefined,
            uploadOriName: undefined,
            uploadRealName: undefined,
            amount: undefined,
            amountCCY: undefined,
            remarks: undefined,
            comments: undefined,
            source: 'Report',
            modes: undefined,
            agreementUploadFileList: [],
            submit2ValidTimes: 0,
        },
    },

    changeCollegeApplicationStatusModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            status: undefined,
            title: undefined,
            department: undefined,
            personInCharge: undefined,
            company: undefined,
            counterparty: undefined,
            agreementType: undefined,
            executionDate: undefined,
            expiryDate: undefined,
            noticePeriod: undefined,
            terms: undefined,
            uploadOriName: undefined,
            uploadRealName: undefined,
            remarks: undefined,
            comments: undefined,
            source: 'Report',
            complianceconfirm: false,
            modes: undefined, 
        }
    },

    onlyShowValidCollegeApplication: true,

};


function startLoadCollegeApplicationInfo(state) {
    return {
        ...state,
        loadCollegeApplicationActionStatus: ACTION_STATUS.LOGINING,
      };
}
function loadCollegeApplicationInfoSuccess(state, resp) {
    return {
        ...state,
        loadCollegeApplicationActionStatus: ACTION_STATUS.SUCCESS,
        loadCollegeApplicationResp: resp,
    };
}
function loadCollegeApplicationInfoFailure(state, err) {
    return {
        ...state,
        loadCollegeApplicationActionStatus: ACTION_STATUS.ERROR,
        loadCollegeApplicationResp: err,
    };
}
function initLoadCollegeApplicationInfoActionStatus(state) {
    return {
        ...state,
        loadCollegeApplicationActionStatus: ACTION_STATUS.READY,
        selectedCollegeApplicationArray: [],
    };
}


// select
function selectCollegeApplication(state, array) {
    return {
        ...state,
        selectedCollegeApplicationArray: array,
    };
}
function setGridEventOnState(state, event) {
    return {
        ...state,
        collegeApplicationGridEvent: event,
    }
}


// add or edit
function openAddOrEditCollegeApplicationModal(state, {mode, data}) {
    if(mode === 'ADD') {
        const employee = localStorageClient.getUserName();
        return {
            ...state,
            addOrEditCollegeApplicationModal: {
                ...state.addOrEditCollegeApplicationModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditCollegeApplicationModal.fields,
                    personInCharge: employee,
                },
            },
        };

    } else {
        const {
            id,
            title,
            desc,
            comperename,
            starttime,
            address,
            note,
            subtitle,
            filepath,
            uploadOriName,
            uploadRealName,
            feedbackScore,
            feedbackComments,
        } = data;

        // COPY时不COPY附件
        const agreementUploadFileList = [];
        let newUploadOriName = uploadOriName;
        let newUploadRealName = uploadRealName;
        if(mode === 'EDIT' && !!uploadOriName && !!uploadRealName) {
            const file = {
                uid: '-1',
                name: uploadOriName,
                status: 'done',
                url: state.collegeApplicationDownloadUrl + "/" + uploadRealName,
                response: {
                    respCode: "S0001",
                    fileNameWithTimeStamp: uploadRealName,
                    originalFileName: uploadOriName,
                }
            };
            agreementUploadFileList.push(file);

        } else {
            newUploadOriName = undefined;
            newUploadRealName = undefined;
        }

        return {
            ...state,
            addOrEditCollegeApplicationModal: {
                ...state.addOrEditCollegeApplicationModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditCollegeApplicationModal.fields,
                    id,
                    title,
                    desc,
                    comperename,
                    starttime,
                    address,
                    note,
                    subtitle,
                    filepath,
                    uploadOriName: newUploadOriName,
                    uploadRealName: newUploadRealName,
                    // amount,
                    // amountCCY,
                    // remarks,
                    agreementUploadFileList,
                    feedbackScore,
                    feedbackComments,
                    // submit2ValidTimes,
                }
            },
        };
    }
}
function resetSubmitSaveCollegeApplicationModal(state) {
    return {
        ...state,
        addOrEditCollegeApplicationModal: initialState.addOrEditCollegeApplicationModal,
    }
}

function addOrEditCollegeApplicationChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditCollegeApplicationModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditCollegeApplicationModal: {
          ...state.addOrEditCollegeApplicationModal,
          fields: updatedFields,
        },
    };
}

function startSubmitSaveCollegeApplicationModal(state) {
    return {
        ...state,
        submitSaveCollegeApplicationActionStatus: ACTION_STATUS.LOGINING,
    };
}
function submitSaveCollegeApplicationModalSuccess(state, resp) {
    return {
        ...state,
        submitSaveCollegeApplicationActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitSaveCollegeApplicationModalFailure(state, err) {
    return {
        ...state,
        submitSaveCollegeApplicationActionStatus: ACTION_STATUS.ERROR,
        addOrEditCollegeApplicationModal: {
            ...state.addOrEditCollegeApplicationModal,
            respErrMsg: err.respMessage,
        },
    };
}
function initSubmitSaveCollegeApplicationModalActionStatus(state) {
    return {
        ...state,
        submitSaveCollegeApplicationActionStatus: ACTION_STATUS.READY,
    }; 
}



// change status
function openChangeCollegeApplicationStatusModal(state, {mode, idList}) {
    // const {
    //     id,
    //     status,
    //     title,
    //     department,
    //     personInCharge,
    //     company,
    //     counterparty,
    //     agreementType,
    //     executionDate,
    //     expiryDate,
    //     noticePeriod,
    //     terms,
    //     uploadOriName,
    //     uploadRealName,
    //     amount,
    //     amountCCY,
    //     remarks,
    // } = data;
    return {
        ...state,
        changeCollegeApplicationStatusModal: {
            ...state.changeCollegeApplicationStatusModal,
            isOpened: true,
            mode,
            fields: {
                ...state.changeCollegeApplicationStatusModal.fields,
                idList,
                // id,
                // status,
                // title,
                // department,
                // personInCharge,
                // company,
                // counterparty,
                // agreementType,
                // executionDate,
                // expiryDate,
                // noticePeriod,
                // terms,
                // uploadOriName,
                // uploadRealName,
                // amount,
                // amountCCY,
                // remarks,
            }
        },
    };
}
function resetChangeCollegeApplicationStatusModal(state) {
    return {
        ...state,
        changeCollegeApplicationStatusModal: initialState.changeCollegeApplicationStatusModal,
    }
}
function changeCollegeApplicationStatusChangeInput(state, {name, value}) {
    return {
        ...state,
        changeCollegeApplicationStatusModal: {
            ...state.changeCollegeApplicationStatusModal,
            fields: {
                ...state.changeCollegeApplicationStatusModal.fields,
                [name]: value,
            }
        },
    }
}

function startSubmitChangeCollegeApplicationStatus(state) {
    return {
        ...state,
        submitChangeCollegeApplicationStatusActionStatus: ACTION_STATUS.LOGINING,
    };
}
function submitChangeCollegeApplicationStatusSuccess(state, resp) {
    return {
        ...state,
        submitChangeCollegeApplicationStatusActionStatus: ACTION_STATUS.SUCCESS,
    };
}
function submitChangeCollegeApplicationStatusFailure(state, err) {
    return {
        ...state,
        submitChangeCollegeApplicationStatusActionStatus: ACTION_STATUS.ERROR,
        changeCollegeApplicationStatusModal: {
            ...state.changeCollegeApplicationStatusModal,
            respErrMsg: err.respMessage,
        },
    };
}
function initSubmitChangeCollegeApplicationStatusActionStatus(state) {
    return {
        ...state,
        submitChangeCollegeApplicationStatusActionStatus: ACTION_STATUS.READY,
    }; 
}

// showAllshowValid
function showAllShowValidBtnOnClicked(state) {
    return {
        ...state,
        onlyShowValidCollegeApplication: !state.onlyShowValidCollegeApplication,
        selectedCollegeApplicationArray: [],  // 状态记录中清空选中
    }
}
// Load User Account
// function loadUserAccountsSuccess(state, resp) {
//     const userAccountArray = resp.data;
//     let pmArray = [];
//   let pmGroupArray = [];
//   let rowSize = 4;
//   if (userAccountArray.length > 0) {
//     pmArray = userAccountArray
//     .filter(userAccount => !!userAccount.isActive)
//     .filter(userAccount =>  userAccount.firm.toUpperCase() === 'PINPOINT')
//       .map(userAccount => Object.assign({}, userAccount, { checked: false }));

//     const groupSize = Math.floor(pmArray.length / rowSize) + (pmArray.length % rowSize > 0 ? 1 : 0);
//     for (let index = 0; index < groupSize; index++) {
//       const array = pmArray.slice(index * rowSize, (index + 1) * rowSize);
//       pmGroupArray.push(array);
//     }
//   }

//   // 已经存在的answeree 默认勾选
//   const answerArray = state.answerArray;
//   const selectedQuestionnaires = state.selectedQuestionnaires;

//     const userAccountOptions = userAccountArray.map(userAccount => <Option key={userAccount.englishName}>{userAccount.englishName}</Option>);
  

//     return {
//         ...state,
//         userAccountArray,
//         pmArray,
//         pmGroupArray,
//         userAccountOptions,
//       };
//   }

export default createReducer(initialState, {
    // query
    [START_LOAD_COLLEGE_APPLICATION_INFO]: startLoadCollegeApplicationInfo,
    [LOAD_COLLEGE_APPLICATION_INFO_SUCCESS]: loadCollegeApplicationInfoSuccess,
    [LOAD_COLLEGE_APPLICATION_INFO_FAILURE]: loadCollegeApplicationInfoFailure,
    [INIT_LOAD_COLLEGE_APPLICATION_INFO_ACTION_STATUS]: initLoadCollegeApplicationInfoActionStatus,
    // select
    [SELECT_COLLEGE_APPLICATION]: selectCollegeApplication,
    [SET_GRID_EVENT_ON_STATE]: setGridEventOnState,
    // add or edit
    [OPEN_ADD_OR_EDIT_COLLEGE_APPLICATION_MODAL]: openAddOrEditCollegeApplicationModal,
    [RESET_SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL]: resetSubmitSaveCollegeApplicationModal,
    [ADD_OR_EDIT_COLLEGE_APPLICATION_CHANGE_INPUT]: addOrEditCollegeApplicationChangeInput,
    [START_SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL]: startSubmitSaveCollegeApplicationModal,
    [SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL_SUCCESS]: submitSaveCollegeApplicationModalSuccess,
    [SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL_FAILURE]: submitSaveCollegeApplicationModalFailure,
    [INIT_SUBMIT_SAVE_COLLEGE_APPLICATION_MODAL_ACTION_STATUS]: initSubmitSaveCollegeApplicationModalActionStatus,
    // change status
    [OPEN_CHANGE_COLLEGE_APPLICATION_STATUS_MODAL]: openChangeCollegeApplicationStatusModal,
    [RESET_CHANGE_COLLEGE_APPLICATION_STATUS_MODAL]: resetChangeCollegeApplicationStatusModal,
    [CHANGE_COLLEGE_APPLICATION_STATUS_CHANGE_INPUT]: changeCollegeApplicationStatusChangeInput,
    [START_SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS]: startSubmitChangeCollegeApplicationStatus,
    [SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS_SUCCESS]: submitChangeCollegeApplicationStatusSuccess,
    [SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS_FAILURE]: submitChangeCollegeApplicationStatusFailure,
    [INIT_SUBMIT_CHANGE_COLLEGE_APPLICATION_STATUS_ACTION_STATUS]: initSubmitChangeCollegeApplicationStatusActionStatus,
    // showAllshowValid
    [SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED]: showAllShowValidBtnOnClicked,

    // [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,

});