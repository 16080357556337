import React, { Component } from 'react';
import { Input, Modal, message, Button , Upload, Icon, Row, Col} from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import RestrictedSecurityOperateMode from '../../../../utils/RestrictedSecurityOperateMode';
import _ from 'lodash';
const { TextArea } = Input;

class RestrictedSecurityOperateDialog extends Component {

  componentDidUpdate() {
    const {
      // On/Off List Restricted Security Request Action Status
      onListRestrictedSecurityRequestActionStatus,
      offListRestrictedSecurityRequestActionStatus,
    } = this.props;

    // On List Restricted Security 提交成功
    if (onListRestrictedSecurityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted security application are on list successfully!');
      this.props.loadRestrictedSecurityRequest();
      this.props.initOnListRestrictedSecurityRequestActionStatus();
      this.props.resetRestrictedSecurityRequestOperateModalDialog();
    }

    // On List Restricted Security 提交失败
    if (onListRestrictedSecurityRequestActionStatus === ACTION_STATUS.ERROR) {
      this.props.initOnListRestrictedSecurityRequestActionStatus();
    }

    // Off List Restricted Security 提交成功
    if (offListRestrictedSecurityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Restricted security application are off list successfully!');
      this.props.loadRestrictedSecurityRequest();
      this.props.initOffListRestrictedSecurityRequestActionStatus();
      this.props.resetRestrictedSecurityRequestOperateModalDialog();
    }

    // Off List Restricted Security 提交失败
    if (offListRestrictedSecurityRequestActionStatus === ACTION_STATUS.ERROR) {
      this.props.initOffListRestrictedSecurityRequestActionStatus();
    }
  }

  onInputChange = ({name, value}) => {
    this.props.restrictedSecurityOperateEditChangeInput({name, value});
  }

  // 提交数据
  submitButtonOnClicked = () => {
    const {
      operateMode,
      restrictedSecGuidList,
      fields,
    } = this.props.restrictedSecurityRequestOperateControlModal;

    const {
      onListReason,
      offListReason,
      relatedCompany,
      attachment,
      attachmentOriginName,
    } = fields;

    if (operateMode === RestrictedSecurityOperateMode.ONLIST) {
      this.props.onListRestrictedSecurityRequest({attachment,attachmentOriginName,restrictedSecGuidList, reason: onListReason, source: 'Client'});
    } 
    
    if (operateMode === RestrictedSecurityOperateMode.OFFLIST) {
      this.props.offListRestrictedSecurityRequest({attachment,attachmentOriginName,restrictedSecGuidList, reason: offListReason, source: 'Client'});
    } 
  }

  // 附件上传
  attachmentHandleChange = (info) => {
    let fileList = [...info.fileList]; 
    fileList = fileList.slice(-1);
  
    let fail = false;
    fileList.forEach(file => {
      if (file.response) {
        if(file.response.respCode !== 'S0001') {
          let err = file.response.respMessage;
          message.error(err);
          fail = true;
          return;
        }
        const filename = file.response.data.fileNameWithTimeStamp;
        const originalFileName = file.response.data.originalFileName;
        this.props.restrictedSecurityOperateEditChangeInput({name: 'attachment', value: filename});
        this.props.restrictedSecurityOperateEditChangeInput({name: 'attachmentOriginName', value: originalFileName});
      }
    });
    let newFileList = fail ? [] : fileList;
    this.props.restrictedSecurityOperateEditChangeInput({name: 'fileList', value: newFileList});
  }
  
  render() {
    const {
      restrictedSecurityRequestOperateControlModal,

      // On/Off List Restricted Security Request Action Status
      onListRestrictedSecurityRequestActionStatus,
      offListRestrictedSecurityRequestActionStatus,
      restrictedSecAttachmentUploadUrl,
    } = this.props;

    const {
      isOpened,
      operateMode,
      respErrMsg,
      fields,
      restrictedSecGuidList,
    } = restrictedSecurityRequestOperateControlModal;

    const {
      onListReason,
      offListReason,
      relatedCompany,
      fileList,
    } = fields;

    const showUpload = !_.isEmpty(restrictedSecGuidList) && restrictedSecGuidList.length === 1;
    
    let operation = 'Operate';
    if (operateMode === RestrictedSecurityOperateMode.ONLIST) {
      operation = 'On List';
    } else if (operateMode === RestrictedSecurityOperateMode.OFFLIST) {
      operation = 'Off List';
    } 

    let modalTitle = `Apply ${operation} Restricted Security`;

    const isLoading = onListRestrictedSecurityRequestActionStatus === ACTION_STATUS.LOGINING || 
        offListRestrictedSecurityRequestActionStatus === ACTION_STATUS.LOGINING; 

    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={500}
          visible={isOpened}
          confirmLoading={isLoading}
          onOk={this.submitButtonOnClicked}
          onCancel={() => this.props.resetRestrictedSecurityRequestOperateModalDialog()}
          destroyOnClose={true}
          maskClosable={false}
          afterClose={this.modalClosedHandle}
          okText="Submit"
          cancelText="Cancel"
        >
          {
            operateMode === RestrictedSecurityOperateMode.ONLIST &&
            <TextArea 
              rows={4} 
              style={{ marginBottom: 10 }}
              placeholder='On List Reason'
              value={onListReason} 
              onChange={(e) => {
                this.onInputChange({ name: 'onListReason', value: e.target.value })
              }}/>
          }
          {
            operateMode === RestrictedSecurityOperateMode.OFFLIST &&
            <TextArea 
              rows={4} 
              style={{ marginBottom: 10 }}
              placeholder='Off List Reason'
              value={offListReason} 
              onChange={(e) => {
                this.onInputChange({ name: 'offListReason', value: e.target.value })
              }}/>
          }
          {showUpload?
            (<Row gutter={24}>
              <Col span={12}>
                      <Upload
                  name="attachment"
                  withCredentials={true}
                  multiple={true}
                  showUploadList={true}
                  fileList={fileList}
                  action={restrictedSecAttachmentUploadUrl}
                  onChange={this.attachmentHandleChange}
                >
                  <Button>
                    <Icon type="upload" /> Upload Attachment
                  </Button>
                </Upload>
              </Col>
              <Col span={12}>
                  <p>Maximum Attachment File Size: 10MB<br/>
                  Maximum Attachment File Name Length: 80 English characters or 40 Chinese characters</p>
              </Col>
            </Row>):("")
          }
          
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

export default RestrictedSecurityOperateDialog;