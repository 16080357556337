import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, DatePicker, Select, AutoComplete, InputNumber, Upload, Button, Icon, Tooltip } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { localStorageClient } from '../../../../utils/localStorageClient';
import { SIDE_TYPE_MAP, SIDE_TYPE_CODE } from '../../../../utils/SideTypeUtil';
import { PRICE_TYPE_CODE, PRICE_TYPE_MAP } from '../../../../utils/PriceTypeUtil';
import { TRADE_REQUEST_STATUS_CODE } from '../../../../utils/PersonalTradeRequestStatus';
import { CATEGORY_MAP } from '../../../../utils/CategoryUtil';
import moment from 'moment';

const FormItem = Form.Item;
const confirm = Modal.confirm;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
  labelCol: { span: 9 },
  wrapperCol: { span: 12 },
};

const accoFormItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 12 },
};

const remarkFormItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

// Side Type
const SideTypeOptions = [];
for (let i = 0; i < SIDE_TYPE_MAP.length; i++) {
  SideTypeOptions.push(<Option key={SIDE_TYPE_MAP[i].code}>{SIDE_TYPE_MAP[i].label}</Option>);
}

// Price Type
const PriceTypeOptions = [];
for (let i = 0; i < PRICE_TYPE_MAP.length; i++) {
  PriceTypeOptions.push(<Option key={PRICE_TYPE_MAP[i].code}>{PRICE_TYPE_MAP[i].label}</Option>);
}

// Category List
const categoryOptions = [];
for (let i = 0; i < CATEGORY_MAP.length; i++) {
    categoryOptions.push(<Option key={CATEGORY_MAP[i].code}>{CATEGORY_MAP[i].label}</Option>);
}

class TradeRequestEditDialog extends Component {

  state = {
    loading: false,
    isSaveBtnOnClicking: true,
  };

  componentWillMount() {
    this.props.setTradeRequestEditDialogForm(this.props.form);
  }

  componentDidUpdate () {
    const {
      query30DayHoldingCheckInfoStatus,
      trigger30DayHoldingCheckInfoStatus,
    } = this.props;
    // 持仓30天检查，触发
    if(trigger30DayHoldingCheckInfoStatus === ACTION_STATUS.LOGINING) {
      this.triggerPresubmitCheck();
    }
  }

  onInputChange = ({name, value}) => {
    this.props.tradeRequestEditChangeInput({name, value});
    if(name === 'accountGuid' || name === 'bbgTicker' || name === 'side') {
      this.props.startTrigger30DayHoldingCheckInfoStatus();
    }
  }

  openCheckHoldingsPage = (accountGuid) => {
    this.props.openCheckHoldingsPage(accountGuid);
  }

  bbgTickerOnChange = (value) => {
    if (!!!value) {
      this.props.clearBbgTickers();
    }
    this.onInputChange({ name: 'bbgTicker', value });
    this.props.form.resetFields();
    this.props.bbgTickerSelectChanged(value);
  }


  lastTimeoutID = null;
  bbgTickerOnSearch = (value) => {
    if (!!!value) {
      this.props.clearBbgTickers();
      return;
    }
    if(value.length < 2) {
      return;
    }
    // 输入完毕等待0.5秒后再查询
    // 无此控制会有以下bug：
    // 当输入 2007 时，会分别根据 20、200、2007进行查询
    // 根据2007的查询结果数据量少，最先返回
    // 根据20的查询结果数据量大，最后返回
    // 因此最终展示的，反而是根据20的查询结果，尽管输入的是2007
    if(!!this.lastTimeoutID) {
      clearTimeout(this.lastTimeoutID);
    }
    this.lastTimeoutID = setTimeout(() => {
      this.props.loadBbgTickers(value);
    }, 500);
  }



  stockMarketOnSearch = (value) => {
    if (!!!value) {
      this.props.clearExchangeCodes();
      return;
    }

    if (value.length >= 1) {
      this.props.loadExchangeCode(value);
    }
  }

  // PriceType Changed
  priceTypeChanged = (value) => {
    this.onInputChange({ name: 'priceType', value });
    if (value === PRICE_TYPE_CODE.MARKET) {
      this.onInputChange({ name: 'buyingPrice', value: '' });
      this.onInputChange({ name: 'sellingPrice', value: '' });
    } else if (value === PRICE_TYPE_CODE.LIMIT) {
      this.onInputChange({ name: 'marketPrice', value: '' });
    }
  }

  // Side Changed
  sideTypeChanged = (value) => {
    this.onInputChange({ name: 'side', value });
    if (value === SIDE_TYPE_CODE.BUY) {
      this.onInputChange({ name: 'holdingDays', value: '' });
    }
  }

  // category Changed
  categoryTypeChanged = (value) => {
    this.onInputChange({ name: 'category', value });
  }

  // 预检查
  triggerPresubmitCheck = () => {
    this.props.stopTrigger30DayHoldingCheckInfoStatus();
    
    const employee = localStorageClient.getUserName();
    const {
      tradeRequestEditControlModal,
    } = this.props;
    const {
      id,
      accountGuid,
      securityId,
      bbgTicker,
      secName,
      stockMarket,
      side,
      priceType,
      price,
      applyQty,
      actualQty,
      currency,
      tradeDate,
      holdingDays,
      remark,
      attachment,
      attachmentOriginName,
      category,
    } = tradeRequestEditControlModal.fields;

    if(!accountGuid || !bbgTicker || (side !== 'SELL' && side !== 'COVER')) {
      // 信息不完整，清空
      this.props.reset30DayHoldingCheckInfo();
    } else {
      // 信息完整，提交查询
      const personalTradeInfo = {
        id,
        employee,
        accountGuid,
        securityId,
        bbgTicker,
        secName,
        stockMarket,
        side,
        priceType,
        price,
        applyQty,
        actualQty,
        currency,
        tradeDate,
        holdingDays,
        remark,
        attachment,
        attachmentOriginName,
        category,
      };
      this.props.query30DayHoldingCheckInfo(personalTradeInfo);
    }
  }


  checkValueNumber = (rule, value, callback) => {

    if (isNaN(value)) {
      if (rule.field === 'quantity') {
        callback(`Quantity must be a number`);
      }

      if (rule.field === 'price') {
        callback(`Price must be a number`);
      }

    } else {
      let numValue = Number(value);
      let valueString = value + '';

      if (rule.field === 'quantity' && (Math.floor(numValue) !== numValue || valueString.indexOf('.') >= 0)) {
        callback(`Quantity must be a integer`);
      }

      callback();
    }
  }

  // 附件上传
  attachmentHandleChange = (info) => {
    let fileList = [...info.fileList]; 
    fileList = fileList.slice(-1);
    this.props.tradeRequestEditChangeInput({name: 'fileList', value: fileList});

    fileList.forEach(file => {
      if (file.response) {
        const filename = file.response.data.fileNameWithTimeStamp;
        const originalFileName = file.response.data.originalFileName;
        this.props.tradeRequestEditChangeInput({name: 'attachment', value: filename});
        this.props.tradeRequestEditChangeInput({name: 'attachmentOriginName', value: originalFileName});
      }
    });
  }
  
  submitPADealingApplication = (needSubmit) => {
    const {
      tradeRequestEditControlModal,
      resultOf30DayHoldingCheckInfo,
    } = this.props;
    const {
      mode,
    } = tradeRequestEditControlModal;
    const {
      side,
      applyQty,
      actualQty,
      requestStatus,
    } = tradeRequestEditControlModal.fields;

    if(needSubmit && requestStatus === TRADE_REQUEST_STATUS_CODE.SUBMITTED && mode === 'EDIT') {
      message.warning("You cannot submit a PA dealing application that has already been submitted. Please use 'Save' if you just want to edit it.");
      return;
    }
  
    const employee = localStorageClient.getUserName();
    if (!!!employee) {
      let that = this;
      confirm({
        title: 'Login status is invalid, please login again!',
        content: 'Do you need to log out to the login page',
        onOk() {
          that.props.history.push({
            pathname: '/login',
          });
        },
        onCancel() {},
      });

      return;
    }

    // 30天持仓相关检查
    let skipConfirm = side !== 'SELL';
    let applyQtyIsOverAvailable = false;
    let actualQtyIsOverAvailable = false;
    if(!skipConfirm) {
      const {
        availableForSellQty
      } = resultOf30DayHoldingCheckInfo;
      applyQtyIsOverAvailable = applyQty!==undefined && applyQty!==null && applyQty!=='' && availableForSellQty < applyQty;
      actualQtyIsOverAvailable = actualQty!==undefined && actualQty!==null && actualQty!=='' && availableForSellQty < actualQty;
    }

    let doSubmitPADealingApplication = this.doSubmitPADealingApplication;
    if(applyQtyIsOverAvailable || actualQtyIsOverAvailable) {
      const checkField = actualQtyIsOverAvailable ? 'Actual Trade Quantity' : 'Apply Trade Quantity';
      confirm({
        title: 'The "' + checkField + '" you input is larger than "Quantity available for sell". Are you sure to continue?',
        onOk() {
          doSubmitPADealingApplication(needSubmit);
        },
        onCancel() {},
      });

    } else {
      doSubmitPADealingApplication(needSubmit);
    }
  }


  doSubmitPADealingApplication  = (needSubmit) => {
    const {
      tradeRequestEditControlModal,
    } = this.props;
    const {
      mode,
    } = tradeRequestEditControlModal;
    const {
      id,
      accountGuid,
      securityId,
      bbgTicker,
      secName,
      stockMarket,
      side,
      priceType,
      price,
      applyQty,
      actualQty,
      currency,
      tradeDate,
      holdingDays,
      remark,
      attachment,
      attachmentOriginName,
      category,
    } = tradeRequestEditControlModal.fields;
    const employee = localStorageClient.getUserName();

    const form = this.props.form;
    // 表单校验
    form.validateFields((err) => {
        if (!err) {
          if (mode === 'ADD' || mode ==='COPY') {
            this.props.createTradeRequest({
              employee,
              accountGuid,
              securityId,
              bbgTicker,
              secName,
              stockMarket,
              side,
              priceType,
              price,
              applyQty,
              actualQty,
              currency,
              tradeDate,
              holdingDays,
              remark,
              attachment,
              attachmentOriginName,
              needSubmit,
              category,
            });
          } else {
            this.props.updateTradeRequest({
              id,
              employee,
              accountGuid,
              securityId,
              bbgTicker,
              secName,
              stockMarket,
              side,
              priceType,
              price,
              applyQty,
              actualQty,
              currency,
              tradeDate,
              holdingDays,
              remark,
              attachment,
              attachmentOriginName,
              needSubmit,
              category,
            });
          }
        }
      },
    );
  }

  // Save PA Dealing Application
  handleSavePADealingApply = () => {
    this.setState({
      isSaveBtnOnClicking: true
    });
    this.submitPADealingApplication(false);
  }

  // Submit PA Dealing Application
  handleSubmitPADealingApply = () => {
    this.setState({
      isSaveBtnOnClicking: false
    });
    this.submitPADealingApplication(true);
  }

  // modal 关闭后清空状态
  modalClosed = () => {
    this.setState({
      loading: false,
    });
  }

  // sell all
  fillInApplyQtySellAll = () => {
    const {
      resultOf30DayHoldingCheckInfo,
    } = this.props;
    const {
      availableForSellQty,
    } = resultOf30DayHoldingCheckInfo;

    if(availableForSellQty === '') {
      message.warn('Please choose Account and Bbg Ticker first');
    } else {
      this.props.form.setFieldsValue({applyQty: availableForSellQty});
      this.onInputChange({ name: 'applyQty', value: availableForSellQty })
    }
  }



  render() {
    const {
      tradeRequestEditControlModal,
      createTradeRequestActionStatus,
      updateTradeRequestActionStatus,

      // Bbg Ticker
      bbgTickerOptions,
      bbgTickerArray,
      loadBbgTickersActionStatus,

      // Personal Trade Field History
      personalTradeFieldHistory,

      // ExchangeCode
      loadExchangeCodeActionStatus,
      exchageCodeOptions,

      // 附件上传URL
      tradeAttachmentUploadUrl,

      personalAccountOptions,

      // 30天持仓相关预检查
      resultOf30DayHoldingCheckInfo,
      
    } = this.props;
      
      // 30天持仓相关预检查
      const {
        allQuantity,
        in30DaysBuyQty,
        approveWaitSellQty,
        submittedNotAbandonedQty,
        availableForSellQty,
      } = resultOf30DayHoldingCheckInfo;


    const {
      isOpened,
      mode,
      respErrMsg,
    } = tradeRequestEditControlModal;

    const {
      loading,
      isSaveBtnOnClicking,
    } = this.state;

    const { getFieldDecorator } = this.props.form;

    const {
      accountGuid,
      securityId,
      bbgTicker,
      secName,
      stockMarket,
      side,
      priceType,
      price,
      applyQty,
      actualQty,
      currency,
      tradeDate,
      holdingDays,
      remark,
      fileList,
      requestStatus,
      category,
    } = tradeRequestEditControlModal.fields;


    const fieldDisabled = requestStatus === TRADE_REQUEST_STATUS_CODE.SUBMITTED && mode === 'EDIT';

    let opType = 'Add';
    if(mode === 'ADD') {
      opType = 'Add';
    } else if(mode === 'EDIT') {
      opType = 'Edit';
    } else if(mode === 'COPY') {
      opType = 'Copy';
    }
    let modalTitle = opType + ' PA Dealing Application';


    const isSaveLoading = (createTradeRequestActionStatus === ACTION_STATUS.LOGINING || updateTradeRequestActionStatus === ACTION_STATUS.LOGINING)  && isSaveBtnOnClicking;
    const isSubmitLoading = (createTradeRequestActionStatus === ACTION_STATUS.LOGINING || updateTradeRequestActionStatus === ACTION_STATUS.LOGINING) && !isSaveBtnOnClicking;
    let sideS = '';
    let flag = '';
    let allQuantityS =allQuantity;
    let in30DaysBuyQtyS = in30DaysBuyQty;
    let approveWaitSellQtyS =approveWaitSellQty;
    let submittedNotAbandonedQtyS =submittedNotAbandonedQty;
    let availableForSellQtyS =availableForSellQty;
    if(side=='BUY' || side =='SELL'){
      sideS = 'Sell';
      allQuantityS = allQuantity ;
      in30DaysBuyQtyS =in30DaysBuyQty;
      approveWaitSellQtyS =approveWaitSellQty;
      submittedNotAbandonedQtyS =submittedNotAbandonedQty;
      availableForSellQtyS =availableForSellQty;
    }else{
      sideS = 'Cover';
      allQuantityS = allQuantity *-1;
      in30DaysBuyQtyS =in30DaysBuyQty*-1;
      approveWaitSellQtyS =approveWaitSellQty*-1;
      submittedNotAbandonedQtyS =submittedNotAbandonedQty*-1;
      availableForSellQtyS =availableForSellQty*-1;
    }
     ;   
    
    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={1000}
          visible={isOpened}
          destroyOnClose={true}
          maskClosable={false}
          okText="Save"
          cancelText="Cancel"
          onCancel={() => this.props.resetTradeRequestEditModalDialog()}
          footer={[
            <Button key="back" onClick={() => this.props.resetTradeRequestEditModalDialog()}>
              Cancel
            </Button>,
            <Button key="save" type="primary" loading={isSaveLoading} onClick={this.handleSavePADealingApply}>
              Save
            </Button>,
            <Button key="submit" type="primary" loading={isSubmitLoading} onClick={this.handleSubmitPADealingApply}>
              Submit
            </Button>,
          ]}
        >
          <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={18}>
                <FormItem {...accoFormItemLayout} label="Account">
                  {getFieldDecorator('accountGuid', {
                      initialValue: accountGuid,
                      rules: [{
                        required: true,
                        message: 'Please choose Account',
                      }],
                    })(
                      <Select
                          allowClear
                          disabled={fieldDisabled}
                          style={{ width: 550, marginLeft: 0 }}
                          onChange={(val) => {
                            this.onInputChange({ name: 'accountGuid', value: val });
                          }} 
                          placeholder="Please choose Account">
                          {personalAccountOptions}
                      </Select>
                  )}
                </FormItem>
              </Col>
              <Col span={6}>
                <Button 
                      disabled={fieldDisabled || !accountGuid }
                      style={{ marginLeft: 20, marginTop: 5 }}
                      onClick={() => this.openCheckHoldingsPage(accountGuid) } 
                      size='small'
                      type='primary'>
                        Check Holdings
                </Button>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label={
                    <span>
                      Bbg Ticker&nbsp;
                      <Tooltip title="Please input by using Bloomberg ticker">
                        <Icon type="question-circle-o" />
                      </Tooltip>
                    </span>
                  }>
                  {getFieldDecorator('bbgTicker', {
                      initialValue: bbgTicker,
                      rules: [{
                        required: true,
                        message: 'Please input by using Bloomberg ticker',
                      }],
                    })(
                    <AutoComplete 
                      allowClear
                      disabled={fieldDisabled}
                      dataSource={bbgTickerArray}
                      onChange={this.bbgTickerOnChange}
                      onSearch={this.bbgTickerOnSearch} />
                    )}
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Security Name">
                  {getFieldDecorator('secName', {
                      initialValue: secName,
                      rules: [{
                        required: true,
                        message: 'Please input security name',
                      }],
                    })(
                    <Input
                      disabled={fieldDisabled}
                      placeholder="" 
                      allowClear
                      onChange={(e) => {
                        this.onInputChange({ name: 'secName', value: e.target.value })
                      }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Stock Market">
                  {getFieldDecorator('stockMarket', {
                      initialValue: stockMarket,
                      rules: [{
                        required: true,
                        message: 'Please input stock market',
                      }],
                    })(
                    <AutoComplete 
                      allowClear
                      disabled={fieldDisabled}
                      dataSource={exchageCodeOptions}
                      onChange={(val) => {
                        this.onInputChange({ name: 'stockMarket', value: val })
                      }}
                      onSearch={this.stockMarketOnSearch} />
                  )}
                </FormItem>
              </Col>
              <Col span={12}>
                  <FormItem {...formItemLayout} label='Category'>
                  {getFieldDecorator('category', {
                      initialValue: category,
                      rules: [{
                          required: !(mode==='EDIT' && fieldDisabled),
                          message: 'Please choose category',
                      }],
                      })(
                          <Select 
                            value={category}
                            disabled={fieldDisabled}
                            onChange={this.categoryTypeChanged} 
                            placeholder="category">
                            {categoryOptions}
                          </Select>
                      )}
                  </FormItem>
              </Col>
              
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Side">
                  <Select 
                    value={side}
                    disabled={fieldDisabled}
                    onChange={this.sideTypeChanged} 
                    placeholder="Please Choose">
                    {SideTypeOptions}
                  </Select>
                </FormItem>
              </Col>
              <Col span={12}>
                <FormItem {...formItemLayout} label="Apply Trade Quantity">
                  {getFieldDecorator('applyQty', {
                      initialValue: applyQty,
                      rules: [{
                        required: true,
                        pattern: new RegExp(/^[1-9]\d*$/, "g"),
                        message: 'Please input correct Apply Quantity',
                      }],
                    })(
                    <Input 
                      disabled={fieldDisabled}
                      placeholder="" 
                      name = 'applyQty'
                      style={{ width: 150, marginLeft: 0 }}
                      onChange={(e) => {
                        this.onInputChange({ name: 'applyQty', value: e.target.value })
                      }}
                    />
                  )}

                  <Button 
                      disabled={side !== 'SELL' || fieldDisabled}
                      style={{ marginLeft: 15 }}
                      onClick={this.fillInApplyQtySellAll} 
                      size='small'
                      type='primary'>
                        Sell All
                  </Button>
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              {
                  (mode==='EDIT' && fieldDisabled) &&
                    <Col span={12}>
                      <FormItem {...formItemLayout} label="Actual Trade Quantity">
                        {getFieldDecorator('actualQty', {
                            initialValue: actualQty,
                            rules: [{
                              required: true,
                              pattern: new RegExp(/^[0-9]\d*$/, "g"),
                              message: 'Please input correct actual quantity',
                            }],
                          })(
                          <Input 
                            placeholder="" 
                            onChange={(e) => {
                              this.onInputChange({ name: 'actualQty', value: e.target.value })
                            }}
                          />
                        )}
                      </FormItem>
                </Col>}
                <Col span={12}>
                  <FormItem {...formItemLayout} label="Price Type">
                    <Select 
                      value={priceType}
                      disabled={fieldDisabled}
                      onChange={this.priceTypeChanged} 
                      placeholder="Please Choose">
                      {PriceTypeOptions}
                    </Select>
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="Price">
                    {getFieldDecorator('price', {
                        initialValue: price,
                        rules: [{
                          required: false,
                          pattern: new RegExp(/^(0|[1-9][0-9]*)(\.?)([0-9]*)$/, "g"),
                          message: 'Please input correct price',
                        }],
                      })(
                      <Input 
                        placeholder=''
                        onChange={(e) => {
                          this.onInputChange({ name: 'price', value: e.target.value })
                        }}
                      />
                    )}
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="Currency">
                    <Input 
                      value={currency}
                      disabled
                      placeholder="" 
                      onChange={(e) => {
                          this.onInputChange({ name: 'currency', value: e.target.value })
                        }}
                    />
                  </FormItem>
                </Col>
                <Col span={12}>
                  <FormItem {...formItemLayout} label="Trade Date">
                    <DatePicker 
                      style={{ width: 230, marginLeft: 0 }}
                      value={moment(tradeDate)}
                      disabled/>
                  </FormItem>
                </Col>
            </Row>
            {/* <Row gutter={24}>
                
              </Row> */}
            {/* 修改时显示上传附件 */}
            {
               (mode==='EDIT' && fieldDisabled) &&
              <Row gutter={24}>
                <Col span={17}>
                    <FormItem {...remarkFormItemLayout} label="Attachment">
                      <Upload
                        name="attachment"
                        withCredentials={true}
                        multiple={true}
                        showUploadList={true}
                        fileList={fileList}
                        action={tradeAttachmentUploadUrl}
                        onChange={this.attachmentHandleChange}
                      >
                        <Button loading={loading}>
                          <Icon type="upload" /> Proof of Executed Trade
                        </Button>
                      </Upload>
                    </FormItem>
                </Col>
              </Row>
            }
            <Row span={24}>
              <Col span={12}>
                <FormItem {...remarkFormItemLayout} label="Remark">
                  <TextArea 
                    rows={5} 
                    style={{ width: 300, marginLeft: 0 }}
                    value={remark} 
                    onChange={(e) => {
                      this.onInputChange({ name: 'remark', value: e.target.value })
                    }}/>
                </FormItem>
              </Col>
              <Col span={12}>
                  <div>
                    <table cellPadding='2'>
                      <tbody>
                        <tr>
                          <td align='right'>Current Ticker quantity holding (S):</td>
                          <td align='left'>&nbsp;{allQuantityS}</td>
                        </tr>
                        <tr>
                          <td align='right'>Quantity holding less than 30 days (A):</td>
                          <td align='left'>&nbsp;<font color='red'>{in30DaysBuyQtyS}</font></td>
                        </tr>
                        <tr>
                          <td align='right'>{sideS} quantity approved waiting for trade (B):</td>
                          <td align='left'>&nbsp;<font color='red'>{approveWaitSellQtyS}</font></td>
                        </tr>
                        <tr>
                          <td align='right'>{sideS} quantity submitted and unabadoned (C):</td>
                          <td align='left'>&nbsp;<font color='red'>{submittedNotAbandonedQtyS}</font></td>
                        </tr>
                        <tr>
                          <td align='right'>Quantity available for {sideS} (X=S-A-B-C):</td>
                          <td align='left'>&nbsp;<font color='green'>{availableForSellQtyS}</font></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
              </Col>
            </Row>
          </Form>
          {
            !!respErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
              <p>{ respErrMsg }</p>
            </Message>
          }
        </Modal>
      </div>
    );
  }
}

const TradeRequestEditDialogForm = Form.create({ name: 'trade_request_edit_dialog' })(TradeRequestEditDialog);

export default TradeRequestEditDialogForm;