import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Select, Button, AutoComplete, DatePicker, Upload, Icon } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import moment from 'moment';


const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


class AddOrEditGeneralAgreementModalDialog extends Component {

    componentDidMount() {
    }

    refreshBtnOnClicked = () => {
        this.props.loadGeneralAgreementInfo();
    }


    componentDidUpdate() {
        const {
            submitSaveGeneralAgreementActionStatus,
        } = this.props;
        const {
            mode,
        } = this.props.addOrEditGeneralAgreementModal;

        if(submitSaveGeneralAgreementActionStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getActionFromMode(mode) + ' General Agreement succeeded.';
            message.success(msg);
            this.props.initSubmitSaveGeneralAgreementModalActionStatus();
            this.props.resetSubmitSaveGeneralAgreementModal();
            this.refreshBtnOnClicked();
        }
        if(submitSaveGeneralAgreementActionStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitSaveGeneralAgreementModalActionStatus();
        }
    }


    getActionFromMode(mode) {
        if(mode === 'ADD') {
            return 'Add';
        } else if(mode === 'COPY') {
            return 'Copy';
        } else {
            return 'Edit';
        }
    }


    generalAgreeementHandleChange = (info) => {
        let fileList = [...info.fileList];
        if(!fileList || fileList.length === 0) {
            this.onInputChange({name: 'agreementUploadFileList', value: []});
            this.onInputChange({name: 'uploadRealName', value: undefined});
            this.onInputChange({name: 'uploadOriName', value: undefined});
            return;
        }

        fileList = fileList.slice(-1);
        this.onInputChange({name: 'agreementUploadFileList', value: fileList});
        const file = fileList[0];
        if (!!file && !!file.response) {
          const filename = file.response.data.fileNameWithTimeStamp;
          const originalFileName = file.response.data.originalFileName;
          this.onInputChange({name: 'uploadRealName', value: filename});
          this.onInputChange({name: 'uploadOriName', value: originalFileName});
        }
    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditGeneralAgreemenChangeInput({name, value});
    }
    onPICInputChange = (val) => {
        const value = !val ? undefined : val.join(';');
        this.props.addOrEditGeneralAgreemenChangeInput({name: 'personInCharge', value});
    }


    submitSaveGeneralAgreementModal = (needSubmit) => {
        const {
            mode,
        } = this.props.addOrEditGeneralAgreementModal;
        const {
            id,
            status,
            title,
            department,
            personInCharge,
            company,
            counterparty,
            agreementType,
            executionDate,
            expiryDate,
            noticePeriod,
            terms,
            uploadOriName,
            uploadRealName,
            amount,
            amountCCY,
            payer,
            remarks,
            comments,
            source,
        } = this.props.addOrEditGeneralAgreementModal.fields;

        if(mode === 'EDIT' && status === 'Valid' && !needSubmit ) {
            const msg = 'For the General Agreements in Valid status, you can only Submit the edit.';
            message.warn(msg);
            return;
        }

        // 通过Report端ADD、COPY、EDIT，可以同时SUBMIT
        let modes = mode;
        if(needSubmit) {
            modes += ',SUBMIT';
        }

        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            // 在addOrEdit页面添加附件后又删除，Form控件会检查不出来，要单独检查
            if(!uploadOriName || !uploadRealName) {
                message.error('Upload of Agreement attachemet is required');
                return;
            }

            this.props.submitSaveGeneralAgreementModal(mode, {
                id,
                status,
                title,
                department,
                personInCharge,
                company,
                counterparty,
                agreementType,
                executionDate,
                expiryDate,
                noticePeriod,
                terms,
                uploadOriName,
                uploadRealName,
                amount,
                amountCCY,
                payer,
                remarks,
                comments,
                source,
                modes,
            });
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            departmentOptions,
            companyOptions,
            agreementTypeOptions,
            ccyOptions,
            generalAgreementUploadUrl,
            addOrEditGeneralAgreementModal,
            submitSaveGeneralAgreementActionStatus,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditGeneralAgreementModal;

        const {
            id,
            status,
            title,
            department,
            personInCharge,
            company,
            counterparty,
            agreementType,
            executionDate,
            expiryDate,
            noticePeriod,
            terms,
            uploadOriName,
            uploadRealName,
            amount,
            amountCCY,
            payer,
            remarks,
            comments,
            agreementUploadFileList,
            submit2ValidTimes,
        } = addOrEditGeneralAgreementModal.fields;

        const executionDateMonent = !executionDate ? undefined : moment(executionDate);
        const expiryDateMonent = !expiryDate ? undefined : moment(expiryDate);

        const modalTitle = this.getActionFromMode(mode) + ' General Agreement';
        const isSubmitLoading = submitSaveGeneralAgreementActionStatus === ACTION_STATUS.LOGINING;

        const needDisable = mode === 'EDIT' && (status !== 'New' && status !== 'Rejected' || status === 'Rejected' && submit2ValidTimes > 0);
        // const needDisable = false
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1000}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetSubmitSaveGeneralAgreementModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetSubmitSaveGeneralAgreementModal() }>
                            Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveGeneralAgreementModal(false) }>
                            Save
                        </Button>,
                        <Button key="SaveAndSubmit" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveGeneralAgreementModal(true) }>
                            Save&nbsp;&amp;&nbsp;Submit
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="title">
                                    {getFieldDecorator('title', {
                                        initialValue: title,
                                        rules: [{
                                            required: true,
                                            message: 'Please input title',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 250, marginLeft: 10 }}
                                            disabled={needDisable}
                                            rows={4} 
                                            placeholder='Please input title' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'title', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="personInCharge">
                                    {getFieldDecorator('personInCharge', {
                                        initialValue: personInCharge,
                                        rules: [{
                                            required: true,
                                            message: 'Please select personInCharge',
                                        }],
                                        })(
                                        <span style={{ marginLeft: 10 }}>{personInCharge}</span>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="department">
                                    {getFieldDecorator('department', {
                                        initialValue: department,
                                        rules: [{
                                            required: true,
                                            message: 'Please select department',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 250, marginLeft: 10 }}
                                            disabled={needDisable}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'department', value: val })
                                            }} 
                                            placeholder="Please select department">
                                                {departmentOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="company">
                                    {getFieldDecorator('company', {
                                        initialValue: company,
                                        rules: [{
                                            required: true,
                                            message: 'Please select or input company',
                                        }],
                                        })(
                                        <AutoComplete
                                            style={{ width: 250, marginLeft: 10 }}
                                            disabled={needDisable}
                                            dataSource={companyOptions}
                                            placeholder='Please select or input company'
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'company', value: val })
                                            }}
                                            filterOption={(inputValue, option) => {
                                                const idx = option.key.toUpperCase().indexOf(inputValue.toUpperCase());
                                                return idx !== -1;
                                            }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="counterparty">
                                    {getFieldDecorator('counterparty', {
                                        initialValue: counterparty,
                                        rules: [{
                                            required: true,
                                            message: 'Please input counterparty',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 250, marginLeft: 10 }}
                                            disabled={needDisable}
                                            rows={4} 
                                            placeholder='Please input counterparty' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'counterparty', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="executionDate">
                                    {getFieldDecorator('executionDate', {
                                        initialValue:executionDateMonent,
                                        rules: [{
                                            required: true,
                                            message: 'Please select executionDate',
                                        }],
                                        })(
                                        <DatePicker 
                                            allowClear
                                            size='small'
                                            style={{ width: 250, marginLeft: 10 }}
                                            disabled={needDisable}
                                            placeholder='executionDate'
                                            onChange={(date, dateString) => {
                                              this.onInputChange({ name: 'executionDate', value: dateString });
                                            }}
                                        />
                                    )}
                                </FormItem>
                                <FormItem {...formItemLayout} label="expiryDate">
                                    {getFieldDecorator('expiryDate', {
                                        initialValue: expiryDateMonent,
                                        rules: [{
                                            required: true,
                                            message: 'Please select expiryDate',
                                        }],
                                        })(
                                        <DatePicker 
                                            allowClear
                                            size='small'
                                            style={{ width: 250, marginLeft: 10 }}
                                            placeholder='expiryDate'
                                            onChange={(date, dateString) => {
                                              this.onInputChange({ name: 'expiryDate', value: dateString });
                                            }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="agreementType">
                                    {getFieldDecorator('agreementType', {
                                        initialValue: agreementType,
                                        rules: [{
                                            required: true,
                                            message: 'Please select or input agreementType',
                                        }],
                                        })(
                                        <AutoComplete
                                            style={{ width: 250, marginLeft: 10 }}
                                            disabled={needDisable}
                                            dataSource={agreementTypeOptions}
                                            placeholder='Please select or input agreementType'
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'agreementType', value: val })
                                            }}
                                            filterOption={(inputValue, option) => {
                                                const idx = option.key.toUpperCase().indexOf(inputValue.toUpperCase());
                                                return idx !== -1;
                                            }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="noticePeriod">
                                    {getFieldDecorator('noticePeriod', {
                                        initialValue: noticePeriod,
                                        rules: [{
                                            required: true,
                                            pattern: new RegExp(/^[1-9]$|^[1-9][0-9]$|^[12][0-9][0-9]$|^3(?:[0-5][0-9]|6[0-5])$/, "g"),
                                            message: 'Please input an integer between 1 and 365',
                                        }],
                                        })(
                                        <Input placeholder='Please input noticePeriod' 
                                            style={{ width: 200, marginLeft: 10 }}
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'noticePeriod', value: e.target.value })
                                            }}
                                        />
                                    )}
                                    <span style={{ marginLeft: 10 }}>days</span>
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="upload">
                                    {getFieldDecorator('uploadOriName', {
                                        initialValue: uploadOriName,
                                        rules: [{
                                            required: true,
                                            message: 'Please upload the Agreement',
                                        }],
                                        })(
                                        <Upload
                                            name="uploadOriName"
                                            withCredentials={true}
                                            disabled={needDisable}
                                            multiple={false}
                                            fileList={agreementUploadFileList}
                                            action={generalAgreementUploadUrl}
                                            onChange={this.generalAgreeementHandleChange}
                                        >
                                            <Button 
                                                style={{ width: 250, marginLeft: 10 }}
                                                disabled={needDisable}
                                            >
                                                <Icon type="upload" />Upload
                                            </Button>
                                        </Upload>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="terms">
                                    {getFieldDecorator('terms', {
                                        initialValue: terms,
                                        rules: [{
                                            required: true,
                                            message: 'Please input terms',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 250, marginLeft: 10 }}
                                            disabled={needDisable}
                                            rows={3} 
                                            placeholder='Please input terms' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'terms', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="amount">
                                    {getFieldDecorator('amount', {
                                        initialValue: amount,
                                        rules: [{
                                            required: false,
                                            pattern: new RegExp(/^(([0-9]+)|([0-9]+\.[0-9]{1,2}))$/, "g"),
                                            message: 'Please input a positive number with at most two decimal places',
                                        }],
                                        })(
                                            <Input placeholder='' 
                                                style={{ width: 250, marginLeft: 10 }}
                                                onChange={(e) => {
                                                    this.onInputChange({ name: 'amount', value: e.target.value })
                                                }}
                                            />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="amountCCY">
                                    {getFieldDecorator('amountCCY', {
                                        initialValue: amountCCY,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                            <Select
                                                    allowClear
                                                    style={{ width: 250, marginLeft: 10 }}
                                                    onChange={(val) => {
                                                        this.onInputChange({ name: 'amountCCY', value: val })
                                                    }} 
                                                    placeholder=''>
                                                {ccyOptions}
                                            </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                        <Col span={12}>
                                <FormItem {...formItemLayout} label="Payer">
                                    {getFieldDecorator('payer', {
                                        initialValue: payer,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                            <Input placeholder='Please input Payer' 
                                                style={{ width: 250, marginLeft: 10 }}
                                                onChange={(e) => {
                                                    this.onInputChange({ name: 'payer', value: e.target.value })
                                                }}
                                            />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="remarks">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remarks,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 250, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='Please input remarks' 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                            </Col>
                        </Row>

                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}

const AddOrEditGeneralAgreementModalDialogForm = Form.create({ name: 'AddOrEditGeneralAgreementModalDialog' })(AddOrEditGeneralAgreementModalDialog);
export default AddOrEditGeneralAgreementModalDialogForm;
