import React from 'react';
import { Select } from 'antd';
import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';
import BaseUrlConfig from '../../../net/BaseUrlConfig';
import UrlConfig from '../../../net/UrlConfig';

import {
    // query
    ON_APPLY_RECON_QUERY_INPUT_CHANGE,
    START_LOAD_APPLY_RECON_INFO,
    LOAD_APPLY_RECON_INFO_SUCCESS,
    LOAD_APPLY_RECON_INFO_FAILURE,
    INIT_LOAD_APPLY_RECON_INFO_ACTION_STATUS,
    // Select
    SELECT_REPORTING_SUMMRAY,
    // add or edit
    OPEN_ADD_OR_EDIT_APPLY_RECON_MODAL,
    ADD_OR_EDIT_APPLY_RECON_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_APPLY_RECON_MODAL,
    START_SUBMIT_SAVE_APPLY_RECON,
    SUBMIT_SAVE_APPLY_RECON_SUCCESS,
    SUBMIT_SAVE_APPLY_RECON_FAILURE,
    INIT_SUBMIT_SAVE_APPLY_RECON_ACTION_STATUS,
    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_APPLY_RECON,
    BATCH_CHANGE_STATUS_FOR_APPLY_RECON_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_APPLY_RECON_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_APPLY_RECON_ACTION_STATUS,
    // finish
    SELECT_REPORTING_SUMMRAY_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_APPLY_RECON_TASK,
    FINISH_APPLY_RECON_TASK_SUCCESS,
    FINISH_APPLY_RECON_TASK_FAILURE,
    INIT_FINISH_APPLY_RECON_TASK_ACTION_STATUS,

    
} from './ApplyReconConstants';

import {
    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,
  } from '../home/homeConstants';


const Option = Select.Option;

const locationOptions = [];
locationOptions.push(<Option key={'All'}>{'All'}</Option>);
locationOptions.push(<Option key={'BVI'}>{'BVI'}</Option>);
locationOptions.push(<Option key={'Cayman'}>{'Cayman'}</Option>);
locationOptions.push(<Option key={'Hainan'}>{'Hainan'}</Option>);
locationOptions.push(<Option key={'Hong Kong'}>{'Hong Kong'}</Option>);
locationOptions.push(<Option key={'India'}>{'India'}</Option>);
locationOptions.push(<Option key={'Japan'}>{'Japan'}</Option>);
locationOptions.push(<Option key={'Shanghai'}>{'Shanghai'}</Option>);
locationOptions.push(<Option key={'Singapore'}>{'Singapore'}</Option>);
locationOptions.push(<Option key={'USA'}>{'USA'}</Option>);
locationOptions.push(<Option key={'Others'}>{'Others'}</Option>);

const fundCompanyOptions = [];
fundCompanyOptions.push(<Option key={'All'}>{'All'}</Option>);
fundCompanyOptions.push(<Option key={'COP GP'}>{'COP GP'}</Option>);
fundCompanyOptions.push(<Option key={'PAML'}>{'PAML'}</Option>);
fundCompanyOptions.push(<Option key={'PAJP'}>{'PAJP'}</Option>);
fundCompanyOptions.push(<Option key={'PASG'}>{'PASG'}</Option>);
fundCompanyOptions.push(<Option key={'PIAS'}>{'PIAS'}</Option>);
fundCompanyOptions.push(<Option key={'PCMG'}>{'PCMG'}</Option>);
// fundCompanyOptions.push(<Option key={'投资顾问'}>{'投资顾问'}</Option>);
// fundCompanyOptions.push(<Option key={'投资管理'}>{'投资管理'}</Option>);
// fundCompanyOptions.push(<Option key={'资产管理'}>{'资产管理'}</Option>);
fundCompanyOptions.push(<Option key={'投资顾问 (上海)'}>{'投资顾问 (上海)'}</Option>);
fundCompanyOptions.push(<Option key={'私募管理 (上海)'}>{'私募管理 (上海)'}</Option>);
fundCompanyOptions.push(<Option key={'私募管理 (海南)'}>{'私募管理 (海南)'}</Option>);
fundCompanyOptions.push(<Option key={'资产管理 (上海)'}>{'资产管理 (上海)'}</Option>);
fundCompanyOptions.push(<Option key={'PAMF VCC'}>{'PAMF VCC'}</Option>);
fundCompanyOptions.push(<Option key={'COP'}>{'COP'}</Option>);
fundCompanyOptions.push(<Option key={'EJ'}>{'EJ'}</Option>);
fundCompanyOptions.push(<Option key={'Wealth Ocean'}>{'Wealth Ocean'}</Option>);
fundCompanyOptions.push(<Option key={'Pinpoint Charity'}>{'Pinpoint Charity'}</Option>);
fundCompanyOptions.push(<Option key={'CVF'}>{'CVF'}</Option>);
fundCompanyOptions.push(<Option key={'DCL'}>{'DCL'}</Option>);
fundCompanyOptions.push(<Option key={'SLHL'}>{'SLHL'}</Option>);
fundCompanyOptions.push(<Option key={'ZJNF'}>{'ZJNF'}</Option>);
fundCompanyOptions.push(<Option key={'MetaPoint'}>{'MetaPoint'}</Option>);
fundCompanyOptions.push(<Option key={'PCF'}>{'PCF'}</Option>);
fundCompanyOptions.push(<Option key={'PLUS'}>{'PLUS'}</Option>);
fundCompanyOptions.push(<Option key={'PMSF'}>{'PMSF'}</Option>);
fundCompanyOptions.push(<Option key={'Others'}>{'Others'}</Option>);

const frequencyOptions = [];
frequencyOptions.push(<Option key={'EveryWorkday'}>{'EveryWorkday'}</Option>);
frequencyOptions.push(<Option key={'Weekly'}>{'Weekly'}</Option>);
frequencyOptions.push(<Option key={'Monthly'}>{'Monthly'}</Option>);
frequencyOptions.push(<Option key={'Quarterly'}>{'Quarterly'}</Option>);
frequencyOptions.push(<Option key={'SemiAnnually'}>{'SemiAnnually'}</Option>);
frequencyOptions.push(<Option key={'Annually'}>{'Annually'}</Option>);
frequencyOptions.push(<Option key={'Every3Years'}>{'Every3Years'}</Option>);
frequencyOptions.push(<Option key={'Incidental'}>{'Incidental'}</Option>);

const weekdayOptions = [];
weekdayOptions.push(<Option key={'Sun'}>{'Sun'}</Option>);
weekdayOptions.push(<Option key={'Mon'}>{'Mon'}</Option>);
weekdayOptions.push(<Option key={'Tue'}>{'Tue'}</Option>);
weekdayOptions.push(<Option key={'Wed'}>{'Wed'}</Option>);
weekdayOptions.push(<Option key={'Thu'}>{'Thu'}</Option>);
weekdayOptions.push(<Option key={'Fri'}>{'Fri'}</Option>);
weekdayOptions.push(<Option key={'Sat'}>{'Sat'}</Option>);

const monthOptions = [];
monthOptions.push(<Option key={'01'}>{'Jan'}</Option>);
monthOptions.push(<Option key={'02'}>{'Feb'}</Option>);
monthOptions.push(<Option key={'03'}>{'Mar'}</Option>);
monthOptions.push(<Option key={'04'}>{'Apr'}</Option>);
monthOptions.push(<Option key={'05'}>{'May'}</Option>);
monthOptions.push(<Option key={'06'}>{'Jun'}</Option>);
monthOptions.push(<Option key={'07'}>{'Jul'}</Option>);
monthOptions.push(<Option key={'08'}>{'Aug'}</Option>);
monthOptions.push(<Option key={'09'}>{'Sep'}</Option>);
monthOptions.push(<Option key={'10'}>{'Oct'}</Option>);
monthOptions.push(<Option key={'11'}>{'Nov'}</Option>);
monthOptions.push(<Option key={'12'}>{'Dec'}</Option>);

const responsibleDeptOptions = [];
responsibleDeptOptions.push(<Option key={'Accounting'}>{'Accounting'}</Option>);
responsibleDeptOptions.push(<Option key={'BD'}>{'BD'}</Option>);
responsibleDeptOptions.push(<Option key={'Compliance'}>{'Compliance'}</Option>);
responsibleDeptOptions.push(<Option key={'CS'}>{'CS'}</Option>);
responsibleDeptOptions.push(<Option key={'Investment'}>{'Investment'}</Option>);
responsibleDeptOptions.push(<Option key={'IT'}>{'IT'}</Option>);
responsibleDeptOptions.push(<Option key={'Marketing'}>{'Marketing'}</Option>);
responsibleDeptOptions.push(<Option key={'Risk'}>{'Risk'}</Option>);
responsibleDeptOptions.push(<Option key={'Settlement'}>{'Settlement'}</Option>);


const initialState = {
    locationOptions: locationOptions,
    fundCompanyOptions: fundCompanyOptions,
    frequencyOptions: frequencyOptions,
    weekdayOptions: weekdayOptions,
    monthOptions: monthOptions,
    responsibleDeptOptions: responsibleDeptOptions,
    meetingAndTrainingUploadUrl: BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.MEETING_AND_TRAINING_UPLOAD_URL,
    applyReconQueryFields: {
        id: undefined,
        location: undefined,
        fundCompany: undefined,
    },

    selectedApplyReconArray: [],
    selectedApplyReconTaskArray: [],

    addOrEditApplyReconModal: {
        isOpened: false,
        mode: 'ADD',
        respErrMsg: '',
        fields: {
            id: undefined,
            status: 'Working',
            location: undefined,
            fundCompany: undefined,
            description: undefined,
            via: undefined,
            frequency: undefined,
            deadline: undefined,
            notification: undefined,
            regulator: undefined,
            responsibleDept: undefined,
            emailTo: undefined,
            emailCC: undefined,
            regulation: undefined,
            remarks: undefined,
            comments: undefined,
            // 将deadline分解后的参数
            deadlineMonth: undefined,
            deadlineDate: undefined,
            deadlineYear: undefined,
            idList: undefined,
            begindate: undefined,
            sector: undefined,
            exception: undefined,
            market: undefined,
            complianceconfirm: undefined,
            uploadOriName: undefined,
            uploadRealName: undefined,
            applyType: undefined,
            remark: undefined,
        },
    },

    batchChangeStatusModal: {
        isOpened: false,
        mode: 'PAUSE',
        respErrMsg: '',
        fields: {
            idList: [],
            comments: undefined,
        },
    },

    finishTaskModal: {
        isOpened: false,
        respErrMsg: '',
        fields: {
            id: undefined,
            remarks: undefined,
            comments: undefined,
        },
    },

    userAccountEmailOptions: [],

};



// query
function onApplyReconQueryInputChange(state, {name, value}) {
    const upatedFields = {
        ...state.applyReconQueryFields,
        [name]: value,
    };
    return {
        ...state,
        applyReconQueryFields: upatedFields,
    }
}
function startLoadApplyReconInfo(state) {
    return {
        ...state,
        loadApplyReconInfoActionStatus: ACTION_STATUS.LOGINING,
      };
}
function loadApplyReconInfoSuccess(state, resp) {
    return {
        ...state,
        loadApplyReconInfoActionStatus: ACTION_STATUS.SUCCESS,
        loadApplyReconInfoResp: resp,
    };
}
function loadApplyReconInfoFailure(state, err) {
    return {
        ...state,
        loadApplyReconInfoActionStatus: ACTION_STATUS.ERROR,
        loadApplyReconInfoResp: err,
    };
}
function initLoadApplyReconInfoActionStatus(state) {
    return {
        ...state,
        loadApplyReconInfoActionStatus: ACTION_STATUS.READY,
        selectedApplyReconArray: [],
        selectedApplyReconTaskArray: [],
    };
}


// select
function selectApplyRecon(state, array) {
    return {
        ...state,
        selectedApplyReconArray: array,
    };
}


// add or edit
function openAddOrEditApplyReconModal(state, {mode, data}) {
    if(mode === 'ADD' || mode === 'Stock Pitch' || mode === 'PaperPortfolioOrBook'  ) {
        return {
            ...state,
            addOrEditApplyReconModal: {
                ...state.addOrEditApplyReconModal,
                isOpened: true,
                mode,
            },
        };

    } else {
        const attachementUploadList = [];
        if(!!data.filePath) {
            const attachmentFile = {
                uid: '-1',
                name: data.filePath,
                status: 'done',
                // url: state.personalAccountDownloadUrl + "/" + account.attachment,
                response: {
                    respCode: "S0001",
                    fileNameWithTimeStamp: data.attachment,
                    originalFileName: data.filePath,
                }
            };
            attachementUploadList.push(attachmentFile);
        }
        // return
        return {
            ...state,
            addOrEditApplyReconModal: {
                ...state.addOrEditApplyReconModal,
                isOpened: true,
                mode,
                fields: {
                    ...state.addOrEditApplyReconModal.fields,
                    id: data.id,
                    status: data.status,
                    location: data.location,
                    fundCompany: data.fundCompany,
                    description: data.description,
                    via: data.via,
                    frequency: data.frequency,
                    deadline: data.deadline,

                    regulator: data.regulator,
                    responsibleDept: data.responsibleDept,
                    emailTo: data.emailTo,
                    emailCC: data.emailCC,
                    regulation: data.regulation,
    

                    idList: data.remarks,
                    begindate: data.begindate,
                    sector: data.sector,
                    exception: data.exception,
                    market: data.market,
                    complianceconfirm: data.complianceconfirm,
                    uploadOriName: data.filePath,
                    uploadRealName: data.uploadRealName,
                    applytype: data.applytype,
                    remark: data.remark,
                    agreementUploadFileList:attachementUploadList,
                }
            },
        }

    }
}
function addOrEditApplyReconChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.addOrEditApplyReconModal.fields,
        [name]: value,
    };
    return {
        ...state,
        addOrEditApplyReconModal: {
          ...state.addOrEditApplyReconModal,
          fields: updatedFields,
        },
    };
}
function resetSubmitSaveApplyReconModal(state) {
    return {
        ...state,
        addOrEditApplyReconModal: initialState.addOrEditApplyReconModal,
    }
}

function startSubmitSaveApplyRecon(state) {
    return {
        ...state,
        addOrEditApplyReconStatus: ACTION_STATUS.LOGINING,
    };
}
function submitSaveApplyReconSuccess(state, resp) {
    return {
        ...state,
        addOrEditApplyReconStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function submitSaveApplyReconFailure(state, err) {
    return {
        ...state,
        addOrEditApplyReconStatus: ACTION_STATUS.ERROR,
        addOrEditApplyReconModal: {
            ...state.addOrEditApplyReconModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initSubmitSaveApplyReconActionStatus(state) {
    return {
        ...state,
        addOrEditApplyReconStatus: ACTION_STATUS.READY,
    };
}


// batch change status
function openBatchChangeStatusModal(state, {mode, idList}) {
    return {
        ...state,
        batchChangeStatusModal: {
            ...state.batchChangeStatusModal,
            isOpened: true,
            mode,
            fields: {
                ...state.batchChangeStatusModal.fields,
                idList,
            },
        },
    };
}
function resetBatchChangeStatusModal(state) {
    return {
        ...state,
        batchChangeStatusModal: initialState.batchChangeStatusModal,
    }
}
function batchChangeStatusChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.batchChangeStatusModal.fields,
        [name]: value,
    };
    return {
        ...state,
        batchChangeStatusModal: {
          ...state.batchChangeStatusModal,
          fields: updatedFields,
        },
    };
}

function startBatchChangeStatusForApplyRecon(state) {
    return {
        ...state,
        batchChangeStatusForApplyReconStatus: ACTION_STATUS.LOGINING,
    };
}
function batchChangeStatusForApplyReconSuccess(state, resp) {
    return {
        ...state,
        batchChangeStatusForApplyReconStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function batchChangeStatusForApplyReconFailure(state, err) {
    return {
        ...state,
        batchChangeStatusForApplyReconStatus: ACTION_STATUS.ERROR,
        batchChangeStatusModal: {
            ...state.batchChangeStatusModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initBatchChangeStatusForApplyReconActionStatus(state) {
    return {
        ...state,
        batchChangeStatusForApplyReconStatus: ACTION_STATUS.READY,
    };
}


// finish
function selectApplyReconTask(state, array) {
    return {
        ...state,
        selectedApplyReconTaskArray: array,
    };
}
function openFinishTaskModal(state, task) {
    return {
        ...state,
        finishTaskModal: {
            ...state.finishTaskModal,
            isOpened: true,
            fields: {
                ...state.finishTaskModal.fields,
                id: task.id,
                remarks: task.remarks,
            },
        },
    };
}
function resetFinishTaskModal(state) {
    return {
        ...state,
        finishTaskModal: initialState.finishTaskModal,
    }; 
}
function finishTaskChangeInput(state, {name, value}) {
    const updatedFields = {
        ...state.finishTaskModal.fields,
        [name]: value,
    };
    return {
        ...state,
        finishTaskModal: {
          ...state.finishTaskModal,
          fields: updatedFields,
        },
    };
}

function startFinishApplyReconTask(state) {
    return {
        ...state,
        finishApplyReconTaskStatus: ACTION_STATUS.LOGINING,
    };
}
function finishApplyReconTaskSuccess(state, resp) {
    return {
        ...state,
        finishApplyReconTaskStatus: ACTION_STATUS.SUCCESS,
    }; 
}
function finishApplyReconTaskFailure(state, err) {
    return {
        ...state,
        finishApplyReconTaskStatus: ACTION_STATUS.ERROR,
        finishTaskModal: {
            ...state.finishTaskModal,
            respErrMsg: err.respMessage,
        }
    };
}
function initFinishApplyReconTaskActionStatus(state) {
    return {
        ...state,
        finishApplyReconTaskStatus: ACTION_STATUS.READY,
    };
}


// user account
function loadUserAccountsSuccess(state, resp) {
    const userAccountArray = resp.data;
    const userAccountEmailOptions = userAccountArray.map(userAccount => <Option key={userAccount.email}>{userAccount.englishName}</Option>);
    return {
        ...state,
        userAccountEmailOptions,
      };
}


export default createReducer(initialState, {
    // query
    [ON_APPLY_RECON_QUERY_INPUT_CHANGE]: onApplyReconQueryInputChange,
    [START_LOAD_APPLY_RECON_INFO]: startLoadApplyReconInfo,
    [LOAD_APPLY_RECON_INFO_SUCCESS]: loadApplyReconInfoSuccess,
    [LOAD_APPLY_RECON_INFO_FAILURE]: loadApplyReconInfoFailure,
    [INIT_LOAD_APPLY_RECON_INFO_ACTION_STATUS]: initLoadApplyReconInfoActionStatus,
    // select
    [SELECT_REPORTING_SUMMRAY]: selectApplyRecon,
    // add or edit
    [OPEN_ADD_OR_EDIT_APPLY_RECON_MODAL]: openAddOrEditApplyReconModal,
    [ADD_OR_EDIT_APPLY_RECON_CHANGE_INPUT]: addOrEditApplyReconChangeInput,
    [RESET_SUBMIT_SAVE_APPLY_RECON_MODAL]: resetSubmitSaveApplyReconModal,
    [START_SUBMIT_SAVE_APPLY_RECON]: startSubmitSaveApplyRecon,
    [SUBMIT_SAVE_APPLY_RECON_SUCCESS]: submitSaveApplyReconSuccess,
    [SUBMIT_SAVE_APPLY_RECON_FAILURE]: submitSaveApplyReconFailure,
    [INIT_SUBMIT_SAVE_APPLY_RECON_ACTION_STATUS]: initSubmitSaveApplyReconActionStatus,
    // batch change status
    [OPEN_BATCH_CHANGE_STATUS_MODAL]: openBatchChangeStatusModal,
    [RESET_BATCH_CHANGE_STATUS_MODAL]: resetBatchChangeStatusModal,
    [BATCH_CHANGE_STATUS_CHANGE_INPUT]: batchChangeStatusChangeInput,
    [START_BATCH_CHANGE_STATUS_FOR_APPLY_RECON]: startBatchChangeStatusForApplyRecon,
    [BATCH_CHANGE_STATUS_FOR_APPLY_RECON_SUCCESS]: batchChangeStatusForApplyReconSuccess,
    [BATCH_CHANGE_STATUS_FOR_APPLY_RECON_FAILURE]: batchChangeStatusForApplyReconFailure,
    [INIT_BATCH_CHANGE_STATUS_FOR_APPLY_RECON_ACTION_STATUS]: initBatchChangeStatusForApplyReconActionStatus,
    // finish
    [SELECT_REPORTING_SUMMRAY_TASK]: selectApplyReconTask,
    [OPEN_FINISH_TASK_MODAL]: openFinishTaskModal,
    [RESET_FINISH_TASK_MODAL]: resetFinishTaskModal,
    [FINISH_TASK_CHANGE_INPUT]: finishTaskChangeInput,
    [START_FINISH_APPLY_RECON_TASK]: startFinishApplyReconTask,
    [FINISH_APPLY_RECON_TASK_SUCCESS]: finishApplyReconTaskSuccess,
    [FINISH_APPLY_RECON_TASK_FAILURE]: finishApplyReconTaskFailure,
    [INIT_FINISH_APPLY_RECON_TASK_ACTION_STATUS]: initFinishApplyReconTaskActionStatus,
    // user account
    [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,
});

