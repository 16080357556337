/*
  Auth
*/

// Login
const LOGIN = '/auth/login';
const LOGIN_BY_ACESS_TOKEN = '/auth/login-by-access-token';
const LOGOUT = '/auth/logout';
const GET_HIERARCHY_MENUS = '/auth/get-hierarchy-menus';

// Personal Trade
const QUERY_PERSONAL_TRADE = '/personal-trade/personal-listNew';
const NEW_PERSONAL_TRADE = '/personal-trade/new-personal-trade';
const UPDATED_PERSONAL_TRADE = '/personal-trade/updated-personal-trade';
const BATCH_DELETED_PERSONAL_TRADES = '/personal-trade/batch-deleted-personal-trade';
const SUBMITTED_PERSONAL_TRADE = '/personal-trade/submitted-personal-trade';
const PERSONAL_TRADE_FIELD_HISTORY = '/personal-trade/personal-trade-field-history';

// Personal Account
const QUERY_PERSONAL_ACCOUNT_LIST = '/personal-account/query-personal-account-list';
const PERSONAL_ACCOUNT_DOWNLOAD_URL = '/personal-account/personal-account-download-url';
const PERSONAL_ACCOUNT_UPLOAD_URL = '/personal-account/personal-account-upload-url';
const SUBMIT_ADD_OR_EDIT_ACCOUNT = '/personal-account/add-or-edit-personal-account';
const SUBMIT_PERSONAL_ACCOUNT = '/personal-account/change-personal-account-status';
const DELETE_PERSONAL_ACCOUNT = '/personal-account/del-personal-account';
const QUERY_HOLDINGS_FOR_CURR_ACCOUNT = '/personal-holding/query-holdings-for-curr-account';

// 附件操作
const UPLOAD_PERSONAL_TRADE_ATTACHMENT = '/personal-trade/personal-trade-attachment';
const PREVIEW_PERSONAL_TRADE_ATTACHMENT = '/personal-trade/preview-personal-trade-attachment';
const DOWNLOAD_PERSONAL_TRADE_ATTACHMENT = '/personal-trade/download-personal-trade-attachment';
const QUERY_HISTORY_UPLOAD = '/common-history-upload/query-history-upload';

// Gift Hospitality Request
const QUERY_GIFT_HOSPITALITY = '/gift-hospitality/list';
const NEW_GIFT_HOSPITALITY = '/gift-hospitality/new-gift-hospitality';
const UPDATED_GIFT_HOSPITALITY = '/gift-hospitality/updated-gift-hospitality';
const BATCH_DELETED_GIFT_HOSPITALITY = '/gift-hospitality/batch-deleted-gift-hospitality';
const SUBMITTED_GIFT_HOSPITALITY = '/gift-hospitality/submitted-gift-hospitality';
const UPLOAD_GIFT_HOSPITALITY_ATTACHMENT = '/gift-hospitality/gift-hospitality-attachment';
const DOWNLOAD_GIFT_HOSPITALITY_ATTACHMENT = '/gift-hospitality/download-gift-hospitality-attachment';


// Banned Trade
const QUERY_BANNED_TRADE = '/banned-trade/personal-list';
const NEW_BANNED_TRADE = '/banned-trade/new-banned-trade';
const UPDATED_BANNED_TRADE = '/banned-trade/updated-banned-trade-for-employee';
const BATCH_DELETED_BANNED_TRADES = '/banned-trade/batch-deleted-banned-trade';
const SUBMITTED_BANNED_TRADE = '/banned-trade/submitted-banned-trade';
const DELISTED_BANNED_TRADE = '/banned-trade/delisted-banned-trade';
const ENLISTED_BANNED_TRADE = '/banned-trade/enlisted-banned-trade';

/*
  Reference Data
*/
// Fund Book Data
const QUERY_FUND_BOOK_DATA = '/reference-data/fund-book-data';

/*
  Security
*/
const QUERY_SECURITY_TICKER = '/security/security-ticker';
const QUERY_BBG_TICKERS = '/security/bbg-tickers';
const QUERY_ASSET_INST_CLASS = '/security/asset-inst-class';
const QUERY_EXCHANGE_CODE = '/security/exchange-codes';

/*
  Restricted Security
*/
const LOAD_RESTRICTED_SECURITIES = '/restricted-security/employee-restricted-securities';
const NEW_RESTRICTED_SECURITY = '/restricted-security/new-restricted-security';
const UPDATE_RESTRICTED_SECURITY = '/restricted-security/updated-restricted-security';
const BATCH_DEL_RESTRICTED_SECURITIES = '/restricted-security/batch-deleted-restricted-securities';

const ON_LIST_RESTRICTED_SECURITIES = '/restricted-security/on-list-restricted-securities';
const OFF_LIST_RESTRICTED_SECURITIES = '/restricted-security/off-list-restricted-securities';
const SEARCH_VALID_RESTRICTED_SECURITY = '/restricted-security/search-valid-restricted-security';

// Restricted Security Attachment
const UPLOAD_RESTRICTED_SECURITY_ATTACHMENT = '/restricted-security/restricted-security-attachment';
const PREVIEW_RESTRICTED_SECURITY_ATTACHMENT = '/restricted-security/preview-restricted-security-attachment';
const DOWNLOAD_RESTRICTED_SECURITY_ATTACHMENT = '/restricted-security/download-restricted-security-attachment';

// load questionnaire results
const LOAD_QUESTIONNAIRE_RESULTS = '/questionnaire/questionnaire-results';


// 30天持仓相关预检查
const QUERY_30_DAY_HOLDING_CHECK_INFO = '/personal-trade/personal-trade-presubmit-check-info';

// licensing
const LOAD_LICENSING_INFO = '/licensing/load-licensing-info';
const LICENSING_ATT_DOWNLOAD_URL = '/licensing/licensing-att-download-url';
const LICENSING_EXAM_CPT_PROOF_UPLOAD_URL = '/licensing/upload-licensing-exam-cpt-proof';
const ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD = '/licensing/add-or-edit-license-emp-required-rcd';
const SUBMIT_EDIT_CPT_RCD = '/licensing/submit-edit-cpt-rcd';


// General Agreement
const LOAD_GENERAL_AGREEMENT_INFO = '/general-agreement/query-agreement-info';
const GENERAL_AGREEMENT_UPLOAD_URL = '/general-agreement/upload-agreement-attachment';
const GENERAL_AGREEMENT_DOWNLOAD_URL = '/general-agreement/download-agreement-attachment';
const INSERT_AGREEMENT_INFO = '/general-agreement/insert-agreement-info';
const UPDATE_AGREEMENT_INFO = '/general-agreement/update-agreement-info';
const SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS = '/general-agreement/update-agreement-info-status';
//account holding 
const SUBMIT_ADD_OR_EDIT_HOLDING = '/personal-holding/submit-add-or-edit-holding';
const DEL_PERSONAL_HOLDING = '/personal-holding/del-personal-holding';

const CHECK_CAN_APPLY_PA_URL = '/checkRule/checkDeclarationRule';

const LOAD_MEETING_AND_TRAINING_INFO = '/pinpoint-college/meetingAndTrainingsubForReport-list';
const MEETING_AND_TRAINING_UPLOAD_URL = '/pinpoint-college/upload-meetingAndTraining';
const MEETING_AND_TRAINING_DOWNLOAD_URL = '/pinpoint-college/download-meetingAndTraining';
const UPDATE_MEETING_AND_TRAINING_INFO = '/pinpoint-college/update-meetingAndTraining';
const SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS = '/pinpoint-college/update-meetingAndTrainingsub-status';
const UPDATE_FEEDBACKMEETING_AND_TRAINING_INFO = '/pinpoint-college/update-feedbackmeetingAndTraining';


const APPLY_CHANGE_MEETING_AND_TRAINING_STATUS = '/pinpoint-college/apply_record_by_name';

const LOAD_MEETING_AND_TRAINING_APPLYRECORD_INFO = '/pinpoint-college/apply_record-list';




const LOAD_MEETING_AND_TRAINING_CALENDER_PINPOINT = '/pinpoint-college-calendar/pinpoint-calendar-list';
const LOAD_MEETING_AND_TRAINING_CALENDER_PERSONAL = '/pinpoint-college-calendar/calendar-list';
const LOAD_MEETING_AND_TRAINING_CALENDER_LIST_BROKER = '/pinpoint-college-calendar/calendar-list-broker';
const LOAD_MEETING_AND_TRAINING_CALENDER_PICKUP = '/pinpoint-college-calendar/pinpoint-pickup';
const LOAD_MEETING_AND_TRAINING_CALENDER_DETAILE = '/pinpoint-college-calendar/getEventDetaileByBroker-list';
const LOAD_MEETING_AND_TRAINING_CALENDER_DEL = '/pinpoint-college-calendar/del_event';
const LOAD_MEETING_AND_TRAINING_CALENDER_UPDATE = '/pinpoint-college-calendar/updateEventById';



const SALE_MEETINGANDTRANINGS = '/pinpoint-college/sale-meetingAndTrainings';
const LOAD_EXPENSE_SUMMARY_INFONEW = '/expense-summary/load-expense-summary-info-new';
const GET_FX_BY_NEAREST_DATE = '/fx-rate/get-fx-by-nearest-date';
const ADD_OR_EDIT_EXPENSE_SUMMARY = '/expense-summary/add-or-edit-expense-summary';
const DEL_EXPENSE_SUMMARY = '/expense-summary/del-expense-summary';

// /pinpoint-college/sumit-meetingAndTrainingsub';

// Reporting Summary
const LOAD_REPORTING_SUMMARY_INFO = '/reporting-summary/query-reporting-summary';
const INSERT_REPORTING_SUMMARY = '/reporting-summary/insert-reporting-summary';
const UPDATE_REPORTING_SUMMARY = '/reporting-summary/update-reporting-summary';
const BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY = '/reporting-summary/batch-change-status-for-reporting-summary';
const FINISH_REPORTING_SUMMARY_TASK = '/reporting-summary/finish-reporting-summary-task';

//Work Management
const LOAD_WORKING_MANAGEMENT_INFO = '/work-management/query-work-management';
const INSERT_WORKING_MANAGEMENT = '/work-management/insert-work-management';
const UPDATE_WORKING_MANAGEMENT = '/work-management/update-work-management';
const DEL_WORKING_MANAGEMENT = '/work-management/del-work-management';
// const BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT = '/work-management/batch-change-status-for-work-management'; /del-work-management
const BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT = '/work-management/del-work-management'; 
const FINISH_WORKING_MANAGEMENT_TASK = '/work-management/finish-work-management-task';

const INSERT_WORKING_MANAGEMENTSUB = '/work-management/insert-work-managementSubtasks';
const UPDATE_WORKING_MANAGEMENTSUB = '/work-management/update-work-managementSubtasks';
// User Account
const QUERY_USER_ACCOUNTS = '/user-account/user-accounts';







// Reporting Summary
const LOAD_APPLY_RECON_INFO = '/pinpoint-college/apply_record-list';
const INSERT_APPLY_RECON = '/reporting-summary/insert-reporting-summary';
const UPDATE_APPLY_RECON = '/reporting-summary/update-reporting-summary';
const BATCH_CHANGE_STATUS_FOR_APPLY_RECON = '/reporting-summary/batch-change-status-for-reporting-summary';
const FINISH_APPLY_RECON_TASK = '/reporting-summary/finish-reporting-summary-task';

const UPDATE_MEETING_AND_TRAINING_APPLYRECORD = '/pinpoint-college/update-applyRecord';

const UrlConfig = {  
  // Login
  LOGIN,
  LOGIN_BY_ACESS_TOKEN,
  LOGOUT,
  GET_HIERARCHY_MENUS,
  // User Account
  QUERY_USER_ACCOUNTS,
  // Personal Trade
  QUERY_PERSONAL_TRADE,
  NEW_PERSONAL_TRADE,
  UPDATED_PERSONAL_TRADE,
  BATCH_DELETED_PERSONAL_TRADES,
  SUBMITTED_PERSONAL_TRADE,
  PERSONAL_TRADE_FIELD_HISTORY,

  // Personal Account
  QUERY_PERSONAL_ACCOUNT_LIST,
  PERSONAL_ACCOUNT_DOWNLOAD_URL,
  PERSONAL_ACCOUNT_UPLOAD_URL,
  SUBMIT_ADD_OR_EDIT_ACCOUNT,
  SUBMIT_PERSONAL_ACCOUNT,
  DELETE_PERSONAL_ACCOUNT,
  QUERY_HOLDINGS_FOR_CURR_ACCOUNT,

  // 附件操作
  UPLOAD_PERSONAL_TRADE_ATTACHMENT,
  PREVIEW_PERSONAL_TRADE_ATTACHMENT,
  DOWNLOAD_PERSONAL_TRADE_ATTACHMENT,

  // Gift Hospitality
  QUERY_GIFT_HOSPITALITY,
  NEW_GIFT_HOSPITALITY,
  UPDATED_GIFT_HOSPITALITY,
  BATCH_DELETED_GIFT_HOSPITALITY,
  SUBMITTED_GIFT_HOSPITALITY,
  UPLOAD_GIFT_HOSPITALITY_ATTACHMENT,
  DOWNLOAD_GIFT_HOSPITALITY_ATTACHMENT,

  // Banned Trade
  QUERY_BANNED_TRADE,
  NEW_BANNED_TRADE,
  UPDATED_BANNED_TRADE,
  BATCH_DELETED_BANNED_TRADES,
  SUBMITTED_BANNED_TRADE,
  DELISTED_BANNED_TRADE,
  ENLISTED_BANNED_TRADE,

  // Fund Book Data
  QUERY_FUND_BOOK_DATA,

  QUERY_SECURITY_TICKER,
  QUERY_BBG_TICKERS,
  QUERY_ASSET_INST_CLASS,
  QUERY_EXCHANGE_CODE,

  /*
    Restricted Security
  */
  LOAD_RESTRICTED_SECURITIES,
  NEW_RESTRICTED_SECURITY,
  UPDATE_RESTRICTED_SECURITY,
  BATCH_DEL_RESTRICTED_SECURITIES,

  ON_LIST_RESTRICTED_SECURITIES,
  OFF_LIST_RESTRICTED_SECURITIES,
  SEARCH_VALID_RESTRICTED_SECURITY,

  // Restricted Security Attachment
  UPLOAD_RESTRICTED_SECURITY_ATTACHMENT,
  PREVIEW_RESTRICTED_SECURITY_ATTACHMENT,
  DOWNLOAD_RESTRICTED_SECURITY_ATTACHMENT,
  QUERY_HISTORY_UPLOAD,

  // load questionnaire results
  LOAD_QUESTIONNAIRE_RESULTS,

  // 30天持仓相关预检查
  QUERY_30_DAY_HOLDING_CHECK_INFO,

  // licensing
  LOAD_LICENSING_INFO,
  LICENSING_ATT_DOWNLOAD_URL,
  LICENSING_EXAM_CPT_PROOF_UPLOAD_URL,
  ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD,
  SUBMIT_EDIT_CPT_RCD,
  

  // General Agreement
  LOAD_GENERAL_AGREEMENT_INFO,
  GENERAL_AGREEMENT_UPLOAD_URL,
  GENERAL_AGREEMENT_DOWNLOAD_URL,
  INSERT_AGREEMENT_INFO,
  UPDATE_AGREEMENT_INFO,
  SUBMIT_CHANGE_GENERAL_AGREEMENT_STATUS,


  // meeting and training
  LOAD_MEETING_AND_TRAINING_INFO,
  MEETING_AND_TRAINING_UPLOAD_URL,
  MEETING_AND_TRAINING_DOWNLOAD_URL,
  UPDATE_MEETING_AND_TRAINING_INFO, 
  SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS,
  UPDATE_FEEDBACKMEETING_AND_TRAINING_INFO,
  APPLY_CHANGE_MEETING_AND_TRAINING_STATUS,

  //account hoilding
  SUBMIT_ADD_OR_EDIT_HOLDING,
  DEL_PERSONAL_HOLDING,

  //check rule
  CHECK_CAN_APPLY_PA_URL,
  LOAD_MEETING_AND_TRAINING_APPLYRECORD_INFO,
  LOAD_MEETING_AND_TRAINING_CALENDER_PINPOINT,
  LOAD_MEETING_AND_TRAINING_CALENDER_PERSONAL,
  LOAD_MEETING_AND_TRAINING_CALENDER_PICKUP,
  LOAD_MEETING_AND_TRAINING_CALENDER_LIST_BROKER,
  LOAD_MEETING_AND_TRAINING_CALENDER_DETAILE,
  LOAD_MEETING_AND_TRAINING_CALENDER_DEL,
  LOAD_MEETING_AND_TRAINING_CALENDER_UPDATE,


  LOAD_EXPENSE_SUMMARY_INFONEW,
  GET_FX_BY_NEAREST_DATE,
  ADD_OR_EDIT_EXPENSE_SUMMARY,
  DEL_EXPENSE_SUMMARY,


  LOAD_REPORTING_SUMMARY_INFO,
  INSERT_REPORTING_SUMMARY,
  UPDATE_REPORTING_SUMMARY,
  BATCH_CHANGE_STATUS_FOR_REPORTING_SUMMARY,
  FINISH_REPORTING_SUMMARY_TASK,




    // Work Management
    LOAD_WORKING_MANAGEMENT_INFO,
    INSERT_WORKING_MANAGEMENT,
    UPDATE_WORKING_MANAGEMENT,
    DEL_WORKING_MANAGEMENT,
    BATCH_CHANGE_STATUS_FOR_WORKING_MANAGEMENT,
    FINISH_WORKING_MANAGEMENT_TASK,
  
    INSERT_WORKING_MANAGEMENTSUB,
    UPDATE_WORKING_MANAGEMENTSUB,



    LOAD_APPLY_RECON_INFO,
    INSERT_APPLY_RECON,
    UPDATE_APPLY_RECON,
    BATCH_CHANGE_STATUS_FOR_APPLY_RECON,
    FINISH_APPLY_RECON_TASK,

    UPDATE_MEETING_AND_TRAINING_APPLYRECORD,

}

export default UrlConfig;
