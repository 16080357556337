import React, {Component} from 'react';
import './CollegeApplicationDashboard.css';
import {Breadcrumb, Button, message , Input,Select,DatePicker,Divider} from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import CollegeApplicationGrid from './CollegeApplicationGrid';
import AddOrEditCollegeApplicationModalDialog from './AddOrEditCollegeApplicationModalDialog';
import ChangeCollegeApplicationStatusModalDialog from './ChangeCollegeApplicationStatusModalDialog';
import { Message } from 'semantic-ui-react'

class CollegeApplicationDashboard extends Component {
    componentDidMount() {
        this.refreshBtnOnClicked();
    }


    componentDidUpdate() {
        const {
            loadCollegeApplicationActionStatus,
            loadCollegeApplicationResp,
            
        } = this.props;
        if(loadCollegeApplicationActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadCollegeApplicationInfoActionStatus();
        }
        if(loadCollegeApplicationActionStatus === ACTION_STATUS.ERROR) {
            let err = 'load General Agreement failed';
            if(!!loadCollegeApplicationResp && !!loadCollegeApplicationResp.respMessage) {
                err = loadCollegeApplicationResp.respMessage;
            }
            message.error(err);
            this.props.initLoadCollegeApplicationInfoActionStatus();
        }
    }


    refreshBtnOnClicked = () => {
        const {
            mode,
        } = this.props.addOrEditCollegeApplicationModal;
        const {
            id,
            type,
            starttime,
            endtime,
            speaker,
            uploadRealName,
            
        } = this.props.addOrEditCollegeApplicationModal.fields;
        // const {
        //     type,
        //     starttime,
        //     endtime,
        //     speaker,
        //     status
        // } = this.props.addOrEditCollegeApplicationModal;
        this.props.loadCollegeApplicationInfo({type,starttime,endtime,speaker})
    }


    addOrEditCollegeApplicationBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditCollegeApplicationModal(mode, null);
        } else {  // COPY, EDIT
            const {
                selectedCollegeApplicationArray,
            } = this.props;
            if(selectedCollegeApplicationArray.length !== 1) {
                message.warning("Please choose one and only one General Agreement");
                return;
            }
            const currentData = selectedCollegeApplicationArray[0];

            // if(mode === 'EDIT') {
            //     if(currentData.state === 'New' ) {
            //         message.warning("Please sign in first and feedback later");
            //         return;
            //     }
            // }
            this.props.openAddOrEditCollegeApplicationModal(mode, currentData);
        }
    }


    changeCollegeApplicationStatusBtnOnClicked = (mode) => {
        const {
            selectedCollegeApplicationArray,
        } = this.props;
        if(selectedCollegeApplicationArray.length === 0) {
            message.warning("Please choose one ");
            return;
        }
        const currentData = selectedCollegeApplicationArray[0];

        // 
        if(currentData.remark === 'true' && currentData.note === null) {
            message.warn('Please finish the Remark first.');
            return;
        }
        // 
        if(currentData.feedback === 'true' && currentData.feedbackComments === null) {
            message.warn('Please finish the feedback first.');
            return;
        }
        // // 只有 New、Rejected、Valid状态的可以SUBMIT
        // if(mode === 'SUBMIT' && currentData.status !== 'New' && currentData.status !== 'Rejected' && currentData.status !== 'Valid') {
        //     message.warn('Only the General Agreements in New, Rejected or Valid status can be Submitted');
        //     return;
        // }
        // // 只有Valid状态的可以EXPIRE
        // if(mode === 'EXPIRE' && currentData.status !== 'Valid') {
        //     message.warn('Only the General Agreements in Valid status can be Expired');
        //     return;
        // }
        // const isopen = 'true'
        // selectedCollegeApplicationArray.map(e => {
        //     if(e.feedback ==='true' && (e.note !==undefined )){
        //         isopen = 'false';
        //     }
        // });
        const idList = selectedCollegeApplicationArray.map(e => e.id);
        this.props.submitChangeCollegeApplicationStatusModal({
            idList,

            modes: mode,
        });
        // this.props.openChangeCollegeApplicationStatusModal(mode, idList);

       
    }

    applyRecord = (mode) => {
        // const {
          
        // } = this.props.addOrEditCollegeApplicationModal;
        // const {
            
        //     speaker,

        // } = this.props.addOrEditCollegeApplicationModal.fields;
        const {

            speaker,

        } = this.props.addOrEditCollegeApplicationModal;
        this.props.openChangeCollegeApplicationStatusModal(mode, speaker);
        // this.props.submitChangeCollegeApplicationStatusModal({
          
        //     speaker: speaker,
        //     modes: mode,
        // });

    }

    onInputChange = ({name, value}) => {
        this.props.addOrEditGeneralAgreemenChangeInput({name, value});
    }
    showAllShowValidBtnOnClicked = () => {
        // 在这里去掉UI上所有选中，在Reducer中清空状态记录
        this.props.collegeApplicationGridEvent.api.forEachNode(node => node.setSelected(false));
        this.props.showAllShowValidBtnOnClicked();
    }
    getRScore =(array) =>{
        if(array.length>0){
        let rArray = array.filter(r=>r.required==='M').reduce(function(prev, cur) {
          return parseInt(cur.score) + prev;
          }, 0);
          return rArray;
        }else{
          return 0;
        }
      
      };
    
      getOScore =(array) =>{
        if(array.length>0){
          let rArray = array.filter(r=>r.required==='O').reduce(function(prev, cur) {
            return parseInt(cur.score) + prev;
            }, 0);
            return rArray;
          }else{
            return 0;
          }
    };

    render() {
        const {
            loadCollegeApplicationActionStatus,
            onlyShowValidCollegeApplication,
            loadCollegeApplicationResp,
            collegeApplicationQueryFields,
            typeOptions,
        } = this.props;

        const refreshBtnIsLoading = loadCollegeApplicationActionStatus === ACTION_STATUS.LOGINING;
        const showAllShowValidBtnName = onlyShowValidCollegeApplication ? 'showAll' : 'showValid';
        let loadCollegeApplicationArray = [];
        if(!!loadCollegeApplicationResp && !!loadCollegeApplicationResp.data) {
            loadCollegeApplicationArray = loadCollegeApplicationResp.data;
        }
        const rScore = this.getRScore(loadCollegeApplicationArray);
        const oScore = this.getOScore(loadCollegeApplicationArray);
        return (
            <div className='CollegeApplicationWrapper'>
                <div className='OperationBar'>
                    
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />
                  
                   
                    <Button 
                        onClick={()=>this.applyRecord('Stock Pitch')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Stock Pitch
                    </Button>
                    <Button 
                        onClick={()=>this.applyRecord('Paper Portfolio')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Paper Portfolio
                    </Button>
                    {/*<Button 
                        onClick={()=>this.changeCollegeApplicationStatusBtnOnClicked('EXPIRE')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Expire
                    </Button> */}

                </div>
                
                <div className='CollegeApplicationGrid'><CollegeApplicationGrid {...this.props} /></div>
                <AddOrEditCollegeApplicationModalDialog {...this.props} />
                <ChangeCollegeApplicationStatusModalDialog {...this.props} />

            </div>
        );
    }
}

export default CollegeApplicationDashboard;

