import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { message } from 'antd';
import qs from 'qs';

import { ACTION_STATUS } from '../../../../utils/ActionStatus';



class Authentication extends Component {
  state = {

  };

  componentDidMount () {
    const searchString = window.location.search;

    // searchString 不为空
    if (!!searchString) {
      const searchObj = qs.parse(searchString, { ignoreQueryPrefix: true });
      const accessToken = searchObj.accessToken;
      this.props.loginByAccessToken(accessToken);
    } else {
      this.props.history.push({
        pathname: '/login', 
      });
    }
  }

  componentDidUpdate() {
    const {
      // Login By Access Token
      loginByAccessTokenStatus,
      loginByAccessTokenErrMsg,
    } = this.props;

    if (loginByAccessTokenStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoginByAccessTokenStatus();
      this.props.history.push({
        pathname: '/home/personal-trade-request',
      });
    }  

    if (loginByAccessTokenStatus === ACTION_STATUS.ERROR) {
      message.error(loginByAccessTokenErrMsg || '系统异常');
      this.props.initLoginByAccessTokenStatus();
    }  
  }

  render() {
    return (
      <div>
      </div>
    );
  }
}

export default Authentication;