import { connect } from 'react-redux';

import {
  // Load Fund Book Data
  loadFundBookData,
  // Load User Account
  loadUserAccounts,
  // Load Security Tickers
  loadSecurityTickers,
    
  // Load Asset Inst Class
  loadAssetInstClass,

  // get hierarchy menus
  getHierarchyMenus,

  // Login
  loginByAccessToken,
  initLoginByAccessTokenStatus,

} from '../homeActions';

import {
  logout,
} from  '../../auth/authActions';


import Home from '../components/Home';

function mapStateToProps(state) {
  return {
    // Fund Book Data
    fundBookData: state.home.fundBookData,
    // User Account
    userAccountArray: state.home.userAccountArray,
    // Login By Access Token
    loginByAccessTokenStatus: state.home.loginByAccessTokenStatus,
    loginByAccessTokenErrMsg: state.home.loginByAccessTokenErrMsg,

    // hierarchy menus
    hierarchyMenus: state.home.hierarchyMenus,
    flatMenus: state.home.flatMenus,
    hierarchyMenuOptions: state.home.hierarchyMenuOptions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // Load Fund Book Data
    loadFundBookData: () => dispatch(loadFundBookData()),

    // Load Security Tickers
    loadSecurityTickers: () => dispatch(loadSecurityTickers()),

    // Load Asset Inst Class
    loadAssetInstClass: () => dispatch(loadAssetInstClass()),

    // get hierarchy menus
    getHierarchyMenus: () => dispatch(getHierarchyMenus()),
    // Load User Account
    loadUserAccounts: () => dispatch(loadUserAccounts()),
    // Login
    loginByAccessToken: (accessToken) => dispatch(loginByAccessToken(accessToken)),
    initLoginByAccessTokenStatus: () => dispatch(initLoginByAccessTokenStatus()),
    logout: () => dispatch(logout()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
