import React, {Component} from 'react';
import './GeneralAgreementDashboard.css';
import { Button, message } from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import GeneralAgreementGrid from './GeneralAgreementGrid';
import AddOrEditGeneralAgreementModalDialog from './AddOrEditGeneralAgreementModalDialog';
import ChangeGeneralAgreementStatusModalDialog from './ChangeGeneralAgreementStatusModalDialog';


class GeneralAgreementDashboard extends Component {
    componentDidMount() {
        this.refreshBtnOnClicked();
    }


    componentDidUpdate() {
        const {
            loadGeneralAgreementActionStatus,
            loadGeneralAgreementResp,
        } = this.props;
        if(loadGeneralAgreementActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadGeneralAgreementInfoActionStatus();
        }
        if(loadGeneralAgreementActionStatus === ACTION_STATUS.ERROR) {
            let err = 'load General Agreement failed';
            if(!!loadGeneralAgreementResp && !!loadGeneralAgreementResp.respMessage) {
                err = loadGeneralAgreementResp.respMessage;
            }
            message.error(err);
            this.props.initLoadGeneralAgreementInfoActionStatus();
        }
    }


    refreshBtnOnClicked = () => {
        this.props.loadGeneralAgreementInfo();
    }


    addOrEditGeneralAgreementBtnOnClicked = (mode) => {
        if(mode === 'ADD') {
            this.props.openAddOrEditGeneralAgreementModal(mode, null);
        } else {  // COPY, EDIT
            const {
                selectedGeneralAgreementArray,
            } = this.props;
            if(selectedGeneralAgreementArray.length !== 1) {
                message.warning("Please choose one and only one General Agreement");
                return;
            }
            const currentData = selectedGeneralAgreementArray[0];

            // if(mode === 'EDIT') {
            //     if(currentData.status !== 'New' && currentData.status !== 'Rejected' && currentData.status !== 'Valid') {
            //         message.warning("You can only Edit the General Agreements in New, Rejected or Valid status");
            //         return;
            //     }
            // }
            this.props.openAddOrEditGeneralAgreementModal(mode, currentData);
        }
    }


    changeGeneralAgreementStatusBtnOnClicked = (mode) => {
        const {
            selectedGeneralAgreementArray,
        } = this.props;
        if(selectedGeneralAgreementArray.length !== 1) {
            message.warning("Please choose one and only one General Agreement");
            return;
        }
        const currentData = selectedGeneralAgreementArray[0];

        // Report端只能DEL New状态的
        if(mode === 'DEL' && currentData.status !== 'New') {
            message.warn('Only the General Agreements in New status can be Deleted');
            return;
        }
        // 只有 New、Rejected、Valid状态的可以SUBMIT
        if(mode === 'SUBMIT' && currentData.status !== 'New' && currentData.status !== 'Rejected' && currentData.status !== 'Valid') {
            message.warn('Only the General Agreements in New, Rejected or Valid status can be Submitted');
            return;
        }
        // 只有Valid状态的可以EXPIRE
        if(mode === 'EXPIRE' && currentData.status !== 'Valid') {
            message.warn('Only the General Agreements in Valid status can be Expired');
            return;
        }

        this.props.openChangeGeneralAgreementStatusModal(mode, currentData);
    }


    showAllShowValidBtnOnClicked = () => {
        // 在这里去掉UI上所有选中，在Reducer中清空状态记录
        this.props.generalAgreementGridEvent.api.forEachNode(node => node.setSelected(false));
        this.props.showAllShowValidBtnOnClicked();
    }


    render() {
        const {
            loadGeneralAgreementActionStatus,
            onlyShowValidGeneralAgreement
        } = this.props;

        const refreshBtnIsLoading = loadGeneralAgreementActionStatus === ACTION_STATUS.LOGINING;
        const showAllShowValidBtnName = onlyShowValidGeneralAgreement ? 'showAll' : 'showValid';

        return (
            <div className='GeneralAgreementWrapper'>
                <div className='OperationBar'>
                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />
                    <Button 
                        onClick={()=>this.showAllShowValidBtnOnClicked()} 
                        size='small'
                        style={{ width: 75, marginLeft: 10 }}
                        type='primary'>
                        {showAllShowValidBtnName}
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditGeneralAgreementBtnOnClicked('ADD')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Add
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditGeneralAgreementBtnOnClicked('COPY')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='positive-button'>
                        Copy
                    </Button>
                    <Button 
                        onClick={()=>this.addOrEditGeneralAgreementBtnOnClicked('EDIT')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Edit
                    </Button>
                    <Button 
                        onClick={()=>this.changeGeneralAgreementStatusBtnOnClicked('DEL')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        className='negative-button'>
                        Del
                    </Button>
                    <Button 
                        onClick={()=>this.changeGeneralAgreementStatusBtnOnClicked('SUBMIT')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Submit
                    </Button>
                    <Button 
                        onClick={()=>this.changeGeneralAgreementStatusBtnOnClicked('EXPIRE')} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Expire
                    </Button>

                </div>

                <div className='GeneralAgreementGrid'><GeneralAgreementGrid {...this.props} /></div>
                <AddOrEditGeneralAgreementModalDialog {...this.props} />
                <ChangeGeneralAgreementStatusModalDialog {...this.props} />

            </div>
        );
    }
}

export default GeneralAgreementDashboard;

