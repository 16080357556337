import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Select, Button, AutoComplete } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { DIRECTION_TYPE_MAP } from '../../../../utils/DirectionTypeUtil';
import { CATEGORY_MAP } from '../../../../utils/CategoryUtil';

const FormItem = Form.Item;
const TextArea = Input.TextArea;
const Option = Select.Option;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

// Direction Type
const directionTypeOptions = [];
for (let i = 0; i < DIRECTION_TYPE_MAP.length; i++) {
    directionTypeOptions.push(<Option key={DIRECTION_TYPE_MAP[i].code}>{DIRECTION_TYPE_MAP[i].label}</Option>);
}
// Category List
const categoryOptions = [];
for (let i = 0; i < CATEGORY_MAP.length; i++) {
    categoryOptions.push(<Option key={CATEGORY_MAP[i].code}>{CATEGORY_MAP[i].label}</Option>);
}

class AddOrEditHoldingDialog extends Component {

    componentDidMount() {

    }


    componentDidUpdate() {
        const {
            submitAddOrEditHoldingActionStatus,
            addOrEditPersonalHoldingModal,
        } = this.props;
        if(submitAddOrEditHoldingActionStatus === ACTION_STATUS.SUCCESS) {
            const action = this.getActionFromMode(addOrEditPersonalHoldingModal.mode);
            message.success(action + ' Personal Holding succeeded');
            this.props.initSubmitAddOrEditHoldingActionStatus();
            this.props.resetAddOrEditHoldingDialog();
            const {
                id,
                employee,
            } = this.props.personalAccountQueryFields;
            const param = {
                id,
                employee,
                approvalStatus: '',
                needHoldingData: 'true',
            }
            this.props.loadPersonalAccount(param);
        }
        if(submitAddOrEditHoldingActionStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitAddOrEditHoldingActionStatus();
        }
    }


    // 同步 amount 的值
    syncAmountOnChange = (price, quantity) => {
        if (isNaN(price) || isNaN(quantity)) {
            this.onAddOrEditHoldingInputChange({ name: 'amount', value: undefined });
            this.props.form.setFieldsValue({amount: undefined});
            return;
        }
        const quantityNumValue = Number(quantity);
        const valueString = quantity + '';
        if (Math.floor(quantityNumValue) !== quantityNumValue || valueString.indexOf('.') >= 0) {
            this.onAddOrEditHoldingInputChange({ name: 'amount', value: undefined });
            this.props.form.setFieldsValue({amount: undefined});
            return;
        }
        const priceNumValue = Number(price);
        let amountNumValue = priceNumValue * quantityNumValue;
        amountNumValue = isNaN(amountNumValue) ? undefined : amountNumValue.toFixed(2);
        this.onAddOrEditHoldingInputChange({ name: 'amount', value: amountNumValue });
        this.props.form.setFieldsValue({amount: amountNumValue});
    }
    // 输入Price
    priceInputOnChange = (price) => {
        this.onAddOrEditHoldingInputChange({ name: 'price', value: price });
        const quantity = this.props.addOrEditPersonalHoldingModal.fields.quantity;
        this.syncAmountOnChange(price, quantity);
    }
    // 输入Quantity
    quantityInputOnChange = (quantity) => {
        this.onAddOrEditHoldingInputChange({ name: 'quantity', value: quantity });
        const price = this.props.addOrEditPersonalHoldingModal.fields.price;
        this.syncAmountOnChange(price, quantity);
    }


    getActionFromMode(mode) {
        if(mode === 'ADD') {
            return 'Add';
        } else {
            return 'Edit';
        }
    }


    lastTimeoutID = null;
    bbgTickerOnSearch = (value) => {
        if (!!!value) {
            this.props.clearHoldingBbgTickers();
            return;
        }
        if(value.length < 2) {
            return;
        }
        // 输入完毕等待0.5秒后再查询
        if(!!this.lastTimeoutID) {
            clearTimeout(this.lastTimeoutID);
        }
        this.lastTimeoutID = setTimeout(() => {
            this.props.loadHoldingBbgTickers(value);
        }, 500);
    }


    bbgTickerOnChange = (value) => {
        if (!value) {
          this.props.clearHoldingBbgTickers();
        }
        this.props.form.resetFields();
        this.props.onAddOrEditHoldingInputChange({ name: 'bbgTicker', value });
        this.props.holdingBbgTickerSelectChanged(value);
    }



    onAddOrEditHoldingInputChange = (param) => {
        this.props.onAddOrEditHoldingInputChange(param);
    }


    submitAddOrEditHolding = () => {
        const {
            addOrEditPersonalHoldingModal,
        } = this.props;
        const {
            mode,
            fields,
        } = addOrEditPersonalHoldingModal;
        const {
            id,
            guid,
            employee,
            securityId,
            bbgTicker,
            secName,
            direction,
            quantity,
            price,
            amount,
            currency,
            personalAccountGuid,
            isAbandoned,
            comments,
            personalAccountId,
            personalAccountName,
            category,
        } = fields;
        this.props.form.validateFields((err) => {
            if (!err) {
                this.props.submitAddOrEditHolding({
                    id,
                    guid,
                    employee,
                    securityId,
                    bbgTicker,
                    secName,
                    direction,
                    quantity,
                    price,
                    amount,
                    currency,
                    personalAccountGuid,
                    isAbandoned,
                    comments,
                    personalAccountId,
                    personalAccountName,
                    mode,
                    category,
                });
            }
        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            addOrEditPersonalHoldingModal,
            bbgTickerArray,
            submitAddOrEditHoldingActionStatus,
        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
            fields,
        } = addOrEditPersonalHoldingModal;

        const {
            id,
            guid,
            employee,
            securityId,
            bbgTicker,
            secName,
            direction,
            quantity,
            price,
            amount,
            currency,
            personalAccountGuid,
            isAbandoned,
            comments,
            personalAccountId,
            personalAccountName,
            category,
        } = fields;

        const modalTitle = this.getActionFromMode(mode) + ' Personal Account';
        const isSubmitLoading = submitAddOrEditHoldingActionStatus === ACTION_STATUS.LOGINING;

        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetAddOrEditHoldingDialog()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetAddOrEditHoldingDialog() }>
                            Cancel
                        </Button>,
                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitAddOrEditHolding() }>
                             Submit
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="employee">
                                {getFieldDecorator('employee', {
                                    initialValue: employee,
                                    rules: [{
                                        required: true,
                                        message: 'Please choose employee',
                                    }],
                                    })(
                                        <Input 
                                            style={{ width: 300, marginLeft: 10 }}
                                            disabled
                                            placeholder='employee'
                                            onChange={(e) => {
                                                this.onAddOrEditHoldingInputChange({ name: 'employee', value: e.target.value })
                                            }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="accountHolder">
                                {getFieldDecorator('personalAccountName', {
                                    initialValue: personalAccountName,
                                    rules: [{
                                        required: true,
                                        message: 'Please choose or input accountHolder',
                                    }],
                                    })(
                                        <Input 
                                            style={{ width: 300, marginLeft: 10 }}
                                            disabled
                                            placeholder='accountHolder'
                                            onChange={(e) => {
                                                this.onAddOrEditHoldingInputChange({ name: 'personalAccountName', value: e.target.value })
                                            }}
                                        />
                                )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label='bbgTicker'>
                                {getFieldDecorator('bbgTicker', {
                                    initialValue: bbgTicker,
                                    rules: [{
                                        required: true,
                                        message: 'Please choose or input bbgTicker',
                                    }],
                                    })(
                                        <AutoComplete 
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder='bbgTicker'
                                            allowClear
                                            dataSource={bbgTickerArray}
                                            onChange={this.bbgTickerOnChange}
                                            onSearch={this.bbgTickerOnSearch} />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="secName">
                                {getFieldDecorator('secName', {
                                    initialValue: secName,
                                    rules: [{
                                        required: false,
                                        message: '',
                                    }],
                                    })(
                                        <Input
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder='secName'
                                            onChange={(e) => {
                                                this.onAddOrEditHoldingInputChange({ name: 'secName', value: e.target.value })
                                            }}
                                        />
                                )}
                                </FormItem>
                            </Col>
                        </Row>


                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label='category'>
                                {getFieldDecorator('category', {
                                    initialValue: category,
                                    rules: [{
                                        required: true,
                                        message: 'Please choose category',
                                    }],
                                    })(
                                        <Select 
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(value) => {
                                                this.onAddOrEditHoldingInputChange({ name: 'category', value })
                                            }} 
                                            placeholder="category">
                                                {categoryOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label='direction'>
                                {getFieldDecorator('direction', {
                                    initialValue: direction,
                                    rules: [{
                                        required: true,
                                        message: 'Please choose direction',
                                    }],
                                    })(
                                        <Select 
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(value) => {
                                                this.onAddOrEditHoldingInputChange({ name: 'direction', value })
                                            }} 
                                            placeholder="direction">
                                                {directionTypeOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="price">
                                {getFieldDecorator('price', {
                                    initialValue: price,
                                    rules: [{
                                        required: true,
                                        message: 'Please input price',
                                    }],
                                    })(
                                        <Input
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder='price'
                                            onChange={(e) => {
                                                this.priceInputOnChange(e.target.value)
                                            }}
                                        />
                                )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label='quantity'>
                                {getFieldDecorator('quantity', {
                                    initialValue: quantity,
                                    rules: [{
                                        required: true,
                                        message: 'Please input quantity',
                                    }],
                                    })(
                                        <Input
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder='quantity'
                                            onChange={(e) => {
                                                this.quantityInputOnChange(e.target.value)
                                            }}
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="amount">
                                {getFieldDecorator('amount', {
                                    initialValue: amount,
                                    rules: [{
                                        required: true,
                                        message: '',
                                    }],
                                    })(
                                        <Input
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder=''
                                            disabled
                                        />
                                )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label='currency'>
                                {getFieldDecorator('currency', {
                                    initialValue: currency,
                                    rules: [{
                                        required: false,
                                        message: '',
                                    }],
                                    })(
                                        <Input
                                            style={{ width: 300, marginLeft: 10 }}
                                            placeholder=''
                                            disabled
                                        />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={4} 
                                            placeholder='comments' 
                                            onChange={(e) => {
                                                this.onAddOrEditHoldingInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>

                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}


const AddOrEditHoldingDialogForm = Form.create({ name: 'AddOrEditHoldingDialog' })(AddOrEditHoldingDialog);
export default AddOrEditHoldingDialogForm;

