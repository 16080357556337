
const DONATION_TYPE_CODE = {
  GIFT: 'Gift',
  HOSPITALITY: 'Hospitality',
};

const DONATION_TYPE_LABEL = {
  GIFT: 'Gift',
  HOSPITALITY: 'Hospitality',
};

export const DONATION_TYPE_MAP = [
  // Gift
  {
    code: DONATION_TYPE_CODE.GIFT,
    label: DONATION_TYPE_LABEL.GIFT
  },
  // Hospitality
  {
    code: DONATION_TYPE_CODE.HOSPITALITY,
    label: DONATION_TYPE_LABEL.HOSPITALITY
  },
];