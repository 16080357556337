
export const START_LOAD_LICENSING_INFO = 'START_LOAD_LICENSING_INFO';
export const LOAD_LICENSING_INFO_SUCCESS = 'LOAD_LICENSING_INFO_SUCCESS';
export const LOAD_LICENSING_INFO_FAILURE = 'LOAD_LICENSING_INFO_FAILURE';
export const INIT_LOAD_LICENSING_INFO_ACTION_STATUS = 'INIT_LOAD_LICENSING_INFO_ACTION_STATUS';

export const SELECT_LICENSING_DATA = 'SELECT_LICENSING_DATA';

export const OPEN_ADD_OR_EDIT_LICENSE_MODAL = 'OPEN_ADD_OR_EDIT_LICENSE_MODAL';
export const ADD_OR_EDIT_LICENSE_CHANGE_INPUT = 'ADD_OR_EDIT_LICENSE_CHANGE_INPUT';

export const START_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD = 'START_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD';
export const ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_SUCCESS = 'ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_SUCCESS';
export const ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_FAILURE = 'ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_FAILURE';
export const INIT_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_ACTION_STATUS = 'INIT_ADD_OR_EDIT_LICENSE_EMP_REQUIRED_RCD_ACTION_STATUS';
export const RESET_SUBMIT_SAVE_LICENSE_MODAL = 'RESET_SUBMIT_SAVE_LICENSE_MODAL';

export const OPEN_EDIT_CPT_RCD_MODAL = 'OPEN_EDIT_CPT_RCD_MODAL';
export const EDIT_CPT_RCD_MODAL_CHANGE_INPUT = 'EDIT_CPT_RCD_MODAL_CHANGE_INPUT';

export const START_SUBMIT_EDIT_CPT_RCD = 'START_SUBMIT_EDIT_CPT_RCD';
export const SUBMIT_EDIT_CPT_RCD_SUCCESS = 'SUBMIT_EDIT_CPT_RCD_SUCCESS';
export const SUBMIT_EDIT_CPT_RCD_FAILURE = 'SUBMIT_EDIT_CPT_RCD_FAILURE';
export const INIT_SUBMIT_EDIT_CPT_RCD_ACTION_STATUS = 'INIT_SUBMIT_EDIT_CPT_RCD_ACTION_STATUS';
export const RESET_EDIT_CPT_RCD_MODAL = 'RESET_EDIT_CPT_RCD_MODAL';
