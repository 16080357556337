import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, Upload, Icon, Button, Tooltip } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import BaseUrlConfig from '../../../../net/BaseUrlConfig';
import UrlConfig from '../../../../net/UrlConfig';
import { localStorageClient } from '../../../../utils/localStorageClient';


const FormItem = Form.Item;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
};

class EditCptRcdModalDialog extends Component {

    onInputChange({name, value}) {
        this.props.editCptRcdModalChangeInput({name, value});
    }


    attachmentHandleChange = (info) => {
        let fileList = [...info.fileList]; 
        fileList = fileList.slice(-1);
        this.props.editCptRcdModalChangeInput({name: 'latestUploadFileList', value: fileList});
        if(!!fileList && fileList.length > 0) {
            const file = fileList[0];
            if (file.response) {
                const filename = file.response.data.fileNameWithTimeStamp;
                this.props.editCptRcdModalChangeInput({name: 'latestUpload', value: filename});
            }
        }
    }


    submitEditCptRcdModal = () => {
        const {
            editCptRcdModal,
        } = this.props;
        const {
            guid,
            year,
            licenseEmpRequireRcd,
            cptHourRequire,
            cptHourFinished,
            cptHourAccepted,
            trainingInfo,
            latestUpload,
            historyUpload,
            remarks,
            feedback,
            comments,
        } = editCptRcdModal.fields;
        const form = this.props.form;
        form.validateFields((err) => {
            if(!err) {
                Modal.confirm({
                    title: 'Are you sure to submit the changes, and notify Compliance Team by email?',
                    onOk: () => {
                        this.props.submitEditCptRcd({
                            guid,
                            year,
                            licenseEmpRequireRcd,
                            cptHourRequire,
                            cptHourFinished,
                            cptHourAccepted,
                            trainingInfo,
                            latestUpload,
                            historyUpload,
                            remarks,
                            feedback,
                            comments,
                            notify: true,
                            source: 'Report',
                        });
                    }
                });
            }
        });
    }


    componentDidUpdate() {
        const {
            submitEditCptRcdStatus,
        } = this.props;

        if(submitEditCptRcdStatus === ACTION_STATUS.SUCCESS) {
            message.success('Edit CPT Record succeeded');
            this.props.initSubmitEditCptRcdActionStatus();
            this.props.resetEditCptRcdModal();
            const employee = localStorageClient.getUserName();
            this.props.loadLicensingInfo({employee});
        }
        if(submitEditCptRcdStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitEditCptRcdActionStatus();
        }
    }


    render() {

        const { getFieldDecorator } = this.props.form;

        const {
            editCptRcdModal,
            licensingExamCPTProofUploadUrl,
            submitEditCptRcdStatus,
        } = this.props;

        const {
            isOpened,
            respErrMsg,
        } = editCptRcdModal;

        const {
            employee,
            licenseBasic,
            year,
            cptHourRequire,
            cptHourFinished,
            cptHourAccepted,
            trainingInfo,
            latestUpload,
            historyUpload,
            remarks,
            feedback,
            comments,
            latestUploadFileList,
            historyUploadFileList,
        } = editCptRcdModal.fields;

        const historyUploadUrl = BaseUrlConfig.api.REST_URL + BaseUrlConfig.api.API_VERSION + UrlConfig.LICENSING_ATT_DOWNLOAD_URL + '/' + historyUpload;
        const historyUploadLink = <a href={historyUploadUrl} target="_blank">{historyUpload}</a>

        const isSubmitLoading = submitEditCptRcdStatus === ACTION_STATUS.LOGINING;

        return (
            <div>
                <Modal
                    title={'Edit CPT Record'}
                    centered
                    width={1300}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetEditCptRcdModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetEditCptRcdModal() }>
                          Cancel
                        </Button>,
                        <Button key="Submit" type="primary" loading={isSubmitLoading} onClick={() => this.submitEditCptRcdModal() }>
                          Submit
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Employee">
                                    {employee}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="License">
                                    {licenseBasic}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Year">
                                    {year}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="CPT Hour Required">
                                    {cptHourRequire}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="CPT Hour Finished">
                                    {getFieldDecorator('cptHourFinished', {
                                        initialValue: cptHourFinished,
                                        rules: [{
                                            required: true,
                                            pattern: new RegExp(/^(0|[1-9][0-9]*)(\.[0-9])?$/, "g"),
                                            message: 'Please input correct CPT Hour Finished Num, with at most one decimal place',
                                        }],
                                    })(
                                    <Input
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'cptHourFinished', value: e.target.value })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="CPT Hour Accepted">
                                    {cptHourAccepted}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label={
                                    <span>
                                        Proof of CPT Hours(for the current year)&nbsp;
                                        <Tooltip title={<div>{'Please compress all your CPT training proofs this year, including the proofs submitted previously, into one single zip archive before upload. You may retrieve your previous records from "History Upload".'}
                                                        <br/>{'请将本年度所有培训证明（包括之前提交过的证明），打zip包后一次上传。提交过的证明可以从“History Upload”截取。'}</div>}>
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </span>
                                }>
                                    {getFieldDecorator('latestUpload', {
                                        initialValue: latestUpload,
                                        rules: [{
                                            required: true,
                                            message: 'Please upload the Proof',
                                        }],
                                        })(
                                        <Upload
                                            name="latestUpload"
                                            withCredentials={true}
                                            multiple={false}
                                            fileList={latestUploadFileList}
                                            action={licensingExamCPTProofUploadUrl}
                                            onChange={this.attachmentHandleChange}
                                        >
                                            <Button style={{ width: 300, marginLeft: 10 }}>
                                                <Icon type="upload" />Upload
                                            </Button>
                                        </Upload>
                                    )}
                                </FormItem>
                                <FormItem {...formItemLayout} label="History Upload">
                                    {historyUploadLink}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label={
                                    <span>
                                        Training Info&nbsp;
                                        <Tooltip title={<div>{'Please briefly desribe the training you have attended.'}<br/>{'请简单描述培训内容。'}</div>}>
                                            <Icon type="question-circle-o" />
                                        </Tooltip>
                                    </span>
                                }>
                                    {getFieldDecorator('trainingInfo', {
                                        initialValue: trainingInfo,
                                        rules: [{
                                            required: true,
                                            message: 'Please input Training Info',
                                        }],
                                        })(
                                            <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            value={trainingInfo} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'trainingInfo', value: e.target.value })
                                            }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Remarks">
                                    <TextArea 
                                        style={{ width: 300, marginLeft: 10 }}
                                        rows={3} 
                                        value={remarks} 
                                        onChange={(e) => {
                                            this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                </FormItem>
                                <FormItem {...formItemLayout} label="Feedback">
                                    {feedback}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <p>Please compress all your CPT training proofs this year, including the proofs submitted previously, into one single zip archive before upload. 
                                    You may retrieve your previous records from "History Upload". And please briefly desribe the training you have attended in "Training Info".</p>
                                <p>请将本年度所有培训证明（包括之前提交过的证明），打zip包后一次上传。提交过的证明可以从“History Upload”截取。另请在“Training Info”中简单描述培训内容。</p>
                            </Col>
                        </Row>
                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }
                </Modal>
            </div>
        );
    }
}


const EditCptRcdModalDialogForm = Form.create({ name: 'edit_cpt_rcd_modal_dialog' })(EditCptRcdModalDialog);

export default EditCptRcdModalDialogForm;

