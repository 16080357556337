import React, {Component} from 'react';
import { Button, message } from 'antd';
import moment from 'moment';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import AddOrEditLicenseModalDialog from './AddOrEditLicenseModalDialog';
import EditCptRcdModalDialog from './EditCptRcdModalDialog';
import './LicensingDashboard.css';
import LicensingGrid from './LicensingGrid';
import { localStorageClient } from '../../../../utils/localStorageClient';


class LicensingDashboard extends Component {

    componentDidMount() {
        this.refreshBtnOnClicked();
    }


    componentDidUpdate() {
        const {
            loadLicensingInfoActionStatus,
            loadLicensingInfoResp,
        } = this.props;
        if(loadLicensingInfoActionStatus === ACTION_STATUS.SUCCESS) {
            this.props.initLoadLicensingInfoActionStatus();
        }
        if(loadLicensingInfoActionStatus === ACTION_STATUS.ERROR) {
            message.error(loadLicensingInfoResp.respMessage);
            this.props.initLoadLicensingInfoActionStatus();
        }

    }


    refreshBtnOnClicked = () => {
        const employee = localStorageClient.getUserName();
        this.props.loadLicensingInfo({employee, source:'Report'});
    }


    addOrEditLicenseBtnOnClicked = () => {
        const {
            selectedLicenseArray,
        } = this.props;
        if(selectedLicenseArray.length !== 1) {
            message.warning("Please choose one and only one License record");
            return;
        }
        const currentLicense = selectedLicenseArray[0];
        this.props.openAddOrEditLicenseModal('EDIT', currentLicense);
    }


    editCPTRcdBtnOnClicked = () => {
        const {
            selectedLicenseArray,
        } = this.props;
        const lincenseDataLength = selectedLicenseArray.length;
        if(lincenseDataLength !== 1) {
            message.warning('Please choose one and only one record.');
            return;
        }

        const currYear = moment().format('YYYY');
        const selectedCptRcdData = selectedLicenseArray[0].licenseCPTYearRcdList.filter(e => e.year+'' === currYear)[0];
        const parentLicenseData = selectedLicenseArray[0];
        if(!selectedCptRcdData) {
            message.warning('System Error!');
            return;
        }

        const parentLicenseEmployee = parentLicenseData.employee;
        const parentLicneseBasicInfo = parentLicenseData.licenseBasicInfo.country + ' / ' + parentLicenseData.licenseBasicInfo.regulator + ' / ' + parentLicenseData.licenseBasicInfo.license;

        this.props.openEditCptRcdModal(selectedCptRcdData, parentLicenseEmployee, parentLicneseBasicInfo);
    }


    render() {
        const {
            // loadLicensingInfo
            loadLicensingInfoActionStatus,
        } = this.props;

        // loading
        const refreshBtnIsLoading = loadLicensingInfoActionStatus === ACTION_STATUS.LOGINING;
        

        return(
            <div className="LicensingWrapper">
                <div className='LicensingOperationBar'>

                    <Button 
                        onClick={this.refreshBtnOnClicked} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    />

                    <Button 
                        onClick={()=>this.addOrEditLicenseBtnOnClicked()} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Upload Proof of Exam Results
                    </Button>

                    <Button 
                        onClick={()=>this.editCPTRcdBtnOnClicked()} 
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'>
                        Upload Proof of CPT Hours
                    </Button>

                </div>


                <div className='LicensingGrid'><LicensingGrid {...this.props} /></div>
                <AddOrEditLicenseModalDialog {...this.props} />
                <EditCptRcdModalDialog {...this.props} />

            </div>
        );
    }

}

export default LicensingDashboard;
