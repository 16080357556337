import React, { Component } from 'react';

import { AgGridReact } from 'ag-grid-react';
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import GiftAttachmentRenderer from '../../../renderer/GiftAttachmentRenderer';

class GiftHospitalityRequestGrid extends Component {

  constructor(props) {
    super(props);

    this.state = {
      columnDefs: props.giftHospitalityRequestGridColumns,
      defaultColDef: {
        enableCellChangeFlash: true,
        minWidth: 100
      },
      rowSelection: "multiple",
      rowClassRules: {
        'UnApprovedReport': function(params) {
          return !!!params.data.approvalStatus;
        },
        'rejectGiftReport': function(params) {
          return params.data.approvalStatus === 'REJECT';
        },
      },
      context: { componentParent: this },
      frameworkComponents: {
        giftAttachmentRenderer: GiftAttachmentRenderer,
      },
    };
  }

  onSelectionChanged = (event) => {
    var rowNodeArray = event.api.getSelectedNodes();
    var giftHosptalityList = rowNodeArray.map(rowNode => rowNode.data);

    this.props.selectGiftHospitalityRequest(giftHosptalityList);
  }

  getRowNodeId = data => {
    return data.id;
  };

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    // const COLUMNS_KEY = 'fund-list-grid-col-state';

    // StateSynchronizer.syncGrid(
    //   params,
    //   this.state.columnDefs,
    //   () => localStorageClient.getGridColumnState(COLUMNS_KEY),
    //   s => localStorageClient.saveGridColumnState(COLUMNS_KEY, s)
    // );
  };

  render() {
    const {
      giftHospitalityRequestArray,
      className = 'grid-wrapper',
      loadGiftHospitalityRequestActionStatus,
    } = this.props;

    const isLoading = loadGiftHospitalityRequestActionStatus === ACTION_STATUS.LOGINING;
    if (loadGiftHospitalityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      this.props.initLoadGiftHospitalityRequestActionStatus();
    }

    return (
      <div className={`ag-theme-balham ${className}`}>
        {isLoading ? (
            <Loader active content="Loading..." />
          ) : (
          <AgGridReact
            // properties
            columnDefs={this.state.columnDefs}
            rowData={giftHospitalityRequestArray}
            defaultColDef={this.state.defaultColDef}
            rowSelection={this.state.rowSelection}
            rowClassRules={this.state.rowClassRules}
            sortable={true}
            filter={true}
            animateRows={true}
            deltaRowDataMode={false}
            resizable={true}
            getRowNodeId={this.getRowNodeId}
            context={this.state.context}
            frameworkComponents={this.state.frameworkComponents}
            // master/detail
            masterDetail={true}
            detailCellRendererParams={this.state.detailCellRendererParams}
            getRowHeight={this.state.getRowHeight}
            // events
            onGridReady={this.onGridReady}
            onSelectionChanged={this.onSelectionChanged}
          />
        )}
      </div>
    );
  }
}

export default GiftHospitalityRequestGrid;