import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, DatePicker,message, Select, Button, Checkbox } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import moment from 'moment';

const FormItem = Form.Item;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};


class AddOrEditWorkManagementSubModalDialog extends Component {

    refreshBtnOnClicked = () => {
        const {
            startDate,
            endDate,
        } = this.props.workManagementQueryFields;
        this.props.loadWorkManagementInfo({startDate,endDate});
    }

    getActionFromMode(mode) {
        if(mode === 'ADD') {
            return 'Add';
        } else if(mode === 'COPY') {
            return 'Copy';
        } else {
            return 'Edit';
        }
    }
    
    componentDidUpdate() {
        const {
            addOrEditWorkManagementSubStatus,
        } = this.props;
        const {
            mode,
        } = this.props.addOrEditWorkManagementSubModal;

        if(addOrEditWorkManagementSubStatus === ACTION_STATUS.SUCCESS) {
            const msg = this.getActionFromMode(mode) + ' Reporting Summary succeeded.';
            message.success(msg);
            this.props.initSubmitSaveWorkManagementSubActionStatus();
            this.props.resetSubmitSaveWorkManagementSubModal();
            this.refreshBtnOnClicked();
        }
        if(addOrEditWorkManagementSubStatus === ACTION_STATUS.ERROR) {
            this.props.initSubmitSaveWorkManagementSubActionStatus();
        }

    }


    onInputChange = ({name, value}) => {
        this.props.addOrEditWorkManagementSubChangeInput({name, value});
    }
   
    onEmailInputChange = ({name, value}) => {
        let emailStr = '';
        if(!!value) {
            value.forEach((e) => {
                if(!!emailStr) {
                    emailStr += ';';
                }
                emailStr += e;
            });
        }
        this.onInputChange({name, value: emailStr});
    }


    submitSaveWorkManagementSubModal = () => {
        const {
            mode,
            idList,
        } = this.props.addOrEditWorkManagementSubModal;
        const {
            id,
            details,
            personlncharge,
            startdate,
            targetdate,
            enddate,
            status,
            remarks,
            comments,

        } = this.props.addOrEditWorkManagementSubModal.fields;


        this.props.form.validateFields((err) => {
            if(err) {
                return;
            }
            // 执行提交
            this.props.submitSaveWorkManagementSub({
                id,
                details,
                personlncharge,
                startdate,
                targetdate,
                enddate,
                status,
                remarks,
                comments,
                mode,
                idList,
            });

        });
    }


    render() {
        const { getFieldDecorator } = this.props.form;

        const {
            locationOptions,
            fundCompanyOptions,
            frequencyOptions,
            weekdayOptions,
            monthOptions,
            responsibleDeptOptions,
            addOrEditWorkManagementSubModal,
            addOrEditWorkManagementSubStatus,
            userAccountEmailOptions,
            userAccountOptions,
            categoryOptions,
            fundCoOptions, 
            statusOptions, 
            importanceOptions,


        } = this.props;

        const {
            isOpened,
            mode,
            respErrMsg,
        } = addOrEditWorkManagementSubModal;

        const {
            id,
            details,
            fundco,
            description,
            personlncharge,
            startdate,
            targetdate,
            enddate,
            status,
            importance,
            emailTo,

            remarks,
            comments,


        } = addOrEditWorkManagementSubModal.fields;

        const emailToArray = !emailTo ? [] : emailTo.split(';');


        const monthDateOptions = [];


        const startYearOptions = [];



        const modalTitle = this.getActionFromMode(mode) + ' Work Management Sub';
        const isSubmitLoading = addOrEditWorkManagementSubStatus === ACTION_STATUS.LOGINING;
        const startdatement = !startdate ? undefined : moment(startdate);
        const targetdatement = !targetdate ? undefined : moment(targetdate);
        const enddatement = !enddate ? undefined : moment(enddate);

        
        return (
            <div>
                <Modal
                    title={modalTitle}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.props.resetSubmitSaveWorkManagementSubModal()}
                    footer={[
                        <Button key="Cancel" onClick={() => this.props.resetSubmitSaveWorkManagementSubModal() }>
                          Cancel
                        </Button>,
                        <Button key="Save" type="primary" loading={isSubmitLoading} onClick={() => this.submitSaveWorkManagementSubModal() }>
                          Save
                        </Button>,
                    ]}
                >
                    <Form layout="horizontal">

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Details*">
                                    {getFieldDecorator('details', {
                                        initialValue: details,
                                        rules: [{
                                            required: true,
                                            message: 'Please input details',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'details', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>

                            </Col>
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Start Date">
                                {getFieldDecorator('startdate', {
                                    initialValue: startdatement,
                                    rules: [{
                                        required: true,
                                        message: 'Please select Start Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'startdate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Target Date">
                                {getFieldDecorator('targetdate', {
                                    initialValue: targetdatement,
                                    rules: [{
                                        required: false,
                                        message: 'Please select Target Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'targetdate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="End Date">
                                {getFieldDecorator('enddate', {
                                    initialValue: enddatement,
                                    rules: [{
                                        required: false,
                                        message: 'Please select End Date',
                                    }],
                                    })(
                                    <DatePicker 
                                        style={{ width: 300, marginLeft: 10 }}
                                        onChange={(date, dateString) => {
                                            this.onInputChange({ name: 'enddate', value: dateString })
                                        }}
                                    />
                                    )}
                                </FormItem>
                            </Col>
                            
                        </Row>

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="PersonInCharge">
                                        {getFieldDecorator('personlncharge', {
                                            initialValue: personlncharge,
                                            rules: [{
                                                required: false,
                                                message: '',
                                            }],
                                            })(
                                            <Select 
                                                allowClear
                                                mode='multiple'
                                                style={{ width: 300, marginLeft: 10 }}
                                                placeholder='Please select personlncharge' 
                                                onChange={(val) => {
                                                    this.onEmailInputChange({ name: 'personlncharge', value: val })
                                            }}>
                                                {userAccountOptions}
                                            </Select>
                                        )}
                                    </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="Status">
                                    {getFieldDecorator('status', {
                                        initialValue: status,
                                        rules: [{
                                            required: true,
                                            message: 'Please select Status',
                                        }],
                                        })(
                                        <Select
                                            allowClear
                                            style={{ width: 300, marginLeft: 10 }}
                                            onChange={(val) => {
                                                this.onInputChange({ name: 'status', value: val })
                                            }} 
                                            placeholder="Please select Status">
                                                {statusOptions}
                                        </Select>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>
                        
                        

                        <Row gutter={24}>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="comments">
                                    {getFieldDecorator('comments', {
                                        initialValue: comments,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'comments', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                            <Col span={12}>
                                <FormItem {...formItemLayout} label="remarks">
                                    {getFieldDecorator('remarks', {
                                        initialValue: remarks,
                                        rules: [{
                                            required: false,
                                            message: '',
                                        }],
                                        })(
                                        <TextArea 
                                            style={{ width: 300, marginLeft: 10 }}
                                            rows={3} 
                                            onChange={(e) => {
                                                this.onInputChange({ name: 'remarks', value: e.target.value })
                                        }}/>
                                    )}
                                </FormItem>
                            </Col>
                        </Row>


                    </Form>
                    {
                        !!respErrMsg &&  
                        <Message negative>
                            <Message.Header>Response Error Message</Message.Header>
                            <p>{ respErrMsg }</p>
                        </Message>
                    }

                </Modal>
            </div>
        );
    }
}


const AddOrEditWorkManagementSubModalDialogForm = Form.create({ name: 'AddOrEditWorkManagementSubModalDialog' })(AddOrEditWorkManagementSubModalDialog);

export default AddOrEditWorkManagementSubModalDialogForm;
