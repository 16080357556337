import React, { Component } from 'react';
import { Modal, message, Button, Row, Col,Input } from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import OperationRecordGrid from './OperationRecordGrid';
import OperationRecordDetail from './OperationRecordDetail';


{/*
    关于本模块的使用：
    客户模块需要在Container的：
        （1）mapStateToProps 上设置：operationRecord: state.operationRecord,
        （2）mapDispatchToProps 上设置： doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),
        第三个参数customColumns表示自定义要显示的列，非必须，为空表示展示所有列
    按钮所在页面需要添加：
        <OperationRecordDialog {...this.props} />
    在客户模块打开本页面时：
        onClick={()=>this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: {module: 'XxxxModuleName'}, customColumns: [] })} 

    本模块的后端部分见CommonOperationRcdService
*/}

class OperationRecordDialog extends Component {
    componentDidUpdate() {
        const {
            queryOperationRecordStatus,
            queryOperationRecordResp,
            operationRecordDialogModal,
        } = this.props.operationRecord;

        if(queryOperationRecordStatus === ACTION_STATUS.SUCCESS) {
            this.props.doOperationRecordAction({action:'INIT_QUERY'});
        }
        if(queryOperationRecordStatus === ACTION_STATUS.ERROR) {
            let err = 'query Operation Record failed';
            if(!!queryOperationRecordResp && !!queryOperationRecordResp.respMessage) {
                err = queryOperationRecordResp.respMessage;
            }
            message.error(err);
            this.props.doOperationRecordAction({action:'INIT_QUERY'});
        }
    }

    btnClicked(action) {
        const {
            operationRecordDialogModal,
            queryOperationRecordResp,
        } = this.props.operationRecord;
        
        const {
            title,
            type,
            startTime,
            entTime,
            participantname,
            
            
        } = this.props.meetingAndTrainingCalenderQueryFields;

        if(action === 'QUERY') {
            this.props.doOperationRecordAction({action:'OPEN_AND_QUERY', queryFields: operationRecordDialogModal.queryFields, customColumns: operationRecordDialogModal.customColumns});
        } else {
            this.props.doOperationRecordAction({action:'RESET_QUERY', queryFields: operationRecordDialogModal.queryFields, customColumns: operationRecordDialogModal.customColumns});
        }
    }
    onInputChange = ({ name, value }) => {
        this.props.onQueryInputChange({ name, value });
    };

    render() {
        const {
            operationRecordDialogModal,
            queryOperationRecordStatus,
        } = this.props.operationRecord;
        const {
            isOpened,
        } = operationRecordDialogModal;

        const refreshBtnIsLoading = queryOperationRecordStatus === ACTION_STATUS.LOGINING;

        return (
            <div>
                <Modal
                    title={'Sign Record'}
                    centered
                    width={1200}
                    visible={isOpened}
                    destroyOnClose={true}
                    maskClosable={false}
                    onCancel={() => this.btnClicked('CLOSE')}
                    footer={[
                        <Button key="back" onClick={() => this.btnClicked('CLOSE')}>
                          Close
                        </Button>,
                      ]}
                >   
                <div className='OperationBar'>
                
                    {/* <Input 
                        placeholder="participant"
                        size="small"
                        style={{ width: 150, marginLeft: 10 }}
                        onPressEnter={this.refreshBtnOnClicked}
                        onChange={(event) => {
                            this.onInputChange({ name: 'participantname', value: event.target.value })
                        }} 
                    />
                    <Button 
                        onClick={() => this.btnClicked('QUERY')} 
                        loading={refreshBtnIsLoading}
                        size='small'
                        style={{ marginLeft: 10 }}
                        type='primary'
                        icon="search" 
                    /> */}
                  <div>Click on one of the rows to check the detail</div>
               
                </div>
                   
                    <div style={{height: 500, marginTop: 10 }}><OperationRecordGrid {...this.props} /></div>
                    <OperationRecordDetail {...this.props} />
                </Modal>
            </div>
        );
    }
}

export default OperationRecordDialog;
