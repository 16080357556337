import {connect} from 'react-redux';
import MeetingAndTrainingCalenderDashboard from '../components/MeetingAndTrainingCalenderDashboard';


import {
    // query
    onQueryInputChange,
    loadMeetingAndTrainingCalenderInfo,
    initLoadMeetingAndTrainingCalenderInfoActionStatus,
    // select
    selectMeetingAndTrainingCalenderInfo,
    // add or edit
    openAddOrEditMeetingAndTrainingCalenderModal,
    addOrEditMeetingAndTrainingCalenderChangeInput,
    addOrEditMeetingAndTrainingCalender,
    initAddOrEditMeetingAndTrainingCalenderActionStatus,
    resetAddOrEditMeetingAndTrainingCalenderModal,
    // refresh fx and amtUSD
    refreshFx,
    initRefreshFxActionStatus,
    refreshAmtUSD,
    // del
    openDelMeetingAndTrainingCalenderModal,
    delMeetingAndTrainingCalenderChangeInput,
    delMeetingAndTrainingCalender,
    initDelMeetingAndTrainingCalenderActionStatus,
    resetDelMeetingAndTrainingCalenderModal,

    //cop
    openCopMeetingAndTrainingCalenderModal,
    copMeetingAndTrainingCalenderChangeInput,
    copMeetingAndTrainingCalender,
    initCopMeetingAndTrainingCalenderActionStatus,
    resetCopMeetingAndTrainingCalenderModal,

      // Select Answeree
    selectAnswerees,
    initSelectAnswereesActionStatus,




    loadRestrictedRuleList,
    loadRestrictedPinPointList,
    loadRestrictedPersonalList,
    
    openFinishTaskModal,

    doOperationRecordAction,
    openRestrictedRuleDialog,

} from '../MeetingAndTrainingCalenderActions';

// import {
//     // operationRcd
//     doOperationRecordAction,
// } from '../../../common/operationRcd/OperationRecordActions';


const mapStateToProps = state => {
    return {
        // options
        statusOptions: state.meetingAndTrainingCalender.statusOptions,
        typeOptions: state.meetingAndTrainingCalender.typeOptions,
        brokerOptions: state.meetingAndTrainingCalender.brokerOptions,
        evaluationOptions: state.meetingAndTrainingCalender.evaluationOptions,
        fundFirmRelatedOptions: state.meetingAndTrainingCalender.fundFirmRelatedOptions,
        ccyOptions: state.meetingAndTrainingCalender.ccyOptions,
        userAccountOptions: state.meetingAndTrainingCalender.userAccountOptions,

        // meetingAndTrainingCalenderQueryFields
        meetingAndTrainingCalenderQueryFields: state.meetingAndTrainingCalender.meetingAndTrainingCalenderQueryFields,
        // query
        loadMeetingAndTrainingCalenderInfoActionStatus: state.meetingAndTrainingCalender.loadMeetingAndTrainingCalenderInfoActionStatus,
        loadMeetingAndTrainingCalenderInfoResp: state.meetingAndTrainingCalender.loadMeetingAndTrainingCalenderInfoResp,
       
        restrictedRuleList:  state.meetingAndTrainingCalender.restrictedRuleList,
        restrictedPinPointList:  state.meetingAndTrainingCalender.restrictedPinPointList,
        restrictedPersonalList:  state.meetingAndTrainingCalender.restrictedPersonalList,
        selectedMeetingAndTrainingCalenderInfoArray: state.meetingAndTrainingCalender.selectedMeetingAndTrainingCalenderInfoArray,
        meetingAndTrainingCalenderGridColumns: state.meetingAndTrainingCalender.meetingAndTrainingCalenderGridColumns,
        // add or edit
        addOrEditMeetingAndTrainingCalenderModal: state.meetingAndTrainingCalender.addOrEditMeetingAndTrainingCalenderModal,
        addOrEditMeetingAndTrainingCalenderStatus: state.meetingAndTrainingCalender.addOrEditMeetingAndTrainingCalenderStatus,
        
        // refresh fx and amtUSD
        refreshFxStatus: state.meetingAndTrainingCalender.refreshFxStatus,
        refreshFxResp: state.meetingAndTrainingCalender.refreshFxResp,
        // del
        delMeetingAndTrainingCalenderModal: state.meetingAndTrainingCalender.delMeetingAndTrainingCalenderModal,
        delMeetingAndTrainingCalenderStatus: state.meetingAndTrainingCalender.delMeetingAndTrainingCalenderStatus,
        // cop
        copMeetingAndTrainingCalenderModal: state.meetingAndTrainingCalender.copMeetingAndTrainingCalenderModal,
        copMeetingAndTrainingCalenderStatus: state.meetingAndTrainingCalender.copMeetingAndTrainingCalenderStatus,
        // opeartionRcd
        operationRecord: state.operationRecord,

        meetingAndTrainingCalenderUploadUrl: state.meetingAndTrainingCalender.meetingAndTrainingCalenderUploadUrl,
        meetingAndTrainingCalenderDownloadUrl: state.meetingAndTrainingCalender.meetingAndTrainingCalenderDownloadUrl,
        finishTaskModal: state.meetingAndTrainingCalender.finishTaskModal,
        restrictedRuleEditControlModal: state.meetingAndTrainingCalender.restrictedRuleEditControlModal,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        // query
        onQueryInputChange: (params) => dispatch(onQueryInputChange(params)),
        loadMeetingAndTrainingCalenderInfo: (params) => dispatch(loadMeetingAndTrainingCalenderInfo(params)),
        initLoadMeetingAndTrainingCalenderInfoActionStatus: () => dispatch(initLoadMeetingAndTrainingCalenderInfoActionStatus()),
        // select
        selectMeetingAndTrainingCalenderInfo: (dataArray) => dispatch(selectMeetingAndTrainingCalenderInfo(dataArray)),
        // add or edit
        openAddOrEditMeetingAndTrainingCalenderModal: (mode, data) => dispatch(openAddOrEditMeetingAndTrainingCalenderModal(mode, data)),
        addOrEditMeetingAndTrainingCalenderChangeInput: (params) => dispatch(addOrEditMeetingAndTrainingCalenderChangeInput(params)),
        addOrEditMeetingAndTrainingCalender: (info) => dispatch(addOrEditMeetingAndTrainingCalender(info)),
        initAddOrEditMeetingAndTrainingCalenderActionStatus: () => dispatch(initAddOrEditMeetingAndTrainingCalenderActionStatus()),
        resetAddOrEditMeetingAndTrainingCalenderModal: () => dispatch(resetAddOrEditMeetingAndTrainingCalenderModal()),
        // refresh fx and amtUSD
        refreshFx: (params) => dispatch(refreshFx(params)),
        initRefreshFxActionStatus: () => dispatch(initRefreshFxActionStatus()),
        refreshAmtUSD: (params) => dispatch(refreshAmtUSD(params)),
        // del
        openDelMeetingAndTrainingCalenderModal: (info,mode) => dispatch(openDelMeetingAndTrainingCalenderModal(info,mode)),
        delMeetingAndTrainingCalenderChangeInput: (params) => dispatch(delMeetingAndTrainingCalenderChangeInput(params)),
        delMeetingAndTrainingCalender: (info) => dispatch(delMeetingAndTrainingCalender(info)),
        initDelMeetingAndTrainingCalenderActionStatus: () => dispatch(initDelMeetingAndTrainingCalenderActionStatus()),
        resetDelMeetingAndTrainingCalenderModal: () => dispatch(resetDelMeetingAndTrainingCalenderModal()),

        // cop
        openCopMeetingAndTrainingCalenderModal: (info) => dispatch(openCopMeetingAndTrainingCalenderModal(info)),
        copMeetingAndTrainingCalenderChangeInput: (params) => dispatch(copMeetingAndTrainingCalenderChangeInput(params)),
        copMeetingAndTrainingCalender: (params) => dispatch(copMeetingAndTrainingCalender(params)),
        initCopMeetingAndTrainingCalenderActionStatus: () => dispatch(initCopMeetingAndTrainingCalenderActionStatus()),
        resetCopMeetingAndTrainingCalenderModal: () => dispatch(resetCopMeetingAndTrainingCalenderModal()),

        // operationRcd
        
        doOperationRecordAction: (params) => dispatch(doOperationRecordAction(params)),






        loadRestrictedRuleList: (params) => dispatch(loadRestrictedRuleList(params)),
        loadRestrictedPinPointList: (params) => dispatch(loadRestrictedPinPointList(params)),
        loadRestrictedPersonalList: (params) => dispatch(loadRestrictedPersonalList(params)),
        openFinishTaskModal: (task) => dispatch(openFinishTaskModal(task)),


        openRestrictedRuleDialog: (mode, detail) => dispatch(openRestrictedRuleDialog(mode, detail)),

    };
}


export default connect (
    mapStateToProps,
    mapDispatchToProps
)(MeetingAndTrainingCalenderDashboard);