import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';


import {
    // query
    ON_QUERY_INPUT_CHANGE,
    START_LOAD_EXPENSE_SUMMARYNEW_INFO,
    LOAD_EXPENSE_SUMMARYNEW_INFO_SUCCESS,
    LOAD_EXPENSE_SUMMARYNEW_INFO_FAILURE,
    INIT_LOAD_EXPENSE_SUMMARYNEW_INFO_ACTION_STATUS,
    // select
    SELECT_EXPENSE_SUMMARYNEW_INFO,
    // add or edit
    OPEN_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_MODAL,
    ADD_OR_EDIT_EXPENSE_SUMMARYNEW_CHANGE_INPUT,
    START_ADD_OR_EDIT_EXPENSE_SUMMARYNEW,
    ADD_OR_EDIT_EXPENSE_SUMMARYNEW_SUCCESS,
    ADD_OR_EDIT_EXPENSE_SUMMARYNEW_FAILURE,
    INIT_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_ACTION_STATUS,
    RESET_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_MODAL,
    // refresh fx and amtUSD
    START_REFRESH_FX,
    REFRESH_FX_SUCCESS,
    REFRESH_FX_FAILURE,
    INIT_REFRESH_FX_ACTION_STATUS,
    REFRESH_AMT_USD,
    // del
    OPEN_DEL_EXPENSE_SUMMARYNEW_MODAL,
    DEL_EXPENSE_SUMMARYNEW_CHANGE_INPUT,
    START_DEL_EXPENSE_SUMMARYNEW,
    DEL_EXPENSE_SUMMARYNEW_SUCCESS,
    DEL_EXPENSE_SUMMARYNEW_FAILURE,
    INIT_DEL_EXPENSE_SUMMARYNEW_ACTION_STATUS,
    RESET_DEL_EXPENSE_SUMMARYNEW_MODAL,

} from './ExpenseSummaryNewConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadExpenseSummaryNewInfo() {
    return {
      type: START_LOAD_EXPENSE_SUMMARYNEW_INFO,
    };
}
export function loadExpenseSummaryNewInfo(params) {



    return function(dispatch) {
        dispatch(startLoadExpenseSummaryNewInfo());
        httpClient
          .get(UrlConfig.LOAD_EXPENSE_SUMMARY_INFONEW, params)
          .then(resp => dispatch(loadExpenseSummaryNewInfoSuccess(resp)))
          .catch(err => dispatch(loadExpenseSummaryNewInfoFailure(err)));
    };
}
function loadExpenseSummaryNewInfoSuccess(resp) {
    return {
      type: LOAD_EXPENSE_SUMMARYNEW_INFO_SUCCESS,
      payload: resp,
    };
}
function loadExpenseSummaryNewInfoFailure(err) {
    return {
        type: LOAD_EXPENSE_SUMMARYNEW_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadExpenseSummaryNewInfoActionStatus() {
    return {
      type: INIT_LOAD_EXPENSE_SUMMARYNEW_INFO_ACTION_STATUS,
    };
}

// select
export function selectExpenseSummaryNewInfo(array) {
    return {
        type: SELECT_EXPENSE_SUMMARYNEW_INFO,
        payload: array,
    };
}


// add or edit
export function openAddOrEditExpenseSummaryNewModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditExpenseSummaryNewChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_EXPENSE_SUMMARYNEW_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startAddOrEditExpenseSummaryNew() {
    return {
        type: START_ADD_OR_EDIT_EXPENSE_SUMMARYNEW,
    };
}
export function addOrEditExpenseSummaryNew(params) {
      return function(dispatch) {
        dispatch(startAddOrEditExpenseSummaryNew());
        httpClient
          .post(UrlConfig.ADD_OR_EDIT_EXPENSE_SUMMARY, params)
          .then(resp => dispatch(addOrEditExpenseSummaryNewSuccess(resp)))
          .catch(err => dispatch(addOrEditExpenseSummaryNewFailure(err)));
      };
}
function addOrEditExpenseSummaryNewSuccess(resp) {
    return {
        type: ADD_OR_EDIT_EXPENSE_SUMMARYNEW_SUCCESS,
        payload: resp,
    };
}
function addOrEditExpenseSummaryNewFailure(err) {
    return {
        type: ADD_OR_EDIT_EXPENSE_SUMMARYNEW_FAILURE,
        payload: err,
    };
}
export function initAddOrEditExpenseSummaryNewActionStatus() {
    return {
        type: INIT_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_ACTION_STATUS,
    };
}
export function resetAddOrEditExpenseSummaryNewModal() {
    return {
        type: RESET_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_MODAL,
    };
}


// refresh fx and amtUSD
function startRefreshFx() {
    return {
        type: START_REFRESH_FX,
    };
}
export function refreshFx({currInvDate, currCcy}) {
    if(!currInvDate || !currCcy) {
        return function(dispatch) {
            dispatch(refreshFxSuccess(null));
        }
    } else {
        const params = {
            fromCurrencyCode: 'USD',
            toCurrencyCode: currCcy,
            specifiedDateStr: currInvDate,
        };
        return function(dispatch) {
            dispatch(startRefreshFx());
            httpClient
            .get(UrlConfig.GET_FX_BY_NEAREST_DATE, params)
            .then(resp => dispatch(refreshFxSuccess(resp)))
            .catch(err => dispatch(refreshFxFailure(err)));
        };
    }
}
function refreshFxSuccess(resp) {
    return {
        type: REFRESH_FX_SUCCESS,
        payload: resp,
    };
}
function refreshFxFailure(err) {
    return {
        type: REFRESH_FX_FAILURE,
        payload: err,
    };   
}
export function initRefreshFxActionStatus() {
    return {
        type: INIT_REFRESH_FX_ACTION_STATUS,
    };
}
export function refreshAmtUSD({currAmount, currFx}) {
    let amtUSD = '';
    if(!!currAmount && !!currFx) {
        amtUSD = currAmount / currFx;
        amtUSD = Math.round(amtUSD * 100) / 100;
    }
    if(isNaN(amtUSD)) {
        amtUSD = '';
    }
    return {
        type: REFRESH_AMT_USD,
        payload: amtUSD,
    }
}


// del
export function openDelExpenseSummaryNewModal(info,mode) {
    return {
        type: OPEN_DEL_EXPENSE_SUMMARYNEW_MODAL,

        payload: {
            info,
            mode,
        },
    };  
}
export function delExpenseSummaryNewChangeInput({name, value}) {
    return {
        type: DEL_EXPENSE_SUMMARYNEW_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startDelExpenseSummaryNew() {
    return {
        type: START_DEL_EXPENSE_SUMMARYNEW,
    };
}
export function delExpenseSummaryNew(params) {
      return function(dispatch) {
        dispatch(startDelExpenseSummaryNew());
        httpClient
          .post(UrlConfig.DEL_EXPENSE_SUMMARY, params)
          .then(resp => dispatch(delExpenseSummaryNewSuccess(resp)))
          .catch(err => dispatch(delExpenseSummaryNewFailure(err)));
      };
}
function delExpenseSummaryNewSuccess(resp) {
    return {
        type: DEL_EXPENSE_SUMMARYNEW_SUCCESS,
        payload: resp,
    };
}
function delExpenseSummaryNewFailure(err) {
    return {
        type: DEL_EXPENSE_SUMMARYNEW_FAILURE,
        payload: err,
    };
}
export function initDelExpenseSummaryNewActionStatus() {
    return {
        type: INIT_DEL_EXPENSE_SUMMARYNEW_ACTION_STATUS,
    };
}
export function resetDelExpenseSummaryNewModal() {
    return {
        type: RESET_DEL_EXPENSE_SUMMARYNEW_MODAL,
    };
}


