import {connect} from 'react-redux';
import RestrictedSecurityRequestDashboard from '../components/RestrictedSecurityRequestDashboard';

import {
  // Load Restricted Security Request
  loadRestrictedSecurityRequest,
  initLoadRestrictedSecurityRequestActionStatus,

  // Select Restricted Security Request
  selectRestrictedSecurityRequest,

  // Open/Reset Restricted Security Request Modal Dialog
  openRestrictedSecurityRequestEditModalDialog,
  resetRestrictedSecurityRequestEditModalDialog,

  // Restricted Security Request Edit Change Input
  restrictedSecurityRequestEditChangeInput,

  // =============================================================
  // Create Restricted Security Request
  createRestrictedSecurityRequest,
  initCreateRestrictedSecurityRequestActionStatus,

  // Update Restricted Security Request
  updateRestrictedSecurityRequest,
  initUpdateRestrictedSecurityRequestActionStatus,

  // Batch Delete Restricted Security Request
  batchDeleteRestrictedSecurityRequest,
  initBatchDeleteRestrictedSecurityRequestActionStatus,

  // Submit Restricted Security Request
  submitRestrictedSecurityRequest,
  initSubmitRestrictedSecurityRequestActionStatus,

  // =================================================================
  // Load Restricted Security BBG Tickers
  loadRestrictedSecurityBbgTickers,
  initLoadRestrictedSecurityBbgTickersActionStatus,

  // Clear Restricted Security bbgTickerArray bbgTickerOptions
  clearRestrictedSecurityBbgTickers,

  // Restricted Security BBG Ticker Select Changed
  restrictedSecurityBbgTickerSelectChanged,

  // Restricted Security Fund Code Select Changed
  restrictedSecurityFundCodeSelectChanged,

  // Restricted Security Book Code Select Changed
  restrictedSecurityBookCodeSelectChanged,

  // Open/Reset Restricted Security Request Operate Modal Dialog
  openRestrictedSecurityRequestOperateModalDialog,
  resetRestrictedSecurityRequestOperateModalDialog,

  // On List Restricted Security Request
  onListRestrictedSecurityRequest,
  initOnListRestrictedSecurityRequestActionStatus,

  // Off List Restricted Security Request
  offListRestrictedSecurityRequest,
  initOffListRestrictedSecurityRequestActionStatus,

  // Restricted Security Operate Edit Change Input
  restrictedSecurityOperateEditChangeInput,

  // Open/Reset Restricted Security List Check Modal Dialog
  openRestrictedSecurityListCheckModalDialog,
  resetRestrictedSecurityListCheckModalDialog,

  // Search Valid Restricted Security 
  searchValidRestrictedSecurity,
  initSearchValidRestrictedSecurityActionStatus,

} from '../restrictedSecurityRequestActions';

import {
  doHistoryUploadAction,
} from '../../common/historyUpload/HistoryUploadActions';

const mapStateToProps = state => {
  return {
    // Grdi Columns
    restrictedSecurityGridColumns: state.restrictedSecurityRequest.restrictedSecurityGridColumns,

    // Restricted Security Request 
    restrictedSecurityRequestArray: state.restrictedSecurityRequest.restrictedSecurityRequestArray,

    // Selected Restricted Security Request
    selectedRestrictedSecurityRequestArray: state.restrictedSecurityRequest.selectedRestrictedSecurityRequestArray,

    // Restricted Security Request Edit Modal
    restrictedSecurityRequestEditControlModal: state.restrictedSecurityRequest.restrictedSecurityRequestEditControlModal,

    // load/create/update/delete/submit Restricted Security Request 
    loadRestrictedSecurityRequestActionStatus: state.restrictedSecurityRequest.loadRestrictedSecurityRequestActionStatus,
    createRestrictedSecurityRequestActionStatus: state.restrictedSecurityRequest.createRestrictedSecurityRequestActionStatus,
    updateRestrictedSecurityRequestActionStatus: state.restrictedSecurityRequest.updateRestrictedSecurityRequestActionStatus,
    batchDeleteRestrictedSecurityRequestsActionStatus: state.restrictedSecurityRequest.batchDeleteRestrictedSecurityRequestsActionStatus,
    submitRestrictedSecurityRequestActionStatus: state.restrictedSecurityRequest.submitRestrictedSecurityRequestActionStatus,

    // Security Ticker Data
    bbgTickerArray: state.restrictedSecurityRequest.bbgTickerArray,
    bbgTickerOptions: state.restrictedSecurityRequest.bbgTickerOptions,
    securityTickerArray: state.restrictedSecurityRequest.securityTickerArray,
    
    // Fund Book Data
    fundBookData: state.restrictedSecurityRequest.fundBookData,
    fundCodeArray: state.restrictedSecurityRequest.fundCodeArray,
    fundCodeOptions: state.restrictedSecurityRequest.fundCodeOptions,
    bookCodeArray: state.restrictedSecurityRequest.bookCodeArray,
    bookCodeOptions: state.restrictedSecurityRequest.bookCodeOptions,
    fundBookArray: state.restrictedSecurityRequest.fundBookArray,

    // Load Restricted Security BBG Tickers Action Status
    loadRestrictedSecurityBbgTickersActionStatus: state.restrictedSecurityRequest.loadRestrictedSecurityBbgTickersActionStatus,

    // Restricted Security Request Operate Modal Dialog
    restrictedSecurityRequestOperateControlModal: state.restrictedSecurityRequest.restrictedSecurityRequestOperateControlModal,

    // On/Off List Restricted Security Request Action Status
    onListRestrictedSecurityRequestActionStatus: state.restrictedSecurityRequest.onListRestrictedSecurityRequestActionStatus,
    offListRestrictedSecurityRequestActionStatus: state.restrictedSecurityRequest.offListRestrictedSecurityRequestActionStatus,

    // 附件操作URL
    restrictedSecAttachmentUploadUrl: state.restrictedSecurityRequest.restrictedSecAttachmentUploadUrl,
    restrictedSecAttachmentPreviewUrl: state.restrictedSecurityRequest.restrictedSecAttachmentPreviewUrl,
    restrictedSecAttachmentDownloadUrl: state.restrictedSecurityRequest.restrictedSecAttachmentDownloadUrl,

    // Restricted Security List Check Modal Dialog
    restrictedSecurityListCheckControlModal: state.restrictedSecurityRequest.restrictedSecurityListCheckControlModal,

    // Search Valid Restricted Security 
    searchValidRestrictedSecurityActionStatus: state.restrictedSecurityRequest.searchValidRestrictedSecurityActionStatus,
    matchedValidRestrictedSecurityArray: state.restrictedSecurityRequest.matchedValidRestrictedSecurityArray,
    
    // historyUpload:
    historyUpload: state.historyUpload,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Restricted Security Request
    loadRestrictedSecurityRequest: () => dispatch(loadRestrictedSecurityRequest()),
    initLoadRestrictedSecurityRequestActionStatus: () => dispatch(initLoadRestrictedSecurityRequestActionStatus()),

    // Select Restricted Security Request
    selectRestrictedSecurityRequest: (payload) => dispatch(selectRestrictedSecurityRequest(payload)),

    // Open/Reset Restricted Security Request Modal Dialog
    openRestrictedSecurityRequestEditModalDialog: (isAddMode, restrictedSecurityRequest) => dispatch(openRestrictedSecurityRequestEditModalDialog(isAddMode, restrictedSecurityRequest)),
    resetRestrictedSecurityRequestEditModalDialog: () => dispatch(resetRestrictedSecurityRequestEditModalDialog()),

    // Restricted Security Request Edit Change Input
    restrictedSecurityRequestEditChangeInput: (input) => dispatch(restrictedSecurityRequestEditChangeInput(input)),

    // =============================================================
    // Create Restricted Security Request
    createRestrictedSecurityRequest: (restrictedSecurityRequest) => dispatch(createRestrictedSecurityRequest(restrictedSecurityRequest)),
    initCreateRestrictedSecurityRequestActionStatus: () => dispatch(initCreateRestrictedSecurityRequestActionStatus()),

    // Update Restricted Security Request
    updateRestrictedSecurityRequest: (restrictedSecurityRequest) => dispatch(updateRestrictedSecurityRequest(restrictedSecurityRequest)),
    initUpdateRestrictedSecurityRequestActionStatus: () => dispatch(initUpdateRestrictedSecurityRequestActionStatus()),

    // Batch Delete Restricted Security Request
    batchDeleteRestrictedSecurityRequest: (restrictedSecGuidList) => dispatch(batchDeleteRestrictedSecurityRequest(restrictedSecGuidList)),
    initBatchDeleteRestrictedSecurityRequestActionStatus: () => dispatch(initBatchDeleteRestrictedSecurityRequestActionStatus()),

    // =================================================================
    // Load Restricted Security BBG Tickers
    loadRestrictedSecurityBbgTickers: (queryText) => dispatch(loadRestrictedSecurityBbgTickers(queryText)),
    initLoadRestrictedSecurityBbgTickersActionStatus: () => dispatch(initLoadRestrictedSecurityBbgTickersActionStatus()),

    // Clear Restricted Security bbgTickerArray bbgTickerOptions
    clearRestrictedSecurityBbgTickers: () => dispatch(clearRestrictedSecurityBbgTickers()),

    // Restricted Security BBG Ticker Select Changed
    restrictedSecurityBbgTickerSelectChanged: (bbgTicker) => dispatch(restrictedSecurityBbgTickerSelectChanged(bbgTicker)),

    // Restricted Security Fund Code Select Changed
    restrictedSecurityFundCodeSelectChanged: (fundCode) => dispatch(restrictedSecurityFundCodeSelectChanged(fundCode)),

    // Restricted Security Book Code Select Changed
    restrictedSecurityBookCodeSelectChanged: (bookCode) => dispatch(restrictedSecurityBookCodeSelectChanged(bookCode)),

    // Open/Reset Restricted Security Request Operate Modal Dialog
    openRestrictedSecurityRequestOperateModalDialog: (operateMode, restrictedSecGuidList, reason) => dispatch(openRestrictedSecurityRequestOperateModalDialog(operateMode, restrictedSecGuidList, reason)),
    resetRestrictedSecurityRequestOperateModalDialog: () => dispatch(resetRestrictedSecurityRequestOperateModalDialog()),

    // On List Restricted Security Request
    onListRestrictedSecurityRequest: (params) => dispatch(onListRestrictedSecurityRequest(params)),
    initOnListRestrictedSecurityRequestActionStatus: () => dispatch(initOnListRestrictedSecurityRequestActionStatus()),

    // Off List Restricted Security Request
    offListRestrictedSecurityRequest: (params) => dispatch(offListRestrictedSecurityRequest(params)),
    initOffListRestrictedSecurityRequestActionStatus: () => dispatch(initOffListRestrictedSecurityRequestActionStatus()),

    // Restricted Security Operate Edit Change Input
    restrictedSecurityOperateEditChangeInput: (input) => dispatch(restrictedSecurityOperateEditChangeInput(input)),

    // Open/Reset Restricted Security List Check Modal Dialog
    openRestrictedSecurityListCheckModalDialog: () => dispatch(openRestrictedSecurityListCheckModalDialog()),
    resetRestrictedSecurityListCheckModalDialog: () => dispatch(resetRestrictedSecurityListCheckModalDialog()),

    // Search Valid Restricted Security 
    searchValidRestrictedSecurity: (queryText) => dispatch(searchValidRestrictedSecurity(queryText)),
    initSearchValidRestrictedSecurityActionStatus: () => dispatch(initSearchValidRestrictedSecurityActionStatus()),
     // historyUpload
    doHistoryUploadAction: (params) => dispatch(doHistoryUploadAction(params)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RestrictedSecurityRequestDashboard);