import {connect} from 'react-redux';
import LicensingDashboard from '../components/LicensingDashboard';

import {
    loadLicensingInfo,
    initLoadLicensingInfoActionStatus,

    selectLicensingData,

    openAddOrEditLicenseModal,
    addOrEditLicenseChangeInput,

    addOrEditLicenseEmpRequireRcd,
    initAddOrEditLicenseEmpRequireRcdActionStatus,
    resetSubmitSaveLicenseModal,

    openEditCptRcdModal,
    editCptRcdModalChangeInput,
    submitEditCptRcd,
    initSubmitEditCptRcdActionStatus,
    resetEditCptRcdModal,


} from '../LicensingActions';


const mapStateToProps = state => {
    return {

        loadLicensingInfoActionStatus: state.licensing.loadLicensingInfoActionStatus,
        loadLicensingInfoResp: state.licensing.loadLicensingInfoResp,
        licensingGridColumns: state.licensing.licensingGridColumns,
        licensingCPTYearRcdGridColumns: state.licensing.licensingCPTYearRcdGridColumns,

        selectedLicenseArray: state.licensing.selectedLicenseArray,

        addOrEditLicenseModal: state.licensing.addOrEditLicenseModal,
        licensingExamCPTProofUploadUrl: state.licensing.licensingExamCPTProofUploadUrl,
        addOrEditLicenseEmpRequiredRcdStatus: state.licensing.addOrEditLicenseEmpRequiredRcdStatus,

        editCptRcdModal: state.licensing.editCptRcdModal,
        submitEditCptRcdStatus: state.licensing.submitEditCptRcdStatus,

    };
}


const mapDispatchToProps = dispatch => {
    return {
        loadLicensingInfo: (params) => dispatch(loadLicensingInfo(params)),
        initLoadLicensingInfoActionStatus: () => dispatch(initLoadLicensingInfoActionStatus()),

        selectLicensingData: (array) => dispatch(selectLicensingData(array)),

        openAddOrEditLicenseModal: (mode, licenseInfo) =>  dispatch(openAddOrEditLicenseModal(mode, licenseInfo)),
        addOrEditLicenseChangeInput: (param) => dispatch(addOrEditLicenseChangeInput(param)),

        addOrEditLicenseEmpRequireRcd: (params) => dispatch(addOrEditLicenseEmpRequireRcd(params)),
        initAddOrEditLicenseEmpRequireRcdActionStatus: () => dispatch(initAddOrEditLicenseEmpRequireRcdActionStatus()),
        resetSubmitSaveLicenseModal: () => dispatch(resetSubmitSaveLicenseModal()),

        openEditCptRcdModal: (cptRcd, employee, licenseBasic) => dispatch(openEditCptRcdModal(cptRcd, employee, licenseBasic)),
        editCptRcdModalChangeInput: (params) => dispatch(editCptRcdModalChangeInput(params)),
        submitEditCptRcd: (rcd) => dispatch(submitEditCptRcd(rcd)),
        initSubmitEditCptRcdActionStatus: () => dispatch(initSubmitEditCptRcdActionStatus()),
        resetEditCptRcdModal: () => dispatch(resetEditCptRcdModal()),

    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(LicensingDashboard);
