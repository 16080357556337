import { createReducer } from '../../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';

import {
    OPEN_HISTORY_UPLOAD_DIALOG,
    RESET_HISTORY_UPLOAD_DIALOG,
    START_QUERY_HISTORY_UPLOAD,
    QUERY_HISTORY_UPLOAD_SUCESS,
    QUERY_HISTORY_UPLOAD_FAILURE,
    INIT_QUERY_HISTORY_UPLOAD_STATUS,
} from './HistoryUploadConstants';


const initialState = {
    historyUploadDialogModal: {
        isOpened: false,
        queryFields: {
            module: undefined,
            dataId: undefined,
            uploadTimeStart: undefined,
            uploadTimeEnd: undefined,
            uploadedBy: undefined,
        },
        baseDownloadUrl: undefined,
    },
};


function openHistoryUploadDialog(state, params) {
    return {
        ...state,
        historyUploadDialogModal: {
            ...state.historyUploadDialogModal,
            isOpened: true,
            queryFields: params.queryFields,
            baseDownloadUrl: params.baseDownloadUrl,
        }
    }
}
function resetHistoryUploadDialog(state) {
    return {
        ...state,
        historyUploadDialogModal: initialState.historyUploadDialogModal,
    } 
}


function startQueryHistoryUpload(state) {
    return {
        ...state,
        queryHistoryUploadStatus: ACTION_STATUS.LOGINING,
    }
}
function queryHistoryUploadSuccess(state, resp) {
    return {
        ...state,
        queryHistoryUploadStatus: ACTION_STATUS.SUCCESS,
        queryHistoryUploadResp: resp,
    }
}
function queryHistoryUploadFailure(state, err) {
    return {
        ...state,
        queryHistoryUploadStatus: ACTION_STATUS.ERROR,
        queryHistoryUploadResp: err,
    }
}
function initQueryHistoryUploadStatus(state) {
    return {
        ...state,
        queryHistoryUploadStatus: ACTION_STATUS.READY,
    }
}


export default createReducer(initialState, {
    [OPEN_HISTORY_UPLOAD_DIALOG]: openHistoryUploadDialog,
    [RESET_HISTORY_UPLOAD_DIALOG]: resetHistoryUploadDialog,
    [START_QUERY_HISTORY_UPLOAD]: startQueryHistoryUpload,
    [QUERY_HISTORY_UPLOAD_SUCESS]: queryHistoryUploadSuccess,
    [QUERY_HISTORY_UPLOAD_FAILURE]: queryHistoryUploadFailure,
    [INIT_QUERY_HISTORY_UPLOAD_STATUS]: initQueryHistoryUploadStatus,
});
