import {
  // Load Fund Book Data
  LOAD_FUND_BOOK_DATA_SUCCESS,
  LOAD_FUND_BOOK_DATA_FAILURE,

  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,
  LOAD_SECURITY_TICKERS_FAILURE,

  // Load Asset Inst Class
  LOAD_ASSET_INST_CLASS_SUCCESS,
  LOAD_ASSET_INST_CLASS_FAILURE,

  // Login By Access Token
  START_LOGIN_BY_ACCESS_TOKEN,
  LOGIN_BY_ACCESS_TOKEN_SUCCESS,
  LOGIN_BY_ACCESS_TOKEN_FAILURE,
  INIT_LOGIN_BY_ACCESS_TOKEN_STATUS,

  // get hierarchy menus
  GET_HIERARCHY_MENUS_SUCCESS,
  GET_HIERARCHY_MENUS_FAILURE,

    // Load User Account
    LOAD_USER_ACCOUNTS_SUCCESS,
    LOAD_USER_ACCOUNTS_FAILURE,

} from './homeConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';
import { localStorageClient } from '../../../utils/localStorageClient';
import { sleep } from '../../../utils/promiseUtils';

// =================================================================
// Load Fund Book Data
export function loadFundBookData() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.QUERY_FUND_BOOK_DATA)
      .then(resp => dispatch(loadFundBookDataSuccess(resp)))
      .catch(err => dispatch(loadFundBookDataFailure(err)));
  };
}

function loadFundBookDataSuccess(resp) {
  return {
    type: LOAD_FUND_BOOK_DATA_SUCCESS,
    payload: resp
  };
}

function loadFundBookDataFailure(err) {
  return {
    type: LOAD_FUND_BOOK_DATA_FAILURE,
    payload: err
  };
}

// =================================================================
// Load Security Tickers
export function loadSecurityTickers() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.QUERY_SECURITY_TICKER)
      .then(resp => dispatch(loadSecurityTickersSuccess(resp)))
      .catch(err => dispatch(loadSecurityTickersFailure(err)));
  };
}

function loadSecurityTickersSuccess(resp) {
  return {
    type: LOAD_SECURITY_TICKERS_SUCCESS,
    payload: resp
  };
}

function loadSecurityTickersFailure(err) {
  return {
    type: LOAD_SECURITY_TICKERS_FAILURE,
    payload: err
  };
}

// =================================================================
// Load Asset Inst Class
export function loadAssetInstClass() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.QUERY_ASSET_INST_CLASS)
      .then(resp => dispatch(loadAssetInstClassSuccess(resp)))
      .catch(err => dispatch(loadAssetInstClassFailure(err)));
  };
}

function loadAssetInstClassSuccess(resp) {
  return {
    type: LOAD_ASSET_INST_CLASS_SUCCESS,
    payload: resp
  };
}

function loadAssetInstClassFailure(err) {
  return {
    type: LOAD_ASSET_INST_CLASS_FAILURE,
    payload: err
  };
}

// Login By Access Token
function startLoginByAccessToken() {
  return {
    type: START_LOGIN_BY_ACCESS_TOKEN
  };
}

export function loginByAccessToken(accessToken) {
  const requestParams = {
    accessToken,
  };

  return function(dispatch) {
    dispatch(startLoginByAccessToken());
    httpClient
      .post(UrlConfig.LOGIN_BY_ACESS_TOKEN, requestParams)
      .then(resp => {
        dispatch(loginByAccessTokenSuccess(resp));
      })
      .catch(err => {
        dispatch(loginByAccessTokenFailure(err));
      });
  };
}

function loginByAccessTokenSuccess(resp) {
  return {
    type: LOGIN_BY_ACCESS_TOKEN_SUCCESS,
    payload: resp
  };
}

function loginByAccessTokenFailure(err) {
  return {
    type: LOGIN_BY_ACCESS_TOKEN_FAILURE,
    payload: err
  };
}

export function initLoginByAccessTokenStatus() {
  return {
    type: INIT_LOGIN_BY_ACCESS_TOKEN_STATUS,
  };
}


// get hierarchy menus
export function getHierarchyMenus() {
  const params = {
    source: 'Report',
  };
  return function(dispatch) {
    httpClient
      .get(UrlConfig.GET_HIERARCHY_MENUS, params)
      .then(resp => dispatch(getHierarchyMenusSuccess(resp)))
      .catch(err => dispatch(getHierarchyMenusFailure(err)));
  };
}
function getHierarchyMenusSuccess(resp) {
  return {
    type: GET_HIERARCHY_MENUS_SUCCESS,
    payload: resp
  };
}
function getHierarchyMenusFailure(err) {
  return {
    type: GET_HIERARCHY_MENUS_FAILURE,
    payload: err
  };
}

// Load User Account
export function loadUserAccounts() {
  return function(dispatch) {
    httpClient
      .get(UrlConfig.QUERY_USER_ACCOUNTS)
      .then(resp => dispatch(loadUserAccountsSuccess(resp)))
      .catch(err => dispatch(loadUserAccountsFailure(err)));
  };
}

function loadUserAccountsSuccess(resp) {
  return {
    type: LOAD_USER_ACCOUNTS_SUCCESS,
    payload: resp
  };
}

function loadUserAccountsFailure(err) {
  return {
    type: LOAD_USER_ACCOUNTS_FAILURE,
    payload: err
  };
}