import React, { Component } from 'react';
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import PrivateRoute from '../../views/components/PrivateRoute';

import './App.css';
import 'antd/dist/antd.css';

// auth
import LoginContainer from '../../views/features/auth/containers/LoginContainer';
import HomeContainer from '../../views/features/home/containers/HomeContainer';
import MainContainer from '../../views/features/home/containers/MainContainer';
import AuthenticationContainer from '../../views/features/auth/containers/AuthenticationContainer';

class App extends Component {
  render() {
    return (
      <div className="box">
        <Switch>
          <Route path="/login" component={LoginContainer} /> 
          <Route path="/auth" component={AuthenticationContainer} /> 
          <PrivateRoute path="/home" component={HomeContainer} />
          <PrivateRoute path="/main" component={MainContainer} />
          <Route exact path="/" render={() => <Redirect to="/home" />} />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
