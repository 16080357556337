// query
export const ON_QUERY_INPUT_CHANGE = 'ON_QUERY_INPUT_CHANGE';
export const START_LOAD_EXPENSE_SUMMARYNEW_INFO = 'START_LOAD_EXPENSE_SUMMARYNEW_INFO';
export const LOAD_EXPENSE_SUMMARYNEW_INFO_SUCCESS = 'LOAD_EXPENSE_SUMMARYNEW_INFO_SUCCESS';
export const LOAD_EXPENSE_SUMMARYNEW_INFO_FAILURE = 'LOAD_EXPENSE_SUMMARYNEW_INFO_FAILURE';
export const INIT_LOAD_EXPENSE_SUMMARYNEW_INFO_ACTION_STATUS = 'INIT_LOAD_EXPENSE_SUMMARYNEW_INFO_ACTION_STATUS';
// select
export const SELECT_EXPENSE_SUMMARYNEW_INFO = 'SELECT_EXPENSE_SUMMARYNEW_INFO';
// add or edit
export const OPEN_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_MODAL = 'OPEN_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_MODAL';
export const ADD_OR_EDIT_EXPENSE_SUMMARYNEW_CHANGE_INPUT = 'ADD_OR_EDIT_EXPENSE_SUMMARYNEW_CHANGE_INPUT';
export const START_ADD_OR_EDIT_EXPENSE_SUMMARYNEW = 'START_ADD_OR_EDIT_EXPENSE_SUMMARYNEW';
export const ADD_OR_EDIT_EXPENSE_SUMMARYNEW_SUCCESS = 'ADD_OR_EDIT_EXPENSE_SUMMARYNEW_SUCCESS';
export const ADD_OR_EDIT_EXPENSE_SUMMARYNEW_FAILURE = 'ADD_OR_EDIT_EXPENSE_SUMMARYNEW_FAILURE';
export const INIT_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_ACTION_STATUS = 'INIT_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_ACTION_STATUS';
export const RESET_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_MODAL = 'RESET_ADD_OR_EDIT_EXPENSE_SUMMARYNEW_MODAL';
// refresh fx and amtUSD
export const START_REFRESH_FX = 'START_REFRESH_FX';
export const REFRESH_FX_SUCCESS = 'REFRESH_FX_SUCCESS';
export const REFRESH_FX_FAILURE = 'REFRESH_FX_FAILURE';
export const INIT_REFRESH_FX_ACTION_STATUS = 'INIT_REFRESH_FX_ACTION_STATUS';
export const REFRESH_AMT_USD = 'REFRESH_AMT_USD';
// del
export const OPEN_DEL_EXPENSE_SUMMARYNEW_MODAL = 'OPEN_DEL_EXPENSE_SUMMARYNEW_MODAL';
export const DEL_EXPENSE_SUMMARYNEW_CHANGE_INPUT = 'DEL_EXPENSE_SUMMARYNEW_CHANGE_INPUT';
export const START_DEL_EXPENSE_SUMMARYNEW = 'START_DEL_EXPENSE_SUMMARYNEW';
export const DEL_EXPENSE_SUMMARYNEW_SUCCESS = 'DEL_EXPENSE_SUMMARYNEW_SUCCESS';
export const DEL_EXPENSE_SUMMARYNEW_FAILURE = 'DEL_EXPENSE_SUMMARYNEW_FAILURE';
export const INIT_DEL_EXPENSE_SUMMARYNEW_ACTION_STATUS = 'INIT_DEL_EXPENSE_SUMMARYNEW_ACTION_STATUS';
export const RESET_DEL_EXPENSE_SUMMARYNEW_MODAL = 'RESET_DEL_EXPENSE_SUMMARYNEW_MODAL';

