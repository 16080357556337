import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';


import {
    // query
    ON_APPLY_RECON_QUERY_INPUT_CHANGE,
    START_LOAD_APPLY_RECON_INFO,
    LOAD_APPLY_RECON_INFO_SUCCESS,
    LOAD_APPLY_RECON_INFO_FAILURE,
    INIT_LOAD_APPLY_RECON_INFO_ACTION_STATUS,
    // select
    SELECT_REPORTING_SUMMRAY,
    // add or edit
    OPEN_ADD_OR_EDIT_APPLY_RECON_MODAL,
    ADD_OR_EDIT_APPLY_RECON_CHANGE_INPUT,
    RESET_SUBMIT_SAVE_APPLY_RECON_MODAL,
    START_SUBMIT_SAVE_APPLY_RECON,
    SUBMIT_SAVE_APPLY_RECON_SUCCESS,
    SUBMIT_SAVE_APPLY_RECON_FAILURE,
    INIT_SUBMIT_SAVE_APPLY_RECON_ACTION_STATUS,
    // batch change status
    OPEN_BATCH_CHANGE_STATUS_MODAL,
    RESET_BATCH_CHANGE_STATUS_MODAL,
    BATCH_CHANGE_STATUS_CHANGE_INPUT,
    START_BATCH_CHANGE_STATUS_FOR_APPLY_RECON,
    BATCH_CHANGE_STATUS_FOR_APPLY_RECON_SUCCESS,
    BATCH_CHANGE_STATUS_FOR_APPLY_RECON_FAILURE,
    INIT_BATCH_CHANGE_STATUS_FOR_APPLY_RECON_ACTION_STATUS,
    // finish
    SELECT_REPORTING_SUMMRAY_TASK,
    OPEN_FINISH_TASK_MODAL,
    RESET_FINISH_TASK_MODAL,
    FINISH_TASK_CHANGE_INPUT,
    START_FINISH_APPLY_RECON_TASK,
    FINISH_APPLY_RECON_TASK_SUCCESS,
    FINISH_APPLY_RECON_TASK_FAILURE,
    INIT_FINISH_APPLY_RECON_TASK_ACTION_STATUS,


} from './ApplyReconConstants';


// query
export function onQueryInputChange({name, value}) {
    return {
        type: ON_APPLY_RECON_QUERY_INPUT_CHANGE,
        payload: {
          name,
          value,
        }
    };
}
function startLoadApplyReconInfo() {
    return {
      type: START_LOAD_APPLY_RECON_INFO,
    };
}
export function loadApplyReconInfo(params) {
    return function(dispatch) {
        dispatch(startLoadApplyReconInfo());
        httpClient
          .get(UrlConfig.LOAD_APPLY_RECON_INFO, params)
          .then(resp => dispatch(loadApplyReconInfoSuccess(resp)))
          .catch(err => dispatch(loadApplyReconInfoFailure(err)));
    };
}
function loadApplyReconInfoSuccess(resp) {
    return {
      type: LOAD_APPLY_RECON_INFO_SUCCESS,
      payload: resp,
    };
}
function loadApplyReconInfoFailure(err) {
    return {
        type: LOAD_APPLY_RECON_INFO_FAILURE,
        payload: err,
    };
}
export function initLoadApplyReconInfoActionStatus() {
    return {
      type: INIT_LOAD_APPLY_RECON_INFO_ACTION_STATUS,
    };
}


// select
export function selectApplyRecon(array) {
    return {
        type: SELECT_REPORTING_SUMMRAY,
        payload: array,
    };
}


// add or edit
export function openAddOrEditApplyReconModal(mode, data) {
    return {
        type: OPEN_ADD_OR_EDIT_APPLY_RECON_MODAL,
        payload: {
            mode,
            data,
        },
    };
}
export function addOrEditApplyReconChangeInput({name, value}) {
    return {
        type: ADD_OR_EDIT_APPLY_RECON_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
export function resetSubmitSaveApplyReconModal() {
    return {
        type: RESET_SUBMIT_SAVE_APPLY_RECON_MODAL,
    };
}

function startSubmitSaveApplyRecon() {
    return {
        type: START_SUBMIT_SAVE_APPLY_RECON,
    };
}
export function submitSaveApplyRecon(bean) {
    const mode = bean.mode;
    return function(dispatch) {
        dispatch(startSubmitSaveApplyRecon());
        let postURL = null;
        if(mode === 'Stock Pitch' || mode === 'PaperPortfolioOrBook') {
            // postURL = UrlConfig.INSERT_APPLY_RECON;
            postURL = UrlConfig.APPLY_CHANGE_MEETING_AND_TRAINING_STATUS;
        } else {  // EDIT
            postURL = UrlConfig.UPDATE_MEETING_AND_TRAINING_APPLYRECORD;
        }
        httpClient
            .post(postURL, bean)
            .then(resp => dispatch(submitSaveApplyReconSuccess(resp)))
            .catch(err => dispatch(submitSaveApplyReconFailure(err)));        
    };
}
function submitSaveApplyReconSuccess(resp) {
    return {
        type: SUBMIT_SAVE_APPLY_RECON_SUCCESS,
        payload: resp,
    };
}
export function submitSaveApplyReconFailure(err) {
    return {
        type: SUBMIT_SAVE_APPLY_RECON_FAILURE,
        payload: err,
    };
}
export function initSubmitSaveApplyReconActionStatus() {
    return {
        type: INIT_SUBMIT_SAVE_APPLY_RECON_ACTION_STATUS,
    };
}


// batch change status
export function openBatchChangeStatusModal(mode, idList) {
    return {
        type: OPEN_BATCH_CHANGE_STATUS_MODAL,
        payload: {
            mode,
            idList,
        },
    };
}
export function resetBatchChangeStatusModal() {
    return {
        type: RESET_BATCH_CHANGE_STATUS_MODAL,
    };
}
export function batchChangeStatusChangeInput({name, value}) {
    return {
        type: BATCH_CHANGE_STATUS_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}
function startBatchChangeStatusForApplyRecon() {
    return {
        type: START_BATCH_CHANGE_STATUS_FOR_APPLY_RECON,
    };
}
export function batchChangeStatusForApplyRecon(params) {
    return function(dispatch) {
        dispatch(startBatchChangeStatusForApplyRecon());
        httpClient
            .post(UrlConfig.UPDATE_MEETING_AND_TRAINING_APPLYRECORD, params)
            .then(resp => dispatch(batchChangeStatusForApplyReconSuccess(resp)))
            .catch(err => dispatch(batchChangeStatusForApplyReconFailure(err)));        
    };
}
function batchChangeStatusForApplyReconSuccess(resp) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_APPLY_RECON_SUCCESS,
        payload: resp,
    };
}
function batchChangeStatusForApplyReconFailure(err) {
    return {
        type: BATCH_CHANGE_STATUS_FOR_APPLY_RECON_FAILURE,
        payload: err,
    };
}
export function initBatchChangeStatusForApplyReconActionStatus() {
    return {
        type: INIT_BATCH_CHANGE_STATUS_FOR_APPLY_RECON_ACTION_STATUS,
    };
}


// finish
export function selectApplyReconTask(array) {
    return {
        type: SELECT_REPORTING_SUMMRAY_TASK,
        payload: array,
    };
}
export function openFinishTaskModal(task) {
    return {
        type: OPEN_FINISH_TASK_MODAL,
        payload: task,
    };
}
export function resetFinishTaskModal() {
    return {
        type: RESET_FINISH_TASK_MODAL,
    };
}
export function finishTaskChangeInput({name, value}) {
    return {
        type: FINISH_TASK_CHANGE_INPUT,
        payload: {
          name,
          value,
        }
    };
}

function startFinishApplyReconTask() {
    return {
        type: START_FINISH_APPLY_RECON_TASK,
    };
}
export function finishApplyReconTask(params) {
    return function(dispatch) {
        dispatch(startFinishApplyReconTask());
        httpClient
            .post(UrlConfig.FINISH_APPLY_RECON_TASK, params)
            .then(resp => dispatch(finishApplyReconTaskSuccess(resp)))
            .catch(err => dispatch(finishApplyReconTaskFailure(err)));        
    };
}
function finishApplyReconTaskSuccess(resp) {
    return {
        type: FINISH_APPLY_RECON_TASK_SUCCESS,
        payload: resp,
    };
}
function finishApplyReconTaskFailure(err) {
    return {
        type: FINISH_APPLY_RECON_TASK_FAILURE,
        payload: err,
    };
}
export function initFinishApplyReconTaskActionStatus() {
    return {
        type: INIT_FINISH_APPLY_RECON_TASK_ACTION_STATUS,
    };
}

