import React, { Component } from 'react';
import { AgGridReact } from "ag-grid-react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';


class OperationRecordGrid extends Component {

    // 有效的列
    // validColumns = [
    //         {
    //             field: 'participant',
    //             headerName: "Participant",
    //             cellClass: 'non-number',
    //             width: 200,
    //         },
    //         {
    //             field: 'required',
    //             headerName: "Required",
    //             cellClass: 'non-number',
    //             width: 150,
    //         },
    //         {
    //             field: 'signin',
    //             headerName: "Sumitter*",
    //             cellClass: 'non-number',
    //             width: 150,
    //         },
    //         {
    //             field: 'note',
    //             headerName: "Remark",
    //             cellClass: 'non-number',
    //             width: 300,
    //         },
    //         {
    //             field: 'filepath',
    //             headerName: "FilePath",
    //             cellClass: 'non-number',
    //             width: 300,
    //             cellRenderer: this.sendHistoryAttachementCellRender
    //         },

    // ];
    constructor(props) {
        super(props);
        const that = this;
        this.state = {
            columnDefs: [
           
          {
                field: 'username',
                headerName: "Name",
                cellClass: 'non-number',
                width: 150,
            },
            {
                field: 'type',
                headerName: "Type",
                cellClass: 'non-number',
                width: 200,
            },
            {
                field: 'note',
                headerName: "Note",
                cellClass: 'non-number',
                width: 200,
            },
            {
                field: 'researchdepth',
                headerName: "ResearchDepth",
                cellClass: 'non-number',
                width: 200,
            },
           
            // {
            //     field: 'filepath',
            //     headerName: "Attachment",
            //     cellClass: 'non-number',
            //     width: 300,
            //     cellRenderer: this.sendHistoryAttachementCellRender
            // },
            {
                field: 'stockpitch',
                headerName: "StockPitch",
                cellClass: 'non-number',
                width: 100,
            },
            // {
            //     field: 'signin',
            //     headerName: "Sumitter*",
            //     cellClass: 'non-number',
            //     width: 100,
            // },
            // {
            //     field: 'feedbackComments',
            //     headerName: "Feedback",
            //     cellClass: 'non-number',
            //     width: 150,
            // },
            // {
            //     field: 'feedbackScore',
            //     headerName: "feedback Score",
            //     cellClass: 'non-number',
            //     width: 150,
            // },
           
        ],
        frameworkComponents: {
        },
        
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            rowSelection: "multiple",
            context: { componentParent: this },
            
      
            rowClassRules: {
                'MarkBackgroundRede': function(params) {
                    // if(params.data.status === 'New') {
                        return params.data.required === 'M' && params.data.signin ==='-';
                    // } alertFlag  limitFlag  1 表示出警

                },
                
                
            },
            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:[
                        {field: 'id', headerName: 'id', cellClass: 'non-number', width: 120 , cellRenderer: 'agGroupCellRenderer', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
                        // {field: 'title', headerName: 'title', cellClass: 'non-number', width: 150},
                        {
                            field: 'starttime',
                            headerName: "Strart Time*",
                            cellClass: 'non-number',
                            width: 200,
                        },

                        {
                            field: 'confirmRNumReat',
                            headerName: "Mandatory Participation Rate",
                            cellClass: 'non-number',
                            width: 200,
                        },
                        {
                            field: 'confirmONumReat',
                            headerName: "Optional Participation",
                            cellClass: 'non-number',
                            width: 200,
                        },
                        // {field: 'starttime', headerName: 'Start Time', cellClass: 'non-number', width: 150},
                        // {field: 'required', headerName: 'required', cellClass: 'non-number', width: 100},
                        {field: 'meetingID', headerName: 'id', cellClass: 'non-number', width: 120,hide:true},
                        {headerName: 'action', cellClass: 'non-number', width: 100, cellRenderer: (node) => this.finishTaskOpenModalBtn(node, props)},
                        
                    ],
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                    onSelectionChanged(event) {
                        let rowNodeArray = event.api.getSelectedNodes();
                        let cptRcdDataArray = rowNodeArray.map(rowNode => rowNode.data);
                        that.props.selectMeetingAndTrainingCalenderInfo(cptRcdDataArray);
                    },

                },
                getDetailRowData: function(params) {
                    params.successCallback(params.data.detialList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">  Detail</div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                '</div>'
            },

            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
        };
    }
    
    sendHistoryAttachementCellRender = (node) => {
        const attachement = node.data.filepath;
        if(!attachement) {
            return '';
        }
        const attArray = attachement.split(';');
        const retElement = document.createElement('div');
        let first = true;
        attArray.forEach(e => {
            if(!first) {
                retElement.append(' | ');
            }
            first = false;

            const url = this.props.meetingAndTrainingCalenderDownloadUrl + '/' + e;
            const link = document.createElement('a');
            link.innerText = e;
            link.target = '_blank';
            link.href = url;
            retElement.appendChild(link);

        });
        return retElement;
    }

    onRowClicked = (event) => {
        const selectedRcd = event.data;
        this.props.doOperationRecordAction({action:'SHOW_DETAIL', detail: selectedRcd});
    }


    render() {
        const {
            queryOperationRecordStatus,
            queryOperationRecordResp
        } = this.props.operationRecord;

        const isLoading = queryOperationRecordStatus === ACTION_STATUS.LOGINING;
        const operationRecordArray = !!queryOperationRecordResp && !!queryOperationRecordResp.data ? queryOperationRecordResp.data : [];

        // return (
        //     <div className={'ag-theme-balham grid-wrapper'}>
        //         {isLoading ? (
        //                 <Loader active content="Loading..." />
        //             ) : (
        //                 <AgGridReact
        //                     // properties
        //                     columnDefs={this.state.columnDefs}
        //                     rowData={operationRecordArray}
        //                     defaultColDef={this.state.defaultColDef}
        //                     suppressRowClickSelection={true}
        //                     suppressAggFuncInHeader={true}
        //                     enableSorting={true}
        //                     enableFilter={true}
        //                     animateRows={true}
        //                     deltaRowDataMode={false}
        //                     enableColResize={true}
        //                     enableRangeSelection={true}
        //                     rowSelection={'single'}
        //                     getRowNodeId={this.getRowNodeId}
        //                     context={this.state.context}
        //                     // EVENT
        //                     onRowClicked={this.onRowClicked}
        //                 />
        //             )}
        //     </div>
        // );



        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {/* {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : ( */}
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={operationRecordArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            suppressRowClickSelection={true}
                            rowClassRules={this.state.rowClassRules}
                            frameworkComponents={this.state.frameworkComponents}
                            
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            masterDetail={true}
                            sideBar={true}
                            resizable={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            // event
                            onGridReady={this.onGridReady}
                            onSelectionChanged={this.onDataSelectionChanged}

                            onRowClicked={this.onRowClicked}
                        />
                    {/* )} */}
            </div>
        );
    }
}

export default OperationRecordGrid;
