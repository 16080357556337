import React, {Component} from 'react';

import { Button, message, Modal } from 'antd';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import GiftHospitalityRequestGrid from './GiftHospitalityRequestGrid';
import GiftHospitalityRequestEditDialog from './GiftHospitalityRequestEditDialog';

import './GiftHospitalityRequest.css';

const confirm = Modal.confirm;

class GiftHospitalityRequestDashboard extends Component {

  componentDidMount() {
    this.props.loadGiftHospitalityRequest();
  }

  componentDidUpdate() {
    const {
      batchDeleteGiftHospitalityRequestsActionStatus,
      submitGiftHospitalityRequestActionStatus,
    } = this.props;

    // Submit Gift Hospitality Request 成功
    if (submitGiftHospitalityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Gift Hospitality Request are submitted successfully!');
      this.props.loadGiftHospitalityRequest();
      this.props.initSubmitGiftHospitalityRequestActionStatus();
    }
      
    // Submit Gift Hospitality Request 失败
    if (submitGiftHospitalityRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error('Gift Hospitality Request are submitted failed!');
      this.props.initSubmitGiftHospitalityRequestActionStatus();
    }

    // Batch Delete Gift Hospitality Request 成功
    if (batchDeleteGiftHospitalityRequestsActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Gift Hospitality Request are deleted successfully!');
      this.props.loadGiftHospitalityRequest();
      this.props.initBatchDeleteGiftHospitalityRequestActionStatus();
    }
      
    // Batch Delete Gift Hospitality Request 失败
    if (batchDeleteGiftHospitalityRequestsActionStatus === ACTION_STATUS.ERROR) {
      message.error('Gift Hospitality Request are deleted failed!');
      this.props.initBatchDeleteGiftHospitalityRequestActionStatus();
    }
  }

  addButtonOnClicked = () => {
    this.props.openGiftHospitalityRequestEditModalDialog(true, null);
  }

  editButtonOnClicked = () => {
    const {
      selectedGiftHospitalityRequestArray
    } = this.props;

    if (selectedGiftHospitalityRequestArray.length !== 1) {
      message.warning("Please select one gift hospitality request!");
      return;
    }

    const filterGiftHospitalityArray = selectedGiftHospitalityRequestArray.filter(giftHospitality => giftHospitality.requestStatus === 'SUBMIT');

    if (filterGiftHospitalityArray.length > 0) {
      message.warning("You cannot edit the submitted gift hospitality request!");
      return;
    } 

    this.props.openGiftHospitalityRequestEditModalDialog(false, selectedGiftHospitalityRequestArray[0]);
  }

  deleteButtonOnClicked = () => {
    const {
      selectedGiftHospitalityRequestArray
    } = this.props;

    if (selectedGiftHospitalityRequestArray.length === 0) {
      message.warning("Please select one gift hospitality request at least!");
      return;
    } 

    const filterGiftHospitalityArray = selectedGiftHospitalityRequestArray.filter(giftHospitality => giftHospitality.requestStatus === 'SUBMIT');

    if (filterGiftHospitalityArray.length > 0) {
      message.warning("You cannot delete the submitted gift hospitality request!");
      return;
    } 

    let that = this;
    confirm({
      title: 'Are you sure you want to delete the gift hospitality request?',
      content: '',
      onOk() {
        const giftHospitalityRequestIdList = selectedGiftHospitalityRequestArray.map(giftHospitalityRequest => giftHospitalityRequest.id);

        that.props.batchDeleteGiftHospitalityRequest(giftHospitalityRequestIdList);
      },
      onCancel() {},
    });
  }

  submitButtonOnClicked = () => {
    const {
      selectedGiftHospitalityRequestArray
    } = this.props;

    if (selectedGiftHospitalityRequestArray.length === 0) {
      message.warning("Please select one gift hospitality request at least!");
      return;
    }

    const filterGiftHospitalityArray = selectedGiftHospitalityRequestArray.filter(giftHospitality => giftHospitality.requestStatus === 'SUBMIT');
    if (filterGiftHospitalityArray.length > 0) {
      message.warning("You cannot resubmit the submitted gift hospitality request!");
      return;
    } 

    let that = this;
    confirm({
      title: 'Are you sure you want to submit the gift hospitality request?',
      content: '',
      onOk() {
        const giftHospitalityIdList = selectedGiftHospitalityRequestArray.map(giftHospitality => giftHospitality.id);

        that.props.submitGiftHospitalityRequest(giftHospitalityIdList);
      },
      onCancel() {},
    });
  }

  refreshButtonOnClicked = () => {
    this.props.loadGiftHospitalityRequest();
  }
  
  render() {
    const {
      loadGiftHospitalityRequestActionStatus,
      batchDeleteGiftHospitalityRequestsActionStatus,
      submitGiftHospitalityRequestActionStatus,
    } = this.props;

    const loadBtnIsLoading = loadGiftHospitalityRequestActionStatus === ACTION_STATUS.LOGINING;
    const submitBtnIsLoading = submitGiftHospitalityRequestActionStatus === ACTION_STATUS.LOGINING;
    const deleteBtnIsLoading = batchDeleteGiftHospitalityRequestsActionStatus === ACTION_STATUS.LOGINING;

    return (
      <div className="tradeDashboardWrapper">
        
        <div className='tradeOperationBar'>
          <Button 
            onClick={this.addButtonOnClicked} 
            style={{ marginLeft: 10 }}
            size='small'
            className='positive-button'>
            Add
          </Button>

          <Button 
            onClick={this.editButtonOnClicked} 
            size='small'
            type='primary'>
            Edit
          </Button>

          <Button 
            onClick={this.deleteButtonOnClicked} 
            size='small'
            loading={deleteBtnIsLoading}
            className='negative-button'>
            Del
          </Button>

          <Button 
            onClick={this.submitButtonOnClicked} 
            size='small'
            loading={submitBtnIsLoading}
            type='primary'>
            Submit
          </Button>

          <Button 
            onClick={this.refreshButtonOnClicked} 
            size='small'
            loading={loadBtnIsLoading}
            type='primary'>
            Refresh
          </Button>
        </div>

        <div className='tradeGrid'>
          <GiftHospitalityRequestGrid {...this.props} />
        </div>

        <GiftHospitalityRequestEditDialog {...this.props} />

      </div>
    );
  }
}

export default GiftHospitalityRequestDashboard;