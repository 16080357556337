import {connect} from 'react-redux';
import AccountHoldingDashboard from '../components/AccountHoldingDashboard';
import {
    doHistoryUploadAction
  } from '../../common/historyUpload/HistoryUploadActions';

import {
    // select data
    selectPersonalAccountList,
    setGridEventOnState,
    // Load Personal Account
    loadPersonalAccount,
    initLoadPersonalAccountActionStatus,
    loadAccountOptionsForAddOrEdit,
    // add or edit account
    openAddOrEditAccountDialog,
    resetAddOrEditAccountDialog,
    submitAddOrEditAccount,
    submitAddOrEditAccountFailure,
    initSubmitAddOrEditAccountActionStatus,
    onAccountInputChange,
    // submit account
    submitPersonalAccount,
    initSubmitPersonalAccountActionStatus,
    // delete account
    deletePersonalAccount,
    initDeletePersonalAccountActionStatus,

    // add or edit holding
    openAddOrEditHoldingDialog,
    resetAddOrEditHoldingDialog,
    submitAddOrEditHolding,
    initSubmitAddOrEditHoldingActionStatus,
    // bbgTicker
    loadHoldingBbgTickers,
    initLoadHoldingBbgTickersActionStatus,
    clearHoldingBbgTickers,
    holdingBbgTickerSelectChanged,
    // del holding
    openDelHoldingDialog,
    resetDelHoldingDialog,
    delPersonalHolding,
    initDelPersonalHoldingActionStatus,
    selectPersonalHoldingList,
    setDetailGridEventOnState,
    onAddOrEditHoldingInputChange,
    onDelHoldingInputChange,

    onQueryInputChange,
} from '../AccountHoldingActions';


const mapStateToProps = state => {
    return {
        // basic data
        relationshipArray: state.personalAccount.relationshipArray,
        relationshipOptions: state.personalAccount.relationshipOptions,
        personalAccountDownloadUrl: state.personalAccount.personalAccountDownloadUrl,
        personalAccountUploadUrl: state.personalAccount.personalAccountUploadUrl,
        // select data
        selectedPersonalAccountList: state.personalAccount.selectedPersonalAccountList,
        dataGridEvent: state.personalAccount.dataGridEvent,
        // Load Personal Account
        loadPersonalAccountActionStatus: state.personalAccount.loadPersonalAccountActionStatus,
        personalAccountArray: state.personalAccount.personalAccountArray,
        loadPersonalAccountResp: state.personalAccount.loadPersonalAccountResp,
        accountNameOptions: state.personalAccount.accountNameOptions, 
        brokerAccountOptions: state.personalAccount.brokerAccountOptions,
        accountNoOptions: state.personalAccount.accountNoOptions,
        // add or edit account
        addOrEditPersonalAccountModal: state.personalAccount.addOrEditPersonalAccountModal,
        submitAddOrEditAccountActionStatus: state.personalAccount.submitAddOrEditAccountActionStatus,
        // submit account
        submitPersonalAccountActionStatus: state.personalAccount.submitPersonalAccountActionStatus,
        submitPersonalAccountResp: state.personalAccount.submitPersonalAccountResp,
        // del account
        deletePersonalAccountActionStatus: state.personalAccount.deletePersonalAccountActionStatus,
        deletePersonalAccountResp: state.personalAccount.deletePersonalAccountResp,
        historyUpload: state.historyUpload,

         // add or edit holding
        addOrEditPersonalHoldingModal: state.personalAccount.addOrEditPersonalHoldingModal,
        submitAddOrEditHoldingActionStatus: state.personalAccount.submitAddOrEditHoldingActionStatus,
        // bbgTicker
        securityTickerArray: state.personalAccount.securityTickerArray,
        loadBbgTickersActionStatus: state.personalAccount.loadBbgTickersActionStatus,
        bbgTickerArray: state.personalAccount.bbgTickerArray,
        // del holding
        delPersonalHoldingModal: state.personalAccount.delPersonalHoldingModal,
        delPersonalHoldingActionStatus: state.personalAccount.delPersonalHoldingActionStatus,
        selectedPersonalHoldingList: state.personalAccount.selectedPersonalHoldingList,
        
        // change input
      personalAccountQueryFields:  state.personalAccount.personalAccountQueryFields,
    };
}


const mapDispatchToProps = dispatch => {
    return {
        // select data
        selectPersonalAccountList: (list) => dispatch(selectPersonalAccountList(list)),
        setGridEventOnState: (event) => dispatch(setGridEventOnState(event)),
        // Load Personal Account
        loadPersonalAccount: (param) => dispatch(loadPersonalAccount(param)),
        initLoadPersonalAccountActionStatus: () => dispatch(initLoadPersonalAccountActionStatus()),
        loadAccountOptionsForAddOrEdit: (employee) => dispatch(loadAccountOptionsForAddOrEdit(employee)),
        // add or edit account
        openAddOrEditAccountDialog: (mode, account) => dispatch(openAddOrEditAccountDialog(mode, account)),
        resetAddOrEditAccountDialog: () => dispatch(resetAddOrEditAccountDialog()),
        submitAddOrEditAccount: (account) => dispatch(submitAddOrEditAccount(account)),
        submitAddOrEditAccountFailure: (err) => dispatch(submitAddOrEditAccountFailure(err)),
        initSubmitAddOrEditAccountActionStatus: () => dispatch(initSubmitAddOrEditAccountActionStatus()),
        onAccountInputChange:(param) => dispatch(onAccountInputChange(param)),
        // submit account
        submitPersonalAccount: (account) => dispatch(submitPersonalAccount(account)),
        initSubmitPersonalAccountActionStatus: () => dispatch(initSubmitPersonalAccountActionStatus()),
        // delete account
        deletePersonalAccount: (account) => dispatch(deletePersonalAccount(account)),
        initDeletePersonalAccountActionStatus: () => dispatch(initDeletePersonalAccountActionStatus()),
        doHistoryUploadAction: (params) => dispatch(doHistoryUploadAction(params)),

        // add or edit holding
        openAddOrEditHoldingDialog: (mode, holding) => dispatch(openAddOrEditHoldingDialog(mode, holding)),
        resetAddOrEditHoldingDialog: () => dispatch(resetAddOrEditHoldingDialog()),
        submitAddOrEditHolding: (holding) => dispatch(submitAddOrEditHolding(holding)),
        initSubmitAddOrEditHoldingActionStatus: () => dispatch(initSubmitAddOrEditHoldingActionStatus()),
        // bbgTicker
        loadHoldingBbgTickers: (bbgTicker) => dispatch(loadHoldingBbgTickers(bbgTicker)),
        initLoadHoldingBbgTickersActionStatus: () => dispatch(initLoadHoldingBbgTickersActionStatus()),
        clearHoldingBbgTickers: () => dispatch(clearHoldingBbgTickers()),
        holdingBbgTickerSelectChanged: (bbgTicker) => dispatch(holdingBbgTickerSelectChanged(bbgTicker)),
        // del holding
        openDelHoldingDialog: (idList) => dispatch(openDelHoldingDialog(idList)),
        resetDelHoldingDialog: () => dispatch(resetDelHoldingDialog()),
        delPersonalHolding: (param) => dispatch(delPersonalHolding(param)),
        initDelPersonalHoldingActionStatus: () => dispatch(initDelPersonalHoldingActionStatus()),
        selectPersonalHoldingList: (list) => dispatch(selectPersonalHoldingList(list)),
        setDetailGridEventOnState: (event) => dispatch(setDetailGridEventOnState(event)),
        onAddOrEditHoldingInputChange: (param) => dispatch(onAddOrEditHoldingInputChange(param)),
        onDelHoldingInputChange: (param) => dispatch(onDelHoldingInputChange(param)),
    
        // change input 
        onQueryInputChange: (param) => dispatch(onQueryInputChange(param)),
    };
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(AccountHoldingDashboard);
