import React, { Component } from 'react';
import { Redirect, Route, Switch, NavLink } from 'react-router-dom';

import TradeRequestContainer from '../../tradeRequest/containers/TradeRequestContainer';
import GiftHospitalityRequestContainer from '../../giftHospitalityRequest/containers/GiftHospitalityRequestContainer';
import RestrictedSecurityRequestContainer from '../../restrictedSecurityRequest/containers/RestrictedSecurityRequestContainer';
import { localStorageClient } from '../../../../utils/localStorageClient';
import { Layout, Menu, Icon, Button, Modal, Dropdown, Avatar } from 'antd';
const { Header, Content, Footer } = Layout;

class Main extends Component {
  state = {
    collapsed: false,
    pathname: '',
  };

  componentDidMount () {
    // Load Fund Book Data
    this.props.loadFundBookData();

    // Load Security Tickers
    this.props.loadSecurityTickers();

    // Load Asset Inst Class
    this.props.loadAssetInstClass();

    const pathname = window.location.pathname;
    this.setState({
      pathname
    });
  }

  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  handleVisibleChange = flag => {
    this.setState({ visible: flag });
  };

  logoutButtonOnClicked = () => {
    this.props.history.push({
      pathname: '/login',
    });
  }

  render() {
    const menu = (
      <Menu>
        <Menu.Item key="logout" onClick={this.logoutButtonOnClicked}>
          <Icon type="logout" />
          Logout
        </Menu.Item>
      </Menu>
    );

    const employee = localStorageClient.getUserName();
    const pathname = this.state.pathname;
    let selectedKey = '';

    if (pathname === '/main/personal-trade-request') {
      selectedKey = 'trade';
    } else if (pathname === '/main/gift-hospitality-request') {
      selectedKey = 'giftHospitality';
    } else if (pathname === '/main/restricted-security-request') {
      selectedKey = 'restrictedSecurity';
    }

    return (
      <div >
        <style>
          {`            
            .logo {
              height: 32px;
              background: rgba(255,255,255,.2);
              margin: 16px;
            }

            .ant-layout {
              min-height: 100vh;
            }

            .ant-layout-content {
              height: calc(100vh - 44px);
            }

            .ant-layout-header {
              height: 44px;
              padding: 0px;
              display: flex;
              justify-content: space-between;
            }
          `}
        </style>
        <Layout>
          <Header>
            <Menu
              theme="dark"
              mode="horizontal"
              selectedKeys={[selectedKey]}
              style={{ lineHeight: '44px', marginLeft: '20px' }}
            >
              {
                pathname === '/main/personal-trade-request' && 
                <Menu.Item key="trade">
                  <NavLink to="/main/personal-trade-request">PA Dealing</NavLink>
                </Menu.Item>
              }
              {
                pathname === '/main/gift-hospitality-request' && 
                <Menu.Item key="giftHospitality">
                  <NavLink to="/main/gift-hospitality-request">Gift/Hospitality</NavLink>
                </Menu.Item>
              }
              {
                pathname === '/main/restricted-security-request' && 
                <Menu.Item key="restrictedSecurity">
                  <NavLink to="/main/restricted-security-request">Restricted Security</NavLink>
                </Menu.Item>
              }
            </Menu>
            <div style={{ lineHeight: '44px' }}>
              <Dropdown
                overlay={menu}
                onVisibleChange={this.handleVisibleChange}
                visible={this.state.visible}
              >
                <span>
                  <Avatar
                    size="small"
                    alt="avatar"
                    style={{ marginRight: 10 }}
                  />
                  <span style={{ color: '#fff', marginRight: 30 }}>{ employee }</span>
                </span>
              </Dropdown>
            </div>
          </Header>
          <Content>
            <Switch>
              <Route path="/main/personal-trade-request" component={TradeRequestContainer} />
              <Route path="/main/gift-hospitality-request" component={GiftHospitalityRequestContainer} />
              <Route path="/main/restricted-security-request" component={RestrictedSecurityRequestContainer} />
            </Switch>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default Main;