
const RESTRICTION_TYPE_CODE = {
  HARD_RESTRICTION: 'Hard Restriction',
  SOFT_RESTRICTION: 'Soft Restriction',
};

const RESTRICTION_TYPE_LABEL = {
  HARD_RESTRICTION: 'Hard Restriction',
  SOFT_RESTRICTION: 'Soft Restriction',
};

export const RESTRICTION_TYPE_MAP = [
  // Hard Restriction
  {
    code: RESTRICTION_TYPE_CODE.HARD_RESTRICTION,
    label: RESTRICTION_TYPE_LABEL.HARD_RESTRICTION
  },
  // Soft Restriction
  {
    code: RESTRICTION_TYPE_CODE.SOFT_RESTRICTION,
    label: RESTRICTION_TYPE_LABEL.SOFT_RESTRICTION
  },
];