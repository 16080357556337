import Cookies from 'js-cookie';
const useLocalStorage = typeof localStorage !== 'undefined';

const get = key => {
  if (useLocalStorage) {
    const item = localStorage.getItem(key);
    try {
      return JSON.parse(item);
    } catch (err) {
      return item;
    }
  }
};

const save = (key, value) => {
  if (useLocalStorage) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

const remove = key => {
  if (useLocalStorage) {
    localStorage.removeItem(key);
  }
};

const getUt = key => {
  let item;
  item = getCookie('ut');

  if (item) {
    return item;
  }
  if (useLocalStorage) {
    item = localStorage.getItem(key);
    try {
      return JSON.parse(item);
    } catch (err) {
      return item;
    }
  }
};

const saveUt = (key, value) => {
  if (useLocalStorage) {
    localStorage.setItem(key, JSON.stringify(value));
  }
  let hostName = window.location.hostname;
  if (hostName) {
    hostName = hostName.replace('imsqa', '');
    hostName = hostName.replace('ims', '');
    setCookie('ut', value, hostName);
  }
};

const removeUt = key => {
  if (useLocalStorage) {
    localStorage.removeItem(key);
  }
  let hostName = window.location.hostname;
  if (hostName) {
    hostName = hostName.replace('imsqa', '');
    hostName = hostName.replace('ims', '');
    removeCookie('ut', '', hostName);
  }
};

// 获取cookie
const getCookie = key => {
  const name = key + '=';
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i].trim();
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

const setCookie = (key, value, domain) => {
  document.cookie = `${key}=${value};domain=${domain}`;
};

const removeCookie = (key, value, domain) => {
  const d = new Date();
  d.setTime(d.getTime() - 1);
  document.cookie = `${key}=${value};domain=${domain};expires=${d.toGMTString()}`;
};
const isLoggedIn = isLoggedIn =>  {
  return !! Cookies.get('cmsUserId');;
}

const getUserName = getUserName => {
    let userName = Cookies.get('cmsUserName');
    if(!!userName) {
      userName = userName.replace(/[+]/g, ' ');
    }
    return userName;
  }

const getUserId = getUserId => {
    return Cookies.get('cmsUserId');
  }
const getToker =   getToken => {
    return Cookies.get('ut');
  }

const getRole =   getRole => {
    return Cookies.get('cmsUserRole');
  }

export const localStorageClient = {
  get,
  save,
  remove,
  getUt,
  saveUt,
  removeUt,
  isLoggedIn,
  getUserName,
  getUserId,
  getToker,
  getRole
};

