import { combineReducers } from 'redux';

import homeReducer from '../../views/features/home/homeReducer';
import authReducer from '../../views/features/auth/authReducer';
import tradeRequestReducer from '../../views/features/tradeRequest/tradeRequestReducer';
import personalAccountReducer from '../../views/features/personalAccountHolding/AccountHoldingReducer';
import giftHospitalityRequestReducer from '../../views/features/giftHospitalityRequest/giftHospitalityRequestReducer';
import restrictedSecurityRequestReducer from '../../views/features/restrictedSecurityRequest/restrictedSecurityRequestReducer';
import QuestionnaireRequesstReducer from '../../views/features/questionnaireRequest/QuestionnaireRequestReducer';
import LicensingReducer from '../../views/features/licensing/LicensingReducer';
import GeneralAgreementReducer from '../../views/features/agreement/GeneralAgreementReducer';
import HistoryUploadReducer from '../../views/features/common/historyUpload/HistoryUploadReducer';
import OperationRecordReducer from '../../views/features/common/historyUpload/operationRcd/OperationRecordReducer';
import MeetingAndTrainingReducer from '../../views/features/meetingAndTraining/MeetingAndTrainingReducer';
import CollegeApplicationReducer from '../../views/features/college-application/CollegeApplicationReducer';
import meetingAndTrainingCalenderReducer from '../../views/features/meetingAndTrainingCalender/MeetingAndTrainingCalenderReducer';
import ExpenseSummaryNewReducer from '../../views/features/expense-summary-new/ExpenseSummaryNewReducer';
import ReportingSummaryRecuder from '../../views/features/reporting-summary/ReportingSummaryReducer';
import WorkManagementReducer from '../../views/features/work-management/WorkManagementReducer';
import ApplyReconReducer from '../../views/features/apply-recon/ApplyReconReducer';
const rootReducer = combineReducers({
  home: homeReducer,
  auth: authReducer,
  tradeRequest: tradeRequestReducer,
  personalAccount: personalAccountReducer,
  giftHospitalityRequest: giftHospitalityRequestReducer,
  restrictedSecurityRequest: restrictedSecurityRequestReducer,
  questionnaire: QuestionnaireRequesstReducer,
  licensing: LicensingReducer,
  generalAgreement: GeneralAgreementReducer,
  historyUpload: HistoryUploadReducer,
    // common
  operationRecord: OperationRecordReducer,
  meetingandtraining: MeetingAndTrainingReducer,
  collegeApplication: CollegeApplicationReducer,
  meetingAndTrainingCalender: meetingAndTrainingCalenderReducer,
  expenseSummaryNew: ExpenseSummaryNewReducer,

  reportingSummary: ReportingSummaryRecuder,
  workManagement: WorkManagementReducer,
  applyRecon: ApplyReconReducer,
});

export default rootReducer;