
const RESP_CODES = {
  // 请求成功
  SUCCESS: 'S0001',

  // 未授权
  UNAUTHORIZED: 'F401',

  // 禁止访问
  FORBIDDEN_ACCESS: 'F403',
  // 未找到页面
  NOT_FOUND: 'F404',
  // 请求频繁
  OFTEN_REQUEST: 'F429',
  // 服务器错误
  SERVER_ERROR: 'F500',
  // 网络错误
  NETWORK_EXCEPTION: 'F9997',
  // 服务接口异常
  SERVICE_EXCEPTION: 'F9998',
  // 系统异常
  SYSTEM_EXCEPTION: 'F9999',
};

const RESP_MSG = {
  SVRER_ERROR: '服务器异常',
  NETWORK_EXCEPTION: '网络异常',
  SERVICE_EXCEPTION: '服务异常',
  SYSTEM_EXCEPTION: '系统异常'
};

function formatErrResp(code, msg) {
  return {
    respCode: code,
    respMessage: msg
  };
}

module.exports = {
  RESP_CODES,
  RESP_MSG,
  formatErrResp
};
