// query
export const START_LOAD_MEETING_AND_TRAINING_INFO = 'START_LOAD_MEETING_AND_TRAINING_INFO';
export const LOAD_MEETING_AND_TRAINING_INFO_SUCCESS = 'LOAD_MEETING_AND_TRAINING_INFO_SUCCESS';
export const LOAD_MEETING_AND_TRAINING_INFO_FAILURE = 'LOAD_MEETING_AND_TRAINING_INFO_FAILURE';
export const INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS = 'INIT_LOAD_MEETING_AND_TRAINING_INFO_ACTION_STATUS';
// select
export const SELECT_MEETING_AND_TRAINING = 'SELECT_MEETING_AND_TRAINING';
export const SET_GRID_EVENT_ON_STATE = 'SET_GRID_EVENT_ON_STATE';
// add or edit
export const OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL = 'OPEN_ADD_OR_EDIT_MEETING_AND_TRAINING_MODAL';
export const RESET_SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL = 'RESET_SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL';
export const ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT = 'ADD_OR_EDIT_MEETING_AND_TRAINING_CHANGE_INPUT';
export const START_SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL = 'START_SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL';
export const SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL_SUCCESS = 'SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL_SUCCESS';
export const SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL_FAILURE = 'SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL_FAILURE';
export const INIT_SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL_ACTION_STATUS = 'INIT_SUBMIT_SAVE_MEETING_AND_TRAINING_MODAL_ACTION_STATUS';
// change status
export const OPEN_CHANGE_MEETING_AND_TRAINING_STATUS_MODAL = 'OPEN_CHANGE_MEETING_AND_TRAINING_STATUS_MODAL';
export const RESET_CHANGE_MEETING_AND_TRAINING_STATUS_MODAL = 'RESET_CHANGE_MEETING_AND_TRAINING_STATUS_MODAL';
export const CHANGE_MEETING_AND_TRAINING_STATUS_CHANGE_INPUT = 'CHANGE_MEETING_AND_TRAINING_STATUS_CHANGE_INPUT';
export const START_SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS = 'START_SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS';
export const SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS_SUCCESS = 'SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS_SUCCESS';
export const SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS_FAILURE = 'SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS_FAILURE';
export const INIT_SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS_ACTION_STATUS = 'INIT_SUBMIT_CHANGE_MEETING_AND_TRAINING_STATUS_ACTION_STATUS';
// showAllshowValid
export const SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED = 'SHOW_ALL_SHOW_VALID_BTN_ON_CLICKED';
