import React, { Component } from 'react';
// import { AgGridReact } from "@ag-grid-community/react";
import { AgGridReact } from "ag-grid-react";
import { Loader } from 'semantic-ui-react';
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import moment from 'moment';
import JsonTooltipComponent from '../../../../utils/JsonTooltipComponent';
import StateSynchronizer from '../../../../utils/StateSynchronizer';
class ReportingSummaryGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                {field: 'id', headerName: "dataID", cellClass: 'non-number', width: 120, cellRenderer: 'agGroupCellRenderer', headerCheckboxSelection: true, headerCheckboxSelectionFilteredOnly: true, checkboxSelection: true},
                {field: 'status', headerName: 'status', cellClass: 'non-number', width: 100, cellStyle: this.dataStatusColorCellStyle},
                {field: 'location', headerName: 'Location', cellClass: 'non-number', width: 100},
                {field: 'fundCompany', headerName: 'Fund/Co.', cellClass: 'non-number', width: 100},
                {field: 'description', headerName: 'Description', cellClass: 'non-number', width: 200,cellRenderer: 'jsonTooltipComponent'},
                {field: 'via', headerName: 'System/Portal', cellClass: 'non-number', width: 150,cellRenderer: 'jsonTooltipComponent'},
                {field: 'frequency', headerName: 'Recurring', cellClass: 'non-number', width: 100},
                {field: 'deadlineLabel', headerName: 'Deadline/End Date', cellClass: 'non-number', width: 100},
                {field: 'notificationLabel', headerName: 'Notification', cellClass: 'non-number', width: 100},
                {field: 'regulator', headerName: 'Regulator/Authority/Dept', cellClass: 'non-number', width: 120},
                {field: 'responsibleDept', headerName: 'Responsible Dept', cellClass: 'non-number', width: 120},
                {field: 'emailTo', headerName: 'Person in Charge', cellClass: 'non-number', width: 150},
                // {field: 'emailCC', headerName: 'emailCC', cellClass: 'non-number', width: 150},
                {field: 'regulation', headerName: 'Regulation Source', cellClass: 'non-number', width: 150,cellRenderer: 'jsonTooltipComponent'},
                {field: 'remarks', headerName: 'Remarks', cellClass: 'non-number', width: 200,cellRenderer: 'jsonTooltipComponent'},
                {field: 'createdBy', headerName: 'createdBy', cellClass: 'non-number', width: 120},
                {field: 'createdTime', headerName: 'createdTime', cellClass: 'non-number', width: 150},
                {field: 'modifiedBy', headerName: 'modifiedBy', cellClass: 'non-number', width: 120},
                {field: 'modifiedTime', headerName: 'modifiedTime', cellClass: 'non-number', width: 150},
            ],
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 50
            },
            frameworkComponents: {
                jsonTooltipComponent: JsonTooltipComponent,
                // tagComponent: TagComponent
              },
            rowSelection: "multiple",
            context: { componentParent: this },
            rowClassRules: {
                'MarkBackgroundRed': function(params) {
                    const today = moment().format('YYYY-MM-DD');
                    const taskList = params.data.reportingSummaryTaskList;
                    const redTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline < today);
                    return redTaskList.length > 0;
                },
                'MarkBackgroundGreen': function(params) {
                    const today = moment().format('YYYY-MM-DD');
                    const taskList = params.data.reportingSummaryTaskList;
                    const redTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline < today);
                    if(redTaskList.length > 0) {
                        return false;
                    }
                    const greedTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline >= today);
                    return greedTaskList.length > 0;
                },
                'MarkBackgroundGray': function(params) {
                    const today = moment().format('YYYY-MM-DD');
                    const taskList = params.data.reportingSummaryTaskList;
                    const redTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline < today);
                    if(redTaskList.length > 0) {
                        return false;
                    }
                    const greedTaskList = taskList.filter(e => e.status === 'Pending' && e.deadline >= today);
                    if(greedTaskList.length > 0) {
                        return false;
                    }
                    return params.data.status === 'Paused';
                },
            },

            detailCellRendererParams: {
                detailGridOptions: {
                    columnDefs:[
                        {field: 'deadline', headerName: 'Deadline/End Date', cellClass: 'non-number', width: 150},
                        {field: 'notifyDate', headerName: 'Notifcation Date', cellClass: 'non-number', width: 120},
                        {field: 'status', headerName: 'status', cellClass: 'non-number', width: 100, cellStyle: this.dataStatusColorCellStyle},
                        {field: 'remarks', headerName: 'Remarks', cellClass: 'non-number', width: 250},
                        {field: 'createdTime', headerName: 'createdTime', cellClass: 'non-number', width: 150},
                        {field: 'modifiedBy', headerName: 'modifiedBy', cellClass: 'non-number', width: 120},
                        {field: 'modifiedTime', headerName: 'modifiedTime', cellClass: 'non-number', width: 150},
                        {headerName: 'action', cellClass: 'non-number', width: 100, cellRenderer: (node) => this.finishTaskOpenModalBtn(node, props)},
                    ],
                    suppressRowClickSelection: true,
                    suppressAggFuncInHeader: true,
                    enableColResize: true,
                    enableSorting: true,
                    enableFilter: true,
                    animateRows: true,
                    deltaRowDataMode: false,
                    onSelectionChanged(event) {
                    },
                    rowClassRules:{
                        'MarkBackgroundRed': function(params) {
                            const today = moment().format('YYYY-MM-DD');
                            return params.data.status === 'Pending' && params.data.deadline < today;
                        },
                        'MarkBackgroundGreen': function(params) {
                            const today = moment().format('YYYY-MM-DD');
                            return params.data.status === 'Pending' && params.data.deadline >= today;
                        },
                    },
                },
                getDetailRowData: function(params) {
                    params.successCallback(params.data.reportingSummaryTaskList);
                },
                getRowNodeId: function(data) {
                    return data.id;
                },
                template:
                '<div style="height: 100%; background-color: #edf6ff; padding: 20px; box-sizing: border-box;">' +
                '  <div style="height: 10%; margin-bottom: 10px; font-weight: bold;">Reporting Summary Task</div>' +
                '  <div ref="eDetailGrid" style="height: 90%;"></div>' +
                '</div>'
            },

            statusBar: {
                statusPanels: [
                  {
                    statusPanel: "agTotalAndFilteredRowCountComponent",
                    align: "left"
                  },
                  {
                    statusPanel: "agTotalRowCountComponent",
                    align: "center"
                  },
                  { statusPanel: "agFilteredRowCountComponent" },
                  { statusPanel: "agSelectedRowCountComponent" },
                  { statusPanel: "agAggregationComponent" }
                ]
            },
        }
    }


    dataStatusColorCellStyle(param) {
        const status = param.value;
        if(status === 'Working' || status === 'Finished') {
            return {color: 'green'};
        }
        return {color: 'black'};
    }


    finishTaskOpenModalBtn = (node, props) => {
        const retDiv = document.createElement('div');
        const data = node.data;
        if(data.status !== 'Pending') {
            return retDiv;
        }
        const finishTaskBtn = document.createElement('button');
        const finishTaskText = document.createTextNode("FinishTask");
        finishTaskBtn.appendChild(finishTaskText);
        finishTaskBtn.onclick = () => {
            props.openFinishTaskModal(data);
        }
        retDiv.appendChild(finishTaskBtn);
        return retDiv;
    }


    getRowNodeId = data => {
        return data.id;
    };

    onDataSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let reportingSummaryArray = rowNodeArray.map(rowNode => rowNode.data);
        this.props.selectReportingSummary(reportingSummaryArray);
    }
    onGridReady = params => {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    
        const COLUMNS_KEY = 'reporting-summary-list-grid-col-state';
    
        StateSynchronizer.syncGrid(params, this.state.columnDefs, COLUMNS_KEY);
      };

    render() {
        const {
            loadReportingSummaryInfoActionStatus,
            loadReportingSummaryInfoResp,
        } = this.props;

        const isLoading = loadReportingSummaryInfoActionStatus === ACTION_STATUS.LOGINING;
        let loadReportingSummaryInfoArray = [];
        if(!!loadReportingSummaryInfoResp && !!loadReportingSummaryInfoResp.data) {
            loadReportingSummaryInfoArray = loadReportingSummaryInfoResp.data;
        }


        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                        <Loader active content="Loading..." />
                    ) : (
                        <AgGridReact
                            // properties
                            columnDefs={this.state.columnDefs}
                            rowData={loadReportingSummaryInfoArray}
                            defaultColDef={this.state.defaultColDef}
                            rowSelection={this.state.rowSelection}
                            rowClassRules={this.state.rowClassRules}
                            resizable={true}
                            sideBar={true}

                            suppressRowClickSelection={true}
                            suppressAggFuncInHeader={true}
                            enableSorting={true}
                            enableFilter={true}
                            animateRows={true}
                            deltaRowDataMode={false}
                            enableColResize={true}
                            enableRangeSelection={true}
                            getRowNodeId={this.getRowNodeId}
                            context={this.state.context}
                            statusBar={this.state.statusBar}
                            // master/detail
                            masterDetail={true}
                            detailCellRendererParams={this.state.detailCellRendererParams}
                            // event
                            onSelectionChanged={this.onDataSelectionChanged}
                            frameworkComponents={this.state.frameworkComponents}
                            onGridReady={this.onGridReady}
                        />
                    )}
            </div>
        );
    }

}

export default ReportingSummaryGrid;
