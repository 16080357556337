import {connect} from 'react-redux';
import Authentication from '../components/Authentication';

import {
  // Login
  loginByAccessToken,
  initLoginByAccessTokenStatus,
} from '../authActions';

const mapStateToProps = state => {
  return {
    // Login By Access Token
    loginByAccessTokenStatus: state.home.loginByAccessTokenStatus,
    loginByAccessTokenErrMsg: state.home.loginByAccessTokenErrMsg,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Login
    loginByAccessToken: (accessToken) => dispatch(loginByAccessToken(accessToken)),
    initLoginByAccessTokenStatus: () => dispatch(initLoginByAccessTokenStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Authentication);