import { connect } from 'react-redux';

import {
  // Load Fund Book Data
  loadFundBookData,

  // Load Security Tickers
  loadSecurityTickers,
    
  // Load Asset Inst Class
  loadAssetInstClass,

  // Login
  loginByAccessToken,
  initLoginByAccessTokenStatus,

} from '../homeActions';

import Main from '../components/Main';

function mapStateToProps(state) {
  return {
    // Fund Book Data
    fundBookData: state.home.fundBookData,

    // Login By Access Token
    loginByAccessTokenStatus: state.home.loginByAccessTokenStatus,
    loginByAccessTokenErrMsg: state.home.loginByAccessTokenErrMsg,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    // Load Fund Book Data
    loadFundBookData: () => dispatch(loadFundBookData()),

    // Load Security Tickers
    loadSecurityTickers: () => dispatch(loadSecurityTickers()),

    // Load Asset Inst Class
    loadAssetInstClass: () => dispatch(loadAssetInstClass()),

    // Login
    loginByAccessToken: (accessToken) => dispatch(loginByAccessToken(accessToken)),
    initLoginByAccessTokenStatus: () => dispatch(initLoginByAccessTokenStatus()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Main);
