import React from 'react';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import { localStorageClient } from '../../../utils/localStorageClient';

import {
  // Load Fund Book Data
  LOAD_FUND_BOOK_DATA_SUCCESS,
  LOAD_FUND_BOOK_DATA_FAILURE,

  // Load Security Tickers
  LOAD_SECURITY_TICKERS_SUCCESS,
  LOAD_SECURITY_TICKERS_FAILURE,

  // Load Asset Inst Class
  LOAD_ASSET_INST_CLASS_SUCCESS,
  LOAD_ASSET_INST_CLASS_FAILURE,

  // Login By Access Token
  START_LOGIN_BY_ACCESS_TOKEN,
  LOGIN_BY_ACCESS_TOKEN_SUCCESS,
  LOGIN_BY_ACCESS_TOKEN_FAILURE,
  INIT_LOGIN_BY_ACCESS_TOKEN_STATUS,

  // get hierarchy menus
  GET_HIERARCHY_MENUS_SUCCESS,
  GET_HIERARCHY_MENUS_FAILURE,
  LOAD_USER_ACCOUNTS_SUCCESS,
  LOAD_USER_ACCOUNTS_FAILURE,

} from './homeConstants';

import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';

const initialState = {
  // Fund Book Data
  fundBookData: null,

  // Security Tickers Data
  securityTickerData: null,

  // All Bbg Tickers
  allBbgTickers: [],

  // Load Asset Inst Class
  assetClassArray: [],
  instClassArray: [],

  // Login By Access Token
  loginByAccessTokenStatus: ACTION_STATUS.READY,
  loginByAccessTokenErrMsg: '',
    // userAccount
    userAccountArray: [],
}


// =================================================================
// Load Fund Book Data
function loadFundBookDataSuccess(state, resp) {
  return {
    ...state,
    fundBookData: resp.data,
  };
}

function loadFundBookDataFailure(state, err) {
  return {
    ...state,
  };
}

// =================================================================
// Load Security Tickers
function loadSecurityTickersSuccess(state, resp) {
  return {
    ...state,
    securityTickerData: resp.data,
  };
}

function loadSecurityTickersFailure(state, err) {
  return {
    ...state,
  };
}


// =================================================================
// Load Asset Inst Class
function loadAssetInstClassSuccess(state, resp) {
  const assetInstClass = resp.data;
  const assetClassArray = assetInstClass.assetClassList;
  const instClassArray = assetInstClass.instClassList;

  return {
    ...state,
    assetInstClass,
    assetClassArray,
    instClassArray,
  };
}

function loadAssetInstClassFailure(state, err) {
  return {
    ...state,
  };
}

// Login By Access Token
function startLoginByAccessToken(state) {
  return {
    ...state,
    loginByAccessTokenStatus: ACTION_STATUS.LOGINING,
  };
}

function loginByAccessTokenSuccess(state, resp) {
  return {
    ...state,
    loginByAccessTokenStatus: ACTION_STATUS.SUCCESS,
  };
}

function loginByAccessTokenFailure(state, err) {
  return {
    ...state,
    loginByAccessTokenStatus: ACTION_STATUS.ERROR,
    loginByAccessTokenErrMsg: err.respMessage,
  };
}

function initLoginByAccessTokenStatus(state) {
  return {
    ...state,
    loginByAccessTokenStatus: ACTION_STATUS.READY,
    loginByAccessTokenErrMsg: '',
  };
}



// get hierarchy menus
function getHierarchyMenusSuccess(state, resp) {
  const hierarchyMenus = resp.data.hierarchyMenus;
  const flatMenus = resp.data.flatMenus;
  const hierarchyMenuOptions = [];
  hierarchyMenus.forEach(m => {
    hierarchyMenuOptions.push(recursiveCreateMenu(m));
  })
  return {
    ...state,
    hierarchyMenus: hierarchyMenus,
    flatMenus: flatMenus,
    hierarchyMenuOptions: hierarchyMenuOptions,
  };
}
function getHierarchyMenusFailure(state, err) {
  return {
    ...state,
  };
}
// 工具方法，递归构造一个菜单
function recursiveCreateMenu(menu) {
  if(menu.type === 'Item') {
    // 检查当前用户是否有此菜单权限
    const userRoleStr = localStorageClient.getRole();
    const userRoleArray = userRoleStr.split(',');
    userRoleArray.push('CMS_REPORT_USER');  // 默认所有人都有CMS_REPORT_USER权限
    //const allowedRoleArray = menu.accessableRoleNames;
    let authorized = true;
    // for(let i in allowedRoleArray) {
    //   for(let j in userRoleArray) {
    //     if(allowedRoleArray[i] === userRoleArray[j]){
    //       authorized = true;
    //       break;
    //     }
    //   }
    //   if(authorized) {
    //     break;
    //   }
    // }
    // 返回
    return (
      <Menu.Item key={menu.permission.permissionId} disabled={!authorized}>
        <NavLink to={menu.module}>{menu.name}</NavLink>
      </Menu.Item>
    );

  } else {
    // 先生成下级结构
    const subMenuOptions = [];
    menu.directSubMenus.forEach(e => {
      subMenuOptions.push(recursiveCreateMenu(e));
    })
    return (
      <Menu.SubMenu key={menu.permission.permissionId} title={menu.name}>
        {subMenuOptions}
      </Menu.SubMenu>
    );
  }
}

function loadUserAccountsSuccess(state, resp) {
  return {
    ...state,
    userAccountArray: resp.data,
  };
}

function loadUserAccountsFailure(state, err) {
  return {
    ...state,
  };
}


export default createReducer(initialState, {
  // Load Fund Book Data
  [LOAD_FUND_BOOK_DATA_SUCCESS]: loadFundBookDataSuccess,
  [LOAD_FUND_BOOK_DATA_FAILURE]: loadFundBookDataFailure,

  // Load Security Tickers
  [LOAD_SECURITY_TICKERS_SUCCESS]: loadSecurityTickersSuccess,
  [LOAD_SECURITY_TICKERS_FAILURE]: loadSecurityTickersFailure,

  // Load Asset Inst Class
  [LOAD_ASSET_INST_CLASS_SUCCESS]: loadAssetInstClassSuccess,
  [LOAD_ASSET_INST_CLASS_FAILURE]: loadAssetInstClassFailure,

  // Login By Access Token
  [START_LOGIN_BY_ACCESS_TOKEN]: startLoginByAccessToken,
  [LOGIN_BY_ACCESS_TOKEN_SUCCESS]: loginByAccessTokenSuccess,
  [LOGIN_BY_ACCESS_TOKEN_FAILURE]: loginByAccessTokenFailure,
  [INIT_LOGIN_BY_ACCESS_TOKEN_STATUS]: initLoginByAccessTokenStatus,

  // get hierarchy menus
  [GET_HIERARCHY_MENUS_SUCCESS]: getHierarchyMenusSuccess,
  [GET_HIERARCHY_MENUS_FAILURE]: getHierarchyMenusFailure,

  // Load User Account
  [LOAD_USER_ACCOUNTS_SUCCESS]: loadUserAccountsSuccess,
  [LOAD_USER_ACCOUNTS_FAILURE]: loadUserAccountsFailure,
});
