import {
  // Login
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_LOGIN_STATUS,

  // Logout
  LOGOUT_SUCCESS,

  // Login By Access Token
  START_LOGIN_BY_ACCESS_TOKEN,
  LOGIN_BY_ACCESS_TOKEN_SUCCESS,
  LOGIN_BY_ACCESS_TOKEN_FAILURE,
  INIT_LOGIN_BY_ACCESS_TOKEN_STATUS,
  
} from './authConstants';

import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';


// Login
function startLogin() {
  return {
    type: START_LOGIN
  };
}

export function login(user) {
  const requestParams = {
    userName: user.userName,
    pwd: user.password,
    source: 'Report',
  };

  return function(dispatch) {
    dispatch(startLogin());
    httpClient
      .post(UrlConfig.LOGIN, requestParams)
      .then(resp => {
        dispatch(loginSuccess(resp));
      })
      .catch(err => {
        dispatch(loginFailure(err));
      });
  };
}

function loginSuccess(resp) {
  return {
    type: LOGIN_SUCCESS,
    payload: resp
  };
}

function loginFailure(err) {
  return {
    type: LOGIN_FAILURE,
    payload: err
  };
}

export function resetLoginStatus() {
  return {
    type: RESET_LOGIN_STATUS,
  };
}

// Logout
export function logout() {
  return function(dispatch) {
    httpClient
      .post(UrlConfig.LOGOUT)
      .then(resp => {
        dispatch(logoutSuccess());
      })
      .catch(err => {
        dispatch(logoutSuccess());
      });
  };
}

function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS
  };
}

// Login By Access Token
function startLoginByAccessToken() {
  return {
    type: START_LOGIN_BY_ACCESS_TOKEN
  };
}

export function loginByAccessToken(accessToken) {
  const requestParams = {
    accessToken,
  };

  return function(dispatch) {
    dispatch(startLoginByAccessToken());
    httpClient
      .post(UrlConfig.LOGIN_BY_ACESS_TOKEN, requestParams)
      .then(resp => {
        dispatch(loginByAccessTokenSuccess(resp));
      })
      .catch(err => {
        dispatch(loginByAccessTokenFailure(err));
      });
  };
}

function loginByAccessTokenSuccess(resp) {
  return {
    type: LOGIN_BY_ACCESS_TOKEN_SUCCESS,
    payload: resp
  };
}

function loginByAccessTokenFailure(err) {
  return {
    type: LOGIN_BY_ACCESS_TOKEN_FAILURE,
    payload: err
  };
}

export function initLoginByAccessTokenStatus() {
  return {
    type: INIT_LOGIN_BY_ACCESS_TOKEN_STATUS,
  };
}