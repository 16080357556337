// Load Gift Hospitality Request
export const START_LOAD_GIFT_HOSPITALITY_REQUEST = 'START_LOAD_GIFT_HOSPITALITY_REQUEST';
export const LOAD_GIFT_HOSPITALITY_REQUEST_SUCCESS = 'LOAD_GIFT_HOSPITALITY_REQUEST_SUCCESS';
export const LOAD_GIFT_HOSPITALITY_REQUEST_FAILURE = 'LOAD_GIFT_HOSPITALITY_REQUEST_FAILURE';
export const INIT_LOAD_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS = 'INIT_LOAD_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS';

// Select Gift Hospitality Request
export const SELECT_GIFT_HOSPITALITY_REQUEST = 'SELECT_GIFT_HOSPITALITY_REQUEST';

// Open/Reset Gift Hospitality Request Modal Dialog
export const GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_OPEN = 'GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_OPEN';
export const GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_RESET = 'GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_RESET';

// Gift Hospitality Request Edit Change Input
export const GIFT_HOSPITALITY_REQUEST_EDIT_CHANGE_INPUT = 'GIFT_HOSPITALITY_REQUEST_EDIT_CHANGE_INPUT';

// Create Gift Hospitality Request
export const START_CREATE_GIFT_HOSPITALITY_REQUEST = 'START_CREATE_GIFT_HOSPITALITY_REQUEST';
export const CREATE_GIFT_HOSPITALITY_REQUEST_SUCCESS = 'CREATE_GIFT_HOSPITALITY_REQUEST_SUCCESS';
export const CREATE_GIFT_HOSPITALITY_REQUEST_FAILURE = 'CREATE_GIFT_HOSPITALITY_REQUEST_FAILURE';
export const INIT_CREATE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS = 'INIT_CREATE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS';

// Update Gift Hospitality Request
export const START_UPDATE_GIFT_HOSPITALITY_REQUEST = 'START_UPDATE_GIFT_HOSPITALITY_REQUEST';
export const UPDATE_GIFT_HOSPITALITY_REQUEST_SUCCESS = 'UPDATE_GIFT_HOSPITALITY_REQUEST_SUCCESS';
export const UPDATE_GIFT_HOSPITALITY_REQUEST_FAILURE = 'UPDATE_GIFT_HOSPITALITY_REQUEST_FAILURE';
export const INIT_UPDATE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS = 'INIT_UPDATE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS';

// Batch Delete Gift Hospitality Request
export const START_BATCH_DELETE_GIFT_HOSPITALITY_REQUEST = 'START_BATCH_DELETE_GIFT_HOSPITALITY_REQUEST';
export const BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_SUCCESS = 'BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_SUCCESS';
export const BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_FAILURE = 'BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_FAILURE';
export const INIT_BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS = 'INIT_BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS';

// Submit Gift Hospitality Request
export const START_SUBMIT_GIFT_HOSPITALITY_REQUEST = 'START_SUBMIT_GIFT_HOSPITALITY_REQUEST';
export const SUBMIT_GIFT_HOSPITALITY_REQUEST_SUCCESS = 'SUBMIT_GIFT_HOSPITALITY_REQUEST_SUCCESS';
export const SUBMIT_GIFT_HOSPITALITY_REQUEST_FAILURE = 'SUBMIT_GIFT_HOSPITALITY_REQUEST_FAILURE';
export const INIT_SUBMIT_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS = 'INIT_SUBMIT_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS';