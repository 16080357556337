import React, { Component } from 'react';
import { Input, Form, Row, Col, Modal, message, DatePicker, Select, Upload, Button, Icon } from 'antd';
import { Message } from 'semantic-ui-react'
import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import { localStorageClient } from '../../../../utils/localStorageClient';
import { DONATION_TYPE_MAP } from '../../../../utils/DonationTypeUtil';
import moment from 'moment';

const FormItem = Form.Item;
const confirm = Modal.confirm;
const Option = Select.Option;
const TextArea = Input.TextArea;

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 12 },
};

// Donation Type
const DonationTypeOptions = [];
for (let i = 0; i < DONATION_TYPE_MAP.length; i++) {
  DonationTypeOptions.push(<Option key={DONATION_TYPE_MAP[i].code}>{DONATION_TYPE_MAP[i].label}</Option>);
}

class GiftHospitalityRequestEditDialog extends Component {

  state = {
    fileList: [],
  };

  currencyOptions = [];


  constructor(props) {
    super();
    const currencySelectList = ['USD', 'CNY', 'HKD', 'JPY', 'INR', 'SGD'];
    this.currencyOptions = currencySelectList.map(currency => <Option key={currency}>{currency}</Option>);
  }

  componentDidUpdate () {
    const {
      createGiftHospitalityRequestActionStatus,
      updateGiftHospitalityRequestActionStatus,
    } = this.props;

    // Create Gift Hospitality Request 成功
    if (createGiftHospitalityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Gift Hospitality Request is created successfully!');
      this.props.loadGiftHospitalityRequest();
      this.props.initCreateGiftHospitalityRequestActionStatus();
      this.props.resetGiftHospitalityRequestEditModalDialog();
    }
      
    // Create Gift Hospitality Request 失败
    if (createGiftHospitalityRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error('Gift Hospitality Request is created failed!');
      this.props.initCreateGiftHospitalityRequestActionStatus();
    }

    // ======================== Update ========================
    // Update Gift Hospitality Request 成功
    if (updateGiftHospitalityRequestActionStatus === ACTION_STATUS.SUCCESS) {
      message.success('Gift Hospitality Request is updated successfully!');
      this.props.loadGiftHospitalityRequest();
      this.props.initUpdateGiftHospitalityRequestActionStatus();
      this.props.resetGiftHospitalityRequestEditModalDialog();
    }
      
    // Update Gift Hospitality Request 失败
    if (updateGiftHospitalityRequestActionStatus === ACTION_STATUS.ERROR) {
      message.error('Gift Hospitality Request is updated failed!');
      this.props.initUpdateGiftHospitalityRequestActionStatus();
    }
  }

  onInputChange = ({name, value}) => {
    this.props.giftHospitalityRequestEditChangeInput({name, value});
  }

  cancelButtonOnClicked = () => {
    this.props.resetGiftHospitalityRequestEditModalDialog();
  }

  saveButtonOnClicked = (alsoSubmit) => {
    const {
      giftHospitalityRequestEditControlModal,
    } = this.props;

    const {
      isAddMode,
    } = giftHospitalityRequestEditControlModal;

    const {
      id,
      date,
      type,
      giver,
      giverPosition,
      giverCompany,
      amount,
      currency,
      localAmount,
      description,
      attachment,
      attachmentOriginName,
    } = giftHospitalityRequestEditControlModal.fields;

    const employee = localStorageClient.getUserName();
    if (!!!employee) {
      let that = this;
      confirm({
        title: 'Login status is invalid, please login again!',
        content: 'Do you need to log out to the login page',
        onOk() {
          that.props.history.push({
            pathname: '/login',
          });
        },
        onCancel() {},
      });

      return;
    }

    const form = this.props.form;

    // 表单校验
    form.validateFields((err) => {
        if (!err) {
          if(alsoSubmit) {
            Modal.confirm({
              title: 'Submit Confirmation',
              content: 'Are you sure to submit at the same time? You can no longer edit this record once it is submmitted.',
              onOk: () => {
                if (isAddMode) {
                  this.props.createGiftHospitalityRequest({
                    date,
                    employee,
                    type,
                    giver,
                    giverPosition,
                    giverCompany,
                    amount,
                    currency,
                    localAmount,
                    description,
                    attachment,
                    attachmentOriginName,
                    alsoSubmit,
                  });
                } else {
                  this.props.updateGiftHospitalityRequest({
                    id,
                    date,
                    employee,
                    type,
                    giver,
                    giverPosition,
                    giverCompany,
                    amount,
                    currency,
                    localAmount,
                    description,
                    attachment,
                    attachmentOriginName,
                    alsoSubmit,
                  });
                }
              },
              onCancel: () => {}
            });
          } else {
            if (isAddMode) {
              this.props.createGiftHospitalityRequest({
                date,
                employee,
                type,
                giver,
                giverPosition,
                giverCompany,
                amount,
                currency,
                localAmount,
                description,
                attachment,
                attachmentOriginName,
                alsoSubmit,
              });
            } else {
              this.props.updateGiftHospitalityRequest({
                id,
                date,
                employee,
                type,
                giver,
                giverPosition,
                giverCompany,
                amount,
                currency,
                localAmount,
                description,
                attachment,
                attachmentOriginName,
                alsoSubmit,
              });
            }
          }
        }
      },
    );
  }
  
  checkValueNumber = (rule, value, callback) => {

    if (isNaN(value)) {
      if (rule.field === 'amount') {
        callback(`Amount must be a number`);
      }

      if (rule.field === 'localAmount') {
        callback(`Local amount must be a number`);
      }

      return;
    }

    callback();
  }

  // 附件上传
  attachmentHandleChange = (info) => {
    let fileList = [...info.fileList];
    fileList = fileList.slice(-1);

    const file = fileList[0];
    if (file.response) {
      const filename = file.response.data.guidFileName;
      const originalFileName = file.response.data.originalFileName;
      this.props.giftHospitalityRequestEditChangeInput({name: 'attachment', value: filename});
      this.props.giftHospitalityRequestEditChangeInput({name: 'attachmentOriginName', value: originalFileName});
    }

    this.setState({ fileList });    
  }

  // Dialog 关闭后
  dialogClosed = () => {
    this.setState({
      fileList: []
    });
  }

  render() {
    const {
      giftHospitalityRequestEditControlModal,
      createGiftHospitalityRequestActionStatus,
      createGiftHospitalityRequestActionResp,
      updateGiftHospitalityRequestActionStatus,
      updateGiftHospitalityRequestActionResp,
      uploadUrl,
      // currencyOptions,
    } = this.props;

    const {
      isOpened,
      isAddMode,
      respErrMsg,
    } = giftHospitalityRequestEditControlModal;

    let showErrMsg = respErrMsg;
    if(!!createGiftHospitalityRequestActionResp && !!createGiftHospitalityRequestActionResp.respMessage) {
      showErrMsg = showErrMsg + '; ' + createGiftHospitalityRequestActionResp.respMessage;
    }
    if(!!updateGiftHospitalityRequestActionResp && !!updateGiftHospitalityRequestActionResp.respMessage) {
      showErrMsg = showErrMsg + '; ' + updateGiftHospitalityRequestActionResp.respMessage;
    }

    const { getFieldDecorator } = this.props.form;

    const {
      date,
      type,
      giver,
      giverPosition,
      giverCompany,
      amount,
      currency,
      localAmount,
      description,
    } = giftHospitalityRequestEditControlModal.fields;

    let modalTitle = 'Edit Gift/Hospitality';
    if (isAddMode) {
      modalTitle = 'Add Gift/Hospitality';
    }

    const isLoading = createGiftHospitalityRequestActionStatus === ACTION_STATUS.LOGINING ||
        updateGiftHospitalityRequestActionStatus === ACTION_STATUS.LOGINING;
        
    return (
      <div>
        <Modal
          title={modalTitle}
          centered
          width={600}
          visible={isOpened}
          confirmLoading={isLoading}
          afterClose={this.dialogClosed}
          destroyOnClose={true}
          maskClosable={false}
          okText="Submit"
          cancelText="Cancel"
          footer={[
            <Button key="back" onClick={() => this.cancelButtonOnClicked() }>
              Cancel
            </Button>,
            <Button key="save" type="primary" onClick={() => this.saveButtonOnClicked(false) }>
              Save
            </Button>,
            <Button key="submit" type="primary" onClick={() => this.saveButtonOnClicked(true) }>
              Submit
            </Button>,
          ]}
        >
          <Form layout="horizontal">
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Date">
                  {getFieldDecorator('date', {
                      initialValue: date === '' ? null : moment(date),
                    })(
                    <DatePicker 
                      allowClear={false}
                      onChange={(date, dateString) => {
                        this.onInputChange({ name: 'date', value: dateString });
                      }} />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Type">
                  {getFieldDecorator('type', {
                      initialValue: type,
                      rules: [{
                        required: true,
                        message: 'Please input type',
                      }],
                    })(
                      <Select onChange={(value) => {
                          this.onInputChange({ name: 'type', value })
                        }} placeholder="Please Choose"
                      >
                        {DonationTypeOptions}
                      </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Giver">
                  {getFieldDecorator('giver', {
                      initialValue: giver,
                      rules: [{
                        required: true,
                        message: 'Please input giver',
                      }],
                    })(
                    <Input placeholder="" 
                      onChange={(e) => {
                          this.onInputChange({ name: 'giver', value: e.target.value })
                        }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Giver Position">
                  {getFieldDecorator('giverPosition', {
                      initialValue: giverPosition,
                      rules: [{
                        required: true,
                        message: 'Please input giver position',
                      }],
                    })(
                    <Input 
                      placeholder="" 
                      onChange={(e) => {
                          this.onInputChange({ name: 'giverPosition', value: e.target.value })
                        }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Giver Company">
                  {getFieldDecorator('giverCompany', {
                      initialValue: giverCompany,
                      rules: [{
                        required: true,
                        message: 'Please input giver company',
                      }],
                    })(
                    <Input
                      placeholder="" 
                      onChange={(e) => {
                          this.onInputChange({ name: 'giverCompany', value: e.target.value })
                        }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            {/**
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Amount">
                  {getFieldDecorator('amount', {
                      initialValue: amount,
                      rules: [{
                        required: true,
                        message: 'Please input amount',
                      }, {
                        validator: this.checkValueNumber,
                      }],
                    })(
                    <Input placeholder="" 
                      onChange={(e) => {
                          this.onInputChange({ name: 'amount', value: e.target.value })
                        }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
             */}
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Currency">
                  {getFieldDecorator('currency', {
                      initialValue: currency,
                      rules: [{
                        required: true,
                        message: 'Please select currency',
                      }],
                    })(
                    <Select onChange={(value) => {
                        this.onInputChange({ name: 'currency', value })
                      }} 
                      placeholder="Please Choose">
                      {this.currencyOptions}
                    </Select>
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Local Amount">
                  {getFieldDecorator('localAmount', {
                      initialValue: localAmount,
                      rules: [{
                        required: true,
                        message: 'Please input local amount',
                      }, {
                        validator: this.checkValueNumber,
                      }],
                    })(
                    <Input placeholder="" 
                      onChange={(e) => {
                          this.onInputChange({ name: 'localAmount', value: e.target.value })
                        }}
                    />
                  )}
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Description">
                    <TextArea 
                      placeholder="" 
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      value={description}
                      onChange={(e) => {
                          this.onInputChange({ name: 'description', value: e.target.value })
                        }}
                    />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={24}>
                <FormItem {...formItemLayout} label="Attachment">
                  <Upload
                    withCredentials={true}
                    action={uploadUrl}
                    onChange={this.attachmentHandleChange}
                    fileList={this.state.fileList}
                    multiple={false}>
                    <Button>
                      <Icon type="upload" /> Upload
                    </Button>
                  </Upload>
                </FormItem>
              </Col>
            </Row>
          </Form>
          {
            !!showErrMsg &&  
            <Message negative>
              <Message.Header>Response Error Message</Message.Header>
            <p>{ showErrMsg }</p>
          </Message>
          }
        </Modal>
      </div>
    );
  }
}

const GiftHospitalityRequestEditDialogForm = Form.create({ name: 'gift_hospitality_edit_dialog' })(GiftHospitalityRequestEditDialog);

export default GiftHospitalityRequestEditDialogForm;