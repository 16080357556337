import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Loader } from 'semantic-ui-react';

import { ACTION_STATUS } from '../../../../utils/ActionStatus';
import './TradeRequest.css';
import { SIDE_TYPE_CODE } from '../../../../utils/SideTypeUtil';


class CheckHoldingsGrid extends Component {

    constructor(props) {
        super(props);
        this.state = {
            columnDefs: props.CheckHoldingsGridColumns,
            defaultColDef: {
                enableCellChangeFlash: true,
                minWidth: 100
            },
            context: { componentParent: this },
        };
    }

    componentDidMount() {
        // 查询
        const {
            checkHoldingsPageModal,
        } = this.props;
        const {
            accountGuid,
        } = checkHoldingsPageModal;
        this.props.queryHoldingsForCurrAccount(accountGuid);
    }


    onSelectionChanged = (event) => {
        let rowNodeArray = event.api.getSelectedNodes();
        let selectedHolding = rowNodeArray[0].data;
        let selectedBBGTicker = selectedHolding.bbgTicker;
        if (!selectedBBGTicker) {
            this.props.clearBbgTickers();
        }
        this.props.tradeRequestEditChangeInput({ name: 'secName', value: selectedHolding.secName });
        this.props.tradeRequestEditChangeInput({ name: 'stockMarket', value: '' });
        this.props.tradeRequestEditChangeInput({ name: 'bbgTicker', value: selectedBBGTicker });
        this.props.tradeRequestEditChangeInput({ name: 'side', value: SIDE_TYPE_CODE.SELL });
        this.props.startTrigger30DayHoldingCheckInfoStatus();
        this.props.tradeRequestEditDialogForm.resetFields();
        this.props.bbgTickerSelectChanged(selectedBBGTicker);
        this.props.resetCheckHoldingsPage();
    }


    render() {
        const {
            queryHoldingsForCurrAccountStatus,
            queryHoldingsForCurrAccountResp,
        } = this.props;
        
        const isLoading = queryHoldingsForCurrAccountStatus === ACTION_STATUS.LOGINING;

        let queryHoldingsForCurrAccountArray = [];
        if(!!queryHoldingsForCurrAccountResp && !!queryHoldingsForCurrAccountResp.data) {
            queryHoldingsForCurrAccountArray = queryHoldingsForCurrAccountResp.data;
        }

        return (
            <div className={'ag-theme-balham grid-wrapper'}>
                {isLoading ? (
                    <Loader active content="Loading..." />
                    ) : (
                    <AgGridReact
                        // properties
                        columnDefs={this.state.columnDefs}
                        rowData={queryHoldingsForCurrAccountArray}
                        defaultColDef={this.state.defaultColDef}
                        rowSelection={'single'}
                        enableSorting={true}
                        enableFilter={true}
                        animateRows={true}
                        deltaRowDataMode={false}
                        enableColResize={true}
                        resizable={true}
                        // events
                        onSelectionChanged={this.onSelectionChanged}
                    />
                )}
            </div>
        );
    }

}

export default CheckHoldingsGrid;
