import {
  // Login
  START_LOGIN,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  RESET_LOGIN_STATUS,

  // Logout
  LOGOUT_SUCCESS,

  // Login By Access Token
  START_LOGIN_BY_ACCESS_TOKEN,
  LOGIN_BY_ACCESS_TOKEN_SUCCESS,
  LOGIN_BY_ACCESS_TOKEN_FAILURE,
  INIT_LOGIN_BY_ACCESS_TOKEN_STATUS,

} from './authConstants';

import { createReducer } from '../../../utils/reducerUtil';
import { ACTION_STATUS } from '../../../utils/ActionStatus';

const initialState = {
  // Login By Access Token
  loginByAccessTokenStatus: ACTION_STATUS.READY,
  loginByAccessTokenErrMsg: '',
}

// Login
function startLogin(state) {
  return {
    ...state,
    loginStatus: ACTION_STATUS.LOGINING,
  };
}

function loginSuccess(state, payload) {
  return {
    ...state,
    loginStatus: ACTION_STATUS.SUCCESS
  };
}

function loginFailure(state, err) {
  return {
    ...state,
    loginStatus: ACTION_STATUS.ERROR,
    loginErrMsg: err.respMessage,
  };
}

function resetLoginStatus(state) {
  return {
    ...state,
    loginStatus: ACTION_STATUS.READY,
    loginErrMsg: '',
  };
}

// Logout
function logoutSuccess(state) {
  return {
    ...state,
  };
}

// Login By Access Token
function startLoginByAccessToken(state) {
  return {
    ...state,
    loginByAccessTokenStatus: ACTION_STATUS.LOGINING,
  };
}

function loginByAccessTokenSuccess(state, resp) {
  return {
    ...state,
    loginByAccessTokenStatus: ACTION_STATUS.SUCCESS,
  };
}

function loginByAccessTokenFailure(state, err) {
  return {
    ...state,
    loginByAccessTokenStatus: ACTION_STATUS.ERROR,
    loginByAccessTokenErrMsg: err.respMessage,
  };
}

function initLoginByAccessTokenStatus(state) {
  return {
    ...state,
    loginByAccessTokenStatus: ACTION_STATUS.READY,
    loginByAccessTokenErrMsg: '',
  };
}

export default createReducer(initialState, {
  // Login
  [START_LOGIN]: startLogin,
  [LOGIN_SUCCESS]: loginSuccess,
  [LOGIN_FAILURE]: loginFailure,
  [RESET_LOGIN_STATUS]: resetLoginStatus,

  // Logout
  [LOGOUT_SUCCESS]: logoutSuccess,
  
  // Login By Access Token
  [START_LOGIN_BY_ACCESS_TOKEN]: startLoginByAccessToken,
  [LOGIN_BY_ACCESS_TOKEN_SUCCESS]: loginByAccessTokenSuccess,
  [LOGIN_BY_ACCESS_TOKEN_FAILURE]: loginByAccessTokenFailure,
  [INIT_LOGIN_BY_ACCESS_TOKEN_STATUS]: initLoginByAccessTokenStatus,
});