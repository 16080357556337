import {connect} from 'react-redux';
import ManualsAndPoliciesDashboard from '../components/ManualsAndPoliciesDashboard';


const mapStateToProps = state => {
    return {};
}

const mapDispatchToProps = dispatch => {
    return {};
}

export default connect (
    mapStateToProps,
    mapDispatchToProps
)(ManualsAndPoliciesDashboard);
