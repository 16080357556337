import {connect} from 'react-redux';
import TradeRequestDashboard from '../components/TradeRequestDashboard';

import {
  // Load Trade Request
  loadTradeRequest,
  initLoadTradeRequestActionStatus,

  // Select Trade Request
  selectTradeRequest,

  // Open/Reset Trade Request Modal Dialog
  openTradeRequestEditModalDialog,
  resetTradeRequestEditModalDialog,

  // Trade Request Edit Change Input
  tradeRequestEditChangeInput,

  // =============================================================
  // Create Trade Request
  createTradeRequest,
  initCreateTradeRequestActionStatus,

  // Update Trade Request
  updateTradeRequest,
  initUpdateTradeRequestActionStatus,

  // Batch Delete Trade Request
  batchDeleteTradeRequest,
  initBatchDeleteTradeRequestActionStatus,

  // Submit Trade Request
  submitTradeRequest,
  initSubmitTradeRequestActionStatus,

  // Load BBG Tickers
  loadBbgTickers,
  initLoadBbgTickersActionStatus,

  // Clear bbgTickerArray bbgTickerOptions
  clearBbgTickers,

  // BBG Ticker Select Changed
  bbgTickerSelectChanged,

  // Load Personal Trade Field History
  loadPersonalTradeFieldHistory,
  initLoadPersonalTradeFieldHistoryActionStatus,

  // Load Exchange Code
  loadExchangeCode,
  initLoadExchangeCodeActionStatus,

  // Clear exchageCodeArray exchageCodeOptions
  clearExchangeCodes,

  // Exchange Code Select Changed
  exchangeCodeSelectChanged,

  // Open/Reset Trade Request Clone Edit Modal Dialog
  openTradeRequestCloneEditModalDialog,
  resetTradeRequestCloneEditModalDialog,

  // Trade Request Clone Edit Change Input
  tradeRequestCloneEditChangeInput,

  // load personal account
  loadPersonalAccount,
  initLoadPersonalAccountActionStatus,

  // 30天持仓相关预检查
  reset30DayHoldingCheckInfo,
  query30DayHoldingCheckInfo,
  startTrigger30DayHoldingCheckInfoStatus,
  stopTrigger30DayHoldingCheckInfoStatus,

  // openCheckHoldingsPage
  openCheckHoldingsPage,
  resetCheckHoldingsPage,
  queryHoldingsForCurrAccount,
  initQueryHoldingsForCurrAccountActionStatus,
  setTradeRequestEditDialogForm,

  checkCanDoPa,
} from '../tradeRequestActions';


const mapStateToProps = state => {
  return {
    // Grdi Columns
    tradeRequestGridColumns: state.tradeRequest.tradeRequestGridColumns,

    // Trade Request
    tradeRequestArray: state.tradeRequest.tradeRequestArray,

    // Selected Trade Request
    selectedTradeRequestArray: state.tradeRequest.selectedTradeRequestArray,

    // Trade Request Edit
    tradeRequestEditControlModal: state.tradeRequest.tradeRequestEditControlModal,

    // load/create/update/delete/submit Trade Request
    loadTradeRequestActionStatus: state.tradeRequest.loadTradeRequestActionStatus,
    createTradeRequestActionStatus: state.tradeRequest.createTradeRequestActionStatus,
    updateTradeRequestActionStatus: state.tradeRequest.updateTradeRequestActionStatus,
    batchDeleteTradeRequestsActionStatus: state.tradeRequest.batchDeleteTradeRequestsActionStatus,
    submitTradeRequestActionStatus: state.tradeRequest.submitTradeRequestActionStatus,

    // Bbg Ticker
    bbgTickerArray: state.tradeRequest.bbgTickerArray,
    bbgTickerOptions: state.tradeRequest.bbgTickerOptions,
    securityTickerData: state.tradeRequest.securityTickerData,
    securityTickerArray: state.tradeRequest.securityTickerArray,

    // Load BBG Tickers Action Status
    loadBbgTickersActionStatus: state.tradeRequest.loadBbgTickersActionStatus,

    // Load Personal Trade Field History
    loadTradeFieldHistoryActionStatus: state.tradeRequest.loadTradeFieldHistoryActionStatus,
    personalTradeFieldHistory: state.tradeRequest.personalTradeFieldHistory,

    // Load Exchange Code
    loadExchangeCodeActionStatus: state.tradeRequest.loadExchangeCodeActionStatus,
    exchageCodeArray: state.tradeRequest.exchageCodeArray,
    exchageCodeOptions: state.tradeRequest.exchageCodeOptions,

    // 附件操作URL
    tradeAttachmentUploadUrl: state.tradeRequest.tradeAttachmentUploadUrl,
    tradeAttachmentPreviewUrl: state.tradeRequest.tradeAttachmentPreviewUrl,
    tradeAttachmentDownloadUrl: state.tradeRequest.tradeAttachmentDownloadUrl,

    // Open/Reset Trade Request Clone Edit Modal Dialog
    tradeRequestCloneEditControlModal: state.tradeRequest.tradeRequestCloneEditControlModal,

    // load personal account
    loadPersonalAccountStatus: state.tradeRequest.loadPersonalAccountStatus,
    personalAccountOptions: state.tradeRequest.personalAccountOptions,

    // 30天持仓相关预检查
    query30DayHoldingCheckInfoStatus:  state.tradeRequest.query30DayHoldingCheckInfoStatus,
    resultOf30DayHoldingCheckInfo:  state.tradeRequest.resultOf30DayHoldingCheckInfo,
    trigger30DayHoldingCheckInfoStatus: state.tradeRequest.trigger30DayHoldingCheckInfoStatus,

    // openCheckHoldingsPage
    checkHoldingsPageModal: state.tradeRequest.checkHoldingsPageModal,
    queryHoldingsForCurrAccountStatus: state.tradeRequest.queryHoldingsForCurrAccountStatus,
    queryHoldingsForCurrAccountResp: state.tradeRequest.queryHoldingsForCurrAccountResp,
    CheckHoldingsGridColumns: state.tradeRequest.CheckHoldingsGridColumns,
    tradeRequestEditDialogForm: state.tradeRequest.tradeRequestEditDialogForm,

    //check 
    checkStatus: state.tradeRequest.checkStatus,
  };
} 

const mapDispatchToProps = dispatch => {
  return {
    // Load Trade Request
    loadTradeRequest: () => dispatch(loadTradeRequest()),
    initLoadTradeRequestActionStatus: () => dispatch(initLoadTradeRequestActionStatus()),

    // Select Trade Request
    selectTradeRequest: (payload) => dispatch(selectTradeRequest(payload)),

    // Open/Reset Trade Request Modal Dialog
    openTradeRequestEditModalDialog: (mode, tradeRequest) => dispatch(openTradeRequestEditModalDialog(mode, tradeRequest)),
    resetTradeRequestEditModalDialog: () => dispatch(resetTradeRequestEditModalDialog()),

    // Trade Request Edit Change Input
    tradeRequestEditChangeInput: (input) => dispatch(tradeRequestEditChangeInput(input)),

    // =============================================================
    // Create Trade Request
    createTradeRequest: (tradeRequest) => dispatch(createTradeRequest(tradeRequest)),
    initCreateTradeRequestActionStatus: () => dispatch(initCreateTradeRequestActionStatus()),

    // Update Trade Request
    updateTradeRequest: (tradeRequest) => dispatch(updateTradeRequest(tradeRequest)),
    initUpdateTradeRequestActionStatus: () => dispatch(initUpdateTradeRequestActionStatus()),

    // Batch Delete Trade Request
    batchDeleteTradeRequest: (tradeRequestIdList) => dispatch(batchDeleteTradeRequest(tradeRequestIdList)),
    initBatchDeleteTradeRequestActionStatus: () => dispatch(initBatchDeleteTradeRequestActionStatus()),

    // Submit Trade Request
    submitTradeRequest: (tradeRequestIdList) => dispatch(submitTradeRequest(tradeRequestIdList)),
    initSubmitTradeRequestActionStatus: () => dispatch(initSubmitTradeRequestActionStatus()),
    
    // Load BBG Tickers
    loadBbgTickers: (queryText) => dispatch(loadBbgTickers(queryText)),
    initLoadBbgTickersActionStatus: () => dispatch(initLoadBbgTickersActionStatus()),

    // Clear bbgTickerArray bbgTickerOptions
    clearBbgTickers: () => dispatch(clearBbgTickers()),
    
    // BBG Ticker Select Changed
    bbgTickerSelectChanged: (bbgTicker) => dispatch(bbgTickerSelectChanged(bbgTicker)),

    // load personal account
    loadPersonalAccount: (employee) => dispatch(loadPersonalAccount(employee)),
    initLoadPersonalAccountActionStatus: () => dispatch(initLoadPersonalAccountActionStatus()),

    // Load Exchange Code
    loadExchangeCode: (queryText) => dispatch(loadExchangeCode(queryText)),
    initLoadExchangeCodeActionStatus: () => dispatch(initLoadExchangeCodeActionStatus()),

    // Clear exchageCodeArray exchageCodeOptions
    clearExchangeCodes: () => dispatch(clearExchangeCodes()),

    // Exchange Code Select Changed
    exchangeCodeSelectChanged: (exchangeCode) => dispatch(exchangeCodeSelectChanged(exchangeCode)),

    // Open/Reset Trade Request Clone Edit Modal Dialog
    openTradeRequestCloneEditModalDialog: (tradeRequest) => dispatch(openTradeRequestCloneEditModalDialog(tradeRequest)),
    resetTradeRequestCloneEditModalDialog: () => dispatch(resetTradeRequestCloneEditModalDialog()),

    // Trade Request Clone Edit Change Input
    tradeRequestCloneEditChangeInput: (input) => dispatch(tradeRequestCloneEditChangeInput(input)),

    // 30天持仓相关预检查
    reset30DayHoldingCheckInfo: () => dispatch(reset30DayHoldingCheckInfo()),
    query30DayHoldingCheckInfo: (personalTradeInfo) => dispatch(query30DayHoldingCheckInfo(personalTradeInfo)),
    startTrigger30DayHoldingCheckInfoStatus: () => dispatch(startTrigger30DayHoldingCheckInfoStatus()),
    stopTrigger30DayHoldingCheckInfoStatus: () => dispatch(stopTrigger30DayHoldingCheckInfoStatus()),

    // openCheckHoldingsPage
    openCheckHoldingsPage: (accountGuid) => dispatch(openCheckHoldingsPage(accountGuid)),
    resetCheckHoldingsPage: () => dispatch(resetCheckHoldingsPage()),
    queryHoldingsForCurrAccount: (accountGuid) => dispatch(queryHoldingsForCurrAccount(accountGuid)),
    initQueryHoldingsForCurrAccountActionStatus: () => dispatch(initQueryHoldingsForCurrAccountActionStatus()),
    setTradeRequestEditDialogForm: (form) => dispatch(setTradeRequestEditDialogForm(form)),

    //check
    checkCanDoPa: () => dispatch(checkCanDoPa()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TradeRequestDashboard);