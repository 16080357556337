// Load Fund Book Data
export const LOAD_FUND_BOOK_DATA_SUCCESS = 'LOAD_FUND_BOOK_DATA_SUCCESS';
export const LOAD_FUND_BOOK_DATA_FAILURE = 'LOAD_FUND_BOOK_DATA_FAILURE';

// Load Security Tickers
export const LOAD_SECURITY_TICKERS_SUCCESS = 'LOAD_SECURITY_TICKERS_SUCCESS';
export const LOAD_SECURITY_TICKERS_FAILURE = 'LOAD_SECURITY_TICKERS_FAILURE';

// Load Asset Inst Class
export const LOAD_ASSET_INST_CLASS_SUCCESS = 'LOAD_ASSET_INST_CLASS_SUCCESS';
export const LOAD_ASSET_INST_CLASS_FAILURE = 'LOAD_ASSET_INST_CLASS_FAILURE';

// Login By Access Token
export const START_LOGIN_BY_ACCESS_TOKEN = 'START_LOGIN_BY_ACCESS_TOKEN';
export const LOGIN_BY_ACCESS_TOKEN_SUCCESS = 'LOGIN_BY_ACCESS_TOKEN_SUCCESS';
export const LOGIN_BY_ACCESS_TOKEN_FAILURE = 'LOGIN_BY_ACCESS_TOKEN_FAILURE';
export const INIT_LOGIN_BY_ACCESS_TOKEN_STATUS = 'INIT_LOGIN_BY_ACCESS_TOKEN_STATUS';

// get hierarchy menus
export const GET_HIERARCHY_MENUS_SUCCESS = 'GET_HIERARCHY_MENUS_SUCCESS';
export const GET_HIERARCHY_MENUS_FAILURE = 'GET_HIERARCHY_MENUS_FAILURE';

// Load User Account
export const LOAD_USER_ACCOUNTS_SUCCESS = 'LOAD_USER_ACCOUNTS_SUCCESS';
export const LOAD_USER_ACCOUNTS_FAILURE = 'LOAD_USER_ACCOUNTS_FAILURE';
