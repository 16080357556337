export const CATEGORY_MAP = [
    // Long
    {
      code: 'Equity',
      label: 'Equity'
    },
    {
      code: 'F&O',
      label: 'F&O'
    },{
        code: 'Swap',
        label: 'Swap'
      },{
        code: 'Bonds',
        label: 'Bonds'
      },{
        code: 'Unlisted asset / Private Equity',
        label: 'Unlisted asset / Private Equity'
      },{
        code: 'Public Funds',
        label: 'Public Funds'
      },
  ];