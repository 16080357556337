import { httpClient } from '../../../net/httpClient';
import UrlConfig from '../../../net/UrlConfig';

import {
  // Load Gift Hospitality Request
  START_LOAD_GIFT_HOSPITALITY_REQUEST,
  LOAD_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  LOAD_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_LOAD_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Select Gift Hospitality Request
  SELECT_GIFT_HOSPITALITY_REQUEST,

  // Open/Reset Gift Hospitality Request Modal Dialog
  GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_OPEN,
  GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_RESET,

  // Gift Hospitality Request Edit Change Input
  GIFT_HOSPITALITY_REQUEST_EDIT_CHANGE_INPUT,

  // Create Gift Hospitality Request
  START_CREATE_GIFT_HOSPITALITY_REQUEST,
  CREATE_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  CREATE_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_CREATE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Update Gift Hospitality Request
  START_UPDATE_GIFT_HOSPITALITY_REQUEST,
  UPDATE_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  UPDATE_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_UPDATE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Batch Delete Gift Hospitality Request
  START_BATCH_DELETE_GIFT_HOSPITALITY_REQUEST,
  BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,

  // Submit Gift Hospitality Request
  START_SUBMIT_GIFT_HOSPITALITY_REQUEST,
  SUBMIT_GIFT_HOSPITALITY_REQUEST_SUCCESS,
  SUBMIT_GIFT_HOSPITALITY_REQUEST_FAILURE,
  INIT_SUBMIT_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS,
} from './giftHospitalityRequestConstants';



// Load Gift Hospitality Request
function startLoadGiftHospitalityRequest() {
  return {
    type: START_LOAD_GIFT_HOSPITALITY_REQUEST
  };
}

export function loadGiftHospitalityRequest() {
  return function(dispatch) {
    dispatch(startLoadGiftHospitalityRequest());
    httpClient
      .get(UrlConfig.QUERY_GIFT_HOSPITALITY)
      .then(resp => dispatch(loadGiftHospitalityRequestSuccess(resp)))
      .catch(err => dispatch(loadGiftHospitalityRequestFailure(err)));
  };
}

function loadGiftHospitalityRequestSuccess(resp) {
  return {
    type: LOAD_GIFT_HOSPITALITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function loadGiftHospitalityRequestFailure(err) {
  return {
    type: LOAD_GIFT_HOSPITALITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initLoadGiftHospitalityRequestActionStatus() {
  return {
    type: INIT_LOAD_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS
  };
}

// Select Gift Hospitality Request
export function selectGiftHospitalityRequest(payload) {
  return {
    type: SELECT_GIFT_HOSPITALITY_REQUEST,
    payload
  };
}

// Open/Reset Gift Hospitality Request Modal Dialog
export function openGiftHospitalityRequestEditModalDialog(isAddMode, giftHospitalityRequest) {
  return {
    type: GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_OPEN,
    payload: {
      isAddMode,
      giftHospitalityRequest,
    },
  };
}

export function resetGiftHospitalityRequestEditModalDialog() {
  return {
    type: GIFT_HOSPITALITY_REQUEST_MODAL_DIALOG_RESET
  };
}

// Gift Hospitality Request Edit Change Input
export function giftHospitalityRequestEditChangeInput({ name, value }) {
  return {
    type: GIFT_HOSPITALITY_REQUEST_EDIT_CHANGE_INPUT,
    payload: {
      name,
      value,
    }
  };
}

// =============================================================
// Create Gift Hospitality Request
function startCreateGiftHospitalityRequest() {
  return {
    type: START_CREATE_GIFT_HOSPITALITY_REQUEST
  };
}

export function createGiftHospitalityRequest(giftHospitalityRequest) {
  return function(dispatch) {
    dispatch(startCreateGiftHospitalityRequest());
    httpClient
      .post(UrlConfig.NEW_GIFT_HOSPITALITY, giftHospitalityRequest)
      .then(resp => dispatch(createGiftHospitalityRequestSuccess(resp)))
      .catch(err => dispatch(createGiftHospitalityRequestFailure(err)));
  };
}

function createGiftHospitalityRequestSuccess(resp) {
  return {
    type: CREATE_GIFT_HOSPITALITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function createGiftHospitalityRequestFailure(err) {
  return {
    type: CREATE_GIFT_HOSPITALITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initCreateGiftHospitalityRequestActionStatus() {
  return {
    type: INIT_CREATE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS
  };
}

// Update Gift Hospitality Request
function startUpdateGiftHospitalityRequest() {
  return {
    type: START_UPDATE_GIFT_HOSPITALITY_REQUEST
  };
}

export function updateGiftHospitalityRequest(giftHospitalityRequest) {
  return function(dispatch) {
    dispatch(startUpdateGiftHospitalityRequest());
    httpClient
      .post(UrlConfig.UPDATED_GIFT_HOSPITALITY, giftHospitalityRequest)
      .then(resp => dispatch(updateGiftHospitalityRequestSuccess(resp)))
      .catch(err => dispatch(updateGiftHospitalityRequestFailure(err)));
  };
}

function updateGiftHospitalityRequestSuccess(resp) {
  return {
    type: UPDATE_GIFT_HOSPITALITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function updateGiftHospitalityRequestFailure(err) {
  return {
    type: UPDATE_GIFT_HOSPITALITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initUpdateGiftHospitalityRequestActionStatus() {
  return {
    type: INIT_UPDATE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS
  };
}

// Batch Delete Gift Hospitality Request
function startBatchDeleteGiftHospitalityRequest() {
  return {
    type: START_BATCH_DELETE_GIFT_HOSPITALITY_REQUEST
  };
}

export function batchDeleteGiftHospitalityRequest(giftHospitalityRequestIdList) {
  return function(dispatch) {
    dispatch(startBatchDeleteGiftHospitalityRequest());
    httpClient
      .post(UrlConfig.BATCH_DELETED_GIFT_HOSPITALITY, giftHospitalityRequestIdList)
      .then(resp => dispatch(batchDeleteGiftHospitalityRequestSuccess(resp)))
      .catch(err => dispatch(batchDeleteGiftHospitalityRequestFailure(err)));
  };
}

function batchDeleteGiftHospitalityRequestSuccess(resp) {
  return {
    type: BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function batchDeleteGiftHospitalityRequestFailure(err) {
  return {
    type: BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initBatchDeleteGiftHospitalityRequestActionStatus() {
  return {
    type: INIT_BATCH_DELETE_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS
  };
}

// Submit Gift Hospitality Request
function startSubmitGiftHospitalityRequest() {
  return {
    type: START_SUBMIT_GIFT_HOSPITALITY_REQUEST
  };
}

export function submitGiftHospitalityRequest(giftHospitalityRequestIdList) {
  return function(dispatch) {
    dispatch(startSubmitGiftHospitalityRequest());
    httpClient
      .post(UrlConfig.SUBMITTED_GIFT_HOSPITALITY, giftHospitalityRequestIdList)
      .then(resp => dispatch(submitGiftHospitalityRequestSuccess(resp)))
      .catch(err => dispatch(submitGiftHospitalityRequestFailure(err)));
  };
}

function submitGiftHospitalityRequestSuccess(resp) {
  return {
    type: SUBMIT_GIFT_HOSPITALITY_REQUEST_SUCCESS,
    payload: resp
  };
}

function submitGiftHospitalityRequestFailure(err) {
  return {
    type: SUBMIT_GIFT_HOSPITALITY_REQUEST_FAILURE,
    payload: err
  };
}

export function initSubmitGiftHospitalityRequestActionStatus() {
  return {
    type: INIT_SUBMIT_GIFT_HOSPITALITY_REQUEST_ACTION_STATUS
  };
}
