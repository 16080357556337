const NEW = 'New';
const MODIFIED = 'Modified';
const ONLIST = 'OnList';
const OFFLIST = 'OffList';

export default {
  NEW,
  MODIFIED,
  ONLIST,
  OFFLIST,
};