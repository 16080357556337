// Load Trade Request
export const START_LOAD_TRADE_REQUEST = 'START_LOAD_TRADE_REQUEST';
export const LOAD_TRADE_REQUEST_SUCCESS = 'LOAD_TRADE_REQUEST_SUCCESS';
export const LOAD_TRADE_REQUEST_FAILURE = 'LOAD_TRADE_REQUEST_FAILURE';
export const INIT_LOAD_TRADE_REQUEST_ACTION_STATUS = 'INIT_LOAD_TRADE_REQUEST_ACTION_STATUS';

// Select Trade Request
export const SELECT_TRADE_REQUEST = 'SELECT_TRADE_REQUEST';

// Open/Reset Trade Request Modal Dialog
export const TRADE_REQUEST_MODAL_DIALOG_OPEN = 'TRADE_REQUEST_MODAL_DIALOG_OPEN';
export const TRADE_REQUEST_MODAL_DIALOG_RESET = 'TRADE_REQUEST_MODAL_DIALOG_RESET';

// Trade Request Edit Change Input
export const TRADE_REQUEST_EDIT_CHANGE_INPUT = 'TRADE_REQUEST_EDIT_CHANGE_INPUT';

// Create Trade Request
export const START_CREATE_TRADE_REQUEST = 'START_CREATE_TRADE_REQUEST';
export const CREATE_TRADE_REQUEST_SUCCESS = 'CREATE_TRADE_REQUEST_SUCCESS';
export const CREATE_TRADE_REQUEST_FAILURE = 'CREATE_TRADE_REQUEST_FAILURE';
export const INIT_CREATE_TRADE_REQUEST_ACTION_STATUS = 'INIT_CREATE_TRADE_REQUEST_ACTION_STATUS';

// Update Trade Request
export const START_UPDATE_TRADE_REQUEST = 'START_UPDATE_TRADE_REQUEST';
export const UPDATE_TRADE_REQUEST_SUCCESS = 'UPDATE_TRADE_REQUEST_SUCCESS';
export const UPDATE_TRADE_REQUEST_FAILURE = 'UPDATE_TRADE_REQUEST_FAILURE';
export const INIT_UPDATE_TRADE_REQUEST_ACTION_STATUS = 'INIT_UPDATE_TRADE_REQUEST_ACTION_STATUS';

// Batch Delete Trade Request
export const START_BATCH_DELETE_TRADE_REQUEST = 'START_BATCH_DELETE_TRADE_REQUEST';
export const BATCH_DELETE_TRADE_REQUEST_SUCCESS = 'BATCH_DELETE_TRADE_REQUEST_SUCCESS';
export const BATCH_DELETE_TRADE_REQUEST_FAILURE = 'BATCH_DELETE_TRADE_REQUEST_FAILURE';
export const INIT_BATCH_DELETE_TRADE_REQUEST_ACTION_STATUS = 'INIT_BATCH_DELETE_TRADE_REQUEST_ACTION_STATUS';

// Submit Trade Request
export const START_SUBMIT_TRADE_REQUEST = 'START_SUBMIT_TRADE_REQUEST';
export const SUBMIT_TRADE_REQUEST_SUCCESS = 'SUBMIT_TRADE_REQUEST_SUCCESS';
export const SUBMIT_TRADE_REQUEST_FAILURE = 'SUBMIT_TRADE_REQUEST_FAILURE';
export const INIT_SUBMIT_TRADE_REQUEST_ACTION_STATUS = 'INIT_SUBMIT_TRADE_REQUEST_ACTION_STATUS';

// Load BBG Tickers
export const START_LOAD_BBG_TICKERS = 'START_LOAD_BBG_TICKERS';
export const LOAD_BBG_TICKERS_SUCCESS = 'LOAD_BBG_TICKERS_SUCCESS';
export const LOAD_BBG_TICKERS_FAILURE = 'LOAD_BBG_TICKERS_FAILURE';
export const INIT_LOAD_BBG_TICKERS_ACTION_STATUS = 'INIT_LOAD_BBG_TICKERS_ACTION_STATUS';

// Clear bbgTickerArray bbgTickerOptions
export const CLEAR_BBG_TICKERS = 'CLEAR_BBG_TICKERS';

// BBG Ticker Select Changed
export const BBG_TICKER_SELECT_CHANGED = 'BBG_TICKER_SELECT_CHANGED';

// Load Personal Trade Field History
export const START_LOAD_TRADE_FIELD_HISTORY = 'START_LOAD_TRADE_FIELD_HISTORY';
export const LOAD_TRADE_FIELD_HISTORY_SUCCESS = 'LOAD_TRADE_FIELD_HISTORY_SUCCESS';
export const LOAD_TRADE_FIELD_HISTORY_FAILURE = 'LOAD_TRADE_FIELD_HISTORY_FAILURE';
export const INIT_LOAD_TRADE_FIELD_HISTORY_ACTION_STATUS = 'INIT_LOAD_TRADE_FIELD_HISTORY_ACTION_STATUS';

// Load ExchangeCode
export const START_LOAD_EXCHANGE_CODE = 'START_LOAD_EXCHANGE_CODE';
export const LOAD_EXCHANGE_CODE_SUCCESS = 'LOAD_EXCHANGE_CODE_SUCCESS';
export const LOAD_EXCHANGE_CODE_FAILURE = 'LOAD_EXCHANGE_CODE_FAILURE';
export const INIT_LOAD_EXCHANGE_CODE_ACTION_STATUS = 'INIT_LOAD_EXCHANGE_CODE_ACTION_STATUS';

// Clear exchageCodeArray exchageCodeOptions
export const CLEAR_EXCHANGE_CODES = 'CLEAR_EXCHANGE_CODES';

// Exchange Code Select Changed
export const EXCHANGE_CODE_SELECT_CHANGED = 'EXCHANGE_CODE_SELECT_CHANGED';

// Open/Reset Trade Request Clone Edit Modal Dialog
export const TRADE_REQUEST_CLONE_EDIT_MODAL_DIALOG_OPEN = 'TRADE_REQUEST_CLONE_EDIT_MODAL_DIALOG_OPEN';
export const TRADE_REQUEST_CLONE_EDIT_MODAL_DIALOG_RESET = 'TRADE_REQUEST_CLONE_EDIT_MODAL_DIALOG_RESET';

// Trade Request Clone Edit Change Input
export const TRADE_REQUEST_CLONE_EDIT_CHANGE_INPUT = 'TRADE_REQUEST_CLONE_EDIT_CHANGE_INPUT';

// load personal account
export const START_LOAD_PERSONAL_ACCOUNT = 'START_LOAD_PERSONAL_ACCOUNT';
export const LOAD_PERSONAL_ACCOUNT_SUCCESS = 'LOAD_PERSONAL_ACCOUNT_SUCCESS';
export const LOAD_PERSONAL_ACCOUNT_FAILURE = 'LOAD_PERSONAL_ACCOUNT_FAILURE';
export const INIT_LOAD_PERSONAL_ACCOUNT_ACTION_STATUS = 'INIT_LOAD_PERSONAL_ACCOUNT_ACTION_STATUS';

// 30天持仓相关预检查
export const START_QUERY_30_DAY_HOLDING_CHECK_INFO = 'START_QUERY_30_DAY_HOLDING_CHECK_INFO';
export const QUERY_30_DAY_HOLDING_CHECK_INFO_SUCCESS = 'QUERY_30_DAY_HOLDING_CHECK_INFO_SUCCESS';
export const QUERY_30_DAY_HOLDING_CHECK_INFO_FAILURE = 'QUERY_30_DAY_HOLDING_CHECK_INFO_FAILURE';
export const RESET_30_DAY_HOLDING_CHECK_INFO = 'RESET_30_DAY_HOLDING_CHECK_INFO';
export const START_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS = 'START_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS';
export const STOP_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS = 'STOP_TRIGGER_30_DAY_HOLDING_CHECK_INFO_STATUS';

// openCheckHoldingsPage
export const OPEN_CHECK_HOLDINGS_PAGE = 'OPEN_CHECK_HOLDINGS_PAGE';
export const RESET_CHECK_HOLDINGS_PAGE = 'RESET_CHECK_HOLDINGS_PAGE';
export const START_QUERY_HOLDINGS_FOR_CURR_ACCOUNT = 'START_QUERY_HOLDINGS_FOR_CURR_ACCOUNT';
export const QUERY_HOLDINGS_FOR_CURR_ACCOUNT_SUCCESS = 'QUERY_HOLDINGS_FOR_CURR_ACCOUNT_SUCCESS';
export const QUERY_HOLDINGS_FOR_CURR_ACCOUNT_FAILURE = 'QUERY_HOLDINGS_FOR_CURR_ACCOUNT_FAILURE';
export const INIT_QUERY_HOLDINGS_FOR_CURR_ACCOUNT_ACTION_STATUS = 'INIT_QUERY_HOLDINGS_FOR_CURR_ACCOUNT_ACTION_STATUS';
export const SET_TRADE_REQUEST_EDIT_DIAGLOG_FORM = 'SET_TRADE_REQUEST_EDIT_DIAGLOG_FORM';

export const CHECK_CAN_DO_PA_SUCCESS = 'CHECK_CAN_DO_PA_SUCCESS';
export const CHECK_CAN_DO_PA_FAILURE = 'CHECK_CAN_DO_PA_FAILURE';

